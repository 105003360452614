import { URL_PORTAL_V2 } from '../../../helpers/url';

const financeiroMenu = [
    {
        header: 'Painel Financeiro',
        path: '/financeiro',
        name: 'Financeiro',
        icon: 'mdi mdi-cash-usd-outline',
        children: [
            {
                name: 'Despesas',
                path: '/despesaaprovada',
            },
            {
                name: 'Tipo Despesas',
                path: '/tipodespesa',
            },
            {
                name: 'Revisão de Reembolso',
                path: `${URL_PORTAL_V2}/drivers-panel/_services/refund-request`,
            },
        ],
    },
];

export const FinanceiroMenu = () => financeiroMenu;
