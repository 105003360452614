import { 
    ALTERARENDPASS_LIST,
    ALTERARENDPASS_LIST_SUCCESS,
    ALTERARENDPASS_LIST_FAILED,
    ALTERARENDPASS_ADD,
    ALTERARENDPASS_ADD_SUCCESS,
    ALTERARENDPASS_ADD_FAILED,
    ALTERARENDPASS_UPDATE,
    ALTERARENDPASS_UPDATE_SUCCESS,
    ALTERARENDPASS_UPDATE_FAILED,
    ALTERARENDPASS_LOAD,
    ALTERARENDPASS_LOAD_SUCCESS,
    ALTERARENDPASS_LOAD_FAILED,
    ALTERARENDPASS_REMOVE,
    ALTERARENDPASS_REMOVE_SUCCESS,
    ALTERARENDPASS_REMOVE_FAILED,
    ALTERARENDPASS_APROVAR,
    ALTERARENDPASS_APROVAR_SUCCESS,
    ALTERARENDPASS_APROVAR_FAILED,
    ALTERARENDPASS_REJEITAR,
    ALTERARENDPASS_REJEITAR_SUCCESS,
    ALTERARENDPASS_REJEITAR_FAILED,
    ALTERARENDPASS_DOWNLOAD,
    ALTERARENDPASS_DOWNLOAD_SUCCESS,
    ALTERARENDPASS_DOWNLOAD_FAILED



} from './constants';

// List
export const alterarEndPassList        = (filter) => ({ type: ALTERARENDPASS_LIST, payload: filter });
export const alterarEndPassListSuccess = (alterarEndPassageiros) => ({ type: ALTERARENDPASS_LIST_SUCCESS, payload: alterarEndPassageiros });
export const alterarEndPassListFailed  = (error) => ({ type: ALTERARENDPASS_LIST_FAILED, payload: error  });

export const alterarEndPassLoad        = (id) => ({ type: ALTERARENDPASS_LOAD, payload: { id } });
export const alterarEndPassLoadSuccess  = (alterarEndPassageiro) => ({ type: ALTERARENDPASS_LOAD_SUCCESS, payload: { alterarEndPassageiro } });
export const alterarEndPassLoadFailed   = (error) => ({ type: ALTERARENDPASS_LOAD_FAILED, payload: error  });

// Add
export const alterarEndPassAdd         = (alterarEndPassageiro) => ({ type: ALTERARENDPASS_ADD, payload: { alterarEndPassageiro } });
export const alterarEndPassAddSuccess  = (alterarEndPassageiro, alterarEndPassageiroStatus) => ({ type: ALTERARENDPASS_ADD_SUCCESS, alterarEndPassageiroStatus: alterarEndPassageiroStatus, payload: { alterarEndPassageiro } });
export const alterarEndPassAddFailed   = (error) => ({ type: ALTERARENDPASS_ADD_FAILED, payload: error  });

// Update
export const alterarEndPassUpdate         = (alterarEndPassageiro) => ({ type: ALTERARENDPASS_UPDATE, payload: { alterarEndPassageiro } });
export const alterarEndPassUpdateSuccess  = (alterarEndPassageiro, alterarEndPassageiroStatus) => ({ type: ALTERARENDPASS_UPDATE_SUCCESS, alterarEndPassageiroStatus: alterarEndPassageiroStatus, payload: { alterarEndPassageiro } });
export const alterarEndPassUpdateFailed   = (error) => ({ type: ALTERARENDPASS_UPDATE_FAILED, payload: error  });

// remove
export const alterarEndPassRemove         = (alterarEndPassageiro) => ({ type: ALTERARENDPASS_REMOVE, payload: { alterarEndPassageiro } });
export const alterarEndPassRemoveSuccess  = (alterarEndPassageiro, alterarEndPassageiroDeletado) => ({ type: ALTERARENDPASS_REMOVE_SUCCESS, alterarEndPassageiroDeletado: alterarEndPassageiroDeletado, payload: { alterarEndPassageiro } });
export const alterarEndPassRemoveFailed   = (error) => ({ type: ALTERARENDPASS_REMOVE_FAILED, payload: error  });

// Update
export const alterarEndPassAprovar         = (id) => ({ type: ALTERARENDPASS_APROVAR, payload: id });
export const alterarEndPassAprovarSuccess  = (alterarEndPassageiro, alterarEndPassageiroStatus) => ({ type: ALTERARENDPASS_APROVAR_SUCCESS, alterarEndPassageiroStatus: alterarEndPassageiroStatus, payload: { alterarEndPassageiro } });
export const alterarEndPassAprovarFailed   = (error) => ({ type: ALTERARENDPASS_APROVAR_FAILED, payload: error  });

// Aprovar files
export const alterarEndPassRejeitar          = (id, motivo) => ({ type: ALTERARENDPASS_REJEITAR, payload: { id, motivo } });
export const alterarEndPassRejeitarSuccess   = (alterarEndPassageiro, alterarEndPassageiroStatus) => ({ type: ALTERARENDPASS_REJEITAR_SUCCESS, alterarEndPassageiroStatus: alterarEndPassageiroStatus, payload: { alterarEndPassageiro } });
export const alterarEndPassRejeitarFailed    = (error) => ({ type: ALTERARENDPASS_REJEITAR_FAILED, payload: error  });


export const alterarEndPassDownload         = (arquivoId, nomeArquivo) => ({ type: ALTERARENDPASS_DOWNLOAD, payload: { arquivoId, nomeArquivo } });
export const alterarEndPassDownloadSuccess  = (reembolsoStatus) => ({ type: ALTERARENDPASS_DOWNLOAD_SUCCESS, reembolsoStatus: reembolsoStatus });
export const alterarEndPassDownloadFailed   = (error) => ({ type: ALTERARENDPASS_DOWNLOAD_FAILED, payload: error  });