import { 
    TIPODESPESA_LIST,
    TIPODESPESA_LIST_SUCCESS,
    TIPODESPESA_LIST_FAILED,
    TIPODESPESA_ADD,
    TIPODESPESA_ADD_SUCCESS,
    TIPODESPESA_ADD_FAILED,
    TIPODESPESA_UPDATE,
    TIPODESPESA_UPDATE_SUCCESS,
    TIPODESPESA_UPDATE_FAILED,
    TIPODESPESA_LOAD,
    TIPODESPESA_LOAD_SUCCESS,
    TIPODESPESA_LOAD_FAILED,
    TIPODESPESA_REMOVE,
    TIPODESPESA_REMOVE_SUCCESS,
    TIPODESPESA_REMOVE_FAILED
} from './constants';

// List
export const tipoDespesaList        = (filter) => ({ type: TIPODESPESA_LIST, payload: filter });
export const tipoDespesaListSuccess = (tipoDespesas) => ({ type: TIPODESPESA_LIST_SUCCESS, payload: tipoDespesas });
export const tipoDespesaListFailed  = (error) => ({ type: TIPODESPESA_LIST_FAILED, payload: error  });

export const tipoDespesaLoad        = (id) => ({ type: TIPODESPESA_LOAD, payload: id });
export const tipoDespesaLoadSuccess  = (tipoDespesa) => ({ type: TIPODESPESA_LOAD_SUCCESS, payload: tipoDespesa });
export const tipoDespesaLoadFailed   = (error) => ({ type: TIPODESPESA_LOAD_FAILED, payload: error  });

// Add
export const tipoDespesaAdd         = (tipoDespesa) => ({ type: TIPODESPESA_ADD, payload: tipoDespesa });
export const tipoDespesaAddSuccess  = (tipoDespesa, tipoDespesaStatus) => ({ type: TIPODESPESA_ADD_SUCCESS, tipoDespesaStatus: tipoDespesaStatus, payload: tipoDespesa });
export const tipoDespesaAddFailed   = (error) => ({ type: TIPODESPESA_ADD_FAILED, payload: error  });

// Update
export const tipoDespesaUpdate         = (tipoDespesa) => ({ type: TIPODESPESA_UPDATE, payload: tipoDespesa });
export const tipoDespesaUpdateSuccess  = (tipoDespesa, tipoDespesaStatus) => ({ type: TIPODESPESA_UPDATE_SUCCESS, tipoDespesaStatus: tipoDespesaStatus, payload: tipoDespesa });
export const tipoDespesaUpdateFailed   = (error) => ({ type: TIPODESPESA_UPDATE_FAILED, payload: error  });

// remove
export const tipoDespesaRemove         = (tipoDespesa) => ({ type: TIPODESPESA_REMOVE, payload: tipoDespesa });
export const tipoDespesaRemoveSuccess  = (tipoDespesa, tipoDespesaDeletado) => ({ type: TIPODESPESA_REMOVE_SUCCESS, tipoDespesaDeletado: tipoDespesaDeletado, payload: tipoDespesa });
export const tipoDespesaRemoveFailed   = (error) => ({ type: TIPODESPESA_REMOVE_FAILED, payload: error  });