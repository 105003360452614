
import { 

    AUDITORIA_LOAD, 
    AUDITORIA_LOAD_SUCCESS, 
    AUDITORIA_LOAD_FAILED, 
    AUDITORIA_LIST, 
    AUDITORIA_LIST_SUCCESS, 
    AUDITORIA_LIST_FAILED, 
    AUDITORIA_ADD, 
    AUDITORIA_ADD_FAILED, 
    AUDITORIA_ADD_SUCCESS, 
    AUDITORIA_REMOVE, 
    AUDITORIA_REMOVE_FAILED, 
    AUDITORIA_REMOVE_SUCCESS, 
    AUDITORIA_UPDATE, 
    AUDITORIA_UPDATE_SUCCESS, 
    AUDITORIA_UPDATE_FAILED,
       
} 
    
from './constants';

const INIT_STATE = {
    auditorias: {},
    auditoria: {},
    loading: false,
    error: null,
    auditoriaStatus: null,
    id: {}
};

const AuditoriaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUDITORIA_LIST:
            return { ...state, loading: true, error: null, auditoriaDeletado: null };
        case AUDITORIA_LIST_SUCCESS:
            return { ...state, auditorias: action.payload, loading: false, error: null, auditoriaDeletado: null };
        case AUDITORIA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, auditoriaDeletado: null };
        case AUDITORIA_LOAD:
            return { ...state, loading: true, error: null, auditoriaStatus: null, auditoriaDeletado: null }
        case AUDITORIA_LOAD_SUCCESS:
            return { ...state, auditoria: action.payload, loading: false, error: null, auditoriaDeletado: null}
        case AUDITORIA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, auditoriaDeletado: null };
        case AUDITORIA_ADD:
            return { ...state, auditoria: action.payload, auditoriaStatus: null, loading: true, error: null, auditoriaDeletado: null}
        case AUDITORIA_ADD_SUCCESS:
            return { ...state, auditoria: action.payload,  auditoriaStatus: "Solicitação criada com sucesso", auditoriaDeletado: null, loading: false, error: null };
        case AUDITORIA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case AUDITORIA_UPDATE:
            return { ...state, auditoria: action.payload, auditoriaStatus: null, loading: true, error: null}
        case AUDITORIA_UPDATE_SUCCESS:
            return { ...state, auditoria: action.payload,  auditoriaStatus: "Solicitação atualizada com sucesso", auditoriaDeletado: null, loading: false, error: null };
        case AUDITORIA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, auditoriaStatus: null };
        case AUDITORIA_REMOVE:
            return { ...state, auditoria: action.payload, auditoriaStatus: null, loading: true, error: null, auditoriaDeletado: null}
        case AUDITORIA_REMOVE_SUCCESS:
            return { ...state, auditoria: {}, auditoriaStatus: null,  auditoriaDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case AUDITORIA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, auditoriaStatus: null, auditoriaDeletado: null };
       
    default:
            return { ...state };
    }
};

export default AuditoriaReducer;