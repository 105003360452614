import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';
import { getLoggedInUser } from "../../../helpers/authUtils";

import {
    faturamentoCalcularSuccess,
    faturamentoCalcularFailed,

    faturaListSuccess,
    faturaListFailed,
    faturaClienteListSuccess,
    faturaClienteListFailed,
    faturaLoadSuccess,
    faturaLoadFailed,
    itemFaturaListSuccess,
    itemFaturaListFailed,
    faturaUpdateFailed,
    faturaUpdateSuccess,
    itemFaturaListAllSuccess,
    itemFaturaListAllFailed,
    itemFaturaAddSuccess,
    itemFaturaAddFailed,
    itemFaturaLoadSuccess,
    itemFaturaLoadFailed,
    itemFaturaUpdateSuccess,
    itemFaturaUpdateFailed,
    itemFaturaRemoveSuccess,
    itemFaturaRemoveFailed,
    faturaRemoveSuccess,
    FaturaRemoveFailed,
    publicarFaturaSuccess,
    faturaListClienteSuccess,
    faturaListClienteFailed,
    aprovarFaturaSuccess,
    aprovarFaturaFailed,
    rejeitarFaturaSuccess,
    rejeitarFaturaFailed,
    faturamentoCalcularFixoSuccess,
    faturamentoCalcularFixoFailed,
    agrupamentoListSuccess,
    agrupamentoListFailed,
    corridasFaturadasListSuccess,
    corridasFaturadasListFailed,
    agrupamentoRemoveSuccess,
    agrupamentoRemoveFailed,
    agrupamentoLoadSuccess,
    agrupamentoLoadFailed,
    agrupamentoClienteLoadSuccess,
    agrupamentoClienteLoadFailed,
    recalcularSuccess,
    recalcularFailed,
    dashFaturamentoSuccess,
    dashFaturamentoFailed,
    dashClienteFaturamentoSuccess,
    dashClienteFaturamentoFailed,
    despesasFaturadasListSuccess,
    despesasFaturadasListFailed
} from './actions'

import { 
    AGRUPAMENTO_LIST,
    AGRUPAMENTO_LOAD,
    AGRUPAMENTO_CLIENTE_LOAD,
    AGRUPAMENTO_REMOVE,
    CORRIDAS_FATURADAS_LIST,
    DASH_FATURAMENTO,
    DASH_CLIENTE_FATURAMENTO,
    DESPESAS_FATURADAS_LIST,
    FATURAMENTO_CALCULAR, 
    FATURAMENTO_CALCULAR_FIXO, 
    FATURA_APROVAR, 
    FATURA_LIST, FATURA_CLIENTE_LIST, FATURA_LIST_CLIENTE, FATURA_LOAD, FATURA_PUBLICAR, FATURA_REJEITAR, FATURA_REMOVE, FATURA_UPDATE, ITEM_FATURA_ADD, ITEM_FATURA_LIST, ITEM_FATURA_LIST_ALL, ITEM_FATURA_LOAD, ITEM_FATURA_REMOVE, ITEM_FATURA_UPDATE, RECALCULAR} from './constants';

function* calcularFaturamento({payload: parametros  }){

    const options = {
        method: 'POST',
        body: JSON.stringify(parametros),
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/calcular', options);
        yield put(faturamentoCalcularSuccess(response));
    } catch (error) {
        yield put(faturamentoCalcularFailed(error.message));
    }    

}

function* calcularFaturamentoFixo({payload: parametros  }){

    const options = {
        method: 'POST',
        body: JSON.stringify(parametros),
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/calcularfixo', options);
        yield put(faturamentoCalcularFixoSuccess(response));
    } catch (error) {
        yield put(faturamentoCalcularFixoFailed(error.message));
    }    

}

/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* faturaLoad({ payload:  id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response =  yield call(fetchJSON, '/central/v1/api/v1/faturamento/' + id, options);
        yield put(faturaLoadSuccess(response));
        yield call(itemFaturaList, { payload: id })
    } catch (error) {
        yield put(faturaLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* faturaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/filter', options);
            yield put(faturaListSuccess(response));
        } catch (error) {
            yield put(faturaListFailed(error.message));
        }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* faturaClienteList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/filter/cliente', options);
            yield put(faturaClienteListSuccess(response));
        } catch (error) {
            yield put(faturaClienteListFailed(error.message));
        }    
}

function* itemFaturaList({ payload: faturamentoId }){

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/${faturamentoId}/itens`, options);
        yield put(itemFaturaListSuccess(response));
    } catch (error) {
        yield put(itemFaturaListFailed(error.message));
    }    

}

function* faturaUpdate({ payload: fatura }){

    const options = {
        method: 'PUT',
        headers: 
        { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
        body: JSON.stringify(fatura)
    };

    try {
        const response =  yield call(fetchJSON, '/central/v1/api/v1/faturamento/' + fatura?.id, options);
        yield put(faturaUpdateSuccess(response));
    } catch (error) {
        yield put(faturaUpdateFailed(error.message));
    }    

}

function* faturaRemove({ payload: faturaId }){
    
    const options = {
        method: 'DELETE',
        headers: 
        { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        }
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/faturamento/' + faturaId, options);
        yield put(faturaRemoveSuccess());
    } catch (error) {
        yield put(FaturaRemoveFailed(error.message));
    }    

}

function* itemFaturaListAll(){

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/itensfarurados/`, options);
        yield put(itemFaturaListAllSuccess(response));
    } catch (error) {
        yield put(itemFaturaListAllFailed(error.message));
    }    

}

export function* itemFaturaAdd({ payload: item }){

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(item)
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/itemfaturamento/`, options);
        yield put(itemFaturaAddSuccess(response));
    } catch (error) {
        yield put(itemFaturaAddFailed(error.message));
    }    

}

export function* itemFaturaLoad({ payload: itemFaturaId }){

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/itemfaturamento/${itemFaturaId}`, options);
        yield put(itemFaturaLoadSuccess(response));
    } catch (error) {
        yield put(itemFaturaLoadFailed(error.message));
    }    

}

export function* itemFaturaUpdate({ payload: item }){
    
    const options = {
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(item)
    };

    try {
        yield call(fetchJSON, `/central/v1/api/v1/itemfaturamento/${item.id}`, options);
        yield put(itemFaturaUpdateSuccess());
        yield call(itemFaturaList, { payload: item.faturamentoId })
    } catch (error) {
        yield put(itemFaturaUpdateFailed(error.message));
    }    

}

export function* itemFaturaRemove({ payload: itemFaturaId }){

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        yield call(fetchJSON, `/central/v1/api/v1/itemfaturamento/${itemFaturaId}`, options);
        yield put(itemFaturaRemoveSuccess());
    } catch (error) {
        yield put(itemFaturaRemoveFailed(error.message));
    }    

}

export function* publicarFatura({ payload: faturaId }){

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/publicar/${faturaId}`, options);
        yield put(publicarFaturaSuccess(response));
        yield call(agrupamentoLoad, { payload: faturaId });
    } catch (error) {
        yield put(publicarFaturaSuccess(error.message));
    }    

}

export function* aprovarFatura({ payload: faturaId }){

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/aprovar/${faturaId}`, options);
        yield put(aprovarFaturaSuccess(response));
        yield call(agrupamentoLoad, { payload: faturaId });
    } catch (error) {
        yield put(aprovarFaturaFailed(error.message));
    }    
}

export function* rejeitarFatura({ payload: { faturaId, motivo } }){

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(motivo)
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/rejeitar/${faturaId}`, options);
        yield put(rejeitarFaturaSuccess(response));
        yield call(agrupamentoLoad, { payload: faturaId });
    } catch (error) {
        yield put(rejeitarFaturaFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* faturaListCliente({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/cliente', options);
        yield put(faturaListClienteSuccess(response));
    } catch (error) {
        yield put(faturaListClienteFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* agrupamentoList({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/agrupamento', options);
        yield put(agrupamentoListSuccess(response));
    } catch (error) {
        yield put(agrupamentoListFailed(error.message));
    }    
}


/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* corridasFatudasList({ payload: itemFaturamentoId }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/corridasFaturadas/' + itemFaturamentoId, options);
        yield put(corridasFaturadasListSuccess(response));
    } catch (error) {
        yield put(corridasFaturadasListFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* despesasFatudasList({ payload: itemFaturamentoId }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/faturamento/despesasFaturadas/' + itemFaturamentoId, options);
        yield put(despesasFaturadasListSuccess(response));
    } catch (error) {
        yield put(despesasFaturadasListFailed(error.message));
    }    
}


export function* agrupamentoRemove({ payload: id }){

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        yield call(fetchJSON, `/central/v1/api/v1/faturamento/agrupamento/${id}`, options);
        yield put(agrupamentoRemoveSuccess(true));
    } catch (error) {
        yield put(agrupamentoRemoveFailed(error.message));
    }    
}

export function* agrupamentoLoad({ payload: id }){

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
       const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/agrupamento/${id}`, options);
        yield put(agrupamentoLoadSuccess(response));
    } catch (error) {
        yield put(agrupamentoLoadFailed(error.message));
    }    
}

export function* agrupamentoClienteLoad({ payload: id }){

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
       const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/agrupamento/${id}/cliente`, options);
        yield put(agrupamentoClienteLoadSuccess(response));
    } catch (error) {
        yield put(agrupamentoClienteLoadFailed(error.message));
    }    
}

export function* recalcular({ payload: id }){

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
       const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/recalcular/${id}`, options);
        yield put(recalcularSuccess("Faturamento recalculado"));
        yield call(agrupamentoLoad, { payload: id });
        yield call(faturaList, { payload: {
            filters: [{
                propertyName: "agrupamentoId",
                filterType: "TEXT",
                value: id,
                comparator: "="
            }]
        } })
    } catch (error) {
        yield put(recalcularFailed(error.message));
    }    
}

export function* dashFaturamento({ payload: id }){

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
       const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/dashboard/${id}`, options);
        yield put(dashFaturamentoSuccess(response));
    } catch (error) {
        yield put(dashFaturamentoFailed(error.message));
    }    
}

export function* dashClienteFaturamento({ payload: id }){

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
       const response = yield call(fetchJSON, `/central/v1/api/v1/faturamento/dashboard/${id}/cliente`, options);
        yield put(dashClienteFaturamentoSuccess(response));
    } catch (error) {
        yield put(dashClienteFaturamentoFailed(error.message));
    }    
}

export function* watchCalcularFaturamento(): any{
    yield takeEvery(FATURAMENTO_CALCULAR, calcularFaturamento);
}

export function* watchCalcularFaturamentoFixo(): any{
    yield takeEvery(FATURAMENTO_CALCULAR_FIXO, calcularFaturamentoFixo);
}

export function* watchFaturaLoad(): any{
    yield takeEvery(FATURA_LOAD, faturaLoad);
}

export function* watchFaturaList(): any {
    yield takeEvery(FATURA_LIST, faturaList);
}

export function* watchFaturaClienteList(): any {
    yield takeEvery(FATURA_CLIENTE_LIST, faturaClienteList);
}

export function* watchItemFaturaList(): any {
    yield takeEvery(ITEM_FATURA_LIST, itemFaturaList);
}

export function* watchFaturaUpdate(): any{
    yield takeEvery(FATURA_UPDATE, faturaUpdate)
}

export function* watchFaturaListAll() : any{
    yield takeEvery(ITEM_FATURA_LIST_ALL, itemFaturaListAll)
}

export function* watchItemFaturaAdd() : any{
    yield takeEvery(ITEM_FATURA_ADD, itemFaturaAdd)
}

export function* watchItemFaturaLoad(): any{
    yield takeEvery(ITEM_FATURA_LOAD, itemFaturaLoad)
}

export function* watchItemFaturaUpdate(): any{
    yield takeEvery(ITEM_FATURA_UPDATE, itemFaturaUpdate)
}

export function* watchItemFaturaRemove(): any{
    yield takeEvery(ITEM_FATURA_REMOVE, itemFaturaRemove);
}

export function* watchFaturaRemove(): any{
    yield takeEvery(FATURA_REMOVE, faturaRemove);
}

export function* watchPublicarFatura(): any{
    yield takeEvery(FATURA_PUBLICAR, publicarFatura)
}

export function* watchAprovarFatura(): any{
    yield takeEvery(FATURA_APROVAR, aprovarFatura)
}

export function* watchRejeitarFatura(): any{
    yield takeEvery(FATURA_REJEITAR, rejeitarFatura)
}

export function* watchFaturaListCliente(): any{
    yield takeEvery(FATURA_LIST_CLIENTE, faturaListCliente);
}

export function* watchAgrupamentoList(): any{
    yield takeEvery(AGRUPAMENTO_LIST, agrupamentoList);
}

export function* watchCorridasFaturadas(): any {
    yield takeEvery(CORRIDAS_FATURADAS_LIST, corridasFatudasList);
}

export function* watchAgrupamentoRemove():any{
    yield takeEvery(AGRUPAMENTO_REMOVE, agrupamentoRemove);
}

export function* watchAgrupamentoLoad(): any{
    yield takeEvery(AGRUPAMENTO_LOAD, agrupamentoLoad);
}

export function* watchAgrupamentoClienteLoad(): any{
    yield takeEvery(AGRUPAMENTO_CLIENTE_LOAD, agrupamentoClienteLoad);
}

export function* watchRecalcular(): any{
    yield takeEvery(RECALCULAR, recalcular);
}

export function* watchDashFaturamento(): any{
    yield takeEvery(DASH_FATURAMENTO, dashFaturamento);
}

export function* watchDashClienteFaturamento(): any{
    yield takeEvery(DASH_CLIENTE_FATURAMENTO, dashClienteFaturamento);
}

export function* watchDespesasFatudasList(): any {
    yield takeEvery(DESPESAS_FATURADAS_LIST, despesasFatudasList);
}

function* faturamento(): any {
    yield all([fork(watchCalcularFaturamento),
        fork(watchFaturaList), 
        fork(watchFaturaLoad),
        fork(watchItemFaturaList),
        fork(watchFaturaClienteList),
        fork(watchFaturaUpdate),
        fork(watchFaturaListAll),
        fork(watchItemFaturaAdd),
        fork(watchItemFaturaLoad),
        fork(watchItemFaturaUpdate),
        fork(watchItemFaturaRemove),
        fork(watchFaturaRemove),
        fork(watchPublicarFatura),
        fork(watchFaturaListCliente),
        fork(watchAprovarFatura),
        fork(watchRejeitarFatura),
        fork(watchCalcularFaturamentoFixo),
        fork(watchAgrupamentoList),
        fork(watchCorridasFaturadas),
        fork(watchAgrupamentoRemove),
        fork(watchAgrupamentoLoad),
        fork(watchAgrupamentoClienteLoad),
        fork(watchRecalcular),
        fork(watchDashFaturamento),
        fork(watchDashClienteFaturamento),
        fork(watchDespesasFatudasList)
    ]);
}

export default faturamento;