/* eslint-disable react/jsx-pascal-case */
import React, {FC} from 'react'

import SectionRegistrations from './registrations'
import SectionReports from './reports'
import SectionServices from './services'

import {
  NavbarMenu_Container,
  NavbarMenu_ContainerLabel,
  Divider,
} from '../../navbar-menu'

const FinancialPanel = () => {
  return (
    <NavbarMenu_Container>
      <NavbarMenu_ContainerLabel>Painel Financeiro</NavbarMenu_ContainerLabel>

      <SectionRegistrations />
      <Divider />

      <SectionServices />
      {/* <Divider /> */}

      {/* <SectionReports />
      <Divider /> */}
    </NavbarMenu_Container>
  )
}

export default FinancialPanel
