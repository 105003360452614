import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { 
        REEMBOLSO_LOAD, 
        REEMBOLSO_LIST, 
        REEMBOLSO_ADD, 
        REEMBOLSO_UPDATE, 
        REEMBOLSO_REMOVE,
        REEMBOLSO_UPLOAD, 
        REEMBOLSO_DOWNLOAD, 
        REEMBOLSO_REMOVEANEXO,
    
    } from './constants';

import {
    reembolsoListSuccess,
    reembolsoListFailed,
    reembolsoAddSuccess,
    reembolsoAddFailed,
    reembolsoUpdateSuccess,
    reembolsoUpdateFailed,
    reembolsoLoadSuccess,
    reembolsoLoadFailed,
    reembolsoRemoveSuccess,
    reembolsoRemoveFailed,
    reembolsoUploadSuccess,
    reembolsoUploadFailed, 
    reembolsoDownloadSuccess, 
    reembolsoDownloadFailed, 
    reembolsoRemoveAnexoSuccess, 
    reembolsoRemoveAnexoFailed,

} from './actions';
//import { formatDate } from '@fullcalendar/core';

import { URL_BASE } from '../../../helpers/url'

/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* reembolsoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/reembolso/' + id, options);
        yield put(reembolsoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(reembolsoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* reembolsoList({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/reembolso/filter', options);
        yield put(reembolsoListSuccess(response));
    } catch (error) {
        yield put(reembolsoListFailed(error.message));
    }
}


/**
 * reembolso
 * @param {*} payload - pageNumber and pageSize
 */
function* reembolsoAdd({ payload: reembolso  }) {

    const options = {
        body: JSON.stringify(reembolso) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/reembolso', options);
        yield put(reembolsoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(reembolsoAddFailed(message));
    }    
}
  

/**
 * reembolso
 * @param {*} payload - pageNumber and pageSize
 */
function* reembolsoUpdate({ payload: reembolso  }) {

    const options = {
        body: JSON.stringify(reembolso) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/reembolso/' + reembolso?.id, options);
        yield put(reembolsoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(reembolsoUpdateFailed(message));
    }    
}

/**
 * reembolso
 * @param {*} payload - pageNumber and pageSize
 */
function* reembolsoRemove({ payload: reembolso  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/reembolso/' + reembolso?.id, options);
        yield put(reembolsoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(reembolsoRemoveFailed(message));
    }    
}

/**
 * reembolso
 * @param {*} reembolsId - ID do Reembolso
 * @param {*} arquivo - Arquivo para upload
 */
function* reembolsoUpload({ payload: { reembolsoId, arquivo }  }) {

    try {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getLoggedInUser().token);

        var formdata = new FormData();
        formdata.append("arquivo", arquivo, arquivo.name);    

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        var response =  yield fetchJSON("v1/reembolso/uploadfile/" + reembolsoId, requestOptions);
        yield put(reembolsoUploadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(reembolsoUploadFailed(message));
    }    
}

/**
 * download de arquivo
 * @param {*} documentoId - ID do Reembolso
 */
function* reembolsoDownload({ payload: { arquivoId, nomeArquivo }  }) {

    try {

        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
        };

        var response =  yield call(fetch, URL_BASE + '/central/v1/api/v1/reembolso/download/' + arquivoId, options);

       response.blob().then(blob => {

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeArquivo);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })

        yield put(reembolsoDownloadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        
        yield put(reembolsoDownloadFailed(message));
    }    
}

/**
 * download de arquivo
 * @param {*} documentoId - ID do Reembolso
 */
function* reembolsoRemoveAnexo({ payload: { arquivoId }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/reembolso/removeanexo/' + arquivoId, options);
        yield put(reembolsoRemoveAnexoSuccess(arquivoId));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(reembolsoRemoveAnexoFailed(message));
    }    
}


export function* watchreembolsoLoad(): any{
    yield takeEvery(REEMBOLSO_LOAD, reembolsoLoad);
}

export function* watchreembolsoList(): any {
    yield takeEvery(REEMBOLSO_LIST, reembolsoList);
}

export function* watchreembolsoAdd(): any {
    yield takeEvery(REEMBOLSO_ADD, reembolsoAdd);
}

export function* watchreembolsoUpload(): any {
    yield takeEvery(REEMBOLSO_UPLOAD, reembolsoUpload);
}

export function* watchreembolsoUpdate(): any {
    yield takeEvery(REEMBOLSO_UPDATE, reembolsoUpdate);
}

export function* watchreembolsoRemove(): any {
    yield takeEvery(REEMBOLSO_REMOVE, reembolsoRemove);
}

export function* watchreembolsoDownload(): any {
    yield takeEvery(REEMBOLSO_DOWNLOAD, reembolsoDownload);
}

export function* watchreembolsoRemoveAnexo(): any {
    yield takeEvery(REEMBOLSO_REMOVEANEXO, reembolsoRemoveAnexo);
}


function* reembolso(): any {
    yield all(
                [
                    fork(watchreembolsoList), 
                    fork(watchreembolsoAdd), 
                    fork(watchreembolsoUpdate), 
                    fork(watchreembolsoLoad), 
                    fork(watchreembolsoRemove), 
                    fork(watchreembolsoUpload), 
                    fork(watchreembolsoDownload), 
                    fork(watchreembolsoRemoveAnexo), 
                  
                ]);
}

export default reembolso;