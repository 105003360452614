import { 
            USUARIO_LIST,
            USUARIO_LIST_SUCCESS,
            USUARIO_LIST_FAILED,
            USUARIO_ADD,
            USUARIO_ADD_SUCCESS,
            USUARIO_ADD_FAILED,
            USUARIO_UPDATE,
            USUARIO_UPDATE_SUCCESS,
            USUARIO_UPDATE_FAILED,
            USUARIO_LOAD,
            USUARIO_LOAD_SUCCESS,
            USUARIO_LOAD_FAILED,
            USUARIO_REMOVE,
            USUARIO_REMOVE_SUCCESS,
            USUARIO_REMOVE_FAILED
        } from './constants';

// List
export const usuarioList        = (filter) => ({ type: USUARIO_LIST, payload: filter,});
export const usuarioListSuccess = (usuarios) => ({ type: USUARIO_LIST_SUCCESS, payload: usuarios });
export const usuarioListFailed  = (error) => ({ type: USUARIO_LIST_FAILED, payload: error });

export const usuarioLoad        = (id) => ({ type: USUARIO_LOAD, payload: { id } });
export const usuarioLoadSuccess  = (usuario) => ({ type: USUARIO_LOAD_SUCCESS, payload: usuario });
export const usuarioLoadFailed   = (error) => ({ type: USUARIO_LOAD_FAILED, payload: error });

// Add
export const usuarioAdd         = (usuario) => ({ type: USUARIO_ADD, payload: usuario });
export const usuarioAddSuccess  = (usuario, usuarioStatus) => ({ type: USUARIO_ADD_SUCCESS, usuarioStatus: usuarioStatus, payload: usuario });
export const usuarioAddFailed   = (error) => ({ type: USUARIO_ADD_FAILED, payload: error });

// Update
export const usuarioUpdate         = (usuario) => ({ type: USUARIO_UPDATE, payload: usuario });
export const usuarioUpdateSuccess  = (usuario, usuarioStatus) => ({ type: USUARIO_UPDATE_SUCCESS, usuarioStatus: usuarioStatus, payload: usuario });
export const usuarioUpdateFailed   = (error) => ({ type: USUARIO_UPDATE_FAILED, payload: error });

// remove
export const usuarioRemove         = (usuario) => ({ type: USUARIO_REMOVE, payload: usuario });
export const usuarioRemoveSuccess  = (usuario, usuarioDeletado) => ({ type: USUARIO_REMOVE_SUCCESS, usuarioDeletado: usuarioDeletado, payload: usuario });
export const usuarioRemoveFailed   = (error) => ({ type: USUARIO_REMOVE_FAILED, payload: error });