import { ESTIMATIVA_CORRIDA, ESTIMATIVA_CORRIDA_SUCCESS, ESTIMATIVA_ESTIMAR_CORRIDA_FAILED } from './constants';

const INIT_STATE = {
    estimativaCorrida: {},
    loading: false,
    erro: null,
};

const EstimativaCorridaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ESTIMATIVA_CORRIDA:
            return { ...state, loading: true, erro: null };
        case ESTIMATIVA_CORRIDA_SUCCESS:
            return { ...state, estimativaCorrida: action.payload, loading: false, erro: null };
        case ESTIMATIVA_ESTIMAR_CORRIDA_FAILED:
            return { ...state, estimativaCorrida: {}, loading: false, erro: action.payload };
        default:
            return { ...state };
    }
};

export default EstimativaCorridaReducer;
