/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Link } from 'react-router-dom';
// import Link from 'next/link'

import { ItemWithoutIcon_Container, ItemWithoutIcon_ItemLabel, ItemWithoutIcon_ItemIcon } from './style';
import theme from '../../../styles/theme';
import { getLoggedInUser } from '../../../helpers/authUtils';

// interface IItemWithoutIcon {
//   link: string
//   label: string
//   disable: boolean
// }

// const initialProps: IItemWithoutIcon = {
//   link: '/',
//   label: '',
//   disable: true
// }
import { allRoutesPath } from '../../../helpers/util';

const IconGoLink = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 16l-6-6h12z" />
            </g>
        </svg>
    );
};
const ItemWithoutIcon = (props) => {
    const paths = allRoutesPath();

    return (
        <>
            {props.link.split('https://').length > 1 ? (
                <Link to={{ pathname: props.link }} target="_parent" style={{ textDecoration: 'none' }} href={props.link}>
                    <ItemWithoutIcon_Container
                        style={{
                            cursor: props.disable ? 'default' : 'cursor',
                            display: paths.includes(props.link) ? 'block' : 'none',
                        }}>
                        <ItemWithoutIcon_ItemIcon style={props.disable ? { background: theme.colors.grey } : {}}>
                            {/* <ItemWithIcon_ItemIcon>{props.children}</ItemWithIcon_ItemIcon> */}
                        </ItemWithoutIcon_ItemIcon>
                        <ItemWithoutIcon_ItemLabel style={props.disable ? { color: theme.colors.grey } : {}}>
                            {props.label}
                        </ItemWithoutIcon_ItemLabel>
                    </ItemWithoutIcon_Container>
                </Link>
            ) : (
                <Link to={props.link} style={{ textDecoration: 'none' }}>
                    <ItemWithoutIcon_Container
                        style={{
                            cursor: props.disable ? 'default' : 'cursor',
                            display: paths.includes(props.link) ? 'block' : 'none',
                        }}>
                        <ItemWithoutIcon_ItemIcon style={props.disable ? { background: theme.colors.grey } : {}}>
                            {/* <ItemWithIcon_ItemIcon>{props.children}</ItemWithIcon_ItemIcon> */}
                        </ItemWithoutIcon_ItemIcon>
                        <ItemWithoutIcon_ItemLabel style={props.disable ? { color: theme.colors.grey } : {}}>
                            {props.label}
                        </ItemWithoutIcon_ItemLabel>
                    </ItemWithoutIcon_Container>
                </Link>
            )}
        </>
    );
};
export default ItemWithoutIcon;
