
import { PARAMETRO_LOAD, PARAMETRO_LOAD_SUCCESS, PARAMETRO_LOAD_FAILED, PARAMETRO_LIST, PARAMETRO_LIST_SUCCESS, PARAMETRO_LIST_FAILED, PARAMETRO_ADD, PARAMETRO_ADD_FAILED, PARAMETRO_ADD_SUCCESS, PARAMETRO_REMOVE, PARAMETRO_REMOVE_FAILED, PARAMETRO_REMOVE_SUCCESS, PARAMETRO_UPDATE, PARAMETRO_UPDATE_SUCCESS, PARAMETRO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    parametros: {},
    parametro: {},
    loading: false,
    error: null,
    parametroStatus: null,
    id: {}
};

const ParametroReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PARAMETRO_LIST:
            return { ...state, loading: true, error: null, parametroDeletado: null };
        case PARAMETRO_LIST_SUCCESS:
            return { ...state, parametros: action.payload, loading: false, error: null, parametroDeletado: null };
        case PARAMETRO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, parametroDeletado: null };
        case PARAMETRO_LOAD:
            return { ...state, loading: true, error: null, parametroStatus: null, parametroDeletado: null }
        case PARAMETRO_LOAD_SUCCESS:
            return { ...state, parametro: action.payload, loading: false, error: null, parametroDeletado: null}
        case PARAMETRO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, parametroDeletado: null };
        case PARAMETRO_ADD:
            return { ...state, parametro: action.payload, parametroStatus: null, loading: true, error: null, parametroDeletado: null}
        case PARAMETRO_ADD_SUCCESS:
            return { ...state, parametro: action.payload,  parametroStatus: "Parametro adicionado com sucesso", parametroDeletado: null, loading: false, error: null };
        case PARAMETRO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PARAMETRO_UPDATE:
            return { ...state, parametro: action.payload, parametroStatus: null, loading: true, error: null}
        case PARAMETRO_UPDATE_SUCCESS:
            return { ...state, parametro: action.payload,  parametroStatus: "Parametro atualizado com sucesso", parametroDeletado: null, loading: false, error: null };
        case PARAMETRO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, parametroStatus: null };
        case PARAMETRO_REMOVE:
            return { ...state, parametro: action.payload, parametroStatus: null, loading: true, error: null, parametroDeletado: null}
        case PARAMETRO_REMOVE_SUCCESS:
            return { ...state, parametro: {}, parametroStatus: null,  parametroDeletado: "Parametro removido com sucesso", loading: false, error: null };
        case PARAMETRO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, parametroStatus: null, parametroDeletado: null };
        default:
            return { ...state };
    }
};

export default ParametroReducer;