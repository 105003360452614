import {
    TAXI_MACHINE_ESTIMAR_CORRIDA,
    TAXI_MACHINE_ESTIMAR_CORRIDA_SUCCESS,
    TAXI_MACHINE_ESTIMAR_CORRIDA_FAILED,
    TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR,
    TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_SUCCESS,
    TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_FAILED,
} from './constants';

export const estimarCorrida = (estimarCorrida) => ({ type: TAXI_MACHINE_ESTIMAR_CORRIDA, payload: estimarCorrida });
export const estimarCorridaSuccess = (sucesso) => ({ type: TAXI_MACHINE_ESTIMAR_CORRIDA_SUCCESS, payload: sucesso });
export const estimarCorridaFailed = (erro) => ({ type: TAXI_MACHINE_ESTIMAR_CORRIDA_FAILED, payload: erro });
export const estimarCorridaValor = (estimarCorridaValor) => ({
    type: TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR,
    payload: estimarCorridaValor,
});
export const estimarCorridaValorSuccess = (sucesso) => ({
    type: TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_SUCCESS,
    payload: sucesso,
});
export const estimarCorridaValorFailed = (erro) => ({ type: TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_FAILED, payload: erro });
