const auditoriaMenu = [
    {
        header: 'Painel auditoria',
        path: '/auditoria',
        name: 'Auditoria',
        icon: ' mdi mdi-file-document-box-check-outline',
        children: [
            { name: 'Horas Online', path: '/monitoramento/horas' },
            { name: 'Não Conformidades', path: '/nao-conformidade' },
            {
                name: 'Documentos vencidos',
                path: '/motorista/documentos'
            }, {
                name: 'Motoristas pendentes',
                path: '/motorista/aprovacoes'
            }, {
                name: 'Disponiblidade',
                path: '/disponibilidade'
            }
        ]
    }
]

export const AuditoriaMenu = () => auditoriaMenu;