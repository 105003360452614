/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
  NavbarMenu_Container,
  NavbarMenu_ContainerLabel,
  Divider,
} from "../../navbar-menu";
import SectionRegistrations from "./registrations";
import SectionServices from "./services";
import SectionReports from "./reports";

const DriversPanel = () => {
  return (
    <NavbarMenu_Container>
      <NavbarMenu_ContainerLabel>
        Painel dos Motoristas
      </NavbarMenu_ContainerLabel>

      <SectionRegistrations />
      <Divider />

      <SectionServices />
      <Divider />

      <SectionReports />
      <Divider />
    </NavbarMenu_Container>
  );
};

export default DriversPanel;
