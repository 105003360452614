import { CORRIDA_REENVIAR, CORRIDA_REENVIAR_FAILED, CORRIDA_REENVIAR_SUCCESS } from '../corrida/constants';
import {
    SOLICITACAO_PARADA_CORRIDA_ADD,
    SOLICITACAO_PARADA_CORRIDA_LOAD,
    SOLICITACAO_PARADA_CORRIDA_UPDATE,
    SOLICITACAO_PARADA_CORRIDA_REMOVE,
    SOLICITACAO_PARADA_CORRIDA_NEW,
    SOLICITACAO_PARADA_CORRIDA_CANCEL,
    SOLICITACAO_PARADA_CORRIDA_LIST,
} from '../solicitacaoParadaCorrida/constants';
import {
    NEW_SOLICITACAO_CORRIDA_ADD,
    NEW_SOLICITACAO_CORRIDA_FAILED,
    NEW_SOLICITACAO_CORRIDA_LIST,
    NEW_SOLICITACAO_CORRIDA_LIST_FAILED,
    NEW_SOLICITACAO_CORRIDA_LIST_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_LOAD,
    NEW_SOLICITACAO_CORRIDA_LOAD_FAILED,
    NEW_SOLICITACAO_CORRIDA_LOAD_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_UPDATE,
    NEW_SOLICITACAO_CORRIDA_UPDATE_FAILED,
    NEW_SOLICITACAO_CORRIDA_UPDATE_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REMOVE,
    NEW_SOLICITACAO_CORRIDA_REMOVE_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REMOVE_FAILED,
    NEW_SOLICITACAO_CORRIDA_APROVAR,
    NEW_SOLICITACAO_CORRIDA_APROVAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_APROVAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_LIBERAR,
    NEW_SOLICITACAO_CORRIDA_LIBERAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_LIBERAR_FAILED,
    NEW_SOLICITACAO_ADD_PARADA,
    NEW_SOLICITACAO_CORRIDA_NEW,
    NEW_SOLICITACAO_CORRIDA_REJEITAR,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_FAILED,
    EVENTOS_SOLICITACAO,
    EVENTOS_SOLICITACAO_SUCCESS,
    EVENTOS_SOLICITACAO_FAILED,
    SHOW_MODAL_EVENTOS_NEW_SOLICITACAO,
    NEW_SOLICITACAO_CORRIDA_REENVIAR,
    NEW_SOLICITACAO_CORRIDA_REENVIAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REENVIAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_REABRIR,
    NEW_SOLICITACAO_CORRIDA_REABRIR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REABRIR_FAILED,
    NEW_SOLICITACAO_CORRIDA_FINALIZAR,
    NEW_SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_FINALIZAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_CANCELAR,
    NEW_SOLICITACAO_CORRIDA_CANCELAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_CANCELAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO,
    NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_FAILED,
    NEW_SOLICITACAO_CORRIDA_EM_FILA,
    NEW_SOLICITACAO_CORRIDA_EM_FILA_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_EM_FILA_FAILED,
    HANDLE_NEW_REFERENCIA_PARTIDA,
    NEW_SOLICITACAO_CORRIDA_ENCERRAR,
    NEW_SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_ENCERRAR_FAILED,
} from './constants';

const INIT_STATE = {
    solicitacoes: {},
    paradas: [],
    solicitacao: {
        solicitacoesParadas: [],
    },
    parada: {},
    success: null,
    erro: null,
    loading: false,
    solicitacaoId: '',
    modal: false,
    eventos: [],
    modalEventos: false,
    aprovadoSuccess: false,
    referenciaPartida: '',
    newParada: false,
    indexLoad: 0,
};

const NewSolicitacaoCorridaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NEW_SOLICITACAO_CORRIDA_ADD:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_SUCCESS:
            return {
                ...state,
                loading: false,
                solicitacaoId: action.payload.solicitacao?.id,
                success: action.payload.success,
                erro: null,
                solicitacao: action.payload.solicitacao,
                paradas: action.payload.solicitacao.solicitacoesParadas,
            };
        case NEW_SOLICITACAO_CORRIDA_FAILED:
            return {
                ...state,
                loading: false,
                success: null,
                erro: action.payload ?? 'Ocorreu algum erro, tente novamente mais tarde',
            };

        case NEW_SOLICITACAO_CORRIDA_LIST:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_LIST_SUCCESS:
            return { ...state, loading: false, solicitacoes: action.payload.solicitacoes, erro: null };
        case NEW_SOLICITACAO_CORRIDA_LIST_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_LOAD:
            return {
                ...state,
                solicitacao: {},
                loading: true,
                success: null,
                erro: null,
                solicitacaoId: action.payload,
            };
        case NEW_SOLICITACAO_CORRIDA_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
                erro: null,
                solicitacao: action.payload.solicitacao,
                paradas: action.payload.solicitacao.solicitacoesParadas,
                referenciaPartida: action.payload.solicitacao.referenciaPartida,
            };
        case NEW_SOLICITACAO_CORRIDA_LOAD_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_UPDATE:
            return { ...state, loading: true, success: null, erro: null, solicitacao: action.payload };
        case NEW_SOLICITACAO_CORRIDA_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_UPDATE_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_REMOVE:
            return { ...state, loading: true, success: null, erro: null, solicitacaoId: action.payload };
        case NEW_SOLICITACAO_CORRIDA_REMOVE_SUCCESS:
            return { ...state, loading: false, success: action.payload, erro: null, solicitacao: {}, paradas: [] };
        case NEW_SOLICITACAO_CORRIDA_REMOVE_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_APROVAR:
            return { ...state, loading: true, aprovadoSuccess: null, erro: null, solicitacaoId: action.payload };
        case NEW_SOLICITACAO_CORRIDA_APROVAR_SUCCESS:
            return {
                ...state,
                loading: false,
                aprovadoSuccess: action.payload.success,
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_APROVAR_FAILED:
            return { ...state, loading: false, aprovadoSuccess: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_LIBERAR:
            return { ...state, loading: true, success: null, erro: null, solicitacaoId: action.payload };
        case NEW_SOLICITACAO_CORRIDA_LIBERAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Solicitação liberada com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_LIBERAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_REJEITAR:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_REJEITAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Solicitação rejeitada com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_REJEITAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Solicitação rejeitada e encerrada com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_CANCELAR:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_CANCELAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Corrida cancelada com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_CANCELAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };
        
        case NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Solicitação de cancelamento feita com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Solicitação rejeitada e encerrada com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_FINALIZAR:
            return { ...state, loading: true, success: null, erro: null };
        case NEW_SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Corrida finalizada com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_FINALIZAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case CORRIDA_REENVIAR:
            return { ...state, loading: true, success: null, erro: null };
        case CORRIDA_REENVIAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Corrida reenviada com sucesso',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case CORRIDA_REENVIAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        // controle das paradas
        case SOLICITACAO_PARADA_CORRIDA_ADD:
            // if (
            //     !state.paradas.find(
            //         (x) => x.tipoParada === action.payload.tipoParada && x.passageiroId === action.payload.passageiroId
            //     )
            // ) {
            return { ...state, modal: false, paradas: [...state.paradas, action.payload], newParada: false };
        // } else {
        //     return { ...state, modal: false };
        // }

        case SOLICITACAO_PARADA_CORRIDA_LOAD:
            var indexLoad = state.paradas.findIndex((item) => item == action.payload);
            return { ...state, modal: true, parada: action.payload, newParada: false, indexLoad: indexLoad };

        case SOLICITACAO_PARADA_CORRIDA_UPDATE:
            state.paradas[state.indexLoad] = action.payload;

            return { ...state, modal: false, newParada: false };

        case SOLICITACAO_PARADA_CORRIDA_REMOVE:
            return {
                ...state,
                modal: false,
                paradas: [...state.paradas.filter((item, index) => index !== state.indexLoad)],
                newParada: false,
            };

        case SOLICITACAO_PARADA_CORRIDA_NEW:
            return { ...state, modal: true, parada: {}, newParada: true };

        case SOLICITACAO_PARADA_CORRIDA_CANCEL:
            return { ...state, parada: {}, modal: false, newParada: false };

        case NEW_SOLICITACAO_CORRIDA_NEW:
            return { ...state, paradas: [], solicitacao: {}, solicitacaoId: '', referenciaPartida: '' };

        case EVENTOS_SOLICITACAO:
            return { ...state, eventos: [], modalEventos: false, loading: true };

        case EVENTOS_SOLICITACAO_SUCCESS:
            return { ...state, eventos: action.payload, modalEventos: true, loading: false };

        case EVENTOS_SOLICITACAO_FAILED:
            return { ...state, erro: action.payload, modalEventos: false, loading: false };

        case SHOW_MODAL_EVENTOS_NEW_SOLICITACAO:
            return { ...state, modalEventos: !state.modalEventos };

        case NEW_SOLICITACAO_CORRIDA_REENVIAR:
            return { ...state, loading: true, success: null, erro: null, solicitacaoId: action.payload };
        case NEW_SOLICITACAO_CORRIDA_REENVIAR_SUCCESS:
            return { ...state, loading: false, success: action.payload, erro: null, solicitacao: {}, paradas: [] };
        case NEW_SOLICITACAO_CORRIDA_REENVIAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_REABRIR:
            return { ...state, loading: true, success: null, erro: null, solicitacaoId: action.payload };
        case NEW_SOLICITACAO_CORRIDA_REABRIR_SUCCESS:
            return { ...state, loading: false, success: action.payload, erro: null, solicitacao: {}, paradas: [] };
        case NEW_SOLICITACAO_CORRIDA_REABRIR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case NEW_SOLICITACAO_CORRIDA_EM_FILA:
            return { ...state, loading: true, success: null, erro: null, solicitacaoId: action.payload };
        case NEW_SOLICITACAO_CORRIDA_EM_FILA_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Solicitação colocada em fila',
                erro: null,
                solicitacao: {},
                paradas: [],
            };
        case NEW_SOLICITACAO_CORRIDA_EM_FILA_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        case HANDLE_NEW_REFERENCIA_PARTIDA:
            return { ...state, referenciaPartida: action.payload };

        case SOLICITACAO_PARADA_CORRIDA_LIST:
            return { ...state, paradas: [...action.payload] };

        case NEW_SOLICITACAO_CORRIDA_ENCERRAR:
            return { ...state, loading: true, success: null, erro: null, solicitacaoId: action.payload };
        case NEW_SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS:
            return { ...state, loading: false, success: action.payload, erro: null, solicitacao: {}, paradas: [] };
        case NEW_SOLICITACAO_CORRIDA_ENCERRAR_FAILED:
            return { ...state, loading: false, success: null, erro: action.payload };

        default:
            return { ...state };
    }
};

export default NewSolicitacaoCorridaReducer;
