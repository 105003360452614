const faturamentoMenu = [
    {
        header: 'Painel Faturamento',
        path: '/faturamento',
        name: 'Faturamento',
        icon:'uil-calculator-alt',
        children: [
            {
                name: 'Calcular Faturamento',
                path: '/calcularfaturamento',
                children: [{
                        name: 'Rede',
                        path: '/faturamento/calcularfaturamento'
                    },{
                        name: 'Fixo',
                        path: '/faturamento/calcularfaturamentofixo'
                    }]
            },
            {
                name: 'Faturas',
                path: '/faturamento/fatura'
            },
            {
                name: 'Faturas internas',
                path: '/faturamento/fatura-interna'
            },
            {
                name: 'Contratos',
                path: '/contrato'
            },
            {
                name: 'Disponibilidade',
                path: '/disponibilidade'
            }
        ]
    }
]

export const FaturamentoMenu = () => faturamentoMenu;