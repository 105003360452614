import { 
    CONTRATO_LIST,
    CONTRATO_LIST_SUCCESS,
    CONTRATO_LIST_FAILED,
    CONTRATO_ADD,
    CONTRATO_ADD_SUCCESS,
    CONTRATO_ADD_FAILED,
    CONTRATO_UPDATE,
    CONTRATO_UPDATE_SUCCESS,
    CONTRATO_UPDATE_FAILED,
    CONTRATO_LOAD,
    CONTRATO_LOAD_SUCCESS,
    CONTRATO_LOAD_FAILED,
    CONTRATO_REMOVE,
    CONTRATO_REMOVE_SUCCESS,
    CONTRATO_REMOVE_FAILED,
    CONTRATO_VEICULO_ADD,
    CONTRATO_VEICULO_REMOVE,
    CONTRATO_TIPO_ADD,
    CONTRATO_TIPO_REMOVE,
    CALCULAR_DISPONIBILIDADE,
    CALCULAR_DISPONIBILIDADE_SUCCESS,
    CALCULAR_DISPONIBILIDADE_FAILED
} from './constants';

// List
export const contratoList        = (filter) => ({ type: CONTRATO_LIST, payload: filter});
export const contratoListSuccess = (contratos) => ({ type: CONTRATO_LIST_SUCCESS, payload: contratos });
export const contratoListFailed  = (error) => ({ type: CONTRATO_LIST_FAILED, payload: error });

export const contratoLoad        = (id) => ({ type: CONTRATO_LOAD, payload: id });
export const contratoLoadSuccess  = (contrato) => ({ type: CONTRATO_LOAD_SUCCESS, payload: contrato });
export const contratoLoadFailed   = (error) => ({ type: CONTRATO_LOAD_FAILED, payload: error });

// Add
export const contratoAdd         = (contrato) => ({ type: CONTRATO_ADD, payload: contrato });
export const contratoAddSuccess  = (contrato, contratoStatus) => ({ type: CONTRATO_ADD_SUCCESS, contratoStatus: contratoStatus, payload: contrato });
export const contratoAddFailed   = (error) => ({ type: CONTRATO_ADD_FAILED, payload: error });

// Update
export const contratoUpdate         = (contrato) => ({ type: CONTRATO_UPDATE, payload: contrato });
export const contratoUpdateSuccess  = (contrato, contratoStatus) => ({ type: CONTRATO_UPDATE_SUCCESS, contratoStatus: contratoStatus, payload: contrato});
export const contratoUpdateFailed   = (error) => ({ type: CONTRATO_UPDATE_FAILED, payload: error });

// remove
export const contratoRemove         = (contrato) => ({ type: CONTRATO_REMOVE, payload: contrato });
export const contratoRemoveSuccess  = (contrato, contratoDeletado) => ({ type: CONTRATO_REMOVE_SUCCESS, contratoDeletado: contratoDeletado, payload: contrato });
export const contratoRemoveFailed   = (error) => ({ type: CONTRATO_REMOVE_FAILED, payload: error });

export const itemContratoAdd     = (item) => ({ type: CONTRATO_VEICULO_ADD, payload: item });
export const itemContratoRemove  = (item) => ({ type: CONTRATO_VEICULO_REMOVE, payload: item });

export const contratoTipoAdd        = (item) => ({ type: CONTRATO_TIPO_ADD, payload: item });
export const contratoTipoRemove     = (item) => ({ type: CONTRATO_TIPO_REMOVE, payload: item });

export const calcularDisponibilidade = (contratoId) => ({ type: CALCULAR_DISPONIBILIDADE, payload: contratoId });
export const calcularDisponibilidadeSuccess = (success) => ({ type: CALCULAR_DISPONIBILIDADE_SUCCESS, payload: success });
export const calcularDisponibilidadeFailed = (error) => ({ type: CALCULAR_DISPONIBILIDADE_FAILED, payload: error });