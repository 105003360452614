/* VEICULO */
export const MALHA_LIST           = 'MALHA_LIST';
export const MALHA_LIST_SUCCESS   = 'MALHA_LIST_SUCCESS';
export const MALHA_LIST_FAILED    = 'MALHA_LIST_FAILED';
export const MALHA_ADD            = 'MALHA_ADD';
export const MALHA_ADD_SUCCESS    = 'MALHA_ADD_SUCCESS';
export const MALHA_ADD_FAILED     = 'MALHA_ADD_FAILED';
export const MALHA_UPDATE         = 'MALHA_UPDATE';
export const MALHA_UPDATE_SUCCESS = 'MALHA_UPDATE_SUCCESS';
export const MALHA_UPDATE_FAILED  = 'MALHA_UPDATE_FAILED';
export const MALHA_REMOVE         = 'MALHA_REMOVE';
export const MALHA_REMOVE_SUCCESS = 'MALHA_REMOVE_SUCCESS';
export const MALHA_REMOVE_FAILED  = 'MALHA_REMOVE_FAILED';
export const MALHA_LOAD           = 'MALHA_LOAD'
export const MALHA_LOAD_SUCCESS   = 'MALHA_LOAD_SUCCESS';
export const MALHA_LOAD_FAILED    = 'MALHA_LOAD_FAILED';