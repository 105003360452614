import { USUARIO_LOAD, USUARIO_LOAD_SUCCESS, USUARIO_LOAD_FAILED, USUARIO_LIST, USUARIO_LIST_SUCCESS, USUARIO_LIST_FAILED, USUARIO_ADD, USUARIO_ADD_FAILED, USUARIO_ADD_SUCCESS, USUARIO_REMOVE, USUARIO_REMOVE_FAILED, USUARIO_REMOVE_SUCCESS, USUARIO_UPDATE, USUARIO_UPDATE_SUCCESS, USUARIO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    usuarios: {},
    usuario: {},
    loading: false,
    error: null,
    usuarioStatus: null,
    id: {}
};

const UsuarioReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USUARIO_LIST:
            return { ...state, loading: true, error: null, usuarioDeletado: null, usuarioStatus: null };
        case USUARIO_LIST_SUCCESS:
            return { ...state, usuarios: action.payload, loading: false, error: null, usuarioDeletado: null, usuarioStatus: null };
        case USUARIO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, usuarioDeletado: null, usuarioStatus: null };
        case USUARIO_LOAD:
            return { ...state, usuario:{}, loading: true, error: null, usuarioDeletado: null, usuarioStatus: null }
        case USUARIO_LOAD_SUCCESS:
            return { ...state, usuario: action.payload, loading: false, error: null, usuarioDeletado: null, usuarioStatus: null}
        case USUARIO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, usuarioDeletado: null, usuarioStatus: null };
        case USUARIO_ADD:
            return { ...state, usuario: action.payload, loading: true, error: null, usuarioDeletado: null, usuarioStatus: null}
        case USUARIO_ADD_SUCCESS:
            return { ...state, usuario: action.payload,  usuarioStatus: "Usuário adicionado com sucesso", usuarioDeletado: null, loading: false, error: null };
        case USUARIO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false, usuarioStatus: null };
        case USUARIO_UPDATE:
            return { ...state, usuario: action.payload, loading: true, error: null, usuarioStatus: null}
        case USUARIO_UPDATE_SUCCESS:
            return { ...state, usuario: action.payload,  usuarioStatus: "Usuário atualizado com sucesso", usuarioDeletado: null, loading: false, error: null };
        case USUARIO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, usuarioStatus: null };
        case USUARIO_REMOVE:
            return { ...state, usuario: action.payload, loading: true, error: null, usuarioDeletado: null}
        case USUARIO_REMOVE_SUCCESS:
            return { ...state, usuario: {}, usuarioStatus: null,  usuarioDeletado: "Usuário removido com sucesso", loading: false, error: null };
        case USUARIO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, usuarioStatus: null, usuarioDeletado: null };
        default:
            return { ...state };
    }
};

export default UsuarioReducer;