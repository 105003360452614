import { 
    VEICULOMARCA_LIST,
    VEICULOMARCA_LIST_SUCCESS,
    VEICULOMARCA_LIST_FAILED,
    VEICULOMARCA_ADD,
    VEICULOMARCA_ADD_SUCCESS,
    VEICULOMARCA_ADD_FAILED,
    VEICULOMARCA_UPDATE,
    VEICULOMARCA_UPDATE_SUCCESS,
    VEICULOMARCA_UPDATE_FAILED,
    VEICULOMARCA_LOAD,
    VEICULOMARCA_LOAD_SUCCESS,
    VEICULOMARCA_LOAD_FAILED,
    VEICULOMARCA_REMOVE,
    VEICULOMARCA_REMOVE_SUCCESS,
    VEICULOMARCA_REMOVE_FAILED
} from './constants';

// List
export const veiculoMarcaList        = (filter) => ({ type: VEICULOMARCA_LIST, payload: filter });
export const veiculoMarcaListSuccess = (veiculoMarcas) => ({ type: VEICULOMARCA_LIST_SUCCESS, payload: veiculoMarcas });
export const veiculoMarcaListFailed  = (error) => ({ type: VEICULOMARCA_LIST_FAILED, payload: error  });

export const veiculoMarcaLoad        = (id) => ({ type: VEICULOMARCA_LOAD, payload: { id } });
export const veiculoMarcaLoadSuccess  = (veiculoMarca) => ({ type: VEICULOMARCA_LOAD_SUCCESS, payload: { veiculoMarca } });
export const veiculoMarcaLoadFailed   = (error) => ({ type: VEICULOMARCA_LOAD_FAILED, payload: error  });

// Add
export const veiculoMarcaAdd         = (veiculoMarca) => ({ type: VEICULOMARCA_ADD, payload: { veiculoMarca } });
export const veiculoMarcaAddSuccess  = (veiculoMarca, veiculoMarcaStatus) => ({ type: VEICULOMARCA_ADD_SUCCESS, veiculoMarcaStatus: veiculoMarcaStatus, payload: { veiculoMarca } });
export const veiculoMarcaAddFailed   = (error) => ({ type: VEICULOMARCA_ADD_FAILED, payload: error  });

// Update
export const veiculoMarcaUpdate         = (veiculoMarca) => ({ type: VEICULOMARCA_UPDATE, payload: { veiculoMarca } });
export const veiculoMarcaUpdateSuccess  = (veiculoMarca, veiculoMarcaStatus) => ({ type: VEICULOMARCA_UPDATE_SUCCESS, veiculoMarcaStatus: veiculoMarcaStatus, payload: { veiculoMarca } });
export const veiculoMarcaUpdateFailed   = (error) => ({ type: VEICULOMARCA_UPDATE_FAILED, payload: error  });

// remove
export const veiculoMarcaRemove         = (veiculoMarca) => ({ type: VEICULOMARCA_REMOVE, payload: { veiculoMarca } });
export const veiculoMarcaRemoveSuccess  = (veiculoMarca, veiculoMarcaDeletado) => ({ type: VEICULOMARCA_REMOVE_SUCCESS, veiculoMarcaDeletado: veiculoMarcaDeletado, payload: { veiculoMarca } });
export const veiculoMarcaRemoveFailed   = (error) => ({ type: VEICULOMARCA_REMOVE_FAILED, payload: error  });