/* VEICULO */
export const FERIADO_LIST           = 'FERIADO_LIST';
export const FERIADO_LIST_SUCCESS   = 'FERIADO_LIST_SUCCESS';
export const FERIADO_LIST_FAILED    = 'FERIADO_LIST_FAILED';
export const FERIADO_ADD            = 'FERIADO_ADD';
export const FERIADO_ADD_SUCCESS    = 'FERIADO_ADD_SUCCESS';
export const FERIADO_ADD_FAILED     = 'FERIADO_ADD_FAILED';
export const FERIADO_UPDATE         = 'FERIADO_UPDATE';
export const FERIADO_UPDATE_SUCCESS = 'FERIADO_UPDATE_SUCCESS';
export const FERIADO_UPDATE_FAILED  = 'FERIADO_UPDATE_FAILED';
export const FERIADO_REMOVE         = 'FERIADO_REMOVE';
export const FERIADO_REMOVE_SUCCESS = 'FERIADO_REMOVE_SUCCESS';
export const FERIADO_REMOVE_FAILED  = 'FERIADO_REMOVE_FAILED';
export const FERIADO_LOAD           = 'FERIADO_LOAD'
export const FERIADO_LOAD_SUCCESS   = 'FERIADO_LOAD_SUCCESS';
export const FERIADO_LOAD_FAILED    = 'FERIADO_LOAD_FAILED';