
import { 
    ATUALIZACAODOCUMENTO_LOAD, 
    ATUALIZACAODOCUMENTO_LOAD_SUCCESS, 
    ATUALIZACAODOCUMENTO_LOAD_FAILED, 
    ATUALIZACAODOCUMENTO_LIST, 
    ATUALIZACAODOCUMENTO_LIST_SUCCESS, 
    ATUALIZACAODOCUMENTO_LIST_FAILED, 
    ATUALIZACAODOCUMENTO_ADD, 
    ATUALIZACAODOCUMENTO_ADD_FAILED,
    ATUALIZACAODOCUMENTO_ADD_SUCCESS, 
    ATUALIZACAODOCUMENTO_REMOVE, 
    ATUALIZACAODOCUMENTO_REMOVE_FAILED, 
    ATUALIZACAODOCUMENTO_REMOVE_SUCCESS, 
    ATUALIZACAODOCUMENTO_UPDATE, 
    ATUALIZACAODOCUMENTO_UPDATE_SUCCESS, 
    ATUALIZACAODOCUMENTO_UPDATE_FAILED ,
    ATUALIZACAODOCUMENTO_UPLOAD,
    ATUALIZACAODOCUMENTO_UPLOAD_SUCCESS,
    ATUALIZACAODOCUMENTO_UPLOAD_FAILED,
    ATUALIZACAODOCUMENTO_DOWNLOAD,
    ATUALIZACAODOCUMENTO_DOWNLOAD_SUCCESS,
    ATUALIZACAODOCUMENTO_DOWNLOAD_FAILED,
    ATUALIZACAODOCUMENTO_REMOVEANEXO,
    ATUALIZACAODOCUMENTO_REMOVEANEXO_SUCCESS,
    ATUALIZACAODOCUMENTO_REMOVEANEXO_FAILED
} from './constants';

const INIT_STATE = {
    atualizacaoDocumentos: {},
    atualizacaoDocumento: {},
    loading: false,
    error: null,
    atualizacaoDocumentoStatus: null,
    id: {}
};

const AtualizacaoDocumentoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ATUALIZACAODOCUMENTO_LIST:
            return { ...state, atualizacaoDocumento: {}, loading: true, error: null, atualizacaoDocumentoDeletado: null };
        case ATUALIZACAODOCUMENTO_LIST_SUCCESS:
            return { ...state, atualizacaoDocumentos: action.payload, loading: false, error: null, atualizacaoDocumentoDeletado: null };
        case ATUALIZACAODOCUMENTO_LIST_FAILED:
            return { ...state, atualizacaoDocumento: {}, error: action.payload, loading: false, atualizacaoDocumentoDeletado: null };
        case ATUALIZACAODOCUMENTO_LOAD:
            return { ...state, atualizacaoDocumento: {}, loading: true, error: null, atualizacaoDocumentoStatus: null, atualizacaoDocumentoDeletado: null }
        case ATUALIZACAODOCUMENTO_LOAD_SUCCESS:
            return { ...state, atualizacaoDocumento: action.payload, loading: false, error: null, atualizacaoDocumentoDeletado: null}
        case ATUALIZACAODOCUMENTO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, atualizacaoDocumentoDeletado: null };
        case ATUALIZACAODOCUMENTO_ADD:
            return { ...state, atualizacaoDocumento: action.payload, atualizacaoDocumentoStatus: null, loading: true, error: null, atualizacaoDocumentoDeletado: null}
        case ATUALIZACAODOCUMENTO_ADD_SUCCESS:
            return { ...state, atualizacaoDocumento: action.payload,  atualizacaoDocumentoStatus: "Solicitação criada com sucesso", atualizacaoDocumentoDeletado: null, loading: false, error: null };
        case ATUALIZACAODOCUMENTO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ATUALIZACAODOCUMENTO_UPDATE:
            return { ...state, atualizacaoDocumento: action.payload, atualizacaoDocumentoStatus: null, loading: true, error: null}
        case ATUALIZACAODOCUMENTO_UPDATE_SUCCESS:
            return { ...state, atualizacaoDocumento: action.payload,  atualizacaoDocumentoStatus: "Solicitação atualizada com sucesso", atualizacaoDocumentoDeletado: null, loading: false, error: null };
        case ATUALIZACAODOCUMENTO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, atualizacaoDocumentoStatus: null };
        case ATUALIZACAODOCUMENTO_REMOVE:
            return { ...state, atualizacaoDocumento: action.payload, atualizacaoDocumentoStatus: null, loading: true, error: null, atualizacaoDocumentoDeletado: null}
        case ATUALIZACAODOCUMENTO_REMOVE_SUCCESS:
            return { ...state, atualizacaoDocumento: {}, atualizacaoDocumentoStatus: null,  atualizacaoDocumentoDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case ATUALIZACAODOCUMENTO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, atualizacaoDocumentoStatus: null, atualizacaoDocumentoDeletado: null };
        case ATUALIZACAODOCUMENTO_UPLOAD:
            return { ...state, atualizacaoDocumentoStatus: null, loading: true, error: null, atualizacaoDocumentoDeletado: null}
        case ATUALIZACAODOCUMENTO_UPLOAD_SUCCESS:
            return { ...state, atualizacaoDocumento: action.payload,  atualizacaoDocumentoStatus: "Arquivo adicionado com sucesso", atualizacaoDocumentoDeletado: null, loading: false, error: null };
        case ATUALIZACAODOCUMENTO_UPLOAD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ATUALIZACAODOCUMENTO_DOWNLOAD:
            return { ...state, atualizacaoDocumentoStatus: null, loading: true, error: null, atualizacaoDocumentoDeletado: null}
        case ATUALIZACAODOCUMENTO_DOWNLOAD_SUCCESS:
            return { ...state, atualizacaoDocumento: action.payload,  atualizacaoDocumentoStatus: "Download enviado", atualizacaoDocumentoDeletado: null, loading: false, error: null };
        case ATUALIZACAODOCUMENTO_DOWNLOAD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ATUALIZACAODOCUMENTO_REMOVEANEXO:
            return { ...state, atualizacaoDocumentoStatus: null, loading: true, error: null, atualizacaoDocumentoDeletado: null}
        case ATUALIZACAODOCUMENTO_REMOVEANEXO_SUCCESS:
            let atualizacaoDocumentoNew = state.atualizacaoDocumento;
            const itemIndexRemove = atualizacaoDocumentoNew.atualizacaoDocumento.solicitacaoAnexos.findIndex(x => x.id === action.payload);
            atualizacaoDocumentoNew.atualizacaoDocumento.solicitacaoAnexos.splice(itemIndexRemove, 1);
            return { ...state, atualizacaoDocumento: atualizacaoDocumentoNew, atualizacaoDocumentoStatus: "Anexo removido com sucesso", atualizacaoDocumentoDeletado: null, loading: false, error: null };
        case ATUALIZACAODOCUMENTO_REMOVEANEXO_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default AtualizacaoDocumentoReducer;