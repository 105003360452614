/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';

import SectionRegistrations from './registrations';
import SectionReports from './reports';
import SectionServices from './services';
import SectionDashBoards from './dashboards';

import { NavbarMenu_Container, NavbarMenu_ContainerLabel, Divider } from '../../navbar-menu';
import { getLoggedInUser } from '../../../../helpers/authUtils';

const permissions = getLoggedInUser()?.usuario?.tipoUsuario ?? [];

const WorkOrderPanel = () => {
    return (
        <NavbarMenu_Container>
            <NavbarMenu_ContainerLabel>Painel de Ordem de Serviço</NavbarMenu_ContainerLabel>

            {(permissions.includes('GestorCentral') || permissions.includes('Administrador')) && (
                <>
                    <SectionRegistrations />
                    <Divider />
                </>
            )}

            {(permissions.includes('GestorCentral') ||
                permissions.includes('GestorCliente') ||
                permissions.includes('OperadorCentral') ||
                permissions.includes('OperadorCliente') ||
                permissions.includes('Auditor')) && (
                <>
                    <SectionServices />
                    <Divider />
                </>
            )}

            {(permissions.includes('GestorCentral') || permissions.includes('GestorCliente')) && (
                <>
                    <SectionDashBoards />
                    <Divider />
                </>
            )}

            {(permissions.includes('GestorCentral') ||
                permissions.includes('GestorCliente') ||
                permissions.includes('Auditor') ||
                permissions.includes('Financeiro')) && (
                <>
                    <SectionReports />
                    <Divider />
                </>
            )}
        </NavbarMenu_Container>
    );
};

export default WorkOrderPanel;
