import { 
    OPERACAO_LIST,
    OPERACAO_LIST_SUCCESS,
    OPERACAO_LIST_FAILED,
    OPERACAO_ADD,
    OPERACAO_ADD_SUCCESS,
    OPERACAO_ADD_FAILED,
    OPERACAO_UPDATE,
    OPERACAO_UPDATE_SUCCESS,
    OPERACAO_UPDATE_FAILED,
    OPERACAO_LOAD,
    OPERACAO_LOAD_SUCCESS,
    OPERACAO_LOAD_FAILED,
    OPERACAO_REMOVE,
    OPERACAO_REMOVE_SUCCESS,
    OPERACAO_REMOVE_FAILED,
    OPERACAO_DETALHE_LOAD,
    OPERACAO_DETALHE_NEW,
    OPERACAO_DETALHE_ADD,
    OPERACAO_DETALHE_ADD_SUCCESS,
    OPERACAO_DETALHE_ADD_FAILED,
    OPERACAO_DETALHE_UPDATE,
    OPERACAO_DETALHE_UPDATE_SUCCESS,
    OPERACAO_DETALHE_UPDATE_FAILED,
    OPERACAO_DETALHE_REMOVE,
    OPERACAO_DETALHE_CANCEL,
    OPERACAO_DETALHE_REMOVE_START,
    OPERACAO_DETALHE_REMOVE_SUCCESS,
    OPERACAO_DETALHE_REMOVE_FAILED,
    OPERACAO_DETALHE_LIST,
    OPERACAO_DETALHE_LIST_SUCCESS,
    OPERACAO_DETALHE_LIST_FAILED
} from './constants';

// List
export const operacaoList        = (filter) => ({ type: OPERACAO_LIST, payload: filter,});
export const operacaoListSuccess = (operacoes) => ({ type: OPERACAO_LIST_SUCCESS, payload: operacoes });
export const operacaoListFailed  = (error) => ({ type: OPERACAO_LIST_FAILED, payload: error  });

export const operacaoLoad        = (id) => ({ type: OPERACAO_LOAD, payload:  id  });
export const operacaoLoadSuccess  = (operacao) => ({ type: OPERACAO_LOAD_SUCCESS, payload: operacao  });
export const operacaoLoadFailed   = (error) => ({ type: OPERACAO_LOAD_FAILED, payload: error  });

// Add
export const operacaoAdd         = (operacao) => ({ type: OPERACAO_ADD, payload: operacao });
export const operacaoAddSuccess  = (operacao, operacaoStatus) => ({ type: OPERACAO_ADD_SUCCESS, operacaoStatus: operacaoStatus, payload:  operacao  });
export const operacaoAddFailed   = (error) => ({ type: OPERACAO_ADD_FAILED, payload:  error  });

// Update
export const operacaoUpdate         = (operacao) => ({ type: OPERACAO_UPDATE, payload: operacao  });
export const operacaoUpdateSuccess  = (operacao, operacaoStatus) => ({ type: OPERACAO_UPDATE_SUCCESS, operacaoStatus: operacaoStatus, payload:  operacao  });
export const operacaoUpdateFailed   = (error) => ({ type: OPERACAO_UPDATE_FAILED, payload: error  });

// remove
export const operacaoRemove         = (operacao) => ({ type: OPERACAO_REMOVE, payload: operacao  });
export const operacaoRemoveSuccess  = (operacao, operacaoDeletado) => ({ type: OPERACAO_REMOVE_SUCCESS, operacaoDeletado: operacaoDeletado, payload:  operacao  });
export const operacaoRemoveFailed   = (error) => ({ type: OPERACAO_REMOVE_FAILED, payload: error  });

export const operacaoDetalheLoad    = (index) => ({ type: OPERACAO_DETALHE_LOAD, payload: index  });
export const operacaoDetalheNew     = () => ({ type: OPERACAO_DETALHE_NEW  });

export const operacaoDetalheAdd     = (operacaoDetalhe) => ({ type: OPERACAO_DETALHE_ADD, payload: operacaoDetalhe });
export const operacaoDetalheAddSuccess = (operacaoDetalhe) => ({ type: OPERACAO_DETALHE_ADD_SUCCESS, payload: operacaoDetalhe })
export const operacaoDetalheAddFailed = (error) => ({ type: OPERACAO_DETALHE_ADD_FAILED, payload: error })

export const operacaoDetalheUpdate     = (operacaoDetalhe) => ({ type: OPERACAO_DETALHE_UPDATE, payload: operacaoDetalhe });
export const operacaoDetalheUpdateSuccess = (operacaoDetalhe) => ({ type: OPERACAO_DETALHE_UPDATE_SUCCESS, payload: operacaoDetalhe })
export const operacaoDetalheUpdateFailed = (error) => ({ type: OPERACAO_DETALHE_UPDATE_FAILED, payload: error })

export const operacaoDetalheRemoveStart  = (operacaoDetalheId) => ({ type: OPERACAO_DETALHE_REMOVE_START, payload: operacaoDetalheId });
export const operacaoDetalheRemove  = (operacaoDetalheId) => ({ type: OPERACAO_DETALHE_REMOVE, payload: operacaoDetalheId });
export const operacaoDetalheRemoveSuccess = (operacaoDetalheId) => ({ type: OPERACAO_DETALHE_REMOVE_SUCCESS, payload: operacaoDetalheId })
export const operacaoDetalheRemoveFailed = (error) => ({ type: OPERACAO_DETALHE_REMOVE_FAILED, payload: error })

export const operacaoDetalheList = (centroCustoId) => ({ type: OPERACAO_DETALHE_LIST, payload: centroCustoId })
export const operacaoDetalheListSuccess = (operacaoDetalhes) => ({ type: OPERACAO_DETALHE_LIST_SUCCESS, payload: operacaoDetalhes });
export const operacaoDetalheListFailed = (erro) => ({ type: OPERACAO_DETALHE_LIST_FAILED, payload: erro });

export const operacaoDetalheCancel  = () => ({ type: OPERACAO_DETALHE_CANCEL });