import axios from 'axios';
import { getLoggedInUser } from '../helpers/authUtils';
import { URL_BASE } from '../helpers/url';

const api = axios.create({
    baseURL: URL_BASE,
});

api.interceptors.request.use(async (config) => {
    const user = getLoggedInUser();
    if (user) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
});

export default api;
