/* VEICULO */
export const CORRIDA_LIST           = 'CORRIDA_LIST';
export const CORRIDA_LIST_SUCCESS   = 'CORRIDA_LIST_SUCCESS';
export const CORRIDA_LIST_FAILED    = 'CORRIDA_LIST_FAILED';
export const CORRIDA_ADD            = 'CORRIDA_ADD';
export const CORRIDA_ADD_SUCCESS    = 'CORRIDA_ADD_SUCCESS';
export const CORRIDA_ADD_FAILED     = 'CORRIDA_ADD_FAILED';
export const CORRIDA_UPDATE         = 'CORRIDA_UPDATE';
export const CORRIDA_UPDATE_SUCCESS = 'CORRIDA_UPDATE_SUCCESS';
export const CORRIDA_UPDATE_FAILED  = 'CORRIDA_UPDATE_FAILED';
export const CORRIDA_REMOVE         = 'CORRIDA_REMOVE';
export const CORRIDA_REMOVE_SUCCESS = 'CORRIDA_REMOVE_SUCCESS';
export const CORRIDA_REMOVE_FAILED  = 'CORRIDA_REMOVE_FAILED';
export const CORRIDA_LOAD           = 'CORRIDA_LOAD'
export const CORRIDA_LOAD_SUCCESS   = 'CORRIDA_LOAD_SUCCESS';
export const CORRIDA_LOAD_FAILED    = 'CORRIDA_LOAD_FAILED';
export const CORRIDA_FINALIZAR      = 'CORRIDA_FINALIZAR';
export const CORRIDA_FINALIZAR_SUCCESS = 'CORRIDA_FINALIZAR_SUCCESS';
export const CORRIDA_FINALIZAR_FAILED  = 'CORRIDA_FINALIZAR_FAILED';
export const CORRIDA_CANCELAR         = 'CORRIDA_CANCELAR';
export const CORRIDA_CANCELAR_SUCCESS = 'CORRIDA_CANCELAR_SUCCESS';
export const CORRIDA_CANCELAR_FAILED  = 'CORRIDA_CANCELAR_FAILED';
export const CORRIDA_MODAL = 'CORRIDA_MODAL';

export const CORRIDA_REENVIAR         = 'CORRIDA_REENVIAR';
export const CORRIDA_REENVIAR_SUCCESS = 'CORRIDA_REENVIAR_SUCCESS';
export const CORRIDA_REENVIAR_FAILED  = 'CORRIDA_REENVIAR_FAILED';


export const CORRIDA_MOTORISTA_LIST = 'CORRIDA_MOTORISTA_LIST';
export const CORRIDA_MOTORISTA_LIST_SUCCESS = 'CORRIDA_MOTORISTA_LIST_SUCCESS';
export const CORRIDA_MOTORISTA_LIST_FAILED = 'CORRIDA_MOTORISTA_LIST_FAILED';

/// ACOMPANHAMENTO 
export const ACOMPANHAMENTO = 'ACOMPANHAMENTO';
export const ACOMPANHAMENTO_SUCCESS = 'ACOMPANHAMENTO_SUCCESS';
export const ACOMPANHAMENTO_FAILED = 'ACOMPANHAMENTO_FAILED';