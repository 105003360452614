
import { LOCAL_LOAD, LOCAL_LOAD_SUCCESS, LOCAL_LOAD_FAILED, LOCAL_LIST, LOCAL_LIST_SUCCESS, LOCAL_LIST_FAILED, LOCAL_ADD, LOCAL_ADD_FAILED, LOCAL_ADD_SUCCESS, LOCAL_REMOVE, LOCAL_REMOVE_FAILED, LOCAL_REMOVE_SUCCESS, LOCAL_UPDATE, LOCAL_UPDATE_SUCCESS, LOCAL_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    locais: [],
    local: {},
    loading: false,
    error: null,
    localStatus: null,
    id: {}
};

const VeiculoMarcaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOCAL_LIST:
            return { ...state, local: {}, loading: true, error: null, localDeletado: null, local: {} };
        case LOCAL_LIST_SUCCESS:
            return { ...state, locais: action.payload, loading: false, error: null, localDeletado: null };
        case LOCAL_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, localDeletado: null };
        case LOCAL_LOAD:
            return { ...state, local: {}, loading: true, error: null, localStatus: null, localDeletado: null }
        case LOCAL_LOAD_SUCCESS:
            return { ...state, local: action.payload, loading: false, error: null, localDeletado: null}
        case LOCAL_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, localDeletado: null };
        case LOCAL_ADD:
            return { ...state, local: action.payload, localStatus: null, loading: true, error: null, localDeletado: null}
        case LOCAL_ADD_SUCCESS:
            return { ...state, local: action.payload,  localStatus: "Local adicionado com sucesso", localDeletado: null, loading: false, error: null };
        case LOCAL_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOCAL_UPDATE:
            return { ...state, local: action.payload, localStatus: null, loading: true, error: null}
        case LOCAL_UPDATE_SUCCESS:
            return { ...state, local: action.payload,  localStatus: "Local atualizado com sucesso", localDeletado: null, loading: false, error: null };
        case LOCAL_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, localStatus: null };
        case LOCAL_REMOVE:
            return { ...state, local: action.payload, localStatus: null, loading: true, error: null, localDeletado: null}
        case LOCAL_REMOVE_SUCCESS:
            return { ...state, local: {}, localStatus: null,  localDeletado: "Local removido com sucesso", loading: false, error: null };
        case LOCAL_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, localStatus: null, localDeletado: null };
        default:
            return { ...state };
    }
};

export default VeiculoMarcaReducer;