
import { GRUPOVEICULO_LOAD, GRUPOVEICULO_LOAD_SUCCESS, GRUPOVEICULO_LOAD_FAILED, GRUPOVEICULO_LIST, GRUPOVEICULO_LIST_SUCCESS, GRUPOVEICULO_LIST_FAILED, GRUPOVEICULO_ADD, GRUPOVEICULO_ADD_FAILED, GRUPOVEICULO_ADD_SUCCESS, GRUPOVEICULO_REMOVE, GRUPOVEICULO_REMOVE_FAILED, GRUPOVEICULO_REMOVE_SUCCESS, GRUPOVEICULO_UPDATE, GRUPOVEICULO_UPDATE_SUCCESS, GRUPOVEICULO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    grupoVeiculos: {},
    grupoVeiculo: {},
    loading: false,
    error: null,
    grupoVeiculoStatus: null,
    id: {}
};

const GrupoVeiculoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GRUPOVEICULO_LIST:
            return { ...state, loading: true, error: null, grupoVeiculoDeletado: null };
        case GRUPOVEICULO_LIST_SUCCESS:
            return { ...state, grupoVeiculos: action.payload, loading: false, error: null, grupoVeiculoDeletado: null };
        case GRUPOVEICULO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, grupoVeiculoDeletado: null };
        case GRUPOVEICULO_LOAD:
            return { ...state, loading: true, error: null, grupoVeiculoStatus: null, grupoVeiculoDeletado: null }
        case GRUPOVEICULO_LOAD_SUCCESS:
            return { ...state, grupoVeiculo: action.payload, loading: false, error: null, grupoVeiculoDeletado: null}
        case GRUPOVEICULO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, grupoVeiculoDeletado: null };
        case GRUPOVEICULO_ADD:
            return { ...state, grupoVeiculo: action.payload, grupoVeiculoStatus: null, loading: true, error: null, grupoVeiculoDeletado: null}
        case GRUPOVEICULO_ADD_SUCCESS:
            return { ...state, grupoVeiculo: action.payload,  grupoVeiculoStatus: "Veículo adicionado com sucesso", grupoVeiculoDeletado: null, loading: false, error: null };
        case GRUPOVEICULO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GRUPOVEICULO_UPDATE:
            return { ...state, grupoVeiculo: action.payload, grupoVeiculoStatus: null, loading: true, error: null}
        case GRUPOVEICULO_UPDATE_SUCCESS:
            return { ...state, grupoVeiculo: action.payload,  grupoVeiculoStatus: "Veículo atualizado com sucesso", grupoVeiculoDeletado: null, loading: false, error: null };
        case GRUPOVEICULO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, grupoVeiculoStatus: null };
        case GRUPOVEICULO_REMOVE:
            return { ...state, grupoVeiculo: action.payload, grupoVeiculoStatus: null, loading: true, error: null, grupoVeiculoDeletado: null}
        case GRUPOVEICULO_REMOVE_SUCCESS:
            return { ...state, grupoVeiculo: {}, grupoVeiculoStatus: null,  grupoVeiculoDeletado: "Veículo removido com sucesso", loading: false, error: null };
        case GRUPOVEICULO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, grupoVeiculoStatus: null, grupoVeiculoDeletado: null };
        default:
            return { ...state };
    }
};

export default GrupoVeiculoReducer;