import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { MONITORAMENTO_LIST, MONITORAMENTO_TOTALIZADORES } from './constants';

import { monitoramentoListFailed, monitoramentoListSuccess, monitoramentoTotalizadoresFailed, monitoramentoTotalizadoresSuccess } from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* monitoramentoList() {

    const options = {
        method: 'GET'
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/monitoramento/andamento', options);
        yield put(monitoramentoListSuccess(response));
    } catch (error) {
        yield put(monitoramentoListFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* monitoramentoTotalizadores() {

        const options = {
            method: 'GET'
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/monitoramento/totalizadores', options);
            yield put(monitoramentoTotalizadoresSuccess(response));
        } catch (error) {
            yield put(monitoramentoTotalizadoresFailed(error.message));
        }    
}

export function* watchMonitramentoList(): any{
    yield takeEvery(MONITORAMENTO_LIST, monitoramentoList);
}

export function* watchMonitramentoTotalizadores(): any {
    yield takeEvery(MONITORAMENTO_TOTALIZADORES, monitoramentoTotalizadores);
}

function* monitoramento(): any {
    yield all([fork(watchMonitramentoList), fork(watchMonitramentoTotalizadores)]);
}

export default monitoramento;