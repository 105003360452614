// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD, RESET_PASSWORD, CREDENCIAR } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    resetPasswordSuccess,
    resetPasswordFailed,
    credenciarSuccess,
    credenciarFailed,
} from './actions';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
    // let cookies = new Cookies();
    if (user) {
        window.localStorage.setItem('userLoged', JSON.stringify(user));
        // window.localStorage.setItem("USER", JSON.stringify(user.usuario));
        // cookies.set('user', JSON.stringify(user), { path: '/' });
    } else {
        window.localStorage.removeItem('userLoged');
        // window.localStorage.removeItem("USER")
        // cookies.remove('user', { path: '/' })
    }
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
    const options = {
        body: JSON.stringify({ cpf: username, senha: password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/conta/login', options);
        setSession(response);
        yield put(loginUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Verifique suas credenciais.';
                break;
            case 400:
                message = error.message ?? 'Verifique se as informações estão corretas.';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/conta/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, cpf, password } }) {
    const options = {
        body: JSON.stringify({ nomeCompleto: fullname, email: email, cpf: cpf, senha: password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/conta/registrar', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo de errado!';
                break;
            case 401:
                message = error.message ?? 'Verifique suas credenciais.';
                break;
            case 400:
                message = error.message ?? 'Verifique se as informações estão corretas.';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username, url } }) {
    const options = {
        body: JSON.stringify({ cpf: username, url }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/conta/enviaremailresetsenha', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

/**
 * Register the user
 */
function* resetPassword({ payload: { password, confirmPassword, token } }) {
    if (password !== confirmPassword) {
        yield put(resetPasswordFailed('As senhas não são iguais'));
        return;
    }

    const options = {
        body: JSON.stringify({ senha: password }),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/conta/redefinirsenha', options);
        yield put(resetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            case 400:
                message = error.message ?? 'Operação inválida';
                break;
            default:
                message = error.message;
        }
        yield put(resetPasswordFailed(message));
    }
}

function* credenciar({ payload: credenciar }) {
    const options = {
        body: JSON.stringify(credenciar),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/conta/credenciar', options);
        yield put(credenciarSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo de errado!';
                break;
            case 401:
                message = error.message ?? 'Verifique suas credenciais.';
                break;
            case 400:
                message = error.message ?? 'Verifique se as informações estão corretas.';
                break;
            default:
                message = error;
        }
        yield put(credenciarFailed(message));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchResetPassword(): any {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchCredenciar(): any {
    yield takeEvery(CREDENCIAR, credenciar);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchResetPassword),
        fork(watchCredenciar),
    ]);
}

export default authSaga;
