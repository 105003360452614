
import { CENTRO_CUSTO_LOAD, CENTRO_CUSTO_LOAD_SUCCESS, CENTRO_CUSTO_LOAD_FAILED, CENTRO_CUSTO_LIST, CENTRO_CUSTO_LIST_SUCCESS, CENTRO_CUSTO_LIST_FAILED, CENTRO_CUSTO_ADD, CENTRO_CUSTO_ADD_FAILED, CENTRO_CUSTO_ADD_SUCCESS, CENTRO_CUSTO_REMOVE, CENTRO_CUSTO_REMOVE_FAILED, CENTRO_CUSTO_REMOVE_SUCCESS, CENTRO_CUSTO_UPDATE, CENTRO_CUSTO_UPDATE_SUCCESS, CENTRO_CUSTO_UPDATE_FAILED, CENTRO_CUSTO_LIST_NIVEL, CENTRO_CUSTO_LIST_NIVEL_SUCCESS, CENTRO_CUSTO_LIST_NIVEL_FAILED, CENTRO_CUSTO_LIST_RECURSIVO_USUARIO, CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_SUCCESS, CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_FAILED } from './constants';

const INIT_STATE = {
    centroCustos: {},
    centroCusto: {},
    loading: false,
    error: null,
    centroCustoStatus: null,
    id: {}
};

const MalhaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CENTRO_CUSTO_LIST:
            return { ...state, loading: true, error: null, centroCustoDeletado: null };
        case CENTRO_CUSTO_LIST_SUCCESS:
            return { ...state, centroCustos: action.payload, loading: false, error: null, centroCustoDeletado: null };
        case CENTRO_CUSTO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoDeletado: null };
        case CENTRO_CUSTO_LOAD:
            return { ...state, centroCusto: {}, loading: true, error: null, centroCustoStatus: null, centroCustoDeletado: null }
        case CENTRO_CUSTO_LOAD_SUCCESS:
            return { ...state, centroCusto: action.payload, loading: false, error: null, centroCustoDeletado: null}
        case CENTRO_CUSTO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoDeletado: null };
        case CENTRO_CUSTO_ADD:
            return { ...state, centroCusto: action.payload, centroCustoStatus: null, loading: true, error: null, centroCustoDeletado: null}
        case CENTRO_CUSTO_ADD_SUCCESS:
            return { ...state, centroCusto: action.payload,  centroCustoStatus: "Centro de Custo criado com sucesso", centroCustoDeletado: null, loading: false, error: null };
        case CENTRO_CUSTO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CENTRO_CUSTO_UPDATE:
            return { ...state,  centroCusto: action.payload, centroCustoStatus: null, loading: true, error: null}
        case CENTRO_CUSTO_UPDATE_SUCCESS:
            return { ...state, centroCusto: action.payload,  centroCustoStatus: "Centro de Custo atualizado com sucesso", centroCustoDeletado: null, loading: false, error: null };
        case CENTRO_CUSTO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoStatus: null };
        case CENTRO_CUSTO_REMOVE:
            return { ...state, centroCustoId: action.payload, centroCustoStatus: null, loading: true, error: null, centroCustoDeletado: null}
        case CENTRO_CUSTO_REMOVE_SUCCESS:
            return { ...state, centroCustoStatus: null,  centroCustoDeletado: "Centro de Custo removida com sucesso", loading: false, error: null };
        case CENTRO_CUSTO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoStatus: null, centroCustoDeletado: null };

        case CENTRO_CUSTO_LIST_NIVEL:
            return { ...state, loading: true, error: null, centroCustoDeletado: null };
        case CENTRO_CUSTO_LIST_NIVEL_SUCCESS:
            return { ...state, centroCustos: action.payload, loading: false, error: null, centroCustoDeletado: null };
        case CENTRO_CUSTO_LIST_NIVEL_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoDeletado: null };

        case CENTRO_CUSTO_LIST_RECURSIVO_USUARIO:
            return { ...state, loading: true, error: null, centroCustoDeletado: null };
        case CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_SUCCESS:
            return { ...state, centroCustos: action.payload, loading: false, error: null, centroCustoDeletado: null };
        case CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoDeletado: null };

        default:
            return { ...state };
    }
};

export default MalhaReducer;