
import { ESTADO_LOAD, ESTADO_LOAD_SUCCESS, ESTADO_LOAD_FAILED, ESTADO_LIST, ESTADO_LIST_SUCCESS, ESTADO_LIST_FAILED, ESTADO_ADD, ESTADO_ADD_FAILED, ESTADO_ADD_SUCCESS, ESTADO_REMOVE, ESTADO_REMOVE_FAILED, ESTADO_REMOVE_SUCCESS, ESTADO_UPDATE, ESTADO_UPDATE_SUCCESS, ESTADO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    estados: {},
    estado: {},
    loading: false,
    error: null,
    estadoStatus: null,
    id: {}
};

const EstadoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ESTADO_LIST:
            return { ...state, loading: true, error: null, estadoDeletado: null };
        case ESTADO_LIST_SUCCESS:
            return { ...state, estados: action.payload, loading: false, error: null, estadoDeletado: null };
        case ESTADO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, estadoDeletado: null };
        case ESTADO_LOAD:
            return { ...state, loading: true, error: null, estadoStatus: null, estadoDeletado: null }
        case ESTADO_LOAD_SUCCESS:
            return { ...state, estado: action.payload, loading: false, error: null, estadoDeletado: null}
        case ESTADO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, estadoDeletado: null };
        case ESTADO_ADD:
            return { ...state, estado: action.payload, estadoStatus: null, loading: true, error: null, estadoDeletado: null}
        case ESTADO_ADD_SUCCESS:
            return { ...state, estado: action.payload,  estadoStatus: "Estação criada com sucesso", estadoDeletado: null, loading: false, error: null };
        case ESTADO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ESTADO_UPDATE:
            return { ...state, estado: action.payload, estadoStatus: null, loading: true, error: null}
        case ESTADO_UPDATE_SUCCESS:
            return { ...state, estado: action.payload,  estadoStatus: "Estação atualizada com sucesso", estadoDeletado: null, loading: false, error: null };
        case ESTADO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, estadoStatus: null };
        case ESTADO_REMOVE:
            return { ...state, estado: action.payload, estadoStatus: null, loading: true, error: null, estadoDeletado: null}
        case ESTADO_REMOVE_SUCCESS:
            return { ...state, estado: {}, estadoStatus: null,  estadoDeletado: "Estação removida com sucesso", loading: false, error: null };
        case ESTADO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, estadoStatus: null, estadoDeletado: null };
        default:
            return { ...state };
    }
};

export default EstadoReducer;