/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';

import SectionRegistrations from './registrations';
import SectionReports from './reports';
import SectionServices from './services';

import { NavbarMenu_Container, NavbarMenu_ContainerLabel, Divider } from '../../navbar-menu';
import { getLoggedInUser } from '../../../../helpers/authUtils';

const permissions = getLoggedInUser()?.usuario?.tipoUsuario ?? [];

const PassegerPanel = () => {
    return (
        <NavbarMenu_Container>
            <NavbarMenu_ContainerLabel>Painel dos Passageiros</NavbarMenu_ContainerLabel>

            {(permissions.includes('OperadorCliente') ||
                permissions.includes('GestorCentral') ||
                permissions.includes('OperadorCentral') ||
                permissions.includes('GestorCliente') ||
                permissions.includes('Administrador')) && (
                <>
                    <SectionRegistrations />
                    <Divider />
                </>
            )}

            {(permissions.includes('GestorCentral') ||
                permissions.includes('Aprovador')) && (
                <>
                    <SectionServices />
                    <Divider />
                </>
            )}

            {(permissions.includes('GestorCentral') || permissions.includes('OperadorCentral')) && (
                <>
                    {/* <SectionReports />
                        <Divider /> */}
                </>
            )}
        </NavbarMenu_Container>
    );
};

export default PassegerPanel;
