
import { MOTORISTA_LOAD, MOTORISTA_LOAD_SUCCESS, MOTORISTA_LOAD_FAILED, MOTORISTA_LIST, MOTORISTA_LIST_SUCCESS, MOTORISTA_LIST_FAILED, MOTORISTA_ADD, MOTORISTA_ADD_FAILED, MOTORISTA_ADD_SUCCESS, MOTORISTA_REMOVE, MOTORISTA_REMOVE_FAILED, MOTORISTA_REMOVE_SUCCESS, MOTORISTA_UPDATE, MOTORISTA_UPDATE_SUCCESS, MOTORISTA_UPDATE_FAILED, MOTORISTA_LIST_DOCUMENTOS, MOTORISTA_LIST_DOCUMENTOS_SUCCESS, MOTORISTA_LIST_DOCUMENTOS_FAILED } from './constants';

const INIT_STATE = {
    motoristas: {},
    motorista: {},
    loading: false,
    error: null,
    motoristaStatus: null,
    id: {}
};

const EmpresaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MOTORISTA_LIST:
            return { ...state, motorista: {}, loading: true, error: null, motoristaDeletado: null };
        case MOTORISTA_LIST_SUCCESS:
            return { ...state, motoristas: action.payload, loading: false, error: null, motoristaDeletado: null };
        case MOTORISTA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, motoristaDeletado: null };
        case MOTORISTA_LOAD:
            return { ...state, motorista: {}, loading: true, error: null, motoristaStatus: null, motoristaDeletado: null }
        case MOTORISTA_LOAD_SUCCESS:
            return { ...state, motorista: action.payload, loading: false, error: null, motoristaDeletado: null}
        case MOTORISTA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, motoristaDeletado: null };
        case MOTORISTA_ADD:
            return { ...state, motorista: action.payload, motoristaStatus: null, loading: true, error: null, motoristaDeletado: null}
        case MOTORISTA_ADD_SUCCESS:
            return { ...state, motorista: action.payload,  motoristaStatus: "Motorista adicionado com sucesso", motoristaDeletado: null, loading: false, error: null };
        case MOTORISTA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case MOTORISTA_UPDATE:
            return { ...state, motorista: action.payload, motoristaStatus: null, loading: true, error: null}
        case MOTORISTA_UPDATE_SUCCESS:
            return { ...state, motorista: action.payload,  motoristaStatus: "Motorista atualizado com sucesso", motoristaDeletado: null, loading: false, error: null };
        case MOTORISTA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, motoristaStatus: null };
        case MOTORISTA_REMOVE:
            return { ...state, motorista: action.payload, motoristaStatus: null, loading: true, error: null, motoristaDeletado: null}
        case MOTORISTA_REMOVE_SUCCESS:
            return { ...state, motorista: {}, motoristaStatus: null,  motoristaDeletado: "Motorista removido com sucesso", loading: false, error: null };
        case MOTORISTA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, motoristaStatus: null, motoristaDeletado: null };

        case MOTORISTA_LIST_DOCUMENTOS:
            return { ...state, loading: true, error: null, motoristaDeletado: null };
        case MOTORISTA_LIST_DOCUMENTOS_SUCCESS:
            return { ...state, motoristas: action.payload, loading: false, error: null, motoristaDeletado: null };
        case MOTORISTA_LIST_DOCUMENTOS_FAILED:
            return { ...state, error: action.payload, loading: false, motoristaDeletado: null };
        default:
            return { ...state };
    }
};

export default EmpresaReducer;