/* GRUPOVEICULO */
export const GRUPOVEICULO_LIST           = 'GRUPOVEICULO_LIST';
export const GRUPOVEICULO_LIST_SUCCESS   = 'GRUPOVEICULO_LIST_SUCCESS';
export const GRUPOVEICULO_LIST_FAILED    = 'GRUPOVEICULO_LIST_FAILED';
export const GRUPOVEICULO_ADD            = 'GRUPOVEICULO_ADD';
export const GRUPOVEICULO_ADD_SUCCESS    = 'GRUPOVEICULO_ADD_SUCCESS';
export const GRUPOVEICULO_ADD_FAILED     = 'GRUPOVEICULO_ADD_FAILED';
export const GRUPOVEICULO_UPDATE         = 'GRUPOVEICULO_UPDATE';
export const GRUPOVEICULO_UPDATE_SUCCESS = 'GRUPOVEICULO_UPDATE_SUCCESS';
export const GRUPOVEICULO_UPDATE_FAILED  = 'GRUPOVEICULO_UPDATE_FAILED';
export const GRUPOVEICULO_REMOVE         = 'GRUPOVEICULO_REMOVE';
export const GRUPOVEICULO_REMOVE_SUCCESS = 'GRUPOVEICULO_REMOVE_SUCCESS';
export const GRUPOVEICULO_REMOVE_FAILED  = 'GRUPOVEICULO_REMOVE_FAILED';
export const GRUPOVEICULO_LOAD           = 'GRUPOVEICULO_LOAD'
export const GRUPOVEICULO_LOAD_SUCCESS   = 'GRUPOVEICULO_LOAD_SUCCESS';
export const GRUPOVEICULO_LOAD_FAILED    = 'GRUPOVEICULO_LOAD_FAILED';