/* VEICULOMARCA */
export const VEICULOMARCA_LIST           = 'VEICULOMARCA_LIST';
export const VEICULOMARCA_LIST_SUCCESS   = 'VEICULOMARCA_LIST_SUCCESS';
export const VEICULOMARCA_LIST_FAILED    = 'VEICULOMARCA_LIST_FAILED';
export const VEICULOMARCA_ADD            = 'VEICULOMARCA_ADD';
export const VEICULOMARCA_ADD_SUCCESS    = 'VEICULOMARCA_ADD_SUCCESS';
export const VEICULOMARCA_ADD_FAILED     = 'VEICULOMARCA_ADD_FAILED';
export const VEICULOMARCA_UPDATE         = 'VEICULOMARCA_UPDATE';
export const VEICULOMARCA_UPDATE_SUCCESS = 'VEICULOMARCA_UPDATE_SUCCESS';
export const VEICULOMARCA_UPDATE_FAILED  = 'VEICULOMARCA_UPDATE_FAILED';
export const VEICULOMARCA_REMOVE         = 'VEICULOMARCA_REMOVE';
export const VEICULOMARCA_REMOVE_SUCCESS = 'VEICULOMARCA_REMOVE_SUCCESS';
export const VEICULOMARCA_REMOVE_FAILED  = 'VEICULOMARCA_REMOVE_FAILED';
export const VEICULOMARCA_LOAD           = 'VEICULOMARCA_LOAD'
export const VEICULOMARCA_LOAD_SUCCESS   = 'VEICULOMARCA_LOAD_SUCCESS';
export const VEICULOMARCA_LOAD_FAILED    = 'VEICULOMARCA_LOAD_FAILED';