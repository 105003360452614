
import { 
        OPERACAO_LOAD, 
        OPERACAO_LOAD_SUCCESS, 
        OPERACAO_LOAD_FAILED, 
        OPERACAO_LIST, 
        OPERACAO_LIST_SUCCESS, 
        OPERACAO_LIST_FAILED, 
        OPERACAO_ADD, 
        OPERACAO_ADD_FAILED,
        OPERACAO_ADD_SUCCESS, 
        OPERACAO_REMOVE, 
        OPERACAO_REMOVE_FAILED, 
        OPERACAO_REMOVE_SUCCESS, 
        OPERACAO_UPDATE, 
        OPERACAO_UPDATE_SUCCESS, 
        OPERACAO_UPDATE_FAILED, 
        
        OPERACAO_DETALHE_NEW, 
        OPERACAO_DETALHE_LOAD, 
        OPERACAO_DETALHE_ADD, 
        OPERACAO_DETALHE_ADD_SUCCESS, 
        OPERACAO_DETALHE_ADD_FAILED,
        OPERACAO_DETALHE_UPDATE, 
        OPERACAO_DETALHE_UPDATE_SUCCESS, 
        OPERACAO_DETALHE_UPDATE_FAILED, 
        OPERACAO_DETALHE_REMOVE_START,
        OPERACAO_DETALHE_REMOVE,
        OPERACAO_DETALHE_REMOVE_SUCCESS,
        OPERACAO_DETALHE_REMOVE_FAILED,
        OPERACAO_DETALHE_CANCEL,
        OPERACAO_DETALHE_LIST,
        OPERACAO_DETALHE_LIST_SUCCESS,
        OPERACAO_DETALHE_LIST_FAILED, 
} from './constants';

const INIT_STATE = {
    operacoes: {},
    operacaoDetalhes: [],
    operacaoDetalhe: {},
    operacao: {},
    loading: false,
    error: null,
    operacaoStatus: null,
    id: {},
    modal: false,
    modalRemove: false
};

const OperacaoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OPERACAO_LIST:
            return { ...state, loading: true, error: null, operacaoDeletado: null };
        case OPERACAO_LIST_SUCCESS:
            return { ...state, operacoes: action.payload, loading: false, error: null, operacaoDeletado: null };
        case OPERACAO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, operacaoDeletado: null };
        case OPERACAO_LOAD:
            return { ...state, loading: true, error: null, operacaoStatus: null, operacaoDeletado: null }
        case OPERACAO_LOAD_SUCCESS:
            return { ...state, operacao: action.payload, operacaoDetalhes: action.payload.operacaoDetalhes, loading: false, error: null, operacaoDeletado: null}
        case OPERACAO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, operacaoDeletado: null };
        case OPERACAO_ADD:
            return { ...state, operacao: action.payload, operacaoStatus: null, loading: true, error: null, operacaoDeletado: null}
        case OPERACAO_ADD_SUCCESS:
            return { ...state, operacao: action.payload,  operacaoStatus: "Operação adicionada com sucesso", operacaoDeletado: null, loading: false, error: null };
        case OPERACAO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case OPERACAO_UPDATE:
            return { ...state, operacao: action.payload, operacaoStatus: null, loading: true, error: null}
        case OPERACAO_UPDATE_SUCCESS:
            return { ...state, operacao: action.payload,  operacaoStatus: "Operação atualizada com sucesso", operacaoDeletado: null, loading: false, error: null };
        case OPERACAO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, operacaoStatus: null };
        case OPERACAO_REMOVE:
            return { ...state, operacao: action.payload, operacaoStatus: null, loading: true, error: null, operacaoDeletado: null}
        case OPERACAO_REMOVE_SUCCESS:
            return { ...state, operacao: {}, operacaoDetalhes: [], operacaoStatus: null,  operacaoDeletado: "Operação removida com sucesso", loading: false, error: null };
        case OPERACAO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, operacaoStatus: null, operacaoDeletado: null };
      
        case OPERACAO_DETALHE_LOAD:
            return { ...state, operacaoDetalhe: state.operacaoDetalhes.find(x => x.id === action.payload), modal: true };

        case OPERACAO_DETALHE_NEW:
            return { ...state,  modal: true, operacaoDetalhe: { operacaoId: state.operacao?.id } };

        case OPERACAO_DETALHE_ADD:
            return { ...state, operacaoDetalhe: action.payload, loading: true };

        case OPERACAO_DETALHE_ADD_SUCCESS:
            return { ...state, operacaoDetalhe: null, operacaoDetalhes: [...state.operacaoDetalhes, action.payload] , modal: false, loading: false };

        case OPERACAO_DETALHE_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };

        case OPERACAO_DETALHE_UPDATE:
            return { ...state, operacaoDetalhe: action.payload, loading: true };

        case OPERACAO_DETALHE_UPDATE_SUCCESS:

            const index = state.operacaoDetalhes.findIndex(item => item.id === action.payload.id);
            state.operacaoDetalhes[index] = action.payload;
            const updateList = [...state.operacaoDetalhes];
            
            return { ...state, operacaoDetalhe: null, operacaoDetalhes: [...updateList], modal: false, loading: false };
        
        case OPERACAO_DETALHE_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false }

        case OPERACAO_DETALHE_REMOVE_START:
            return { ...state, modalRemove: true, operacaoDetalhe: state.operacaoDetalhes.find(item => item.id === action.payload) }
            
        case OPERACAO_DETALHE_REMOVE:
            return { ...state, operacaoDetalheId: action.payload, loading: true };

        case OPERACAO_DETALHE_REMOVE_SUCCESS:
            
            const newList = state.operacaoDetalhes;
            const indexRemove = newList.findIndex(item => item.id === action.payload);
            newList.splice(indexRemove, 1);
            return { ...state, operacaoDetalhes: [...newList], modal: false, modalRemove: false, loading: false };

        case OPERACAO_DETALHE_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case OPERACAO_DETALHE_CANCEL:
            return { ...state, operacaoDetalhe: {}, modal: false, modalRemove: false };

        case OPERACAO_DETALHE_LIST:
            return { ...state, loading: true, error: null };
        case OPERACAO_DETALHE_LIST_SUCCESS: 
            return { ...state, loading: false, error: null, operacaoDetalhes: action.payload }
        case OPERACAO_DETALHE_LIST_FAILED:
            return { ...state, loading: false, error: action.payload }

        default:
            return { ...state };
    }
};

export default OperacaoReducer;