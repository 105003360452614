/* DASH_TOTALIZADOR */
export const DASH_TOTALIZADOR_LIST = 'DASH_TOTALIZADOR_LIST';
export const DASH_TOTALIZADOR_LIST_SUCCESS = 'DASH_TOTALIZADOR_LIST_SUCCESS';
export const DASH_TOTALIZADOR_LIST_FAILED = 'DASH_TOTALIZADOR_LIST_FAILED';

export const DASH_TOTAL_CENTRO_CUSTO = 'DASH_TOTAL_CENTRO_CUSTO';
export const DASH_TOTAL_CENTRO_CUSTO_SUCCESS = 'DASH_TOTAL_CENTRO_CUSTO_SUCCESS';
export const DASH_TOTAL_CENTRO_CUSTO_FAILED = 'DASH_TOTAL_CENTRO_CUSTO_FAILED';

export const DASH_TOTAL_CIDADE = 'DASH_TOTAL_CIDADE';
export const DASH_TOTAL_CIDADE_SUCCESS = 'DASH_TOTAL_CIDADE_SUCCESS';
export const DASH_TOTAL_CIDADE_FAILED = 'DASH_TOTAL_CIDADE_FAILED';

export const DASH_KM_LINEAR = 'DASH_KM_LINEAR';
export const DASH_KM_LINEAR_SUCCESS = 'DASH_KM_LINEAR_SUCCESS';
export const DASH_KM_LINEAR_FAILED = 'DASH_KM_LINEAR_FAILED';

export const MOTORISTA_EM_ATIVIDADE = 'MOTORISTA_EM_ATIVIDADE';
export const MOTORISTA_EM_ATIVIDADE_SUCCESS = 'MOTORISTA_EM_ATIVIDADE_SUCCESS';
export const MOTORISTA_EM_ATIVIDADE_FAILED = 'MOTORISTA_EM_ATIVIDADE_FAILED';

export const QUANTIDADE_CORRIDA_SLA_LIST = 'QUANTIDADE_CORRIDA_SLA_LIST';
export const QUANTIDADE_CORRIDA_SLA_SUCCESS = 'QUANTIDADE_CORRIDA_SLA_SUCCESS';
export const QUANTIDADE_CORRIDA_SLA_FAILED = 'QUANTIDADE_CORRIDA_SLA_FAILED';

export const QUANTIDADE_CORRIDA_EM_FILA_LIST = 'QUANTIDADE_CORRIDA_EM_FILA_LIST';
export const QUANTIDADE_CORRIDA_EM_FILA_SUCCESS = 'QUANTIDADE_CORRIDA_EM_FILA_SUCCESS';
export const QUANTIDADE_CORRIDA_EM_FILA_FAILED = 'QUANTIDADE_CORRIDA_EM_FILA_FAILED';

// Horas Online

export const DASH_MOTORISTA_HORAS_ONLINE = 'DASH_MOTORISTA_HORAS_ONLINE';
export const DASH_MOTORISTA_HORAS_ONLINE_SUCCESS = 'DASH_MOTORISTA_HORAS_ONLINE_SUCCESS';
export const DASH_MOTORISTA_HORAS_ONLINE_FAILED = 'DASH_MOTORISTA_HORAS_ONLINE_FAILED';