/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { getLoggedInUser } from '../../../../helpers/authUtils'

import SectionRegistrations from './registrations'
import SectionReports from './reports'
import SectionServices from './services'

import {
  NavbarMenu_Container,
  NavbarMenu_ContainerLabel,
  Divider,
} from '../../navbar-menu'

const ContractsPanel = () => {

  const loggedInUser = getLoggedInUser();

  const permissions = loggedInUser?.usuario?.tipoUsuario;

  return (
    <NavbarMenu_Container>
      <NavbarMenu_ContainerLabel>Painel de Contratos</NavbarMenu_ContainerLabel>

      {(!permissions.includes('GestorCliente') ||
        !permissions.includes('OperadorCliente')) && (
          <>
            <SectionRegistrations />
            <Divider />
          </>
        )}

      <SectionServices />
      <Divider />

      {/* <SectionReports />
      <Divider /> */}
    </NavbarMenu_Container>
  )
}

export default ContractsPanel
