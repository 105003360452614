
import { PERMISSAO_LOAD, PERMISSAO_LOAD_SUCCESS, PERMISSAO_LOAD_FAILED, PERMISSAO_LIST, PERMISSAO_LIST_SUCCESS, PERMISSAO_LIST_FAILED, PERMISSAO_ADD, PERMISSAO_ADD_FAILED, PERMISSAO_ADD_SUCCESS, PERMISSAO_REMOVE, PERMISSAO_REMOVE_FAILED, PERMISSAO_REMOVE_SUCCESS, PERMISSAO_UPDATE, PERMISSAO_UPDATE_SUCCESS, PERMISSAO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    permissoes: [],
    permissao: {},
    loading: false,
    error: null,
    permissaoStatus: null,
    id: {}
};

const PermissaoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PERMISSAO_LIST:
            return { ...state, loading: true, error: null, permissaoDeletado: null };
        case PERMISSAO_LIST_SUCCESS:
            return { ...state, permissoes: action.payload, loading: false, error: null, permissaoDeletado: null };
        case PERMISSAO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, permissaoDeletado: null };
        case PERMISSAO_LOAD:
            return { ...state, loading: true, error: null, permissaoStatus: null, permissaoDeletado: null }
        case PERMISSAO_LOAD_SUCCESS:
            return { ...state, permissao: action.payload, loading: false, error: null, permissaoDeletado: null}
        case PERMISSAO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, permissaoDeletado: null };
        case PERMISSAO_ADD:
            return { ...state, permissao: action.payload, permissaoStatus: null, loading: true, error: null, permissaoDeletado: null}
        case PERMISSAO_ADD_SUCCESS:
            return { ...state, permissao: action.payload,  permissaoStatus: "Permissao adicionado com sucesso", permissaoDeletado: null, loading: false, error: null };
        case PERMISSAO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PERMISSAO_UPDATE:
            return { ...state, permissao: action.payload, permissaoStatus: null, loading: true, error: null}
        case PERMISSAO_UPDATE_SUCCESS:
            return { ...state, permissao: action.payload,  permissaoStatus: "Permissao atualizado com sucesso", permissaoDeletado: null, loading: false, error: null };
        case PERMISSAO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, permissaoStatus: null };
        case PERMISSAO_REMOVE:
            return { ...state, permissao: action.payload, permissaoStatus: null, loading: true, error: null, permissaoDeletado: null}
        case PERMISSAO_REMOVE_SUCCESS:
            return { ...state, permissao: {}, permissaoStatus: null,  permissaoDeletado: "Permissao removido com sucesso", loading: false, error: null };
        case PERMISSAO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, permissaoStatus: null, permissaoDeletado: null };
        default:
            return { ...state };
    }
};

export default PermissaoReducer;