import {
    DASH_TOTALIZADOR_LIST,
    DASH_TOTALIZADOR_LIST_SUCCESS,
    DASH_TOTALIZADOR_LIST_FAILED,
    DASH_TOTAL_CENTRO_CUSTO,
    DASH_TOTAL_CENTRO_CUSTO_SUCCESS,
    DASH_TOTAL_CENTRO_CUSTO_FAILED,
    DASH_TOTAL_CIDADE,
    DASH_TOTAL_CIDADE_SUCCESS,
    DASH_TOTAL_CIDADE_FAILED,
    DASH_KM_LINEAR,
    DASH_KM_LINEAR_SUCCESS,
    DASH_KM_LINEAR_FAILED,
    MOTORISTA_EM_ATIVIDADE,
    MOTORISTA_EM_ATIVIDADE_SUCCESS,
    MOTORISTA_EM_ATIVIDADE_FAILED,
    QUANTIDADE_CORRIDA_SLA_LIST,
    QUANTIDADE_CORRIDA_SLA_SUCCESS,
    QUANTIDADE_CORRIDA_SLA_FAILED,
    QUANTIDADE_CORRIDA_EM_FILA_LIST,
    QUANTIDADE_CORRIDA_EM_FILA_SUCCESS,
    QUANTIDADE_CORRIDA_EM_FILA_FAILED,
    DASH_MOTORISTA_HORAS_ONLINE,
    DASH_MOTORISTA_HORAS_ONLINE_SUCCESS,
    DASH_MOTORISTA_HORAS_ONLINE_FAILED,
} from './constants';

// List
export const dashTotalizadorList = (filter) => ({ type: DASH_TOTALIZADOR_LIST, payload: filter });
export const dashTotalizadorListSuccess = (totalizadores) => ({
    type: DASH_TOTALIZADOR_LIST_SUCCESS,
    payload: totalizadores,
});
export const dashTotalizadorListFailed = (error) => ({ type: DASH_TOTALIZADOR_LIST_FAILED, payload: error });

// List
export const dashCentroCustoList = (filter) => ({ type: DASH_TOTAL_CENTRO_CUSTO, payload: filter });
export const dashCentroCustoListSuccess = (totalCentroCustos) => ({
    type: DASH_TOTAL_CENTRO_CUSTO_SUCCESS,
    payload: totalCentroCustos,
});
export const dashCentroCustoListFailed = (error) => ({ type: DASH_TOTAL_CENTRO_CUSTO_FAILED, payload: error });

// List
export const dashCidadeList = (filter) => ({ type: DASH_TOTAL_CIDADE, payload: filter });
export const dashCidadeListSuccess = (totalCidades) => ({ type: DASH_TOTAL_CIDADE_SUCCESS, payload: totalCidades });
export const dashCidadeListFailed = (error) => ({ type: DASH_TOTAL_CIDADE_FAILED, payload: error });

// List
export const dashKmLinearList = (filter) => ({ type: DASH_KM_LINEAR, payload: filter });
export const dashKmLinearListSuccess = (kmLinear) => ({ type: DASH_KM_LINEAR_SUCCESS, payload: kmLinear });
export const dashKmLinearListFailed = (error) => ({ type: DASH_KM_LINEAR_FAILED, payload: error });

// List
export const motoristaEmAtividade = () => ({ type: MOTORISTA_EM_ATIVIDADE });
export const motoristaEmAtividadeSuccess = (list) => ({ type: MOTORISTA_EM_ATIVIDADE_SUCCESS, payload: list });
export const motoristaEmAtividadeFailed = (error) => ({ type: MOTORISTA_EM_ATIVIDADE_FAILED, payload: error });

// List
export const quantidadeCorridaSlaList = (filter) => ({ type: QUANTIDADE_CORRIDA_SLA_LIST, payload: filter });
export const quantidadeCorridaSlaSuccess = (list) => ({ type: QUANTIDADE_CORRIDA_SLA_SUCCESS, payload: list });
export const quantidadeCorridaSlaFailed = (error) => ({ type: QUANTIDADE_CORRIDA_SLA_FAILED, payload: error });

// List
export const quantidadeCorridaEmFilaList = (filter) => ({ type: QUANTIDADE_CORRIDA_EM_FILA_LIST, payload: filter });
export const quantidadeCorridaEmFilaSuccess = (list) => ({ type: QUANTIDADE_CORRIDA_EM_FILA_SUCCESS, payload: list });
export const quantidadeCorridaEmFilaFailed = (error) => ({ type: QUANTIDADE_CORRIDA_EM_FILA_FAILED, payload: error });

// List
export const dashMotoristaHorasOnline = () => ({ type: DASH_MOTORISTA_HORAS_ONLINE });
export const dashMotoristaHorasOnlineSuccess = (list) => ({ type: DASH_MOTORISTA_HORAS_ONLINE_SUCCESS, payload: list });
export const dashMotoristaHorasOnlineFailed = (error) => ({ type: DASH_MOTORISTA_HORAS_ONLINE_FAILED, payload: error });