import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { TIPO_OPERACAO_LOAD, TIPO_OPERACAO_LIST, TIPO_OPERACAO_ADD, TIPO_OPERACAO_UPDATE, TIPO_OPERACAO_REMOVE } from './constants';

import {
    tipoOperacaoListSuccess,
    tipoOperacaoListFailed,
    tipoOperacaoAddSuccess,
    tipoOperacaoAddFailed,
    tipoOperacaoUpdateSuccess,
    tipoOperacaoUpdateFailed,
    tipoOperacaoLoadSuccess,
    tipoOperacaoLoadFailed,
    tipoOperacaoRemoveSuccess,
    tipoOperacaoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoOperacaoLoad({ payload: { id } }) {
    const options = {
        method: 'GET',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipoOperacao/' + id, options);
        yield put(tipoOperacaoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoOperacaoLoadFailed(message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoOperacaoList({ payload: filter }) {

    const options = {
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
        body: JSON.stringify(filter)
    };

    try {

        let response
        if (
            false
            // getLoggedInUser()?.usuario?.tipoUsuario?.includes('GestorCliente') || 
            // getLoggedInUser()?.usuario?.tipoUsuario?.includes('OperadorCliente')
        ) {
            response = yield call(fetchJSON, '/central/v2/api/OperationType/ForCustomer', { ...options, method: 'GET' });
        } else {
            response = yield call(fetchJSON, '/central/v1/api/v1/tipoOperacao/filter', { ...options, method: 'POST' });
        }

        yield put(tipoOperacaoListSuccess(response));
    } catch (error) {
        yield put(tipoOperacaoListFailed(error.message));
    }
}

/**
 * tipoOperacao
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoOperacaoAdd({ payload: tipoOperacao }) {

    const options = {
        body: JSON.stringify(tipoOperacao),
        method: 'POST',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipoOperacao', options);
        yield put(tipoOperacaoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoOperacaoAddFailed(message));
    }
}

/**
 * tipoOperacao
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoOperacaoUpdate({ payload: tipoOperacao }) {

    const options = {
        body: JSON.stringify(tipoOperacao),
        method: 'PUT',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipoOperacao/' + tipoOperacao?.id, options);
        yield put(tipoOperacaoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoOperacaoUpdateFailed(message));
    }
}

/**
 * tipoOperacao
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoOperacaoRemove({ payload: tipoOperacaoId }) {

    const options = {
        method: 'DELETE',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/tipoOperacao/' + tipoOperacaoId, options);
        yield put(tipoOperacaoRemoveSuccess(tipoOperacaoId));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break;
            default:
                message = error.message;
        }
        yield put(tipoOperacaoRemoveFailed(message));
    }
}

export function* watchCentroCustoLoad(): any {
    yield takeEvery(TIPO_OPERACAO_LOAD, tipoOperacaoLoad);
}

export function* watchCentroCustoList(): any {
    yield takeEvery([TIPO_OPERACAO_LIST], tipoOperacaoList);
}

export function* watchCentroCustoAdd(): any {
    yield takeEvery(TIPO_OPERACAO_ADD, tipoOperacaoAdd);
}

export function* watchCentroCustoUpdate(): any {
    yield takeEvery(TIPO_OPERACAO_UPDATE, tipoOperacaoUpdate);
}

export function* watchCentroCustoRemove(): any {
    yield takeEvery(TIPO_OPERACAO_REMOVE, tipoOperacaoRemove);
}

function* tipoOperacao(): any {
    yield all([
        fork(watchCentroCustoAdd),
        fork(watchCentroCustoUpdate),
        fork(watchCentroCustoLoad),
        fork(watchCentroCustoRemove),
        fork(watchCentroCustoList)
    ]);
}

export default tipoOperacao;