/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import {
    SectionRegistrations_Container,
    SectionRegistrations_Label,
    SectionRegistrations_Items,
} from '../../navbar-menu';
import { URL_PORTAL_V2 } from '../../../../helpers/url';
// import { IconProfile } from '../../../../assets/icons/menus/profile-fill';

// -> Icon <--------------------------------------------------------------------------------------

const IconUser = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12z" />
            </g>
        </svg>
    );
};

const IconProfile = () => {
    return (
        <svg viewBox="0 0 24 24" version="1.1" id="svg8" xmlns="http://www.w3.org/2000/svg">
            <defs id="defs12" />

            <path fill="none" d="m 12,3.942 h 14 v 14 H 12 Z" id="path2-0" style={{ strokeWidth: '0.583333' }} />
            <path
                fill="none"
                d="M -2,3.9423077 H 12 V 17.942308 H -2 Z"
                id="path2-0-1"
                style={{ strokeWidth: '0.583333' }}
            />
            <path fill="none" d="M 0,0 H 24 V 24 H 0 Z" id="path2" />
            <path
                id="path2858-4-4"
                style={{ strokeWidth: '0.542411' }}
                d="M 19.660156 3.6992188 A 3.2544645 3.2544645 0 0 0 18.525391 3.9199219 A 7.3557695 6.9725282 0 0 1 19.326172 7.0722656 A 7.3557695 6.9725282 0 0 1 18.632812 10.023438 A 3.2544645 3.2544645 0 0 0 19.660156 10.208984 A 3.2544645 3.2544645 0 0 0 19.660156 3.6992188 z "
            />
            <path
                id="path4-3-0"
                style={{ strokeWidth: '0.542411' }}
                d="M 18.033203 11.292969 A 2.7120535 2.7120535 0 0 0 15.322266 14 L 15.443359 14 A 5.8834134 6.8440506 0 0 1 18.615234 15.089844 L 24 15.089844 L 24 14.005859 A 2.7120535 2.7120535 0 0 0 21.287109 11.292969 L 18.033203 11.292969 z "
            />
            <path
                id="path2858-4-4-0"
                style={{ strokeWidth: '0.542411' }}
                d="M 4.3398438 3.765625 A 3.2544644 3.2544644 0 0 0 4.3398438 10.275391 A 3.2544644 3.2544644 0 0 0 5.3515625 10.09375 A 7.3557695 6.9725282 0 0 1 4.6152344 7.0722656 A 7.3557695 6.9725282 0 0 1 5.3964844 3.9609375 A 3.2544644 3.2544644 0 0 0 4.3398438 3.765625 z "
            />
            <path
                id="path4-3-0-3"
                style={{ strokeWidth: '0.542411' }}
                d="M 2.7128906 11.359375 A 2.7120535 2.7120535 0 0 0 0 14.072266 L 0 15.15625 L 5.125 15.15625 A 5.8834134 6.8440506 0 0 1 8.3828125 14 L 8.6757812 14 A 2.7120535 2.7120535 0 0 0 5.9667969 11.359375 L 2.7128906 11.359375 z "
            />
            <g id="g11436">
                <path d="M 12,13 A 6,6 0 1 1 12,1 6,6 0 0 1 12,13 Z" id="path2858" />
                <path d="M 20,22 H 4 v -2 a 5,5 0 0 1 5,-5 h 6 a 5,5 0 0 1 5,5 z" id="path4" />
            </g>
        </svg>
    );
};

const IconPermissions = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 18H10V42H4C3.46957 42 2.96086 41.7893 2.58579 41.4142C2.21071 41.0391 2 40.5304 2 40V20C2 19.4696 2.21071 18.9609 2.58579 18.5858C2.96086 18.2107 3.46957 18 4 18V18ZM14.586 15.414L27.386 2.614C27.5561 2.44339 27.7821 2.33994 28.0224 2.32266C28.2628 2.30539 28.5012 2.37547 28.694 2.52L30.4 3.8C30.8738 4.15569 31.2317 4.64371 31.4285 5.20251C31.6253 5.76132 31.6523 6.36589 31.506 6.94L29.2 16H42C43.0609 16 44.0783 16.4214 44.8284 17.1716C45.5786 17.9217 46 18.9391 46 20V24.208C46.0005 24.7307 45.8986 25.2485 45.7 25.732L39.51 40.762C39.359 41.1284 39.1025 41.4417 38.7732 41.6622C38.4438 41.8826 38.0563 42.0002 37.66 42H16C15.4696 42 14.9609 41.7893 14.5858 41.4142C14.2107 41.0391 14 40.5304 14 40V16.828C14.0001 16.2976 14.2109 15.789 14.586 15.414Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const SectionRegistrations = () => {
    return (
        <SectionRegistrations_Container>
            <SectionRegistrations_Label>Cadastros</SectionRegistrations_Label>
            <SectionRegistrations_Items>
                <ItemWithIcon link={'/admin/usuario'} label="Usuários Local" disable={false}>
                    <IconUser />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/global-registers/_registrations/person`}
                    label="Usuários Identity"
                    disable={false}>
                    <IconUser />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/global-registers/_registrations/profiles`}
                    label="Perfis"
                    disable={false}>
                    <IconProfile />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/global-registers/_registrations/permissions`}
                    label="Permissões"
                    disable={false}>
                    <IconPermissions />
                </ItemWithIcon>
            </SectionRegistrations_Items>
        </SectionRegistrations_Container>
    );
};

export default SectionRegistrations;
