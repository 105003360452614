import { FERIADO_LOAD, FERIADO_LOAD_SUCCESS, FERIADO_LOAD_FAILED, FERIADO_LIST, FERIADO_LIST_SUCCESS, FERIADO_LIST_FAILED, FERIADO_ADD, FERIADO_ADD_FAILED, FERIADO_ADD_SUCCESS, FERIADO_REMOVE, FERIADO_REMOVE_FAILED, FERIADO_REMOVE_SUCCESS, FERIADO_UPDATE, FERIADO_UPDATE_SUCCESS, FERIADO_UPDATE_FAILED } from './constants';

// List
export const feriadoList        = (filter) => ({ type: FERIADO_LIST, payload: filter });
export const feriadoListSuccess = (feriados) => ({ type: FERIADO_LIST_SUCCESS, payload: feriados });
export const feriadoListFailed  = (error) => ({ type: FERIADO_LIST_FAILED, payload: error });

export const feriadoLoad        = (id) => ({ type: FERIADO_LOAD, payload: { id } });
export const feriadoLoadSuccess  = (feriado) => ({ type: FERIADO_LOAD_SUCCESS, payload: feriado });
export const feriadoLoadFailed   = (error) => ({ type: FERIADO_LOAD_FAILED, payload: error  });

// Add
export const feriadoAdd         = (feriado) => ({ type: FERIADO_ADD, payload: feriado });
export const feriadoAddSuccess  = (feriado, feriadoStatus) => ({ type: FERIADO_ADD_SUCCESS, feriadoStatus: feriadoStatus, payload: feriado });
export const feriadoAddFailed   = (error) => ({ type: FERIADO_ADD_FAILED, payload: error  });

// Update
export const feriadoUpdate         = (feriado) => ({ type: FERIADO_UPDATE, payload: feriado });
export const feriadoUpdateSuccess  = (feriado, feriadoStatus) => ({ type: FERIADO_UPDATE_SUCCESS, feriadoStatus: feriadoStatus, payload: feriado  });
export const feriadoUpdateFailed   = (error) => ({ type: FERIADO_UPDATE_FAILED, payload: error  });

// remove
export const feriadoRemove         = (feriadoId) => ({ type: FERIADO_REMOVE, payload: feriadoId});
export const feriadoRemoveSuccess  = (feriado, feriadoDeletado) => ({ type: FERIADO_REMOVE_SUCCESS, feriadoDeletado: feriadoDeletado, payload: feriado  });
export const feriadoRemoveFailed   = (error) => ({ type: FERIADO_REMOVE_FAILED, payload: error  });