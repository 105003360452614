// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    CREDENCIAR,
    CREDENCIAR_SUCCESS,
    CREDENCIAR_FAILED
} from './constants';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string): AuthAction => ({
    type: LOGIN_USER,
    payload: { username, password },
});

export const loginUserSuccess = (user: string): AuthAction => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error: string): AuthAction => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (fullname: string, email: string, cpf: String, password: string): AuthAction => ({
    type: REGISTER_USER,
    payload: { fullname, email, cpf, password },
});

export const registerUserSuccess = (user: {}): AuthAction => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error: string): AuthAction => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history: any): AuthAction => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (username: string, url: string): AuthAction => ({
    type: FORGET_PASSWORD,
    payload: { username, url },
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const resetPassword = (password: string, confirmPassword: string, token: string): AuthAction => ({
    type: RESET_PASSWORD,
    payload: { password, confirmPassword, token },
});

export const resetPasswordSuccess = (resetPasswordStatus: {}): AuthAction => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: resetPasswordStatus,
});

export const resetPasswordFailed = (error: string): AuthAction => ({
    type: RESET_PASSWORD_FAILED,
    payload: error,
});

export const credenciar = (credenciar: {}): AuthAction => ({
    type: CREDENCIAR,
    payload: credenciar,
});

export const credenciarSuccess = (credenciarStatus: {}): AuthAction => ({
    type: CREDENCIAR_SUCCESS,
    payload: credenciarStatus,
});

export const credenciarFailed = (error: string): AuthAction => ({
    type: CREDENCIAR_FAILED,
    payload: error,
});