/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import {
    SectionRegistrations_Container,
    SectionRegistrations_Label,
    SectionRegistrations_Items,
} from '../../navbar-menu';

import { URL_PORTAL_V2 } from '../../../../helpers/url';

// -> Icon <--------------------------------------------------------------------------------------

const IconTypeOfExpenses = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33325 36.6667H31.6666C32.9927 36.6667 34.2644 36.1399 35.2021 35.2022C36.1398 34.2645 36.6666 32.9928 36.6666 31.6667V18.7298C35.6874 19.6094 34.5628 20.3302 33.3333 20.8519V31.6667C33.3333 32.1087 33.1577 32.5326 32.8451 32.8452C32.5325 33.1577 32.1086 33.3333 31.6666 33.3333C31.2246 33.3333 30.8006 33.1577 30.4881 32.8452C30.1755 32.5326 29.9999 32.1087 29.9999 31.6667V21.725C29.5569 21.7745 29.1066 21.8 28.6503 21.8C24.2634 21.8 20.4263 19.4459 18.3333 15.9321V20H8.33325V10H16.6519C16.6509 9.93346 16.6503 9.8668 16.6503 9.8C16.6503 7.41879 17.3439 5.19957 18.54 3.33333H4.99992C4.55789 3.33333 4.13397 3.50893 3.82141 3.82149C3.50885 4.13405 3.33325 4.55797 3.33325 5V31.6667C3.33325 32.9928 3.86004 34.2645 4.79772 35.2022C5.7354 36.1399 7.00717 36.6667 8.33325 36.6667ZM8.33325 25V21.6667H24.9999V25H8.33325ZM8.33325 30V26.6667H24.9999V30H8.33325Z"
            />
            <path d="M11.6666 13.3333H14.9999V16.6667H11.6666V13.3333Z" />
            <path d="M27.9999 18C23.5815 18 19.9999 14.4184 19.9999 10C19.9999 5.5816 23.5815 2 27.9999 2C32.4183 2 35.9999 5.5816 35.9999 10C35.9999 14.4184 32.4183 18 27.9999 18ZM25.1999 11.6V13.2H27.1999V14.8H28.7999V13.2H29.5999C30.1304 13.2 30.6391 12.9893 31.0141 12.6142C31.3892 12.2391 31.5999 11.7304 31.5999 11.2C31.5999 10.6696 31.3892 10.1609 31.0141 9.78579C30.6391 9.41071 30.1304 9.2 29.5999 9.2H26.3999C26.2938 9.2 26.1921 9.15786 26.1171 9.08284C26.0421 9.00783 25.9999 8.90609 25.9999 8.8C25.9999 8.69391 26.0421 8.59217 26.1171 8.51716C26.1921 8.44214 26.2938 8.4 26.3999 8.4H30.7999V6.8H28.7999V5.2H27.1999V6.8H26.3999C25.8695 6.8 25.3608 7.01071 24.9857 7.38579C24.6106 7.76086 24.3999 8.26957 24.3999 8.8C24.3999 9.33043 24.6106 9.83914 24.9857 10.2142C25.3608 10.5893 25.8695 10.8 26.3999 10.8H29.5999C29.706 10.8 29.8077 10.8421 29.8828 10.9172C29.9578 10.9922 29.9999 11.0939 29.9999 11.2C29.9999 11.3061 29.9578 11.4078 29.8828 11.4828C29.8077 11.5579 29.706 11.6 29.5999 11.6H25.1999Z" />
        </svg>
    );
};

const IconCurrency = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.1111 28.7H35.5556V4.1H13.3333V8.19999H31.1111V28.7ZM31.1111 32.8V38.95C31.1111 40.0816 30.1111 41 28.8733 41H2.23778C1.94473 41.0016 1.65422 40.9498 1.38294 40.8475C1.11166 40.7452 0.864963 40.5945 0.657019 40.404C0.449075 40.2135 0.283984 39.987 0.171232 39.7375C0.0584791 39.4879 0.000287498 39.2203 0 38.95L0.00666671 10.25C0.00666671 9.11839 1.00667 8.19999 2.24445 8.19999H8.88889V2.05C8.88889 1.5063 9.12302 0.98488 9.53976 0.60043C9.95651 0.215981 10.5217 0 11.1111 0H37.7778C38.3671 0 38.9324 0.215981 39.3491 0.60043C39.7659 0.98488 40 1.5063 40 2.05V30.75C40 31.2937 39.7659 31.8151 39.3491 32.1995C38.9324 32.584 38.3671 32.8 37.7778 32.8H31.1111ZM8.88889 28.7V32.8H13.3333V34.85H17.7778V32.8H18.8889C20.3623 32.8 21.7754 32.26 22.8173 31.2989C23.8591 30.3378 24.4444 29.0342 24.4444 27.675C24.4444 26.3157 23.8591 25.0122 22.8173 24.0511C21.7754 23.0899 20.3623 22.55 18.8889 22.55H12.2222C11.9275 22.55 11.6449 22.442 11.4365 22.2498C11.2282 22.0575 11.1111 21.7968 11.1111 21.525C11.1111 21.2531 11.2282 20.9924 11.4365 20.8002C11.6449 20.608 11.9275 20.5 12.2222 20.5H22.2222V16.4H17.7778V14.35H13.3333V16.4H12.2222C10.7488 16.4 9.33572 16.9399 8.29385 17.9011C7.25198 18.8622 6.66667 20.1657 6.66667 21.525C6.66667 22.8842 7.25198 24.1878 8.29385 25.1489C9.33572 26.11 10.7488 26.65 12.2222 26.65H18.8889C19.1836 26.65 19.4662 26.758 19.6746 26.9502C19.8829 27.1424 20 27.4031 20 27.675C20 27.9468 19.8829 28.2075 19.6746 28.3998C19.4662 28.592 19.1836 28.7 18.8889 28.7H8.88889Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const SectionRegistrations = () => {
    return (
        <SectionRegistrations_Container>
            <SectionRegistrations_Label>Cadastros</SectionRegistrations_Label>
            <SectionRegistrations_Items>
                <ItemWithIcon link="/tipodespesa" label="Tipos de Despesas" disable={false}>
                    <IconTypeOfExpenses />
                </ItemWithIcon>
                <ItemWithIcon link="/tarifa" label="Tarifas" disable={false}>
                    <IconCurrency />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/financial-panel/_registrations/rate`}
                    label="Tarifas Identity"
                    disable={false}>
                    <IconCurrency />
                </ItemWithIcon>
            </SectionRegistrations_Items>
        </SectionRegistrations_Container>
    );
};

export default SectionRegistrations;
