
import { TIPODESPESA_LOAD, TIPODESPESA_LOAD_SUCCESS, TIPODESPESA_LOAD_FAILED, TIPODESPESA_LIST, TIPODESPESA_LIST_SUCCESS, TIPODESPESA_LIST_FAILED, TIPODESPESA_ADD, TIPODESPESA_ADD_FAILED, TIPODESPESA_ADD_SUCCESS, TIPODESPESA_REMOVE, TIPODESPESA_REMOVE_FAILED, TIPODESPESA_REMOVE_SUCCESS, TIPODESPESA_UPDATE, TIPODESPESA_UPDATE_SUCCESS, TIPODESPESA_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    tipoDespesas: {},
    tipoDespesa: {},
    loading: false,
    error: null,
    tipoDespesaStatus: null,
    id: {}
};

const ReembolsoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TIPODESPESA_LIST:
            return { ...state, tipoDespesa: {}, loading: true, error: null, tipoDespesaDeletado: null };
        case TIPODESPESA_LIST_SUCCESS:
            return { ...state, tipoDespesas: action.payload, loading: false, error: null, tipoDespesaDeletado: null };
        case TIPODESPESA_LIST_FAILED:
            return { ...state, tipoDespesa: {}, error: action.payload, loading: false, tipoDespesaDeletado: null };
        case TIPODESPESA_LOAD:
            return { ...state, tipoDespesa: {}, loading: true, error: null, tipoDespesaStatus: null, tipoDespesaDeletado: null }
        case TIPODESPESA_LOAD_SUCCESS:
            return { ...state, tipoDespesa: action.payload, loading: false, error: null, tipoDespesaDeletado: null}
        case TIPODESPESA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, tipoDespesaDeletado: null };
        case TIPODESPESA_ADD:
            return { ...state, tipoDespesa: action.payload, tipoDespesaStatus: null, loading: true, error: null, tipoDespesaDeletado: null}
        case TIPODESPESA_ADD_SUCCESS:
            return { ...state, tipoDespesa: action.payload,  tipoDespesaStatus: "Solicitação criada com sucesso", tipoDespesaDeletado: null, loading: false, error: null };
        case TIPODESPESA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TIPODESPESA_UPDATE:
            return { ...state, tipoDespesa: action.payload, tipoDespesaStatus: null, loading: true, error: null}
        case TIPODESPESA_UPDATE_SUCCESS:
            return { ...state, tipoDespesa: action.payload,  tipoDespesaStatus: "Solicitação atualizada com sucesso", tipoDespesaDeletado: null, loading: false, error: null };
        case TIPODESPESA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, tipoDespesaStatus: null };
        case TIPODESPESA_REMOVE:
            return { ...state, tipoDespesa: action.payload, tipoDespesaStatus: null, loading: true, error: null, tipoDespesaDeletado: null}
        case TIPODESPESA_REMOVE_SUCCESS:
            return { ...state, tipoDespesa: {}, tipoDespesaStatus: null,  tipoDespesaDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case TIPODESPESA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, tipoDespesaStatus: null, tipoDespesaDeletado: null };
        default:
            return { ...state };
    }
};

export default ReembolsoReducer;