import styled from 'styled-components';
import theme from '../../styles/theme';

// -> Container <--------------------------------------------------------
export const NavbarMenu_Container = styled.div`
    width: 100%;
    height: 100%;

    /* padding: 1rem; */
    @media (max-width: 720px) {
        display: flex;
        flex-direction: column;
    }
`;

export const NavbarMenu_ContainerLabel = styled.h2`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    color: ${theme.colors.blue};
    margin: 0px 0px 24px 0px;
`;
//TODO
//color: ${props => props.theme.colors.blue};

export const Divider = styled.div`
    background-color: #e4e4e4;
    height: 1px;
    border-radius: 10px;
    margin: 16px 0px 16px 0px;
    width: 100%;
`;

// -> Section Registrations <--------------------------------------------
export const SectionRegistrations_Container = styled.section`
    margin: 0px 0px 16px 24px;
    @media (max-width: 720px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const SectionRegistrations_Label = styled.h3`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    color: ${theme.colors.dark};
`;
//TODO
//  color: ${props => props.theme.colors.dark};`

export const SectionRegistrations_Items = styled.div`
    margin: 0px 0px 0px 32px;
    display: flex;
`;

// -> Section Services <-------------------------------------------------
export const SectionServices_Container = styled.div`
    margin: 0px 0px 16px 24px;
    @media (max-width: 720px) {
        display: flex;
        flex-direction: column;
    }
`;

export const SectionServices_Label = styled.h3`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    color: ${theme.colors.dark};
`;
// color: ${props => props.theme.colors.dark};

export const SectionServices_Items = styled.div`
    margin: 0px 0px 0px 32px;
    display: flex;
`;

// -> Section Reports <--------------------------------------------------
export const SectionReports_Container = styled.section`
    margin: 0 0 16px 24px;
`;

export const SectionReports_Label = styled.h3`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    color: ${theme.colors.dark};
    margin: 0px 0px 16px 12px;
`;

export const SectionReports_Items = styled.div`
    margin: 0px 0px 0px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;
