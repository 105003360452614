import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { GRUPOVEICULO_LOAD, GRUPOVEICULO_LIST, GRUPOVEICULO_ADD, GRUPOVEICULO_UPDATE, GRUPOVEICULO_REMOVE } from './constants';

import {
    grupoVeiculoListSuccess,
    grupoVeiculoListFailed,
    grupoVeiculoAddSuccess,
    grupoVeiculoAddFailed,
    grupoVeiculoUpdateSuccess,
    grupoVeiculoUpdateFailed,
    grupoVeiculoLoadSuccess,
    grupoVeiculoLoadFailed,
    grupoVeiculoRemoveSuccess,
    grupoVeiculoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoVeiculoLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/grupoVeiculo/' + id, options);
        yield put(grupoVeiculoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(grupoVeiculoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoVeiculoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/grupoVeiculo/filter', options);
            yield put(grupoVeiculoListSuccess(response));
        } catch (error) {
            yield put(grupoVeiculoListFailed(error.message));
        }    
}


/**
 * grupoVeiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoVeiculoAdd({ payload: grupoVeiculo  }) {

    const options = {
        body: JSON.stringify(grupoVeiculo) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/grupoVeiculo', options);
        yield put(grupoVeiculoAddSuccess(response));
    } catch (error) {
        yield put(grupoVeiculoAddFailed(error.message));
    }    
}

/**
 * grupoVeiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoVeiculoUpdate({ payload: grupoVeiculo  }) {

    const options = {
        body: JSON.stringify(grupoVeiculo) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/grupoVeiculo/' + grupoVeiculo?.id, options);
        yield put(grupoVeiculoUpdateSuccess(response));
    } catch (error) {
        yield put(grupoVeiculoUpdateFailed(error.message));
    }    
}

/**
 * grupoVeiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoVeiculoRemove({ payload: grupoVeiculo  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/grupoVeiculo/' + grupoVeiculo?.id, options);
        yield put(grupoVeiculoRemoveSuccess({}));
    } catch (error) {
        yield put(grupoVeiculoRemoveFailed(error.message));
    }    
}

export function* watchgrupoVeiculoLoad(): any{
    yield takeEvery(GRUPOVEICULO_LOAD, grupoVeiculoLoad);
}

export function* watchgrupoVeiculoList(): any {
    yield takeEvery(GRUPOVEICULO_LIST, grupoVeiculoList);
}

export function* watchgrupoVeiculoAdd(): any {
    yield takeEvery(GRUPOVEICULO_ADD, grupoVeiculoAdd);
}

export function* watchgrupoVeiculoUpdate(): any {
    yield takeEvery(GRUPOVEICULO_UPDATE, grupoVeiculoUpdate);
}

export function* watchgrupoVeiculoRemove(): any {
    yield takeEvery(GRUPOVEICULO_REMOVE, grupoVeiculoRemove);
}

function* grupoVeiculo(): any {
    yield all([fork(watchgrupoVeiculoList), fork(watchgrupoVeiculoAdd), fork(watchgrupoVeiculoUpdate), fork(watchgrupoVeiculoLoad), fork(watchgrupoVeiculoRemove)]);
}

export default grupoVeiculo;