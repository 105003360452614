import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { TIPODESPESA_LOAD, TIPODESPESA_LIST, TIPODESPESA_ADD, TIPODESPESA_UPDATE, TIPODESPESA_REMOVE } from './constants';

import {
    tipoDespesaListSuccess,
    tipoDespesaListFailed,
    tipoDespesaAddSuccess,
    tipoDespesaAddFailed,
    tipoDespesaUpdateSuccess,
    tipoDespesaUpdateFailed,
    tipoDespesaLoadSuccess,
    tipoDespesaLoadFailed,
    tipoDespesaRemoveSuccess,
    tipoDespesaRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {id} payload - pageNumber and pageSize
 */
function* tipoDespesaLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipodespesa/' + id, options);
        yield put(tipoDespesaLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoDespesaLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoDespesaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/tipodespesa/filter', options);
            yield put(tipoDespesaListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(tipoDespesaListFailed(message));
        }    
}


/**
 * tipoDespesa
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoDespesaAdd({ payload: tipoDespesa  }) {

    const options = {
        body: JSON.stringify(tipoDespesa) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipodespesa', options);
        yield put(tipoDespesaAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoDespesaAddFailed(message));
    }    
}

/**
 * tipoDespesa
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoDespesaUpdate({ payload: tipoDespesa  }) {

    const options = {
        body: JSON.stringify(tipoDespesa) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipodespesa/' + tipoDespesa?.id, options);
        yield put(tipoDespesaUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoDespesaUpdateFailed(message));
    }    
}

/**
 * tipoDespesa
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoDespesaRemove({ payload: tipoDespesa  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/tipodespesa/' + tipoDespesa?.id, options);
        yield put(tipoDespesaRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(tipoDespesaRemoveFailed(message));
    }    
}

export function* watchtipoDespesaLoad(): any{
    yield takeEvery(TIPODESPESA_LOAD, tipoDespesaLoad);
}

export function* watchtipoDespesaList(): any {
    yield takeEvery(TIPODESPESA_LIST, tipoDespesaList);
}

export function* watchtipoDespesaAdd(): any {
    yield takeEvery(TIPODESPESA_ADD, tipoDespesaAdd);
}

export function* watchtipoDespesaUpdate(): any {
    yield takeEvery(TIPODESPESA_UPDATE, tipoDespesaUpdate);
}

export function* watchtipoDespesaRemove(): any {
    yield takeEvery(TIPODESPESA_REMOVE, tipoDespesaRemove);
}

function* tipoDespesa(): any {
    yield all([fork(watchtipoDespesaList), fork(watchtipoDespesaAdd), fork(watchtipoDespesaUpdate), fork(watchtipoDespesaLoad), fork(watchtipoDespesaRemove)]);
}

export default tipoDespesa;