import { 
    SOLICITACAO_LIST,
    SOLICITACAO_LIST_SUCCESS,
    SOLICITACAO_LIST_FAILED,
    SOLICITACAO_LISTALL,
    SOLICITACAO_LISTALL_SUCCESS,
    SOLICITACAO_LISTALL_FAILED,
    SOLICITACAO_APROVAR,
    SOLICITACAO_APROVAR_SUCCESS,
    SOLICITACAO_APROVAR_FAILED,
    SOLICITACAO_REJEITAR,
    SOLICITACAO_REJEITAR_SUCCESS,
    SOLICITACAO_REJEITAR_FAILED,
    SOLICITACAO_DOWNLOAD,
    SOLICITACAO_DOWNLOAD_SUCCESS,
    SOLICITACAO_DOWNLOAD_FAILED
} from './constants';

// List
export const solicitacaoList        = (filter) => ({ type: SOLICITACAO_LIST, payload: filter,});
export const solicitacaoListSuccess = (solicitacoes) => ({ type: SOLICITACAO_LIST_SUCCESS, payload: solicitacoes });
export const solicitacaoListFailed  = (error) => ({ type: SOLICITACAO_LIST_FAILED, payload: error  });

// List
export const solicitacaoListAll        = (filter) => ({ type: SOLICITACAO_LISTALL, payload: filter,});
export const solicitacaoListAllSuccess = (solicitacoes) => ({ type: SOLICITACAO_LISTALL_SUCCESS, payload: solicitacoes });
export const solicitacaoListAllFailed  = (error) => ({ type: SOLICITACAO_LISTALL_FAILED, payload: error  });

// Update
export const solicitacaoAprovar         = (id) => ({ type: SOLICITACAO_APROVAR, payload: id });
export const solicitacaoAprovarSuccess  = (solicitacao, solicitacaoStatus) => ({ type: SOLICITACAO_APROVAR_SUCCESS, solicitacaoStatus: solicitacaoStatus, payload: solicitacao });
export const solicitacaoAprovarFailed   = (error) => ({ type: SOLICITACAO_APROVAR_FAILED, payload: error  });

// Aprovar files
export const solicitacaoRejeitar          = (id, motivo) => ({ type: SOLICITACAO_REJEITAR, payload: { id, motivo } });
export const solicitacaoRejeitarSuccess   = (solicitacao) => ({ type: SOLICITACAO_REJEITAR_SUCCESS, payload: solicitacao });
export const solicitacaoRejeitarFailed    = (error) => ({ type: SOLICITACAO_REJEITAR_FAILED, payload: error  });

export const solicitacaoDownload          = (id, nomeArquivo) => ({ type: SOLICITACAO_DOWNLOAD, payload: { id, nomeArquivo } });
export const solicitacaoDownloadSuccess   = (solicitacaoStatus) => ({ type: SOLICITACAO_DOWNLOAD_SUCCESS, payload: solicitacaoStatus });
export const solicitacaoDownloadFailed    = (error) => ({ type: SOLICITACAO_DOWNLOAD_FAILED, payload: error });