import { 
        SOLICITACAO_LIST, 
        SOLICITACAO_LIST_SUCCESS, 
        SOLICITACAO_LIST_FAILED,
        SOLICITACAO_LISTALL, 
        SOLICITACAO_LISTALL_SUCCESS, 
        SOLICITACAO_LISTALL_FAILED,
        SOLICITACAO_APROVAR,
        SOLICITACAO_APROVAR_SUCCESS,
        SOLICITACAO_APROVAR_FAILED,
        SOLICITACAO_REJEITAR,
        SOLICITACAO_REJEITAR_SUCCESS,
        SOLICITACAO_REJEITAR_FAILED,
        SOLICITACAO_DOWNLOAD,
        SOLICITACAO_DOWNLOAD_SUCCESS,
        SOLICITACAO_DOWNLOAD_FAILED
} from './constants';

const INIT_STATE = {
    solicitacoes: {},
    solicitacao:{},
    loading: false,
    error: null,
};

const SolicitacaoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SOLICITACAO_LIST:
            return { ...state, loading: true, error: null };
        case SOLICITACAO_LIST_SUCCESS:
            return { ...state, solicitacoes: action.payload, loading: false, error: null };
        case SOLICITACAO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case SOLICITACAO_LISTALL:
            return { ...state, loading: true, error: null };
        case SOLICITACAO_LISTALL_SUCCESS:
            return { ...state, solicitacoes: action.payload, loading: false, error: null };
        case SOLICITACAO_LISTALL_FAILED:
            return { ...state, error: action.payload, loading: false };
        case SOLICITACAO_APROVAR:
                return { ...state, solicitacaoStatus: null, loading: true, error: null, solicitacaoDeletado: null}
        case SOLICITACAO_APROVAR_SUCCESS:
                return { ...state, solicitacao: action.payload,  solicitacaoStatus: "Solicitação Aprovada com sucesso", solicitacaoDeletado: null, loading: false, error: null };
        case SOLICITACAO_APROVAR_FAILED:
                return { ...state, error: action.payload, loading: false };
        case SOLICITACAO_REJEITAR:
                return { ...state, solicitacaoStatus: null, loading: true, error: null, solicitacaoDeletado: null}
        case SOLICITACAO_REJEITAR_SUCCESS:
                return { ...state, solicitacao: action.payload,  solicitacaoStatus: "Solicitação Rejeitada com sucesso", solicitacaoDeletado: null, loading: false, error: null };
        case SOLICITACAO_REJEITAR_FAILED:
                return { ...state, error: action.payload, loading: false };
        case SOLICITACAO_DOWNLOAD:
                return { ...state, error: action.payload, loading: true };
        case SOLICITACAO_DOWNLOAD_SUCCESS:
                return { ...state, solicitacaoStatus: "Download efetuado com sucesso", loading: false };
        case SOLICITACAO_DOWNLOAD_FAILED: 
                return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default SolicitacaoReducer;