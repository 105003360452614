import { 
            NOTIFICACAO_LIST,
            NOTIFICACAO_LIST_SUCCESS,
            NOTIFICACAO_LIST_FAILED,
            NOTIFICACAO_ADD,
            NOTIFICACAO_ADD_SUCCESS,
            NOTIFICACAO_ADD_FAILED,
            NOTIFICACAO_UPDATE,
            NOTIFICACAO_UPDATE_SUCCESS,
            NOTIFICACAO_UPDATE_FAILED,
            NOTIFICACAO_LOAD,
            NOTIFICACAO_LOAD_SUCCESS,
            NOTIFICACAO_LOAD_FAILED,
            NOTIFICACAO_REMOVE,
            NOTIFICACAO_REMOVE_SUCCESS,
            NOTIFICACAO_REMOVE_FAILED,
            NOTIFICACAO_NAOLIDAS,
            NOTIFICACAO_NAOLIDAS_SUCCESS,
            NOTIFICACAO_NAOLIDAS_FAILED
        } from './constants';

// List
export const notificacaoList        = (filter) => ({ type: NOTIFICACAO_LIST, payload: filter,});
export const notificacaoListSuccess = (notificacoes) => ({ type: NOTIFICACAO_LIST_SUCCESS, payload: notificacoes });
export const notificacaoListFailed  = (error) => ({ type: NOTIFICACAO_LIST_FAILED, payload: error  });

// List nao lidas
export const notificacaoNaoLidas        = () => ({ type: NOTIFICACAO_NAOLIDAS });
export const notificacaoNaoLidasSuccess = (notificacoesNaoLidas) => ({ type: NOTIFICACAO_NAOLIDAS_SUCCESS, payload: notificacoesNaoLidas });
export const notificacaoNaoLidasFailed  = (error) => ({ type: NOTIFICACAO_NAOLIDAS_FAILED, payload: error  });

export const notificacaoLoad        = (id) => ({ type: NOTIFICACAO_LOAD, payload: id });
export const notificacaoLoadSuccess  = (notificacao) => ({ type: NOTIFICACAO_LOAD_SUCCESS, payload: notificacao });
export const notificacaoLoadFailed   = (error) => ({ type: NOTIFICACAO_LOAD_FAILED, payload: error  });

// Add
export const notificacaoAdd         = (notificacao) => ({ type: NOTIFICACAO_ADD, payload: notificacao });
export const notificacaoAddSuccess  = (notificacao, notificacaoStatus) => ({ type: NOTIFICACAO_ADD_SUCCESS, notificacaoStatus: notificacaoStatus, payload: notificacao });
export const notificacaoAddFailed   = (error) => ({ type: NOTIFICACAO_ADD_FAILED, payload: error  });

// Update
export const notificacaoUpdate         = (notificacao) => ({ type: NOTIFICACAO_UPDATE, payload: notificacao });
export const notificacaoUpdateSuccess  = (notificacao, notificacaoStatus) => ({ type: NOTIFICACAO_UPDATE_SUCCESS, notificacaoStatus: notificacaoStatus, payload: notificacao });
export const notificacaoUpdateFailed   = (error) => ({ type: NOTIFICACAO_UPDATE_FAILED, payload: error  });

// remove
export const notificacaoRemove         = (notificacao) => ({ type: NOTIFICACAO_REMOVE, payload: notificacao });
export const notificacaoRemoveSuccess  = (notificacao, notificacaoDeletado) => ({ type: NOTIFICACAO_REMOVE_SUCCESS, notificacaoDeletado: notificacaoDeletado, payload: notificacao });
export const notificacaoRemoveFailed   = (error) => ({ type: NOTIFICACAO_REMOVE_FAILED, payload: error  });