/* PERMISSAO */
export const PERMISSAO_LIST           = 'PERMISSAO_LIST';
export const PERMISSAO_LIST_SUCCESS   = 'PERMISSAO_LIST_SUCCESS';
export const PERMISSAO_LIST_FAILED    = 'PERMISSAO_LIST_FAILED';
export const PERMISSAO_ADD            = 'PERMISSAO_ADD';
export const PERMISSAO_ADD_SUCCESS    = 'PERMISSAO_ADD_SUCCESS';
export const PERMISSAO_ADD_FAILED     = 'PERMISSAO_ADD_FAILED';
export const PERMISSAO_UPDATE         = 'PERMISSAO_UPDATE';
export const PERMISSAO_UPDATE_SUCCESS = 'PERMISSAO_UPDATE_SUCCESS';
export const PERMISSAO_UPDATE_FAILED  = 'PERMISSAO_UPDATE_FAILED';
export const PERMISSAO_REMOVE         = 'PERMISSAO_REMOVE';
export const PERMISSAO_REMOVE_SUCCESS = 'PERMISSAO_REMOVE_SUCCESS';
export const PERMISSAO_REMOVE_FAILED  = 'PERMISSAO_REMOVE_FAILED';
export const PERMISSAO_LOAD           = 'PERMISSAO_LOAD'
export const PERMISSAO_LOAD_SUCCESS   = 'PERMISSAO_LOAD_SUCCESS';
export const PERMISSAO_LOAD_FAILED    = 'PERMISSAO_LOAD_FAILED';