import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { LOCAL_LOAD, LOCAL_LIST, LOCAL_ADD, LOCAL_UPDATE, LOCAL_REMOVE } from './constants';

import {
    localListSuccess,
    localListFailed,
    localAddSuccess,
    localAddFailed,
    localUpdateSuccess,
    localUpdateFailed,
    localLoadSuccess,
    localLoadFailed,
    localRemoveSuccess,
    localRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* localLoad({ payload: id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/local/' + id, options);
        yield put(localLoadSuccess(response));
    } catch (error) {
        yield put(localLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* localList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/local/filter', options);
            yield put(localListSuccess(response));
        } catch (error) {
            yield put(localListFailed(error.message));
        }    
}


/**
 * local
 * @param {*} payload - pageNumber and pageSize
 */
function* localAdd({ payload: local }) {

    const options = {
        body: JSON.stringify(local) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/local', options);
        yield put(localAddSuccess(response));
    } catch (error) {
        yield put(localAddFailed(error.message));
    }    
}

/**
 * local
 * @param {*} payload - pageNumber and pageSize
 */
function* localUpdate({ payload: local  }) {

    const options = {
        body: JSON.stringify(local) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/local/' + local.id, options);
        yield put(localUpdateSuccess(response));
    } catch (error) {
        yield put(localUpdateFailed(error.message));
    }    
}

/**
 * local
 * @param {*} payload - pageNumber and pageSize
 */
function* localRemove({ payload: local  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/local/' + local.id, options);
        yield put(localRemoveSuccess({}));
    } catch (error) {
        yield put(localRemoveFailed(error.message));
    }    
}

export function* watchLocalLoad(): any{
    yield takeEvery(LOCAL_LOAD, localLoad);
}

export function* watchLocalList(): any {
    yield takeEvery(LOCAL_LIST, localList);
}

export function* watchLocalAdd(): any {
    yield takeEvery(LOCAL_ADD, localAdd);
}

export function* watchLocalUpdate(): any {
    yield takeEvery(LOCAL_UPDATE, localUpdate);
}

export function* watchLocalRemove(): any {
    yield takeEvery(LOCAL_REMOVE, localRemove);
}

function* local(): any {
    yield all([fork(watchLocalList), fork(watchLocalAdd), fork(watchLocalUpdate), fork(watchLocalLoad), fork(watchLocalRemove)]);
}

export default local;