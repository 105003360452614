/* VEICULO */
export const AJUSTEKM_LIST           = 'AJUSTEKM_LIST';
export const AJUSTEKM_LIST_SUCCESS   = 'AJUSTEKM_LIST_SUCCESS';
export const AJUSTEKM_LIST_FAILED    = 'AJUSTEKM_LIST_FAILED';
export const AJUSTEKM_ADD            = 'AJUSTEKM_ADD';
export const AJUSTEKM_ADD_SUCCESS    = 'AJUSTEKM_ADD_SUCCESS';
export const AJUSTEKM_ADD_FAILED     = 'AJUSTEKM_ADD_FAILED';
export const AJUSTEKM_UPDATE         = 'AJUSTEKM_UPDATE';
export const AJUSTEKM_UPDATE_SUCCESS = 'AJUSTEKM_UPDATE_SUCCESS';
export const AJUSTEKM_UPDATE_FAILED  = 'AJUSTEKM_UPDATE_FAILED';
export const AJUSTEKM_REMOVE         = 'AJUSTEKM_REMOVE';
export const AJUSTEKM_REMOVE_SUCCESS = 'AJUSTEKM_REMOVE_SUCCESS';
export const AJUSTEKM_REMOVE_FAILED  = 'AJUSTEKM_REMOVE_FAILED';
export const AJUSTEKM_LOAD           = 'AJUSTEKM_LOAD'
export const AJUSTEKM_LOAD_SUCCESS   = 'AJUSTEKM_LOAD_SUCCESS';
export const AJUSTEKM_LOAD_FAILED    = 'AJUSTEKM_LOAD_FAILED';