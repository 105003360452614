/* TARIFA */
export const TARIFA_LIST           = 'TARIFA_LIST';
export const TARIFA_LIST_SUCCESS   = 'TARIFA_LIST_SUCCESS';
export const TARIFA_LIST_FAILED    = 'TARIFA_LIST_FAILED';
export const TARIFA_ADD            = 'TARIFA_ADD';
export const TARIFA_ADD_SUCCESS    = 'TARIFA_ADD_SUCCESS';
export const TARIFA_ADD_FAILED     = 'TARIFA_ADD_FAILED';
export const TARIFA_UPDATE         = 'TARIFA_UPDATE';
export const TARIFA_UPDATE_SUCCESS = 'TARIFA_UPDATE_SUCCESS';
export const TARIFA_UPDATE_FAILED  = 'TARIFA_UPDATE_FAILED';
export const TARIFA_REMOVE         = 'TARIFA_REMOVE';
export const TARIFA_REMOVE_SUCCESS = 'TARIFA_REMOVE_SUCCESS';
export const TARIFA_REMOVE_FAILED  = 'TARIFA_REMOVE_FAILED';
export const TARIFA_LOAD           = 'TARIFA_LOAD'
export const TARIFA_LOAD_SUCCESS   = 'TARIFA_LOAD_SUCCESS';
export const TARIFA_LOAD_FAILED    = 'TARIFA_LOAD_FAILED';