import { 
    GRUPOACESSO_LIST,
    GRUPOACESSO_LIST_SUCCESS,
    GRUPOACESSO_LIST_FAILED,
    GRUPOACESSO_ADD,
    GRUPOACESSO_ADD_SUCCESS,
    GRUPOACESSO_ADD_FAILED,
    GRUPOACESSO_UPDATE,
    GRUPOACESSO_UPDATE_SUCCESS,
    GRUPOACESSO_UPDATE_FAILED,
    GRUPOACESSO_LOAD,
    GRUPOACESSO_LOAD_SUCCESS,
    GRUPOACESSO_LOAD_FAILED,
    GRUPOACESSO_REMOVE,
    GRUPOACESSO_REMOVE_SUCCESS,
    GRUPOACESSO_REMOVE_FAILED
} from './constants';

// List
export const grupoAcessoList        = (filter) => ({ type: GRUPOACESSO_LIST, payload: filter,});
export const grupoAcessoListSuccess = (grupoAcessos) => ({ type: GRUPOACESSO_LIST_SUCCESS, payload: { grupoAcessos } });
export const grupoAcessoListFailed  = (error) => ({ type: GRUPOACESSO_LIST_FAILED, payload: error  });

export const grupoAcessoLoad        = (id) => ({ type: GRUPOACESSO_LOAD, payload: { id } });
export const grupoAcessoLoadSuccess  = (grupoAcesso) => ({ type: GRUPOACESSO_LOAD_SUCCESS, payload: { grupoAcesso } });
export const grupoAcessoLoadFailed   = (error) => ({ type: GRUPOACESSO_LOAD_FAILED, payload: error  });

// Add
export const grupoAcessoAdd         = (grupoAcesso) => ({ type: GRUPOACESSO_ADD, payload: { grupoAcesso } });
export const grupoAcessoAddSuccess  = (grupoAcesso, grupoAcessoStatus) => ({ type: GRUPOACESSO_ADD_SUCCESS, grupoAcessoStatus: grupoAcessoStatus, payload: { grupoAcesso } });
export const grupoAcessoAddFailed   = (error) => ({ type: GRUPOACESSO_ADD_FAILED, payload: error  });

// Update
export const grupoAcessoUpdate         = (grupoAcesso) => ({ type: GRUPOACESSO_UPDATE, payload: { grupoAcesso } });
export const grupoAcessoUpdateSuccess  = (grupoAcesso, grupoAcessoStatus) => ({ type: GRUPOACESSO_UPDATE_SUCCESS, grupoAcessoStatus: grupoAcessoStatus, payload: { grupoAcesso } });
export const grupoAcessoUpdateFailed   = (error) => ({ type: GRUPOACESSO_UPDATE_FAILED, payload: error  });

// remove
export const grupoAcessoRemove         = (grupoAcesso) => ({ type: GRUPOACESSO_REMOVE, payload: { grupoAcesso } });
export const grupoAcessoRemoveSuccess  = (grupoAcesso, grupoAcessoDeletado) => ({ type: GRUPOACESSO_REMOVE_SUCCESS, grupoAcessoDeletado: grupoAcessoDeletado, payload: { grupoAcesso } });
export const grupoAcessoRemoveFailed   = (error) => ({ type: GRUPOACESSO_REMOVE_FAILED, payload: error  });