import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { ESTACAO_LOAD, ESTACAO_LIST, ESTACAO_ADD, ESTACAO_UPDATE, ESTACAO_REMOVE } from './constants';

import {
    estacaoListSuccess,
    estacaoListFailed,
    estacaoAddSuccess,
    estacaoAddFailed,
    estacaoUpdateSuccess,
    estacaoUpdateFailed,
    estacaoLoadSuccess,
    estacaoLoadFailed,
    estacaoRemoveSuccess,
    estacaoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* estacaoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/estacao/' + id, options);
        yield put(estacaoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(estacaoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* estacaoList({ payload: { pageNumber, pageSize, rota, motoristaId } }) {

        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
        };
    
        try {

            if(rota !== null && rota !== undefined && rota !== ""){
                const response = yield call(fetchJSON, rota, options);            
                yield put(estacaoListSuccess(response));
            }else if((pageNumber !== null && pageNumber !== undefined) && (pageSize !== null && pageSize !== undefined)){
                const response = yield call(fetchJSON, '/central/v1/api/v1/estacao?pageNumber=' + pageNumber + '&pageSize=' + pageSize, options);
                yield put(estacaoListSuccess(response));
            }else if (motoristaId){
                const response = yield call(fetchJSON, '/central/v1/api/v1/estacao?motoristaId=' + motoristaId, options);
                yield put(estacaoListSuccess(response));
            }
            else{
                const response = yield call(fetchJSON, '/central/v1/api/v1/estacao', options);
                yield put(estacaoListSuccess(response));
            }
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(estacaoListFailed(message));
        }    
}


/**
 * estacao
 * @param {*} payload - pageNumber and pageSize
 */
function* estacaoAdd({ payload: { estacao }  }) {

    const options = {
        body: JSON.stringify(estacao) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/estacao', options);
        yield put(estacaoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(estacaoAddFailed(message));
    }    
}

/**
 * estacao
 * @param {*} payload - pageNumber and pageSize
 */
function* estacaoUpdate({ payload: { estacao }  }) {

    const options = {
        body: JSON.stringify(estacao) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/estacao/' + estacao?.id, options);
        yield put(estacaoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(estacaoUpdateFailed(message));
    }    
}

/**
 * estacao
 * @param {*} payload - pageNumber and pageSize
 */
function* estacaoRemove({ payload: { estacao }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/estacao/' + estacao?.id, options);
        yield put(estacaoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(estacaoRemoveFailed(message));
    }    
}

export function* watchEstacaoLoad(): any{
    yield takeEvery(ESTACAO_LOAD, estacaoLoad);
}

export function* watchEstacaoList(): any {
    yield takeEvery(ESTACAO_LIST, estacaoList);
}

export function* watchEstacaoAdd(): any {
    yield takeEvery(ESTACAO_ADD, estacaoAdd);
}

export function* watchEstacaoUpdate(): any {
    yield takeEvery(ESTACAO_UPDATE, estacaoUpdate);
}

export function* watchEstacaoRemove(): any {
    yield takeEvery(ESTACAO_REMOVE, estacaoRemove);
}

function* estacao(): any {
    yield all([fork(watchEstacaoList), fork(watchEstacaoAdd), fork(watchEstacaoUpdate), fork(watchEstacaoLoad), fork(watchEstacaoRemove)]);
}

export default estacao;