/* LOCAL */
export const LOCAL_LIST           = 'LOCAL_LIST';
export const LOCAL_LIST_SUCCESS   = 'LOCAL_LIST_SUCCESS';
export const LOCAL_LIST_FAILED    = 'LOCAL_LIST_FAILED';
export const LOCAL_ADD            = 'LOCAL_ADD';
export const LOCAL_ADD_SUCCESS    = 'LOCAL_ADD_SUCCESS';
export const LOCAL_ADD_FAILED     = 'LOCAL_ADD_FAILED';
export const LOCAL_UPDATE         = 'LOCAL_UPDATE';
export const LOCAL_UPDATE_SUCCESS = 'LOCAL_UPDATE_SUCCESS';
export const LOCAL_UPDATE_FAILED  = 'LOCAL_UPDATE_FAILED';
export const LOCAL_REMOVE         = 'LOCAL_REMOVE';
export const LOCAL_REMOVE_SUCCESS = 'LOCAL_REMOVE_SUCCESS';
export const LOCAL_REMOVE_FAILED  = 'LOCAL_REMOVE_FAILED';
export const LOCAL_LOAD           = 'LOCAL_LOAD'
export const LOCAL_LOAD_SUCCESS   = 'LOCAL_LOAD_SUCCESS';
export const LOCAL_LOAD_FAILED    = 'LOCAL_LOAD_FAILED';