import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { 
    ALTERARENDPASS_LOAD, 
    ALTERARENDPASS_LIST, 
    ALTERARENDPASS_ADD, 
    ALTERARENDPASS_UPDATE, 
    ALTERARENDPASS_REMOVE,
    ALTERARENDPASS_APROVAR,
    ALTERARENDPASS_REJEITAR,
    ALTERARENDPASS_DOWNLOAD
} from './constants';

import {
     alterarEndPassListSuccess,
     alterarEndPassListFailed,
     alterarEndPassAddSuccess,
     alterarEndPassAddFailed,
     alterarEndPassUpdateSuccess,
     alterarEndPassUpdateFailed,
     alterarEndPassLoadSuccess,
     alterarEndPassLoadFailed,
     alterarEndPassRemoveSuccess,
     alterarEndPassRemoveFailed,
     alterarEndPassAprovarSuccess,
     alterarEndPassAprovarFailed,
     alterarEndPassRejeitarSuccess,
     alterarEndPassRejeitarFailed,
     alterarEndPassDownloadFailed,
     alterarEndPassDownloadSuccess

} from './actions';
import { URL_BASE } from '../../../helpers/url';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function*  alterarEndPassLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoalteracaoendereco/' + id, options);
        yield put( alterarEndPassLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put( alterarEndPassLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function*  alterarEndPassList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
                const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoalteracaoendereco/filter', options);
                yield put(alterarEndPassListSuccess(response));
        } catch (error) {
            yield put( alterarEndPassListFailed(error.message));
        }    
}


/**
 *  alterarEndPass
 * @param {*} payload - pageNumber and pageSize
 */
function*  alterarEndPassAdd({ payload: { alterarEndPassageiro }  }) {

    const options = {
        body: JSON.stringify(alterarEndPassageiro) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoalteracaoendereco', options);
        yield put(alterarEndPassAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(alterarEndPassAddFailed(message));
    }    
}

/**
 * alterarEndPass
 * @param {*} payload - pageNumber and pageSize
 */
function* alterarEndPassUpdate({ payload: { alterarEndPassageiro }  }) {

    const options = {
        body: JSON.stringify(alterarEndPassageiro) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoalteracaoendereco/' + alterarEndPassageiro?.id, options);
        yield put(alterarEndPassUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(alterarEndPassUpdateFailed(message));
    }    
}

/**
 * alterarEndPass
 * @param {*} payload - pageNumber and pageSize
 */
function* alterarEndPassRemove({ payload: { alterarEndPassageiro }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/solicitacaoalteracaoendereco/' + alterarEndPassageiro?.id, options);
        yield put(alterarEndPassRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(alterarEndPassRemoveFailed(message));
    }    
}

/**
 * Lista de Solicitações
 * @param {*} payload - pageNumber and pageSize
 */
function* alterarEndPassAprovar({ payload: id }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoalteracaoendereco/aprovar/${id}`, options);
        yield put(alterarEndPassAprovarSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(alterarEndPassAprovarFailed(message));
    }    
}

function* alterarEndPassRejeitar({ payload: { id, motivo } }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoalteracaoendereco/rejeitar/${id}/${motivo}`, options);
        yield put(alterarEndPassRejeitarSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(alterarEndPassRejeitarFailed(message));
    }    
}

/**
 * download de arquivo
 * @param {*} documentoId - ID do Reembolso
 */
function* alterarEndPassDownload({ payload: { arquivoId, nomeArquivo }  }) {

    try {

        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
        };

        var response =  yield call(fetch, URL_BASE + '/central/v1/api/v1/solicitacaoalteracaoendereco/download/' + arquivoId, options);

       response.blob().then(blob => {

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeArquivo);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })

        yield put(alterarEndPassDownloadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        
        yield put(alterarEndPassDownloadFailed(message));
    }    
}

export function* watchalterarEndPassLoad(): any{
    yield takeEvery(ALTERARENDPASS_LOAD, alterarEndPassLoad);
}

export function* watchalterarEndPassList(): any {
    yield takeEvery(ALTERARENDPASS_LIST, alterarEndPassList);
}

export function* watchalterarEndPassAdd(): any {
    yield takeEvery(ALTERARENDPASS_ADD, alterarEndPassAdd);
}

export function* watchalterarEndPassUpdate(): any {
    yield takeEvery(ALTERARENDPASS_UPDATE, alterarEndPassUpdate);
}

export function* watchalterarEndPassRemove(): any {
    yield takeEvery(ALTERARENDPASS_REMOVE, alterarEndPassRemove);
}
export function* watchalterarEndPassAprovar(): any {
    yield takeEvery(ALTERARENDPASS_APROVAR, alterarEndPassAprovar);
}
export function* watchalterarEndPassRejeitar(): any {
    yield takeEvery(ALTERARENDPASS_REJEITAR, alterarEndPassRejeitar);
}

export function* watchalterarEndPassDownload(): any{
    yield takeEvery(ALTERARENDPASS_DOWNLOAD, alterarEndPassDownload);
}

function* alterarEndPass(): any {
    yield all([
                fork(watchalterarEndPassList), 
                fork(watchalterarEndPassAdd), 
                fork(watchalterarEndPassUpdate), 
                fork(watchalterarEndPassLoad), 
                fork(watchalterarEndPassRemove),
                fork(watchalterarEndPassAprovar),
                fork(watchalterarEndPassRejeitar),
                fork(watchalterarEndPassDownload)
            ]);
}

export default alterarEndPass;