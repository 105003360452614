import React from 'react';
import {PrivateRoute } from './privateRoute'

const DespesaAprovadaList         = React.lazy(() => import('../../../pages/backoffice/despesaaprovada/DespesaAprovadaList'));
const DespesaAprovadaCadastro     = React.lazy(() => import('../../../pages/backoffice/despesaaprovada/DespesaAprovadaCadastro'));

const TipoDespesasList         = React.lazy(() => import('../../../pages/backoffice/tipodespesa/TipoDespesaList'));
const TipoDespesasCadastro     = React.lazy(() => import('../../../pages/backoffice/tipodespesa/TipoDespesaCadastro'));

export const financeiroRoute = [
    { path: '/despesaaprovada',                 exact: true, roles: ['master', 'financeiro'], component: DespesaAprovadaList,     route: PrivateRoute },
    { path: '/despesaaprovada/adicionar',       exact: true, roles: ['master', 'financeiro'], component: DespesaAprovadaCadastro, route: PrivateRoute },
    { path: '/despesaaprovada/atualizar/:id',   exact: true, roles: ['master', 'financeiro'], component: DespesaAprovadaCadastro, route: PrivateRoute },
    { path: '/despesaaprovada/deletar/:id',     exact: true, roles: ['master', 'financeiro'], component: DespesaAprovadaCadastro, route: PrivateRoute },
    
    { path: '/tipodespesa',                    exact: true, roles: ['master', 'financeiro'], component: TipoDespesasList,        route: PrivateRoute },
    { path: '/tipodespesa/adicionar',       exact: true, roles: ['master', 'financeiro'], component: TipoDespesasCadastro,       route: PrivateRoute },
    { path: '/tipodespesa/atualizar/:id',   exact: true, roles: ['master', 'financeiro'], component: TipoDespesasCadastro,       route: PrivateRoute },
    { path: '/tipodespesa/deletar/:id',     exact: true, roles: ['master', 'financeiro'], component: TipoDespesasCadastro,       route: PrivateRoute } 
]