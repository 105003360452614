import { getLoggedInUser } from '../../authUtils';

var opcoes = [];

opcoes = [
    ...opcoes,
    {
        header: 'Painel Motorista',
        name: 'Solicitações',
        icon: 'mdi mdi-comment-question-outline',
        path: '/solicitacao',
    },
    {        
        name: 'Meu Extrato',
        icon: 'mdi mdi-file-document-outline',
        path: '/motorista/extrato',
    },
];

if (getLoggedInUser()?.usuario?.permiteReembolso) opcoes = [...opcoes, { name: 'Reembolso', path: '/reembolso' }];

const motoristaMenu = opcoes;

export const MotoristaMenu = () => motoristaMenu;
