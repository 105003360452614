import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

import { ConfigProvider } from 'antd';
import pt_BR from 'antd/lib/locale/pt_BR';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import config from './config';

// if (config.appInsightsInstrumentationKey) {
//     const appInsights = new ApplicationInsights({
//         config: {
//             instrumentationKey: config.appInsightsInstrumentationKey,
//             /* ...Other Configuration Options... */
//         },
//     });
//     appInsights.loadAppInsights();
//     appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
// }

ReactDOM.render(
    <Provider store={configureStore()}>
        <ConfigProvider locale={pt_BR}>
            <App />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
