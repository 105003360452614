/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import { SectionServices_Container, SectionServices_Label, SectionServices_Items } from '../../navbar-menu';

const IconOnlineOrderTracking = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 13.3333V6.66665H6.655C5.74167 6.66665 5 7.40831 5 8.32165V35.0116C5.00349 35.4493 5.17903 35.8698 5.4887 36.1791C5.79836 36.4885 6.2173 36.6636 6.655 36.6666H20.425C18.9538 34.5558 18.2217 32.0178 18.3426 29.4477C18.4635 26.8776 19.4306 24.4195 21.0935 22.456C22.7563 20.4926 25.0215 19.1339 27.5367 18.5914C30.0518 18.049 32.6757 18.3532 35 19.4566V8.32165C35 7.40831 34.2583 6.66665 33.345 6.66665H30V13.3333H10ZM11.6667 28.3333H15V31.6666H11.6667V28.3333ZM11.6667 23.3333H15V26.6666H11.6667V23.3333ZM11.6667 18.3333H15V21.6666H11.6667V18.3333Z"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.6667 9.99998V3.33331H13.3333V9.99998H26.6667Z" />
            <path d="M30 21.6666C34.6025 21.6666 38.3333 25.3975 38.3333 30C38.3333 34.6025 34.6025 38.3333 30 38.3333C25.3975 38.3333 21.6667 34.6025 21.6667 30C21.6667 25.3975 25.3975 21.6666 30 21.6666ZM30 23.3333C28.2319 23.3333 26.5362 24.0357 25.286 25.2859C24.0357 26.5362 23.3333 28.2319 23.3333 30C23.3333 31.7681 24.0357 33.4638 25.286 34.714C26.5362 35.9643 28.2319 36.6666 30 36.6666C31.7681 36.6666 33.4638 35.9643 34.714 34.714C35.9643 33.4638 36.6667 31.7681 36.6667 30C36.6667 28.2319 35.9643 26.5362 34.714 25.2859C33.4638 24.0357 31.7681 23.3333 30 23.3333ZM30 25.8333C30.8772 25.8336 31.7318 26.1108 32.4423 26.6253C33.1527 27.1397 33.6827 27.8653 33.9566 28.6986C34.2306 29.5318 34.2346 30.4303 33.9681 31.266C33.7016 32.1017 33.1781 32.8319 32.4723 33.3527C31.7665 33.8735 30.9143 34.1583 30.0372 34.1665C29.1601 34.1746 28.3028 33.9058 27.5874 33.3982C26.872 32.8906 26.335 32.1703 26.053 31.3397C25.771 30.5091 25.7583 29.6107 26.0167 28.7725C26.2116 29.2081 26.5502 29.5635 26.9759 29.7793C27.4015 29.9951 27.8884 30.0581 28.355 29.9579C28.8215 29.8576 29.2395 29.6002 29.539 29.2286C29.8384 28.857 30.0012 28.3939 30 27.9166C30.0001 27.5154 29.8843 27.1226 29.6666 26.7855C29.4488 26.4485 29.1384 26.1815 28.7725 26.0166C29.1608 25.8975 29.5725 25.8333 30 25.8333Z" />
        </svg>
    );
};

const IconWorkOrderHistory = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 13.3332V6.66656H6.655C5.74167 6.66656 5 7.40823 5 8.32156V35.0116C5 35.9249 5.74167 36.6666 6.655 36.6666H33.345C34.2583 36.6666 35 35.9249 35 35.0116V20.5432C32.6757 21.6467 30.0518 21.9509 27.5367 21.4084C25.0215 20.866 22.7563 19.5073 21.0935 17.5438C20.0444 16.3051 19.2723 14.8696 18.8148 13.3332H10ZM11.6667 28.3332H15V31.6666H11.6667V28.3332ZM11.6667 23.3332H15V26.6666H11.6667V23.3332ZM11.6667 18.3332H15V21.6666H11.6667V18.3332Z"
            />
            <path d="M13.3333 3.33323V9.9999H18.3297C18.3305 7.62026 19.0593 5.29272 20.425 3.33323H13.3333Z" />
            <path d="M30 1.66656C34.6025 1.66656 38.3333 5.3974 38.3333 9.9999C38.3333 14.6024 34.6025 18.3332 30 18.3332C25.3975 18.3332 21.6667 14.6024 21.6667 9.9999H23.3333C23.3333 13.6816 26.3183 16.6666 30 16.6666C33.6817 16.6666 36.6667 13.6816 36.6667 9.9999C36.6667 6.31823 33.6817 3.33323 30 3.33323C27.9467 3.33323 26.11 4.26156 24.8875 5.72073L26.6667 7.4999H21.6667V2.4999L23.7058 4.53823C25.2333 2.7799 27.4867 1.66656 30 1.66656ZM30.8333 5.83323V9.65406L33.5358 12.3566L32.3567 13.5357L29.1667 10.3441V5.83323H30.8333Z" />
        </svg>
    );
};

const IconOrderMonitoring = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 13.3332V6.66656H6.655C5.74167 6.66656 5 7.40823 5 8.32156V35.0116C5 35.9249 5.74167 36.6666 6.655 36.6666H33.345C34.2583 36.6666 35 35.9249 35 35.0116V20.5432C32.6757 21.6467 30.0518 21.9509 27.5367 21.4084C25.0215 20.866 22.7563 19.5073 21.0935 17.5438C20.0444 16.3051 19.2723 14.8696 18.8148 13.3332H10ZM11.6667 28.3332H15V31.6666H11.6667V28.3332ZM11.6667 23.3332H15V26.6666H11.6667V23.3332ZM11.6667 18.3332H15V21.6666H11.6667V18.3332Z"
            />
            <path d="M13.3333 3.33323V9.9999H18.3297C18.3305 7.62026 19.0593 5.29272 20.425 3.33323H13.3333Z" />
            <path d="M36.1346 14.8584L40 18.7229L38.723 19.9999L34.8585 16.1345C33.4206 17.2872 31.632 17.9141 29.7891 17.9115C25.3055 17.9115 21.6667 14.2726 21.6667 9.78904C21.6667 5.30544 25.3055 1.66656 29.7891 1.66656C34.2728 1.66656 37.9116 5.30544 37.9116 9.78904C37.9142 11.6319 37.2873 13.4205 36.1346 14.8584ZM34.3242 14.1887C35.4696 13.0109 36.1092 11.432 36.1066 9.78904C36.1066 6.29818 33.2791 3.47156 29.7891 3.47156C26.2983 3.47156 23.4717 6.29818 23.4717 9.78904C23.4717 13.279 26.2983 16.1065 29.7891 16.1065C31.4321 16.1091 33.011 15.4695 34.1888 14.3241L34.3242 14.1887ZM30.8523 6.33789C30.5862 6.4581 30.3532 6.64108 30.1733 6.87114C29.9935 7.10119 29.8721 7.37144 29.8197 7.65871C29.7672 7.94597 29.7853 8.24167 29.8723 8.52043C29.9592 8.79919 30.1125 9.05269 30.319 9.25917C30.5255 9.46566 30.779 9.61896 31.0578 9.70593C31.3365 9.7929 31.6322 9.81094 31.9195 9.7585C32.2067 9.70606 32.477 9.58471 32.707 9.40486C32.9371 9.225 33.1201 8.99202 33.2403 8.7259C33.4775 9.49629 33.45 10.3239 33.1622 11.0769C32.8744 11.8298 32.3429 12.4648 31.6524 12.8806C30.9619 13.2965 30.152 13.4692 29.3519 13.3714C28.5518 13.2735 27.8074 12.9107 27.2375 12.3407C26.6675 11.7707 26.3047 11.0264 26.2068 10.2263C26.109 9.42617 26.2817 8.61632 26.6976 7.92579C27.1134 7.23525 27.7484 6.70375 28.5013 6.41598C29.2543 6.12821 30.0819 6.10072 30.8523 6.33789Z" />
        </svg>
    );
};

const IconHoursOnline = () => {
    return (
        <svg viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2727 36.5453C8.733 36.5453 1 28.8123 1 19.2726C1 9.73288 8.733 1.99988 18.2727 1.99988C27.8125 1.99988 35.5455 9.73288 35.5455 19.2726C35.5455 20.0772 35.4904 20.8689 35.384 21.6443C33.2627 20.7388 30.9105 20.5121 28.6499 21.0015C28.0533 21.1307 27.4713 21.308 26.9091 21.5303V19.2726H20V10.6362H16.5455V22.7271H24.6682C23.857 23.2875 23.1179 23.9564 22.4732 24.7206C20.8792 26.6101 19.9521 28.9756 19.8362 31.4489C19.7567 33.146 20.0621 34.8285 20.7173 36.3737C19.9187 36.4868 19.1026 36.5453 18.2727 36.5453Z" />
            <path d="M30.7955 39.9999C26.2641 39.9999 22.5909 36.3267 22.5909 31.7953C22.5909 27.264 26.2641 23.5908 30.7955 23.5908C35.3268 23.5908 39 27.264 39 31.7953C39 36.3267 35.3268 39.9999 30.7955 39.9999ZM29.9775 35.0772L35.7781 29.2757L34.618 28.1156L29.9775 32.7569L27.6564 30.4358L26.4963 31.596L29.9775 35.0772Z" />
        </svg>
    );
};

const IconNonCompliance = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 13.3332V6.66652H6.655C5.74167 6.66652 5 7.40819 5 8.32152V35.0115C5.00349 35.4492 5.17903 35.8697 5.4887 36.179C5.79836 36.4884 6.2173 36.6635 6.655 36.6665H20.425C18.9538 34.5557 18.2217 32.0177 18.3426 29.4475C18.4635 26.8774 19.4306 24.4194 21.0935 22.4559C22.7563 20.4925 25.0215 19.1338 27.5367 18.5913C30.0518 18.0489 32.6757 18.353 35 19.4565V8.32152C35 7.40819 34.2583 6.66652 33.345 6.66652H30V13.3332H10ZM11.6667 28.3332H15V31.6665H11.6667V28.3332ZM11.6667 23.3332H15V26.6665H11.6667V23.3332ZM11.6667 18.3332H15V21.6665H11.6667V18.3332Z"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.6667 9.99986V3.33319H13.3333V9.99986H26.6667Z" />
            <path d="M30 37.9999C25.5816 37.9999 22 34.4183 22 29.9999C22 25.5815 25.5816 21.9999 30 21.9999C34.4184 21.9999 38 25.5815 38 29.9999C38 34.4183 34.4184 37.9999 30 37.9999ZM30 28.8687L27.7376 26.6055L26.6056 27.7375L28.8688 29.9999L26.6056 32.2623L27.7376 33.3943L30 31.1311L32.2624 33.3943L33.3944 32.2623L31.1312 29.9999L33.3944 27.7375L32.2624 26.6055L30 28.8687Z" />
        </svg>
    );
};

const IconMonitoramento = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15zm-3.847-8.699a2 2 0 1 0 2.646 2.646 4 4 0 1 1-2.646-2.646z" />
        </svg>
    );
};

const SectionServices = () => {
    return (
        <SectionServices_Container>
            <SectionServices_Label>Serviços</SectionServices_Label>
            <SectionServices_Items>
                <ItemWithIcon link="/corrida/acompanhamento" label="Acompanhamento Online de OS" disable={false}>
                    <IconOnlineOrderTracking />
                </ItemWithIcon>

                <ItemWithIcon link="/corrida/historico" label="Histórico de OS`s" disable={false}>
                    <IconWorkOrderHistory />
                </ItemWithIcon>

                <ItemWithIcon link="/monitoramento/full" label="Monitoramento de OS`s" disable={false}>
                    <IconOrderMonitoring />
                </ItemWithIcon>

                <ItemWithIcon link="/monitoramento/horas" label="Horas Online" disable={false}>
                    <IconHoursOnline />
                </ItemWithIcon>

                <ItemWithIcon link="/nao-conformidade" label="Não-conformidade" disable={false}>
                    <IconNonCompliance />
                </ItemWithIcon>
                
                <ItemWithIcon link="/monitoramento/corridas" label="Monitoramento de corridas" disable={false}>
                    <IconMonitoramento />
                </ItemWithIcon>
            </SectionServices_Items>
        </SectionServices_Container>
    );
};

export default SectionServices;
