/* CONTRATO */
export const CONTRATO_LIST           = 'CONTRATO_LIST';
export const CONTRATO_LIST_SUCCESS   = 'CONTRATO_LIST_SUCCESS';
export const CONTRATO_LIST_FAILED    = 'CONTRATO_LIST_FAILED';
export const CONTRATO_ADD            = 'CONTRATO_ADD';
export const CONTRATO_ADD_SUCCESS    = 'CONTRATO_ADD_SUCCESS';
export const CONTRATO_ADD_FAILED     = 'CONTRATO_ADD_FAILED';
export const CONTRATO_UPDATE         = 'CONTRATO_UPDATE';
export const CONTRATO_UPDATE_SUCCESS = 'CONTRATO_UPDATE_SUCCESS';
export const CONTRATO_UPDATE_FAILED  = 'CONTRATO_UPDATE_FAILED';
export const CONTRATO_REMOVE         = 'CONTRATO_REMOVE';
export const CONTRATO_REMOVE_SUCCESS = 'CONTRATO_REMOVE_SUCCESS';
export const CONTRATO_REMOVE_FAILED  = 'CONTRATO_REMOVE_FAILED';
export const CONTRATO_LOAD           = 'CONTRATO_LOAD'
export const CONTRATO_LOAD_SUCCESS   = 'CONTRATO_LOAD_SUCCESS';
export const CONTRATO_LOAD_FAILED    = 'CONTRATO_LOAD_FAILED';

export const CONTRATO_VEICULO_ADD    = 'CONTRATO_VEICULO_ADD';
export const CONTRATO_VEICULO_REMOVE = 'CONTRATO_VEICULO_REMOVE';

export const CONTRATO_TIPO_ADD       = 'CONTRATO_TIPO_ADD';
export const CONTRATO_TIPO_REMOVE    = 'CONTRATO_TIPO_REMOVE';

export const CALCULAR_DISPONIBILIDADE = 'CALCULAR_DISPONIBILIDADE';
export const CALCULAR_DISPONIBILIDADE_SUCCESS = 'CALCULAR_DISPONIBILIDADE_SUCCESS';
export const CALCULAR_DISPONIBILIDADE_FAILED = 'CALCULAR_DISPONIBILIDADE_FAILED';