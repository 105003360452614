/* VEICULO */
export const VEICULO_LIST           = 'VEICULO_LIST';
export const VEICULO_LIST_SUCCESS   = 'VEICULO_LIST_SUCCESS';
export const VEICULO_LIST_FAILED    = 'VEICULO_LIST_FAILED';
export const VEICULO_ADD            = 'VEICULO_ADD';
export const VEICULO_ADD_SUCCESS    = 'VEICULO_ADD_SUCCESS';
export const VEICULO_ADD_FAILED     = 'VEICULO_ADD_FAILED';
export const VEICULO_UPDATE         = 'VEICULO_UPDATE';
export const VEICULO_UPDATE_SUCCESS = 'VEICULO_UPDATE_SUCCESS';
export const VEICULO_UPDATE_FAILED  = 'VEICULO_UPDATE_FAILED';
export const VEICULO_REMOVE         = 'VEICULO_REMOVE';
export const VEICULO_REMOVE_SUCCESS = 'VEICULO_REMOVE_SUCCESS';
export const VEICULO_REMOVE_FAILED  = 'VEICULO_REMOVE_FAILED';
export const VEICULO_LOAD           = 'VEICULO_LOAD'
export const VEICULO_LOAD_SUCCESS   = 'VEICULO_LOAD_SUCCESS';
export const VEICULO_LOAD_FAILED    = 'VEICULO_LOAD_FAILED';