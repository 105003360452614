import { 
    AUDITORIA_LIST,
    AUDITORIA_LIST_SUCCESS,
    AUDITORIA_LIST_FAILED,
    AUDITORIA_ADD,
    AUDITORIA_ADD_SUCCESS,
    AUDITORIA_ADD_FAILED,
    AUDITORIA_UPDATE,
    AUDITORIA_UPDATE_SUCCESS,
    AUDITORIA_UPDATE_FAILED,
    AUDITORIA_LOAD,
    AUDITORIA_LOAD_SUCCESS,
    AUDITORIA_LOAD_FAILED,
    AUDITORIA_REMOVE,
    AUDITORIA_REMOVE_SUCCESS,
    AUDITORIA_REMOVE_FAILED,
    

} from './constants';

// List
export const auditoriaList        = (filter) => ({ type: AUDITORIA_LIST, payload: filter,});
export const auditoriaListSuccess = (auditorias) => ({ type: AUDITORIA_LIST_SUCCESS, payload: { auditorias } });
export const auditoriaListFailed  = (error) => ({ type: AUDITORIA_LIST_FAILED, payload: error  });

export const auditoriaLoad        = (id) => ({ type: AUDITORIA_LOAD, payload: { id } });
export const auditoriaLoadSuccess  = (auditoria) => ({ type: AUDITORIA_LOAD_SUCCESS, payload: { auditoria } });
export const auditoriaLoadFailed   = (error) => ({ type: AUDITORIA_LOAD_FAILED, payload: error  });

// Add
export const auditoriaAdd         = (auditoria) => ({ type: AUDITORIA_ADD, payload: { auditoria } });
export const auditoriaAddSuccess  = (auditoria, auditoriaStatus) => ({ type: AUDITORIA_ADD_SUCCESS, auditoriaStatus: auditoriaStatus, payload: { auditoria } });
export const auditoriaAddFailed   = (error) => ({ type: AUDITORIA_ADD_FAILED, payload: error  });

// Update
export const auditoriaUpdate         = (auditoria) => ({ type: AUDITORIA_UPDATE, payload: { auditoria } });
export const auditoriaUpdateSuccess  = (auditoria, auditoriaStatus) => ({ type: AUDITORIA_UPDATE_SUCCESS, auditoriaStatus: auditoriaStatus, payload: { auditoria } });
export const auditoriaUpdateFailed   = (error) => ({ type: AUDITORIA_UPDATE_FAILED, payload: error  });

// remove
export const auditoriaRemove         = (auditoria) => ({ type: AUDITORIA_REMOVE, payload: { auditoria } });
export const auditoriaRemoveSuccess  = (auditoria, auditoriaDeletado) => ({ type: AUDITORIA_REMOVE_SUCCESS, auditoriaDeletado: auditoriaDeletado, payload: { auditoria } });
export const auditoriaRemoveFailed   = (error) => ({ type: AUDITORIA_REMOVE_FAILED, payload: error  });

