/* VEICULO */
export const ESTADO_LIST           = 'ESTADO_LIST';
export const ESTADO_LIST_SUCCESS   = 'ESTADO_LIST_SUCCESS';
export const ESTADO_LIST_FAILED    = 'ESTADO_LIST_FAILED';
export const ESTADO_ADD            = 'ESTADO_ADD';
export const ESTADO_ADD_SUCCESS    = 'ESTADO_ADD_SUCCESS';
export const ESTADO_ADD_FAILED     = 'ESTADO_ADD_FAILED';
export const ESTADO_UPDATE         = 'ESTADO_UPDATE';
export const ESTADO_UPDATE_SUCCESS = 'ESTADO_UPDATE_SUCCESS';
export const ESTADO_UPDATE_FAILED  = 'ESTADO_UPDATE_FAILED';
export const ESTADO_REMOVE         = 'ESTADO_REMOVE';
export const ESTADO_REMOVE_SUCCESS = 'ESTADO_REMOVE_SUCCESS';
export const ESTADO_REMOVE_FAILED  = 'ESTADO_REMOVE_FAILED';
export const ESTADO_LOAD           = 'ESTADO_LOAD'
export const ESTADO_LOAD_SUCCESS   = 'ESTADO_LOAD_SUCCESS';
export const ESTADO_LOAD_FAILED    = 'ESTADO_LOAD_FAILED';