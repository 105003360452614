/* VEICULO */
export const TROCAVEICULO_LIST           = 'TROCAVEICULO_LIST';
export const TROCAVEICULO_LIST_SUCCESS   = 'TROCAVEICULO_LIST_SUCCESS';
export const TROCAVEICULO_LIST_FAILED    = 'TROCAVEICULO_LIST_FAILED';
export const TROCAVEICULO_ADD            = 'TROCAVEICULO_ADD';
export const TROCAVEICULO_ADD_SUCCESS    = 'TROCAVEICULO_ADD_SUCCESS';
export const TROCAVEICULO_ADD_FAILED     = 'TROCAVEICULO_ADD_FAILED';
export const TROCAVEICULO_UPDATE         = 'TROCAVEICULO_UPDATE';
export const TROCAVEICULO_UPDATE_SUCCESS = 'TROCAVEICULO_UPDATE_SUCCESS';
export const TROCAVEICULO_UPDATE_FAILED  = 'TROCAVEICULO_UPDATE_FAILED';
export const TROCAVEICULO_REMOVE         = 'TROCAVEICULO_REMOVE';
export const TROCAVEICULO_REMOVE_SUCCESS = 'TROCAVEICULO_REMOVE_SUCCESS';
export const TROCAVEICULO_REMOVE_FAILED  = 'TROCAVEICULO_REMOVE_FAILED';
export const TROCAVEICULO_LOAD           = 'TROCAVEICULO_LOAD'
export const TROCAVEICULO_LOAD_SUCCESS   = 'TROCAVEICULO_LOAD_SUCCESS';
export const TROCAVEICULO_LOAD_FAILED    = 'TROCAVEICULO_LOAD_FAILED';