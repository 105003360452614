/* VEICULO */
export const TIPODESPESA_LIST           = 'TIPODESPESA_LIST';
export const TIPODESPESA_LIST_SUCCESS   = 'TIPODESPESA_LIST_SUCCESS';
export const TIPODESPESA_LIST_FAILED    = 'TIPODESPESA_LIST_FAILED';
export const TIPODESPESA_ADD            = 'TIPODESPESA_ADD';
export const TIPODESPESA_ADD_SUCCESS    = 'TIPODESPESA_ADD_SUCCESS';
export const TIPODESPESA_ADD_FAILED     = 'TIPODESPESA_ADD_FAILED';
export const TIPODESPESA_UPDATE         = 'TIPODESPESA_UPDATE';
export const TIPODESPESA_UPDATE_SUCCESS = 'TIPODESPESA_UPDATE_SUCCESS';
export const TIPODESPESA_UPDATE_FAILED  = 'TIPODESPESA_UPDATE_FAILED';
export const TIPODESPESA_REMOVE         = 'TIPODESPESA_REMOVE';
export const TIPODESPESA_REMOVE_SUCCESS = 'TIPODESPESA_REMOVE_SUCCESS';
export const TIPODESPESA_REMOVE_FAILED  = 'TIPODESPESA_REMOVE_FAILED';
export const TIPODESPESA_LOAD           = 'TIPODESPESA_LOAD'
export const TIPODESPESA_LOAD_SUCCESS   = 'TIPODESPESA_LOAD_SUCCESS';
export const TIPODESPESA_LOAD_FAILED    = 'TIPODESPESA_LOAD_FAILED';