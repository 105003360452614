const operadorMenu = [
    {
        header: 'Painel Administrativo',
        path: '/admin',
        name: 'Cadastros',
        icon: 'dripicons-document',
        children: [
            { name: 'Motoristas', path: '/motorista' },
            { name: 'Veículos', path: '/veiculo' },
            { name: 'Passageiros', path: '/passageiro' },
            { name: 'Locais', path: '/local' },
        ],
    },
    {
        header: 'Painel Operacional',
        path: '/operacional',
        name: 'Corridas',
        icon: 'mdi mdi-car-connected',
        children: [
            { name: 'Acompanhamento', path: '/corrida/acompanhamento' },
            { name: 'Histórico', path: '/corrida/historico' },
            {
                name: 'Monitoramento',
                children: [
                    { name: 'Horas Online', path: '/monitoramento/horas' },
                    { name: 'Corridas', path: '/monitoramento/corridas' },
                    { name: 'Completo', path: '/monitoramento/full' },
                ],
            },
        ],
    },
];

export const OperadorMenu = () => operadorMenu;
