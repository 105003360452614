import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { TARIFA_LOAD, TARIFA_LIST, TARIFA_ADD, TARIFA_UPDATE, TARIFA_REMOVE } from './constants';

import {
    tarifaListSuccess,
    tarifaListFailed,
    tarifaAddSuccess,
    tarifaAddFailed,
    tarifaUpdateSuccess,
    tarifaUpdateFailed,
    tarifaLoadSuccess,
    tarifaLoadFailed,
    tarifaRemoveSuccess,
    tarifaRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* tarifaLoad({ payload: id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tarifa/' + id, options);
        yield put(tarifaLoadSuccess(response));
    } catch (error) {
        yield put(tarifaLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* tarifaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/tarifa/filter', options);
            yield put(tarifaListSuccess(response));
        } catch (error) {
            yield put(tarifaListFailed(error.message));
        }    
}


/**
 * tarifa
 * @param {*} payload - pageNumber and pageSize
 */
function* tarifaAdd({ payload: tarifa }) {

    const options = {
        body: JSON.stringify(tarifa) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tarifa', options);
        yield put(tarifaAddSuccess(response));
    } catch (error) {
        yield put(tarifaAddFailed(error.message));
    }    
}

/**
 * tarifa
 * @param {*} payload - pageNumber and pageSize
 */
function* tarifaUpdate({ payload: tarifa  }) {

    const options = {
        body: JSON.stringify(tarifa) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tarifa/' + tarifa?.id, options);
        yield put(tarifaUpdateSuccess(response));
    } catch (error) {
        yield put(tarifaUpdateFailed(error.message));
    }    
}

/**
 * tarifa
 * @param {*} payload - pageNumber and pageSize
 */
function* tarifaRemove({ payload: tarifa  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/tarifa/' + tarifa?.id, options);
        yield put(tarifaRemoveSuccess({}));
    } catch (error) {
        yield put(tarifaRemoveFailed(error.message));
    }    
}

export function* watchTarifaLoad(): any{
    yield takeEvery(TARIFA_LOAD, tarifaLoad);
}

export function* watchTarifaList(): any {
    yield takeEvery(TARIFA_LIST, tarifaList);
}

export function* watchTarifaAdd(): any {
    yield takeEvery(TARIFA_ADD, tarifaAdd);
}

export function* watchTarifaUpdate(): any {
    yield takeEvery(TARIFA_UPDATE, tarifaUpdate);
}

export function* watchTarifaRemove(): any {
    yield takeEvery(TARIFA_REMOVE, tarifaRemove);
}

function* tarifa(): any {
    yield all([fork(watchTarifaList), fork(watchTarifaAdd), fork(watchTarifaUpdate), fork(watchTarifaLoad), fork(watchTarifaRemove)]);
}

export default tarifa;