import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { NOTIFICACAO_NAOLIDAS, NOTIFICACAO_LOAD, NOTIFICACAO_LIST, NOTIFICACAO_UPDATE, NOTIFICACAO_REMOVE } from './constants';

import {
    notificacaoListSuccess,
    notificacaoListFailed,
    notificacaoNaoLidasSuccess,
    notificacaoNaoLidasFailed,
    notificacaoAddSuccess,
    notificacaoAddFailed,
    notificacaoUpdateSuccess,
    notificacaoUpdateFailed,
    notificacaoLoadSuccess,
    notificacaoLoadFailed,
    notificacaoRemoveSuccess,
    notificacaoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* notificacaoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/notificacao/' + id, options);
        yield put(notificacaoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(notificacaoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* notificacaoListNaoLidas() {
    
        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/notificacao/naolidas', options);
            yield put(notificacaoNaoLidasSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(notificacaoNaoLidasFailed(message));
        }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* notificacaoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/notificacao/filter', options);
            yield put(notificacaoListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(notificacaoListFailed(message));
        }    
}


/**
 * Notificacao
 * @param {*} payload - pageNumber and pageSize
 */
function* notificacaoAdd({ payload: { notificacao }  }) {

    const options = {
        body: JSON.stringify(notificacao) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/notificacao', options);
        yield put(notificacaoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(notificacaoAddFailed(message));
    }    
}

/**
 * Notificacao
 * @param {*} payload - pageNumber and pageSize
 */
function* notificacaoUpdate({ payload: notificacao }) {

    const options = {
        body: JSON.stringify(notificacao) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/notificacao/' + notificacao?.id, options);
        yield put(notificacaoUpdateSuccess(response));
        yield call(notificacaoList, {
            payload: {
                sortField: "lida",
                sortOrder: "asc"
            }
        });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(notificacaoUpdateFailed(message));
    }    
}

/**
 * Notificacao
 * @param {*} payload - pageNumber and pageSize
 */
function* notificacaoRemove({ payload: { notificacao }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/notificacao/' + notificacao?.id, options);
        yield put(notificacaoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(notificacaoRemoveFailed(message));
    }    
}

export function* watchnotificacaoLoad(): any{
    yield takeEvery(NOTIFICACAO_LOAD, notificacaoLoad);
}

export function* watchNotificacaoList(): any {
    yield takeEvery(NOTIFICACAO_LIST, notificacaoList);
}

export function* watchNotificacaoListNaoLidas(): any {
    yield takeEvery(NOTIFICACAO_NAOLIDAS, notificacaoListNaoLidas);
}

export function* watchNotificacaoAdd(): any {
    // yield takeEvery(NOTIFICACAO_ADD, notificacaoAdd);
}

export function* watchNotificacaoUpdate(): any {
    yield takeEvery(NOTIFICACAO_UPDATE, notificacaoUpdate);
}

export function* watchNotificacaoRemove(): any {
    yield takeEvery(NOTIFICACAO_REMOVE, notificacaoRemove);
}

function* notificacao(): any {
    yield all([fork(watchNotificacaoList), fork(watchNotificacaoAdd), fork(watchNotificacaoUpdate), fork(watchnotificacaoLoad), fork(watchNotificacaoRemove), fork(watchNotificacaoListNaoLidas)]);
}

export default notificacao;