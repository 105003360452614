import styled from 'styled-components';
import theme from '../../../src/styles/theme';

/* background-color: ${props => props.theme.colors.primaryDark}; */
export const Navbar_Container = styled.nav`
    background-color: ${theme.colors.primaryDark};
    height: 100vh;
    width: 70px;
    display: flex;
    flex-direction: column;

    @media print {
        display: none;
    }
`;

export const Navbar_SectionBrand = styled.section`
    width: 50px;
    height: 50px;
    margin: 16px 10px 16px 10px;
    cursor: pointer;
`;

export const Navbar_SectionMenu = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const Navbar_SectionUser = styled.div`
    width: 100%;
    margin: 0px 0px 8px 0px;
    display: flex;
    flex-direction: column;
`;

export const Navbar_Items = styled.div``;
//TODO
// ${props => props.theme.colors.greyLight}
export const Navbar_IconMenu = styled.div`
    fill: ${theme.colors.greyLight};
    width: 26px;
    height: 26px;
`;
// TODO
// fill: ${props => props.theme.colors.secondary};
export const Navbar_IconUser = styled.div`
    fill: ${theme.colors.secondary};
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        border-radius: 54px;
    }
`;
//TODO
/* background-color: ${props => props.theme.colors.greyLight}; */
export const Navbar_Item = styled.div`
    width: 100%;
    height: 44px;
    margin: 0px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: ${theme.colors.greyLight};
        ${Navbar_IconMenu} {
            fill: ${theme.colors.blue};
        }
    }
`;
//TOPO
// ${props => props.theme.colors.blue};

export const Navbar_ItemDisable = styled.div`
    width: 100%;
    height: 36px;
    margin: 0px 0px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* ${Navbar_IconUser} {
        fill: ${theme.colors.grey};
    } */
`;
//TODO
//  fill: ${props => props.theme.colors.grey};

export const Divider = styled.div`
    background-color: ${theme.colors.secondary};
    height: 1px;
    margin: auto;
    width: 56px;
    margin: 0px auto 16px;
`;

// -> Menus <--------------------------------------------------------------------

// => Menu Item ---------------------------------------------------------------
export const Navbar_MenuItem = styled.div`
    width: 54vw;
    height: 91vh;
    max-width: 693px;
    display: none;
    position: absolute;
    left: 70px;
    top: 32px;
    overflow: auto;
    z-index: 10;

    ${Navbar_Item}:hover && {
        display: block;
    }

    // NavbarMenu_Container => navbar-menu.ts <------------
    background-color: #f0f2f4;
    box-shadow: 0px 2px 6px rgba(108, 117, 125, 0.5);
    border-radius: 0px 10px 10px 0px;
    padding: 1rem;
`;
//TODO
//background-color: ${props => props.theme.colors.greyLight};
// ----------------------------------------------------------------------------

// => Menu Search -------------------------------------------------------------
export const Navbar_MenuSearch = styled.div`
    width: 350px;
    height: 550px;
    display: none;
    position: absolute;
    left: 70px;
    bottom: 4px;
    overflow: auto;
    z-index: 10;

    ${Navbar_Item}:hover && {
        display: block;
    }

    // NavbarMenu_Container => navbar-menu.ts <------------
    background-color: ${theme.colors.greyLight};
    box-shadow: 0px 2px 6px rgba(108, 117, 125, 0.5);
    border-radius: 0px 10px 10px 0px;
    padding: 1rem;
`;
// ----------------------------------------------------------------------------

// => Menu User ---------------------------------------------------------------
export const Navbar_MenuUser = styled.div`
    width: 250px;
    height: 300px;
    display: none;
    position: absolute;
    left: 70px;
    bottom: 4px;
    overflow: auto;
    z-index: 10;

    ${Navbar_Item}:hover && {
        display: block;
    }

    // NavbarMenu_Container => navbar-menu.ts <------------
    background-color: #f0f2f4;
    box-shadow: 0px 2px 6px rgba(108, 117, 125, 0.5);
    border-radius: 0px 10px 10px 0px;
    padding: 1rem;
`;
//TODO
// background-color: ${props => props.theme.colors.greyLight};
// ----------------------------------------------------------------------------

// => Menu Notification -------------------------------------------------------
export const Navbar_MenuNotification = styled.div`
    width: 300px;
    height: 500px;
    display: none;
    position: absolute;
    left: 70px;
    bottom: 4px;
    overflow: auto;
    z-index: 10;

    ${Navbar_Item}:hover && {
        display: block;
    }

    // NavbarMenu_Container => navbar-menu.ts <------------
    background-color: ${theme.colors.greyLight};
    box-shadow: 0px 2px 6px rgba(108, 117, 125, 0.5);
    border-radius: 0px 10px 10px 0px;
    padding: 1rem;
`;
// ----------------------------------------------------------------------------

// => Menu FAQ ----------------------------------------------------------------
export const Navbar_MenuFAQ = styled.div`
    width: 350px;
    height: 550px;
    display: none;
    position: absolute;
    left: 70px;
    bottom: 4px;
    overflow: auto;
    z-index: 10;

    ${Navbar_Item}:hover && {
        display: block;
    }

    // NavbarMenu_Container => navbar-menu.ts <------------
    background-color: ${theme.colors.greyLight};
    box-shadow: 0px 2px 6px rgba(108, 117, 125, 0.5);
    border-radius: 0px 10px 10px 0px;
    padding: 1rem;
`;
// ----------------------------------------------------------------------------
