/* VEICULOCOR */
export const VEICULOCOR_LIST           = 'VEICULOCOR_LIST';
export const VEICULOCOR_LIST_SUCCESS   = 'VEICULOCOR_LIST_SUCCESS';
export const VEICULOCOR_LIST_FAILED    = 'VEICULOCOR_LIST_FAILED';
export const VEICULOCOR_ADD            = 'VEICULOCOR_ADD';
export const VEICULOCOR_ADD_SUCCESS    = 'VEICULOCOR_ADD_SUCCESS';
export const VEICULOCOR_ADD_FAILED     = 'VEICULOCOR_ADD_FAILED';
export const VEICULOCOR_UPDATE         = 'VEICULOCOR_UPDATE';
export const VEICULOCOR_UPDATE_SUCCESS = 'VEICULOCOR_UPDATE_SUCCESS';
export const VEICULOCOR_UPDATE_FAILED  = 'VEICULOCOR_UPDATE_FAILED';
export const VEICULOCOR_REMOVE         = 'VEICULOCOR_REMOVE';
export const VEICULOCOR_REMOVE_SUCCESS = 'VEICULOCOR_REMOVE_SUCCESS';
export const VEICULOCOR_REMOVE_FAILED  = 'VEICULOCOR_REMOVE_FAILED';
export const VEICULOCOR_LOAD           = 'VEICULOCOR_LOAD'
export const VEICULOCOR_LOAD_SUCCESS   = 'VEICULOCOR_LOAD_SUCCESS';
export const VEICULOCOR_LOAD_FAILED    = 'VEICULOCOR_LOAD_FAILED';