import { 
    DESPESAAPROVADA_LIST,
    DESPESAAPROVADA_LIST_SUCCESS,
    DESPESAAPROVADA_LIST_FAILED,
    DESPESAAPROVADA_ADD,
    DESPESAAPROVADA_ADD_SUCCESS,
    DESPESAAPROVADA_ADD_FAILED,
    DESPESAAPROVADA_UPDATE,
    DESPESAAPROVADA_UPDATE_SUCCESS,
    DESPESAAPROVADA_UPDATE_FAILED,
    DESPESAAPROVADA_LOAD,
    DESPESAAPROVADA_LOAD_SUCCESS,
    DESPESAAPROVADA_LOAD_FAILED,
    DESPESAAPROVADA_REMOVE,
    DESPESAAPROVADA_REMOVE_SUCCESS,
    DESPESAAPROVADA_REMOVE_FAILED
} from './constants';

// List
export const despesaAprovadaList        = (filter) => ({ type: DESPESAAPROVADA_LIST, payload: filter });
export const despesaAprovadaListSuccess = (despesaAprovadas) => ({ type: DESPESAAPROVADA_LIST_SUCCESS, payload: despesaAprovadas });
export const despesaAprovadaListFailed  = (error) => ({ type: DESPESAAPROVADA_LIST_FAILED, payload: error  });

export const despesaAprovadaLoad        = (id) => ({ type: DESPESAAPROVADA_LOAD, payload: id });
export const despesaAprovadaLoadSuccess  = (despesaAprovada) => ({ type: DESPESAAPROVADA_LOAD_SUCCESS, payload: despesaAprovada });
export const despesaAprovadaLoadFailed   = (error) => ({ type: DESPESAAPROVADA_LOAD_FAILED, payload: error  });

// Add
export const despesaAprovadaAdd         = (despesaAprovada) => ({ type: DESPESAAPROVADA_ADD, payload: despesaAprovada });
export const despesaAprovadaAddSuccess  = (despesaAprovada, despesaAprovadaStatus) => ({ type: DESPESAAPROVADA_ADD_SUCCESS, despesaAprovadaStatus: despesaAprovadaStatus, payload: despesaAprovada });
export const despesaAprovadaAddFailed   = (error) => ({ type: DESPESAAPROVADA_ADD_FAILED, payload: error  });

// Update
export const despesaAprovadaUpdate         = (despesaAprovada) => ({ type: DESPESAAPROVADA_UPDATE, payload: despesaAprovada });
export const despesaAprovadaUpdateSuccess  = (despesaAprovada, despesaAprovadaStatus) => ({ type: DESPESAAPROVADA_UPDATE_SUCCESS, despesaAprovadaStatus: despesaAprovadaStatus, payload: despesaAprovada });
export const despesaAprovadaUpdateFailed   = (error) => ({ type: DESPESAAPROVADA_UPDATE_FAILED, payload: error  });

// remove
export const despesaAprovadaRemove         = (despesaAprovada) => ({ type: DESPESAAPROVADA_REMOVE, payload: despesaAprovada });
export const despesaAprovadaRemoveSuccess  = (despesaAprovada, despesaAprovadaDeletado) => ({ type: DESPESAAPROVADA_REMOVE_SUCCESS, despesaAprovadaDeletado: despesaAprovadaDeletado, payload: despesaAprovada });
export const despesaAprovadaRemoveFailed   = (error) => ({ type: DESPESAAPROVADA_REMOVE_FAILED, payload: error  });