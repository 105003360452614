import React from 'react';
import { Route } from 'react-router-dom';
import {PrivateRoute } from './privateRoute'

// Notificação
const NotificacaoList     = React.lazy(() => import('../../../pages/notificacao/NotificacaoList'));

export const centralNotificacaoRoute = [
    {
        path: "/notificacao",
        exact: true,
        component: NotificacaoList,
        route: Route
    }
]