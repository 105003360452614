import React from 'react';
import { Route } from 'react-router-dom';
// auth
const Login             = React.lazy(() => import('../../../pages/auth/Login'));
const Logout            = React.lazy(() => import('../../../pages/auth/Logout'));
const Register          = React.lazy(() => import('../../../pages/auth/Register'));
const ForgetPassword    = React.lazy(() => import('../../../pages/auth/ForgetPassword'));
const Confirm           = React.lazy(() => import('../../../pages/auth/Confirm'));
const ResetPassword     = React.lazy(() => import('../../../pages/auth/ResetPassword'));
const Monitoramento     = React.lazy(() => import('../../../pages/monitoramento/monitoramento'));

// auth
export const authRoutes = {
    path: '/conta',
    name: 'Auth',
    children: [
        {
            path: '/conta/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/conta/registrar',
            name: 'Registrar',
            component: Register,
            route: Route,
        },
        {
            path: '/conta/sucesso',
            name: 'Sucesso',
            component: Confirm,
            route: Route,
        },
        {
            path: '/conta/esqueceusenha',
            name: 'Esqueceu sua Senha',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/conta/redefinirsenha',
            name: 'Redefinir sua senha',
            component: ResetPassword,
            route: Route,
        },
        {
            path: '/monitoramento/full',
            name: 'Monitoramento',
            component: Monitoramento,
            route: Route
        }
    ],
};