import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { DISPONIBILIDADE_LOAD, DISPONIBILIDADE_LIST, DISPONIBILIDADE_ADD, DISPONIBILIDADE_UPDATE, DISPONIBILIDADE_REMOVE } from './constants';

import {
    disponibilidadeListSuccess,
    disponibilidadeListFailed,
    disponibilidadeAddSuccess,
    disponibilidadeAddFailed,
    disponibilidadeUpdateSuccess,
    disponibilidadeUpdateFailed,
    disponibilidadeLoadSuccess,
    disponibilidadeLoadFailed,
    disponibilidadeRemoveSuccess,
    disponibilidadeRemoveFailed,
    disponibilidadePivotListSuccess
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* disponibilidadeLoad({ payload:  id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/disponibilidade/' + id, options);
        yield put(disponibilidadeLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(disponibilidadeLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* disponibilidadeList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            // const response = yield call(fetchJSON, '/central/v1/api/v1/disponibilidade/filter', options);
            const responsePivot = yield call(fetchJSON, '/central/v1/api/v1/disponibilidade/filter/pivot', options);
            // yield put(disponibilidadeListSuccess(response));
            yield put(disponibilidadePivotListSuccess(responsePivot));
        } catch (error) {
            yield put(disponibilidadeListFailed(error.message));
        }    
}


/**
 * disponibilidade
 * @param {*} payload - pageNumber and pageSize
 */
function* disponibilidadeAdd({ payload: { disponibilidade }  }) {

    const options = {
        body: JSON.stringify(disponibilidade) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/disponibilidade', options);
        yield put(disponibilidadeAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(disponibilidadeAddFailed(message));
    }    
}

/**
 * disponibilidade
 * @param {*} payload - pageNumber and pageSize
 */
function* disponibilidadeUpdate({ payload: disponibilidade  }) {

    const options = {
        body: JSON.stringify(disponibilidade) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/disponibilidade/' + disponibilidade?.id, options);
        yield put(disponibilidadeUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(disponibilidadeUpdateFailed(message));
    }    
}

/**
 * disponibilidade
 * @param {*} payload - pageNumber and pageSize
 */
function* disponibilidadeRemove({ payload: { disponibilidade }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/disponibilidade/' + disponibilidade?.id, options);
        yield put(disponibilidadeRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(disponibilidadeRemoveFailed(message));
    }    
}

export function* watchTipoLocalLoad(): any{
    yield takeEvery(DISPONIBILIDADE_LOAD, disponibilidadeLoad);
}

export function* watchTipoLocalList(): any {
    yield takeEvery(DISPONIBILIDADE_LIST, disponibilidadeList);
}

export function* watchTipoLocalAdd(): any {
    yield takeEvery(DISPONIBILIDADE_ADD, disponibilidadeAdd);
}

export function* watchTipoLocalUpdate(): any {
    yield takeEvery(DISPONIBILIDADE_UPDATE, disponibilidadeUpdate);
}

export function* watchTipoLocalRemove(): any {
    yield takeEvery(DISPONIBILIDADE_REMOVE, disponibilidadeRemove);
}

function* disponibilidade(): any {
    yield all([fork(watchTipoLocalList), fork(watchTipoLocalAdd), fork(watchTipoLocalUpdate), fork(watchTipoLocalLoad), fork(watchTipoLocalRemove)]);
}

export default disponibilidade;