
import { VEICULOCOR_LOAD, VEICULOCOR_LOAD_SUCCESS, VEICULOCOR_LOAD_FAILED, VEICULOCOR_LIST, VEICULOCOR_LIST_SUCCESS, VEICULOCOR_LIST_FAILED, VEICULOCOR_ADD, VEICULOCOR_ADD_FAILED, VEICULOCOR_ADD_SUCCESS, VEICULOCOR_REMOVE, VEICULOCOR_REMOVE_FAILED, VEICULOCOR_REMOVE_SUCCESS, VEICULOCOR_UPDATE, VEICULOCOR_UPDATE_SUCCESS, VEICULOCOR_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    veiculoCores: {},
    veiculoCor: {},
    loading: false,
    error: null,
    veiculoCorStatus: null,
    id: {}
};

const VeiculoCorReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VEICULOCOR_LIST:
            return { ...state, loading: true, error: null, veiculoCorDeletado: null };
        case VEICULOCOR_LIST_SUCCESS:
            return { ...state, veiculoCores: action.payload, loading: false, error: null, veiculoCorDeletado: null };
        case VEICULOCOR_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoCorDeletado: null };
        case VEICULOCOR_LOAD:
            return { ...state, loading: true, error: null, veiculoCorStatus: null, veiculoCorDeletado: null }
        case VEICULOCOR_LOAD_SUCCESS:
            return { ...state, veiculoCor: action.payload, loading: false, error: null, veiculoCorDeletado: null}
        case VEICULOCOR_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoCorDeletado: null };
        case VEICULOCOR_ADD:
            return { ...state, veiculoCor: action.payload, veiculoCorStatus: null, loading: true, error: null, veiculoCorDeletado: null}
        case VEICULOCOR_ADD_SUCCESS:
            return { ...state, veiculoCor: action.payload,  veiculoCorStatus: "Cor adicionado com sucesso", veiculoCorDeletado: null, loading: false, error: null };
        case VEICULOCOR_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case VEICULOCOR_UPDATE:
            return { ...state, veiculoCor: action.payload, veiculoCorStatus: null, loading: true, error: null}
        case VEICULOCOR_UPDATE_SUCCESS:
            return { ...state, veiculoCor: action.payload,  veiculoCorStatus: "Cor atualizado com sucesso", veiculoCorDeletado: null, loading: false, error: null };
        case VEICULOCOR_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoCorStatus: null };
        case VEICULOCOR_REMOVE:
            return { ...state, veiculoCor: action.payload, veiculoCorStatus: null, loading: true, error: null, veiculoCorDeletado: null}
        case VEICULOCOR_REMOVE_SUCCESS:
            return { ...state, veiculoCor: {}, veiculoCorStatus: null,  veiculoCorDeletado: "Cor removido com sucesso", loading: false, error: null };
        case VEICULOCOR_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoCorStatus: null, veiculoCorDeletado: null };
        default:
            return { ...state };
    }
};

export default VeiculoCorReducer;