import {
    CENTRO_CUSTO_INTERNO_LOAD,
    CENTRO_CUSTO_INTERNO_LOAD_SUCCESS,
    CENTRO_CUSTO_INTERNO_LOAD_FAILED,
    CENTRO_CUSTO_INTERNO_LIST,
    CENTRO_CUSTO_INTERNO_LIST_SUCCESS,
    CENTRO_CUSTO_INTERNO_LIST_FAILED,
    CENTRO_CUSTO_INTERNO_ADD,
    CENTRO_CUSTO_INTERNO_ADD_FAILED,
    CENTRO_CUSTO_INTERNO_ADD_SUCCESS,
    CENTRO_CUSTO_INTERNO_REMOVE,
    CENTRO_CUSTO_INTERNO_REMOVE_FAILED,
    CENTRO_CUSTO_INTERNO_REMOVE_SUCCESS,
    CENTRO_CUSTO_INTERNO_UPDATE,
    CENTRO_CUSTO_INTERNO_UPDATE_SUCCESS,
    CENTRO_CUSTO_INTERNO_UPDATE_FAILED,
} from './constants';

const INIT_STATE = {
    centroCustosInternos: {},
    centroCustoInterno: {},
    loading: false,
    error: null,
    centroCustoInternoStatus: null,
    id: {},
};

const CentroCustoInternoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CENTRO_CUSTO_INTERNO_LIST:
            return { ...state, loading: true, error: null, centroCustoInternoDeletado: null };
        case CENTRO_CUSTO_INTERNO_LIST_SUCCESS:
            return {
                ...state,
                centroCustosInternos: action.payload,
                loading: false,
                error: null,
                centroCustoInternoDeletado: null,
            };
        case CENTRO_CUSTO_INTERNO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoInternoDeletado: null };
        case CENTRO_CUSTO_INTERNO_LOAD:
            return {
                ...state,
                centroCustoInterno: {},
                loading: true,
                error: null,
                centroCustoInternoStatus: null,
                centroCustoInternoDeletado: null,
            };
        case CENTRO_CUSTO_INTERNO_LOAD_SUCCESS:
            return {
                ...state,
                centroCustoInterno: action.payload,
                loading: false,
                error: null,
                centroCustoInternoDeletado: null,
            };
        case CENTRO_CUSTO_INTERNO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoInternoDeletado: null };
        case CENTRO_CUSTO_INTERNO_ADD:
            return {
                ...state,
                centroCustoInterno: action.payload,
                centroCustoInternoStatus: null,
                loading: true,
                error: null,
                centroCustoInternoDeletado: null,
            };
        case CENTRO_CUSTO_INTERNO_ADD_SUCCESS:
            return {
                ...state,
                centroCustoInterno: action.payload,
                centroCustoInternoStatus: 'Centro de Custo Interno criado com sucesso',
                centroCustoInternoDeletado: null,
                loading: false,
                error: null,
            };
        case CENTRO_CUSTO_INTERNO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CENTRO_CUSTO_INTERNO_UPDATE:
            return {
                ...state,
                centroCustoInterno: action.payload,
                centroCustoInternoStatus: null,
                loading: true,
                error: null,
            };
        case CENTRO_CUSTO_INTERNO_UPDATE_SUCCESS:
            return {
                ...state,
                centroCustoInterno: action.payload,
                centroCustoInternoStatus: 'Centro de Custo Interno atualizado com sucesso',
                centroCustoInternoDeletado: null,
                loading: false,
                error: null,
            };
        case CENTRO_CUSTO_INTERNO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, centroCustoStatus: null };
        case CENTRO_CUSTO_INTERNO_REMOVE:
            return {
                ...state,
                centroCustoInternoId: action.payload,
                centroCustoInternoStatus: null,
                loading: true,
                error: null,
                centroCustoInternoDeletado: null,
            };
        case CENTRO_CUSTO_INTERNO_REMOVE_SUCCESS:
            return {
                ...state,
                centroCustoInternoStatus: null,
                centroCustoInternoDeletado: 'Centro de Custo Interno removida com sucesso',
                loading: false,
                error: null,
            };
        case CENTRO_CUSTO_INTERNO_REMOVE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
                centroCustoInternoStatus: null,
                centroCustoInternoDeletado: null,
            };

        default:
            return { ...state };
    }
};

export default CentroCustoInternoReducer;
