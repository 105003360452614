/* eslint-disable react/jsx-pascal-case */
import React, { FC, useContext } from 'react';

import { NavbarMenu_Container } from '../../navbar-menu';

import {
    UserProfile_Container,
    UserProfile_Settings,
    UserProfile_SettingsIcon,
    UserProfile_Info,
    UserProfile_InfoPhoto,
    UserProfile_InfoIconPhoto,
    UserProfile_InfoName,
    UserProfile_InfoEmail,
    UserProfile_Logout,
    UserProfile_LogoutLabel,
    UserProfile_LogoutIcon,
} from './style';

// import IconPhoto from '../../../../assets/icons/system/user-3-fill.svg'
// import IconUser from '../../../../assets/icons/navbar/user-fill.svg';
import ProfileDropdown from '../../../ProfileDropdown';
import profilePic from '../../../../assets/images/users/avatar-1.jpg';
import { getLoggedInUser, isUserAuthenticated } from '../../../../helpers/authUtils';

import Gravatar from 'react-gravatar';
// import { useRouter } from 'next/router'
// import { signOut, useSession } from 'next-auth/react'

// interface IUserProfile {
//   username: string
//   email: string
// }

const IconUser = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
            </g>
        </svg>
    );
};

const IconLogout = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z" />
            </g>
        </svg>
    );
};

const IconSettings = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M5.334 4.545a9.99 9.99 0 0 1 3.542-2.048A3.993 3.993 0 0 0 12 3.999a3.993 3.993 0 0 0 3.124-1.502 9.99 9.99 0 0 1 3.542 2.048 3.993 3.993 0 0 0 .262 3.454 3.993 3.993 0 0 0 2.863 1.955 10.043 10.043 0 0 1 0 4.09c-1.16.178-2.23.86-2.863 1.955a3.993 3.993 0 0 0-.262 3.455 9.99 9.99 0 0 1-3.542 2.047A3.993 3.993 0 0 0 12 20a3.993 3.993 0 0 0-3.124 1.502 9.99 9.99 0 0 1-3.542-2.047 3.993 3.993 0 0 0-.262-3.455 3.993 3.993 0 0 0-2.863-1.954 10.043 10.043 0 0 1 0-4.091 3.993 3.993 0 0 0 2.863-1.955 3.993 3.993 0 0 0 .262-3.454zM13.5 14.597a3 3 0 1 0-3-5.196 3 3 0 0 0 3 5.196z" />
            </g>
        </svg>
    );
};

const ProfileMenus = [
    // {
    //     label: 'Minha conta',
    //     icon: 'uil uil-user',
    //     redirectTo: '/minha-conta',
    // },
    {
        label: 'Logout',
        icon: 'uil uil-exit',
        redirectTo: '/account/logout',
    },
    {
        label: 'Trocar senha',
        icon: 'uil uil-lock',
        redirectTo: '/conta/esqueceusenha',
    },
];

const UserProfile = (props) => {
    // const { data: session } = useSession()

    // const router = useRouter()

    const handlePersonEdit = () => {
        // router.push(
        '/global-registers/_registrations/person/form';
        // )
    };

    return (
        <NavbarMenu_Container>
            <UserProfile_Container>
                <UserProfile_Settings>
                    <UserProfile_SettingsIcon onClick={() => handlePersonEdit()}>
                        <div>
                            <IconSettings />
                        </div>
                    </UserProfile_SettingsIcon>
                </UserProfile_Settings>

                <UserProfile_Info onClick={() => handlePersonEdit()}>
                    <UserProfile_InfoPhoto>
                        <UserProfile_InfoIconPhoto>
                            {/* {session?.user?.email && ( */}
                            {/* <Gravatar email={getLoggedInUser()?.usuario?.nomeUsuario} size={70} /> */}
                            {/* )} */}

                            {/* TODO */}
                            {!getLoggedInUser()?.usuario?.nomeUsuario && <IconUser />}
                            <ProfileDropdown
                                profilePic={profilePic}
                                menuItems={ProfileMenus}
                                username={
                                    isUserAuthenticated()
                                        ? getLoggedInUser()?.usuario?.nomeUsuario
                                        : 'sua sessão expirou'
                                }
                                userTitle={
                                    isUserAuthenticated()
                                        ? getLoggedInUser()?.usuario?.nomeDominio
                                        : 'sua sessão expirou'
                                }
                            />
                        </UserProfile_InfoIconPhoto>
                    </UserProfile_InfoPhoto>
                    {/* <UserProfile_InfoName>{getLoggedInUser()?.usuario?.nomeUsuario}</UserProfile_InfoName>
                    <UserProfile_InfoEmail>{getLoggedInUser()?.usuario?.nomeDominio}</UserProfile_InfoEmail> */}
                </UserProfile_Info>

                <UserProfile_Logout
                // onClick={() => {
                //   signOut({
                //     redirect: false,
                //     callbackUrl: "/logout"
                //   }).then(r => window.location.href = "https://hom-autonomoz-identity-sts.autonomoz.com.br/connect/endsession?id_token_hint=eyJhbGciOiJSUzI1NiIsImtpZCI6IjQ4OTkwMEMxN0M3NkVCRUE3REQ4MUYyRTQzQTY0RDdEIiwidHlwIjoiSldUIn0.eyJuYmYiOjE2NDYxODg4MzEsImV4cCI6MTY0NjE4OTEzMSwiaXNzIjoiaHR0cHM6Ly9ob20tYXV0b25vbW96LWlkZW50aXR5LXN0cy5hdXRvbm9tb3ouY29tLmJyIiwiYXVkIjoicG9ydGFsLWF1dG9ub21vei1jZW50cmFsLWRldiIsImlhdCI6MTY0NjE4ODgzMSwiYXRfaGFzaCI6Ii1vRDNDbVNZbVF2bndHdV9BT1pjQ3ciLCJzaWQiOiI1MjA2MkUwOEIzQjVEMjFDOTEzRTgwNTg2RjJENTg1OCIsInN1YiI6IjkwZWQyZTNhLWJkNDQtNDMwZC05ZTY3LWJhMjA2NTIyNDA0ZSIsImF1dGhfdGltZSI6MTY0NjE4ODc5NywiaWRwIjoiQXp1cmVBRCIsImFtciI6WyJleHRlcm5hbCJdfQ.JpRQ9mLbD2z7Ho-eAIlNSlFlfR3a7TMzOTBKUHI4el3hH2sT8wjmpi7MPe4W7GmYWpJxJKIC_lDnpJoetFnvAY6qZEfBywt1VDz50iHCIuzRQASLZpfVcmMQ_uvZty7uyxoUj_dNwE-V8fEleYTP02xJR97hU-edeDSbhXHLkulTqEoIk_HA31gGCG_SHVB6nMHSrdDgYHijznw96u4oK9bpxnez0V9VgudTpx6a20K8PphX2CDrsjEXOyH5cN2Ut_1_T6QXTp6kf2p7mxtK57SjbtCXTQ_UQkLjLDXykSTXyaY0xrqfNXo6c00YTwTWsJKnOz_cVf9SU5JQ5cUAHA&post_logout_redirect_uri=http://localhost:3000")
                // }}
                >
                    {/* <UserProfile_LogoutLabel>Sair</UserProfile_LogoutLabel> */}
                    {/* <UserProfile_LogoutIcon>
                        <IconLogout />
                    </UserProfile_LogoutIcon> */}
                </UserProfile_Logout>
            </UserProfile_Container>
        </NavbarMenu_Container>
    );
};

export default UserProfile;
