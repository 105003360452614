
import { DISPONIBILIDADE_LIST_PIVOT_SUCCESS, DISPONIBILIDADE_LOAD, DISPONIBILIDADE_LOAD_SUCCESS, DISPONIBILIDADE_LOAD_FAILED, DISPONIBILIDADE_LIST, DISPONIBILIDADE_LIST_SUCCESS, DISPONIBILIDADE_LIST_FAILED, DISPONIBILIDADE_ADD, DISPONIBILIDADE_ADD_FAILED, DISPONIBILIDADE_ADD_SUCCESS, DISPONIBILIDADE_REMOVE, DISPONIBILIDADE_REMOVE_FAILED, DISPONIBILIDADE_REMOVE_SUCCESS, DISPONIBILIDADE_UPDATE, DISPONIBILIDADE_UPDATE_SUCCESS, DISPONIBILIDADE_UPDATE_FAILED, DISPONIBILIDADE_CLEAR } from './constants';

const INIT_STATE = {
    disponibilidades: {},
    disponibilidadesPivot: {},
    disponibilidade: {},
    loading: false,
    error: null,
    success: null,
    updateSuccess: false,
    id: {}
};

const DisponibilidadeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DISPONIBILIDADE_LIST:
            return { ...state, loading: true, error: null, disponibilidadeDeletado: null, disponibilidades: {}, disponibilidadesPivot: {} };
        case DISPONIBILIDADE_LIST_SUCCESS:
            return { ...state, disponibilidades: action.payload, loading: false, error: null, disponibilidadeDeletado: null };
        case DISPONIBILIDADE_LIST_PIVOT_SUCCESS: 
            return { ...state, disponibilidadesPivot: action.payload, loading: false, error: null, disponibilidadeDeletado: null };
        case DISPONIBILIDADE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, disponibilidadeDeletado: null };
        case DISPONIBILIDADE_LOAD:
            return { ...state, disponibilidade: {}, loading: true, error: null, success: null, disponibilidadeDeletado: null }
        case DISPONIBILIDADE_LOAD_SUCCESS:
            return { ...state, disponibilidade: action.payload, loading: false, error: null, disponibilidadeDeletado: null}
        case DISPONIBILIDADE_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, disponibilidadeDeletado: null };
        case DISPONIBILIDADE_ADD:
            return { ...state, disponibilidade: action.payload, success: null, loading: true, error: null, disponibilidadeDeletado: null}
        case DISPONIBILIDADE_ADD_SUCCESS:
            return { ...state, disponibilidade: action.payload,  success: "Disponibilidade adicionado com sucesso", disponibilidadeDeletado: null, loading: false, error: null };
        case DISPONIBILIDADE_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DISPONIBILIDADE_UPDATE:
            return { ...state, disponibilidade: action.payload, updateSuccess: false, loading: true, error: null}
        case DISPONIBILIDADE_UPDATE_SUCCESS:
            return { ...state, disponibilidade: action.payload,  updateSuccess: true, loading: false, error: null };
        case DISPONIBILIDADE_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, updateSuccess: false };
        case DISPONIBILIDADE_REMOVE:
            return { ...state, disponibilidade: action.payload, success: null, loading: true, error: null, disponibilidadeDeletado: null}
        case DISPONIBILIDADE_REMOVE_SUCCESS:
            return { ...state, disponibilidade: {}, success: null,  disponibilidadeDeletado: "Disponibilidade removida com sucesso", loading: false, error: null };
        case DISPONIBILIDADE_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, success: null, disponibilidadeDeletado: null };

        case DISPONIBILIDADE_CLEAR:
            return { ...state, disponibilidade: {}, disponibilidades: {}, disponibilidadesPivot: {} }
        default:
            return { ...state };
    }
};

export default DisponibilidadeReducer;