import { 
    TIPO_LOCAL_LIST,
    TIPO_LOCAL_LIST_SUCCESS,
    TIPO_LOCAL_LIST_FAILED,
    TIPO_LOCAL_ADD,
    TIPO_LOCAL_ADD_SUCCESS,
    TIPO_LOCAL_ADD_FAILED,
    TIPO_LOCAL_UPDATE,
    TIPO_LOCAL_UPDATE_SUCCESS,
    TIPO_LOCAL_UPDATE_FAILED,
    TIPO_LOCAL_LOAD,
    TIPO_LOCAL_LOAD_SUCCESS,
    TIPO_LOCAL_LOAD_FAILED,
    TIPO_LOCAL_REMOVE,
    TIPO_LOCAL_REMOVE_SUCCESS,
    TIPO_LOCAL_REMOVE_FAILED
} from './constants';

// List
export const tipoLocalList        = (filter) => ({ type: TIPO_LOCAL_LIST, payload: filter });
export const tipoLocalListSuccess = (tipoLocais) => ({ type: TIPO_LOCAL_LIST_SUCCESS, payload: { tipoLocais } });
export const tipoLocalListFailed  = (error) => ({ type: TIPO_LOCAL_LIST_FAILED, payload: error  });

export const tipoLocalLoad        = (id) => ({ type: TIPO_LOCAL_LOAD, payload: { id } });
export const tipoLocalLoadSuccess  = (tipoLocal) => ({ type: TIPO_LOCAL_LOAD_SUCCESS, payload: { tipoLocal } });
export const tipoLocalLoadFailed   = (error) => ({ type: TIPO_LOCAL_LOAD_FAILED, payload: error  });

// Add
export const tipoLocalAdd         = (tipoLocal) => ({ type: TIPO_LOCAL_ADD, payload: { tipoLocal } });
export const tipoLocalAddSuccess  = (tipoLocal, tipoLocalStatus) => ({ type: TIPO_LOCAL_ADD_SUCCESS, tipoLocalStatus: tipoLocalStatus, payload: { tipoLocal } });
export const tipoLocalAddFailed   = (error) => ({ type: TIPO_LOCAL_ADD_FAILED, payload: error  });

// Update
export const tipoLocalUpdate         = (tipoLocal) => ({ type: TIPO_LOCAL_UPDATE, payload: { tipoLocal } });
export const tipoLocalUpdateSuccess  = (tipoLocal, tipoLocalStatus) => ({ type: TIPO_LOCAL_UPDATE_SUCCESS, tipoLocalStatus: tipoLocalStatus, payload: { tipoLocal } });
export const tipoLocalUpdateFailed   = (error) => ({ type: TIPO_LOCAL_UPDATE_FAILED, payload: error  });

// remove
export const tipoLocalRemove         = (tipoLocal) => ({ type: TIPO_LOCAL_REMOVE, payload: { tipoLocal } });
export const tipoLocalRemoveSuccess  = (tipoLocal, tipoLocalDeletado) => ({ type: TIPO_LOCAL_REMOVE_SUCCESS, tipoLocalDeletado: tipoLocalDeletado, payload: { tipoLocal } });
export const tipoLocalRemoveFailed   = (error) => ({ type: TIPO_LOCAL_REMOVE_FAILED, payload: error  });