
import { LIMITADOR_CORRIDA_LOAD, LIMITADOR_CORRIDA_LOAD_SUCCESS, LIMITADOR_CORRIDA_LOAD_FAILED, LIMITADOR_CORRIDA_LIST, LIMITADOR_CORRIDA_LIST_SUCCESS, LIMITADOR_CORRIDA_LIST_FAILED, LIMITADOR_CORRIDA_ADD, LIMITADOR_CORRIDA_ADD_FAILED, LIMITADOR_CORRIDA_ADD_SUCCESS, LIMITADOR_CORRIDA_REMOVE, LIMITADOR_CORRIDA_REMOVE_FAILED, LIMITADOR_CORRIDA_REMOVE_SUCCESS, LIMITADOR_CORRIDA_UPDATE, LIMITADOR_CORRIDA_UPDATE_SUCCESS, LIMITADOR_CORRIDA_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    limitadorCorridas: [],
    limitadorCorrida: {},
    loading: false,
    error: null,
    limitadorCorridaStatus: null,
    id: {}
};

const TarifaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIMITADOR_CORRIDA_LIST:
            return { ...state, limitadorCorrida: {}, loading: true, error: null, limitadorCorridaDeletado: null };
        case LIMITADOR_CORRIDA_LIST_SUCCESS:
            return { ...state, limitadorCorridas: action.payload, loading: false, error: null, limitadorCorridaDeletado: null };
        case LIMITADOR_CORRIDA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, limitadorCorridaDeletado: null };
        case LIMITADOR_CORRIDA_LOAD:
            return { ...state, limitadorCorrida: {}, loading: true, error: null, limitadorCorridaStatus: null, limitadorCorridaDeletado: null }
        case LIMITADOR_CORRIDA_LOAD_SUCCESS:
            return { ...state, limitadorCorrida: action.payload, loading: false, error: null, limitadorCorridaDeletado: null}
        case LIMITADOR_CORRIDA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, limitadorCorridaDeletado: null };
        case LIMITADOR_CORRIDA_ADD:
            return { ...state, limitadorCorrida: action.payload, limitadorCorridaStatus: null, loading: true, error: null, limitadorCorridaDeletado: null}
        case LIMITADOR_CORRIDA_ADD_SUCCESS:
            return { ...state, limitadorCorrida: action.payload,  limitadorCorridaStatus: "Adicionado com sucesso", limitadorCorridaDeletado: null, loading: false, error: null };
        case LIMITADOR_CORRIDA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LIMITADOR_CORRIDA_UPDATE:
            return { ...state, limitadorCorrida: action.payload, limitadorCorridaStatus: null, loading: true, error: null}
        case LIMITADOR_CORRIDA_UPDATE_SUCCESS:
            return { ...state, limitadorCorrida: action.payload,  limitadorCorridaStatus: "Atualizado com sucesso", limitadorCorridaDeletado: null, loading: false, error: null };
        case LIMITADOR_CORRIDA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, limitadorCorridaStatus: null };
        case LIMITADOR_CORRIDA_REMOVE:
            return { ...state, limitadorCorrida: action.payload, limitadorCorridaStatus: null, loading: true, error: null, limitadorCorridaDeletado: null}
        case LIMITADOR_CORRIDA_REMOVE_SUCCESS:
            return { ...state, limitadorCorrida: {}, limitadorCorridaStatus: null,  limitadorCorridaDeletado: "Removido com sucesso", loading: false, error: null };
        case LIMITADOR_CORRIDA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, limitadorCorridaStatus: null, limitadorCorridaDeletado: null };
        default:
            return { ...state };
    }
};

export default TarifaReducer;