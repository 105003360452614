import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../../helpers/api';
import { getLoggedInUser } from '../../../helpers/authUtils';
import {
    SOLICITACAO_CORRIDA_ADD,
    SOLICITACAO_CORRIDA_LIST,
    SOLICITACAO_CORRIDA_LOAD,
    SOLICITACAO_CORRIDA_UPDATE,
    SOLICITACAO_CORRIDA_REMOVE,
    SOLICITACAO_CORRIDA_APROVAR,
    SOLICITACAO_CORRIDA_LIBERAR,
    SOLICITACAO_CORRIDA_REJEITAR,
    EVENTOS_SOLICITACAO,
    SHOW_MODAL_EVENTOS_SOLICITACAO,
    SOLICITACAO_CORRIDA_REABRIR,
    SOLICITACAO_CORRIDA_REENVIAR,
    SOLICITACAO_CORRIDA_FINALIZAR,
    SOLICITACAO_CORRIDA_CANCELAR,
    SOLICITACAO_CORRIDA_EM_FILA,
    SOLICITACAO_CORRIDA_ENCERRAR,
} from './constants';
import {
    eventoSolicitacaoFailed,
    eventoSolicitacaoSuccess,
    solicitacaoCorridaAprovarFailed,
    solicitacaoCorridaAprovarSuccess,
    solicitacaoCorridaCancelarFailed,
    solicitacaoCorridaCancelarSuccess,
    solicitacaoCorridaEmFilaFailed,
    solicitacaoCorridaEmFilaSuccess,
    solicitacaoCorridaEncerrarFailed,
    solicitacaoCorridaEncerrarSuccess,
    solicitacaoCorridaFailed,
    solicitacaoCorridaFinalizarFailed,
    solicitacaoCorridaFinalizarSuccess,
    solicitacaoCorridaLiberarFailed,
    solicitacaoCorridaLiberarSuccess,
    solicitacaoCorridaListFailed,
    solicitacaoCorridaListSuccess,
    solicitacaoCorridaLoad,
    solicitacaoCorridaLoadFailed,
    solicitacaoCorridaLoadSuccess,
    solicitacaoCorridaReabrirFailed,
    solicitacaoCorridaReabrirSuccess,
    solicitacaoCorridaRejeitarFailed,
    solicitacaoCorridaRejeitarSuccess,
    solicitacaoCorridaRemoveFailed,
    solicitacaoCorridaRemoveSuccess,
    solicitacaoCorridaSuccess,
    solicitacaoCorridaUpdateFailed,
    solicitacaoCorridaUpdateSuccess,
} from './actions';

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* add({ payload: solicitacao }) {
    const options = {
        body: JSON.stringify(solicitacao),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoCorrida', options);
        yield put(
            solicitacaoCorridaSuccess(response, 'Solicitação de Corrida gerada com sucesso. Nº ' + response.codigo)
        );
    } catch (error) {
        yield put(solicitacaoCorridaFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* List({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoCorrida/filter', options);
        yield put(solicitacaoCorridaListSuccess(response));
    } catch (error) {
        yield put(solicitacaoCorridaListFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Load({ payload: solicitacaoId }) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaLoadSuccess(response));
    } catch (error) {
        yield put(solicitacaoCorridaLoadFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Update({ payload: solicitacao }) {
    const options = {
        body: JSON.stringify(solicitacao),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/${solicitacao?.id}`, options);
        yield put(solicitacaoCorridaUpdateSuccess(response, response.mensagem));
        yield call(Load, { payload: solicitacao?.id });
    } catch (error) {
        yield put(solicitacaoCorridaUpdateFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Remove({ payload: solicitacaoId }) {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaRemoveSuccess(response, 'Solicitação removida com sucesso'));
    } catch (error) {
        yield put(solicitacaoCorridaRemoveFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Aprovar({ payload: { solicitacaoId, aprovacao } }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(aprovacao),
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/aprovar/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaAprovarSuccess(response, `Solicitação ${aprovacao.codigo} aprovada com sucesso`));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaAprovarFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Liberar({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/aprovarliberacao/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaLiberarSuccess(response, 'Solicitação liberada com sucesso'));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaLiberarFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Rejeitar({ payload: { solicitacaoId, motivo } }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/rejeitar/${solicitacaoId}/${motivo}`, options);
        yield put(solicitacaoCorridaRejeitarSuccess(response, 'Solicitação rejeitada'));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaRejeitarFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* EventosSolicitacao({ payload: solicitacaoId }) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/eventos/${solicitacaoId}`, options);
        yield put(eventoSolicitacaoSuccess(response));
    } catch (error) {
        yield put(eventoSolicitacaoFailed(error.message));
    }
}

/**
 * Muda o status da solicitação de encerrada para reaberta.
 * @param {solicitacaoId} payload
 */
function* Reabrir({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const solicitacao = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/reabrir/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaReabrirSuccess(solicitacao, `Solicitação reaberta com sucesso`));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaReabrirFailed(error.message));
    }
}

/**
 * Atualiza as informações da corrida e gera uma nova corrida na integração
 * @param {solicitacaoId} payload
 */
function* Reenviar({ payload: solicitacao }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(solicitacao),
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/reenviar/${solicitacao?.id}`, options);
        yield put(solicitacaoCorridaReabrirSuccess(response, `Solicitação reaberta com sucesso`));
        yield call(Load, { payload: solicitacao?.id });
    } catch (error) {
        yield put(solicitacaoCorridaReabrirFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* finalizar({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/finalizar/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaFinalizarSuccess(response));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaFinalizarFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* cancelar({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/cancelar/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaCancelarSuccess(response));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaCancelarFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* EmFila({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/emfila/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaEmFilaSuccess(response, 'Solicitação colocada em fila'));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaEmFilaFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* encerrar({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorrida/encerrar/${solicitacaoId}`, options);
        yield put(solicitacaoCorridaEncerrarSuccess(response));
        yield call(Load, { payload: solicitacaoId });
    } catch (error) {
        yield put(solicitacaoCorridaEncerrarFailed(error.message));
    }
}

export function* watchAdd() {
    yield takeEvery(SOLICITACAO_CORRIDA_ADD, add);
}

export function* wahtchList() {
    yield takeEvery(SOLICITACAO_CORRIDA_LIST, List);
}

export function* wahtchLoad() {
    yield takeEvery(SOLICITACAO_CORRIDA_LOAD, Load);
}

export function* wahtchUpdate() {
    yield takeEvery(SOLICITACAO_CORRIDA_UPDATE, Update);
}

export function* wahtchRemove() {
    yield takeEvery(SOLICITACAO_CORRIDA_REMOVE, Remove);
}

export function* wahtchAprovar() {
    yield takeEvery(SOLICITACAO_CORRIDA_APROVAR, Aprovar);
}

export function* wahtchLiberar() {
    yield takeEvery(SOLICITACAO_CORRIDA_LIBERAR, Liberar);
}

export function* watchRejeitar() {
    yield takeEvery(SOLICITACAO_CORRIDA_REJEITAR, Rejeitar);
}

export function* watchEventosSolicitacao() {
    yield takeEvery(EVENTOS_SOLICITACAO, EventosSolicitacao);
}

export function* watchSolicitacaoCorridaReabrir() {
    yield takeEvery(SOLICITACAO_CORRIDA_REABRIR, Reabrir);
}

export function* watchSolicitacaoCorridaReenviar() {
    yield takeEvery(SOLICITACAO_CORRIDA_REENVIAR, Reenviar);
}

export function* watchSolicitacaoCorridaFinalizar() {
    yield takeEvery(SOLICITACAO_CORRIDA_FINALIZAR, finalizar);
}

export function* watchSolicitacaoCorridaCancelar() {
    yield takeEvery(SOLICITACAO_CORRIDA_CANCELAR, cancelar);
}

export function* watchSolicitacaoCorridaEmFila() {
    yield takeEvery(SOLICITACAO_CORRIDA_EM_FILA, EmFila);
}

export function* watchSolicitacaoCorridaEncerra() {
    yield takeEvery(SOLICITACAO_CORRIDA_ENCERRAR, encerrar);
}

function* SolicitacaoCorridaSaga() {
    yield all([
        fork(watchAdd),
        fork(wahtchList),
        fork(wahtchLoad),
        fork(wahtchUpdate),
        fork(wahtchRemove),
        fork(wahtchAprovar),
        fork(wahtchLiberar),
        fork(watchRejeitar),
        fork(watchEventosSolicitacao),
        fork(watchSolicitacaoCorridaReabrir),
        fork(watchSolicitacaoCorridaReenviar),
        fork(watchSolicitacaoCorridaFinalizar),
        fork(watchSolicitacaoCorridaCancelar),
        fork(watchSolicitacaoCorridaEmFila),
        fork(watchSolicitacaoCorridaEncerra),
    ]);
}

export default SolicitacaoCorridaSaga;
