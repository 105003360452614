
import { MALHA_LOAD, MALHA_LOAD_SUCCESS, MALHA_LOAD_FAILED, MALHA_LIST, MALHA_LIST_SUCCESS, MALHA_LIST_FAILED, MALHA_ADD, MALHA_ADD_FAILED, MALHA_ADD_SUCCESS, MALHA_REMOVE, MALHA_REMOVE_FAILED, MALHA_REMOVE_SUCCESS, MALHA_UPDATE, MALHA_UPDATE_SUCCESS, MALHA_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    malhas: {},
    malha: {},
    loading: false,
    error: null,
    malhaStatus: null,
    id: {}
};

const MalhaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MALHA_LIST:
            return { ...state, loading: true, error: null, malhaDeletado: null };
        case MALHA_LIST_SUCCESS:
            return { ...state, malhas: action.payload, loading: false, error: null, malhaDeletado: null };
        case MALHA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, malhaDeletado: null };
        case MALHA_LOAD:
            return { ...state, loading: true, error: null, malhaStatus: null, malhaDeletado: null }
        case MALHA_LOAD_SUCCESS:
            return { ...state, malha: action.payload, loading: false, error: null, malhaDeletado: null}
        case MALHA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, malhaDeletado: null };
        case MALHA_ADD:
            return { ...state, malha: action.payload, malhaStatus: null, loading: true, error: null, malhaDeletado: null}
        case MALHA_ADD_SUCCESS:
            return { ...state, malha: action.payload,  malhaStatus: "Estação criada com sucesso", malhaDeletado: null, loading: false, error: null };
        case MALHA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case MALHA_UPDATE:
            return { ...state, malha: action.payload, malhaStatus: null, loading: true, error: null}
        case MALHA_UPDATE_SUCCESS:
            return { ...state, malha: action.payload,  malhaStatus: "Estação atualizada com sucesso", malhaDeletado: null, loading: false, error: null };
        case MALHA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, malhaStatus: null };
        case MALHA_REMOVE:
            return { ...state, malha: action.payload, malhaStatus: null, loading: true, error: null, malhaDeletado: null}
        case MALHA_REMOVE_SUCCESS:
            return { ...state, malha: {}, malhaStatus: null,  malhaDeletado: "Estação removida com sucesso", loading: false, error: null };
        case MALHA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, malhaStatus: null, malhaDeletado: null };
        default:
            return { ...state };
    }
};

export default MalhaReducer;