/* TURNO */
export const TURNO_LIST           = 'TURNO_LIST';
export const TURNO_LIST_SUCCESS   = 'TURNO_LIST_SUCCESS';
export const TURNO_LIST_FAILED    = 'TURNO_LIST_FAILED';
export const TURNO_ADD            = 'TURNO_ADD';
export const TURNO_ADD_SUCCESS    = 'TURNO_ADD_SUCCESS';
export const TURNO_ADD_FAILED     = 'TURNO_ADD_FAILED';
export const TURNO_UPDATE         = 'TURNO_UPDATE';
export const TURNO_UPDATE_SUCCESS = 'TURNO_UPDATE_SUCCESS';
export const TURNO_UPDATE_FAILED  = 'TURNO_UPDATE_FAILED';
export const TURNO_REMOVE         = 'TURNO_REMOVE';
export const TURNO_REMOVE_SUCCESS = 'TURNO_REMOVE_SUCCESS';
export const TURNO_REMOVE_FAILED  = 'TURNO_REMOVE_FAILED';
export const TURNO_LOAD           = 'TURNO_LOAD'
export const TURNO_LOAD_SUCCESS   = 'TURNO_LOAD_SUCCESS';
export const TURNO_LOAD_FAILED    = 'TURNO_LOAD_FAILED';