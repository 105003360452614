import styled from 'styled-components';
import theme from '../../../styles/theme';

// -> ItemWithIcon ----------------------------------------
export const ItemWithIcon_Container = styled.div`
    width: 96px;
    height: 117px;
    margin: 8px 4px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    @media (max-width: 720px) {
    }
`;
//TODO
// background-color: ${props => props.theme.colors.dark};
export const ItemWithIcon_ItemBox = styled.div`
    background-color: ${theme.colors.dark};
    width: 64px;
    height: 64px;
    border-radius: 4px;
    margin: 16px 16px 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
//TODO
// fill: ${props => props.theme.colors.greyLight};
export const ItemWithIcon_ItemIcon = styled.div`
    fill: ${theme.colors.greyLight};
    width: 40px;
    height: 40px;
`;
//TODO
//color: ${props => props.theme.colors.dark};
export const ItemWithIcon_ItemLabel = styled.p`
    color: ${theme.colors.dark};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    width: 80px;
    height: 25px;
    margin: 4px 0px 8px 0px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
`;

// -> ItemWithoutIcon ----------------------------------------
export const ItemWithoutIcon_Container = styled.div`
    width: auto;
    height: 12px;
    max-width: 571px;
    margin: 0px 0px 4px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @media (max-width: 720px) {
        margin: 0px 0px 16px 12px;
    }
`;

export const ItemWithoutIcon_ItemLabel = styled.p`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 11px;
    color: ${theme.colors.dark};
    @media (max-width: 720px) {
        line-height: 12px;
    }
`;
//TODO
//  color: ${props => props.theme.colors.dark};

export const ItemWithoutIcon_ItemIcon = styled.div`
    width: 12px;
    height: 12px;
    fill: ${theme.colors.dark};
    @media (max-width: 720px) {
        display: none;
    }
`;
