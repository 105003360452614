import React from 'react';
import { getLoggedInUser } from '../../authUtils';
import { PrivateRoute } from './privateRoute';

const TrocaVeiculoList = React.lazy(() => import('../../../pages/solicitacao/trocaveiculo/TrocaVeiculoList'));
const TrocaVeiculoCadastro = React.lazy(() => import('../../../pages/solicitacao/trocaveiculo/TrocaVeiculoCadastro'));
const AjusteKmList = React.lazy(() => import('../../../pages/solicitacao/ajustekm/AjustKmList'));
const AjusteKmCadastro = React.lazy(() => import('../../../pages/solicitacao/ajustekm/AjusteKmCadastro'));
const ReembolsoCadastro = React.lazy(() => import('../../../pages/solicitacao/reembolso/ReembolsoCadastro'));
const ReembolsoList = React.lazy(() => import('../../../pages/solicitacao/reembolso/ReembolsoList'));
const AtualizacaoDocumentoCadastro = React.lazy(() =>
    import('../../../pages/solicitacao/atualizacaodocumento/AtualizacaoDocumentoCadastro')
);
const AtualizacaoDocumentoList = React.lazy(() =>
    import('../../../pages/solicitacao/atualizacaodocumento/AtualizacaoDocumentoList')
);
const SolicitacaoList = React.lazy(() => import('../../../pages/solicitacao/SolicitacaoList'));
const MotoristaList = React.lazy(() => import('../../../pages/motorista/MotoristaList'));
const MotoristaCadastro = React.lazy(() => import('../../../pages/motorista/MotoristaCadastro'));
const VeiculoList = React.lazy(() => import('../../../pages/veiculo/VeiculoList'));
const VeiculoCadastro = React.lazy(() => import('../../../pages/veiculo/VeiculoCadastro'));

const MotoristaExtrato = React.lazy(() => import('../../../pages/motorista/MotoristaExtrato'));

const permiteReembolso = getLoggedInUser()?.usuario?.permiteReembolso ?? false;

export const motoristaRoute = [
    { path: '/motorista', exact: true, roles: ['master', 'motorista'], component: MotoristaList, route: PrivateRoute },
    {
        path: '/motorista/adicionar',
        exact: true,
        roles: ['master', 'motorista'],
        component: MotoristaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/motorista/atualizar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: MotoristaCadastro,
        route: PrivateRoute,
    },
    {
        path: '/motorista/deletar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: MotoristaCadastro,
        route: PrivateRoute,
    },
    { path: '/veiculo', exact: true, roles: ['master', 'motorista'], component: VeiculoList, route: PrivateRoute },
    {
        path: '/veiculo/adicionar',
        exact: true,
        roles: ['master', 'motorista'],
        component: VeiculoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/veiculo/atualizar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: VeiculoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/veiculo/deletar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: VeiculoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/trocaveiculo',
        exact: true,
        roles: ['master', 'motorista'],
        component: TrocaVeiculoList,
        route: PrivateRoute,
    },
    {
        path: '/trocaveiculo/adicionar',
        exact: true,
        roles: ['master', 'motorista'],
        component: TrocaVeiculoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/trocaveiculo/atualizar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: TrocaVeiculoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/trocaveiculo/deletar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: TrocaVeiculoCadastro,
        route: PrivateRoute,
    },
    { path: '/ajustekm', exact: true, roles: ['master', 'motorista'], component: AjusteKmList, route: PrivateRoute },
    {
        path: '/ajustekm/adicionar',
        exact: true,
        roles: ['master', 'motorista'],
        component: AjusteKmCadastro,
        route: PrivateRoute,
    },
    {
        path: '/ajustekm/atualizar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: AjusteKmCadastro,
        route: PrivateRoute,
    },
    {
        path: '/ajustekm/deletar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: AjusteKmCadastro,
        route: PrivateRoute,
    },

    {
        path: '/reembolso',
        exact: true,
        roles: ['master', permiteReembolso && 'motorista'],
        component: ReembolsoList,
        route: PrivateRoute,
    },
    {
        path: '/reembolso/adicionar',
        exact: true,
        roles: ['master', permiteReembolso && 'motorista'],
        component: ReembolsoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/reembolso/atualizar/:id',
        exact: true,
        roles: ['master', permiteReembolso && 'motorista'],
        component: ReembolsoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/reembolso/deletar/:id',
        exact: true,
        roles: ['master', permiteReembolso && 'motorista'],
        component: ReembolsoCadastro,
        route: PrivateRoute,
    },

    {
        path: '/atualizacaodocumento',
        exact: true,
        roles: ['master', 'motorista'],
        component: AtualizacaoDocumentoList,
        route: PrivateRoute,
    },
    {
        path: '/atualizacaodocumento/adicionar',
        exact: true,
        roles: ['master', 'motorista'],
        component: AtualizacaoDocumentoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/atualizacaodocumento/atualizar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: AtualizacaoDocumentoCadastro,
        route: PrivateRoute,
    },
    {
        path: '/atualizacaodocumento/deletar/:id',
        exact: true,
        roles: ['master', 'motorista'],
        component: AtualizacaoDocumentoCadastro,
        route: PrivateRoute,
    },

    {
        path: '/solicitacao',
        exact: true,
        roles: ['master', 'motorista', 'aprovador'],
        component: SolicitacaoList,
        route: PrivateRoute,
    },

    {
        path: '/motorista/extrato',
        exact: true,
        roles: ['master', 'motorista'],
        component: MotoristaExtrato,
        route: PrivateRoute,
    },
    {
        path: '/motorista/extrato/:id',
        exact: true,
        roles: ['master', 'financeiro', 'auditor', 'gestorcentral', 'operador'],
        component: MotoristaExtrato,
        route: PrivateRoute,
    },
];
