import { 
    CENTRO_CUSTO_LIST,
    CENTRO_CUSTO_LIST_SUCCESS,
    CENTRO_CUSTO_LIST_FAILED,
    CENTRO_CUSTO_ADD,
    CENTRO_CUSTO_ADD_SUCCESS,
    CENTRO_CUSTO_ADD_FAILED,
    CENTRO_CUSTO_UPDATE,
    CENTRO_CUSTO_UPDATE_SUCCESS,
    CENTRO_CUSTO_UPDATE_FAILED,
    CENTRO_CUSTO_LOAD,
    CENTRO_CUSTO_LOAD_SUCCESS,
    CENTRO_CUSTO_LOAD_FAILED,
    CENTRO_CUSTO_REMOVE,
    CENTRO_CUSTO_REMOVE_SUCCESS,
    CENTRO_CUSTO_REMOVE_FAILED,
    CENTRO_CUSTO_LIST_NIVEL,
    CENTRO_CUSTO_LIST_NIVEL_SUCCESS,
    CENTRO_CUSTO_LIST_NIVEL_FAILED,
    CENTRO_CUSTO_LIST_RECURSIVO_USUARIO,
    CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_SUCCESS,
    CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_FAILED
} from './constants';

// List
export const centroCustoList        = (filter) => ({ type: CENTRO_CUSTO_LIST, payload: filter });
export const centroCustoListSuccess = (centroCustos) => ({ type: CENTRO_CUSTO_LIST_SUCCESS, payload: centroCustos });
export const centroCustoListFailed  = (error) => ({ type: CENTRO_CUSTO_LIST_FAILED, payload: error });

export const centroCustoLoad        = (id) => ({ type: CENTRO_CUSTO_LOAD, payload: { id } });
export const centroCustoLoadSuccess  = (centroCusto) => ({ type: CENTRO_CUSTO_LOAD_SUCCESS, payload: { centroCusto } });
export const centroCustoLoadFailed   = (error) => ({ type: CENTRO_CUSTO_LOAD_FAILED, payload: error  });

// Add
export const centroCustoAdd         = (centroCusto) => ({ type: CENTRO_CUSTO_ADD, payload: { centroCusto } });
export const centroCustoAddSuccess  = (centroCusto, centroCustoStatus) => ({ type: CENTRO_CUSTO_ADD_SUCCESS, centroCustoStatus: centroCustoStatus, payload: { centroCusto } });
export const centroCustoAddFailed   = (error) => ({ type: CENTRO_CUSTO_ADD_FAILED, payload: error  });

// Update
export const centroCustoUpdate         = (centroCusto) => ({ type: CENTRO_CUSTO_UPDATE, payload: { centroCusto } });
export const centroCustoUpdateSuccess  = (centroCusto, centroCustoStatus) => ({ type: CENTRO_CUSTO_UPDATE_SUCCESS, centroCustoStatus: centroCustoStatus, payload: { centroCusto } });
export const centroCustoUpdateFailed   = (error) => ({ type: CENTRO_CUSTO_UPDATE_FAILED, payload: error  });

// remove
export const centroCustoRemove         = (centroCustoId) => ({ type: CENTRO_CUSTO_REMOVE, payload: centroCustoId});
export const centroCustoRemoveSuccess  = (centroCusto, centroCustoDeletado) => ({ type: CENTRO_CUSTO_REMOVE_SUCCESS, centroCustoDeletado: centroCustoDeletado, payload: centroCusto  });
export const centroCustoRemoveFailed   = (error) => ({ type: CENTRO_CUSTO_REMOVE_FAILED, payload: error  });


// List
export const centroCustoListNivel        = (nivel) => ({ type: CENTRO_CUSTO_LIST_NIVEL, payload: nivel});
export const centroCustoListNivelSuccess = (centroCustos) => ({ type: CENTRO_CUSTO_LIST_NIVEL_SUCCESS, payload: centroCustos });
export const centroCustoListNivelFailed  = (error) => ({ type: CENTRO_CUSTO_LIST_NIVEL_FAILED, payload: error});

export const centroCustoRecursivoPorUsuario = (usuarioId) => ({ type: CENTRO_CUSTO_LIST_RECURSIVO_USUARIO, payload: usuarioId });
export const centroCustoRecursivoPorUsuarioSuccess = (success) => ({ type: CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_SUCCESS, payload: success });
export const centroCustoRecursivoPorUsuarioFailed = (error) => ({ type: CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_FAILED, payload: error });