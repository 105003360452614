export const SOLICITACAO_PARADA_CORRIDA_ADD = 'SOLICITACAO_PARADA_CORRIDA_ADD';

export const SOLICITACAO_PARADA_CORRIDA_LOAD = 'SOLICITACAO_PARADA_CORRIDA_LOAD';

export const SOLICITACAO_PARADA_CORRIDA_UPDATE = 'SOLICITACAO_PARADA_CORRIDA_UPDATE';

export const SOLICITACAO_PARADA_CORRIDA_REMOVE = 'SOLICITACAO_PARADA_CORRIDA_REMOVE';

export const SOLICITACAO_PARADA_CORRIDA_NEW = 'SOLICITACAO_PARADA_CORRIDA_NEW';

export const SOLICITACAO_PARADA_CORRIDA_CANCEL = 'SOLICITACAO_PARADA_CORRIDA_CANCEL';

export const SOLICITACAO_PARADA_CORRIDA_LIST = 'SOLICITACAO_PARADA_CORRIDA_LIST';
