import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils';

import {
    CENTRO_CUSTO_INTERNO_LOAD,
    CENTRO_CUSTO_INTERNO_LIST,
    CENTRO_CUSTO_INTERNO_ADD,
    CENTRO_CUSTO_INTERNO_UPDATE,
    CENTRO_CUSTO_INTERNO_REMOVE,
} from './constants';

import {
    centroCustoInternoListSuccess,
    centroCustoInternoListFailed,
    centroCustoInternoAddSuccess,
    centroCustoInternoAddFailed,
    centroCustoInternoUpdateSuccess,
    centroCustoInternoUpdateFailed,
    centroCustoInternoLoadSuccess,
    centroCustoInternoLoadFailed,
    centroCustoInternoRemoveSuccess,
    centroCustoInternoRemoveFailed,
} from './actions';

/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoInternoLoad({ payload: { id } }) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/centroCustoInterno/' + id, options);
        yield put(centroCustoInternoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message =
                    error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(centroCustoInternoLoadFailed(message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoInternoList({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/centroCustoInterno/filter', options);
        yield put(centroCustoInternoListSuccess(response));
    } catch (error) {
        yield put(centroCustoInternoListFailed(error.message));
    }
}

/**
 * centroCusto
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoInternoAdd({ payload: { centroCustoInterno } }) {
    const options = {
        body: JSON.stringify(centroCustoInterno),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/centroCustoInterno', options);
        yield put(centroCustoInternoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message =
                    error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(centroCustoInternoAddFailed(message));
    }
}

/**
 * centroCusto
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoInternoUpdate({ payload: { centroCustoInterno } }) {
    const options = {
        body: JSON.stringify(centroCustoInterno),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(
            fetchJSON,
            '/central/v1/api/v1/centroCustoInterno/' + centroCustoInterno?.id,
            options
        );
        yield put(centroCustoInternoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message =
                    error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(centroCustoInternoUpdateFailed(message));
    }
}

/**
 * centroCusto
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoInternoRemove({ payload: centroCustoInternoId }) {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/centroCustoInterno/' + centroCustoInternoId, options);
        yield put(centroCustoInternoRemoveSuccess(centroCustoInternoId));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message =
                    error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break;
            default:
                message = error.message;
        }
        yield put(centroCustoInternoRemoveFailed(message));
    }
}

export function* watchCentroCustoInternoLoad(): any {
    yield takeEvery(CENTRO_CUSTO_INTERNO_LOAD, centroCustoInternoLoad);
}

export function* watchCentroCustoInternoList(): any {
    yield takeEvery([CENTRO_CUSTO_INTERNO_LIST], centroCustoInternoList);
}

export function* watchCentroCustoInternoAdd(): any {
    yield takeEvery(CENTRO_CUSTO_INTERNO_ADD, centroCustoInternoAdd);
}

export function* watchCentroCustoInternoUpdate(): any {
    yield takeEvery(CENTRO_CUSTO_INTERNO_UPDATE, centroCustoInternoUpdate);
}

export function* watchCentroCustoInternoRemove(): any {
    yield takeEvery(CENTRO_CUSTO_INTERNO_REMOVE, centroCustoInternoRemove);
}

function* centroCustoInterno(): any {
    yield all([
        fork(watchCentroCustoInternoAdd),
        fork(watchCentroCustoInternoUpdate),
        fork(watchCentroCustoInternoLoad),
        fork(watchCentroCustoInternoRemove),
        fork(watchCentroCustoInternoList),
    ]);
}

export default centroCustoInterno;
