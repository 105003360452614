import { 
    VEICULOCOR_LIST,
    VEICULOCOR_LIST_SUCCESS,
    VEICULOCOR_LIST_FAILED,
    VEICULOCOR_ADD,
    VEICULOCOR_ADD_SUCCESS,
    VEICULOCOR_ADD_FAILED,
    VEICULOCOR_UPDATE,
    VEICULOCOR_UPDATE_SUCCESS,
    VEICULOCOR_UPDATE_FAILED,
    VEICULOCOR_LOAD,
    VEICULOCOR_LOAD_SUCCESS,
    VEICULOCOR_LOAD_FAILED,
    VEICULOCOR_REMOVE,
    VEICULOCOR_REMOVE_SUCCESS,
    VEICULOCOR_REMOVE_FAILED
} from './constants';

// List
export const veiculoCorList        = (filter) => ({ type: VEICULOCOR_LIST, payload: filter,});
export const veiculoCorListSuccess = (veiculoCores) => ({ type: VEICULOCOR_LIST_SUCCESS, payload:  veiculoCores });
export const veiculoCorListFailed  = (error) => ({ type: VEICULOCOR_LIST_FAILED, payload: error  });

export const veiculoCorLoad        = (id) => ({ type: VEICULOCOR_LOAD, payload: { id } });
export const veiculoCorLoadSuccess  = (veiculoCor) => ({ type: VEICULOCOR_LOAD_SUCCESS, payload:  veiculoCor  });
export const veiculoCorLoadFailed   = (error) => ({ type: VEICULOCOR_LOAD_FAILED, payload: error  });

// Add
export const veiculoCorAdd         = (veiculoCor) => ({ type: VEICULOCOR_ADD, payload: { veiculoCor } });
export const veiculoCorAddSuccess  = (veiculoCor, veiculoCorStatus) => ({ type: VEICULOCOR_ADD_SUCCESS, veiculoCorStatus: veiculoCorStatus, payload:  veiculoCor  });
export const veiculoCorAddFailed   = (error) => ({ type: VEICULOCOR_ADD_FAILED, payload: error  });

// Update
export const veiculoCorUpdate         = (veiculoCor) => ({ type: VEICULOCOR_UPDATE, payload: { veiculoCor } });
export const veiculoCorUpdateSuccess  = (veiculoCor, veiculoCorStatus) => ({ type: VEICULOCOR_UPDATE_SUCCESS, veiculoCorStatus: veiculoCorStatus, payload:  veiculoCor  });
export const veiculoCorUpdateFailed   = (error) => ({ type: VEICULOCOR_UPDATE_FAILED, payload: error  });

// remove
export const veiculoCorRemove         = (veiculoCor) => ({ type: VEICULOCOR_REMOVE, payload:  veiculoCor  });
export const veiculoCorRemoveSuccess  = (veiculoCor, veiculoCorDeletado) => ({ type: VEICULOCOR_REMOVE_SUCCESS, veiculoCorDeletado: veiculoCorDeletado, payload:  veiculoCor  });
export const veiculoCorRemoveFailed   = (error) => ({ type: VEICULOCOR_REMOVE_FAILED, payload: error  });