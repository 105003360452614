import { 
    EMPRESA_LIST,
    EMPRESA_LIST_SUCCESS,
    EMPRESA_LIST_FAILED,
    EMPRESA_ADD,
    EMPRESA_ADD_SUCCESS,
    EMPRESA_ADD_FAILED,
    EMPRESA_UPDATE,
    EMPRESA_UPDATE_SUCCESS,
    EMPRESA_UPDATE_FAILED,
    EMPRESA_LOAD,
    EMPRESA_LOAD_SUCCESS,
    EMPRESA_LOAD_FAILED,
    EMPRESA_REMOVE,
    EMPRESA_REMOVE_SUCCESS,
    EMPRESA_REMOVE_FAILED
} from './constants';

// List
export const empresaList        = (filter) => ({ type: EMPRESA_LIST, payload: filter });
export const empresaListSuccess = (empresas) => ({ type: EMPRESA_LIST_SUCCESS, payload: empresas });
export const empresaListFailed  = (error) => ({ type: EMPRESA_LIST_FAILED, payload: error });

export const empresaLoad        = (id) => ({ type: EMPRESA_LOAD, payload: { id } });
export const empresaLoadSuccess  = (empresa) => ({ type: EMPRESA_LOAD_SUCCESS, payload: { empresa } });
export const empresaLoadFailed   = (error) => ({ type: EMPRESA_LOAD_FAILED, payload: error  });

// Add
export const empresaAdd         = (empresa) => ({ type: EMPRESA_ADD, payload: { empresa } });
export const empresaAddSuccess  = (empresa, empresaStatus) => ({ type: EMPRESA_ADD_SUCCESS, empresaStatus: empresaStatus, payload: { empresa } });
export const empresaAddFailed   = (error) => ({ type: EMPRESA_ADD_FAILED, payload: error  });

// Update
export const empresaUpdate         = (empresa) => ({ type: EMPRESA_UPDATE, payload: { empresa } });
export const empresaUpdateSuccess  = (empresa, empresaStatus) => ({ type: EMPRESA_UPDATE_SUCCESS, empresaStatus: empresaStatus, payload: { empresa } });
export const empresaUpdateFailed   = (error) => ({ type: EMPRESA_UPDATE_FAILED, payload: error  });

// remove
export const empresaRemove         = (empresa) => ({ type: EMPRESA_REMOVE, payload: { empresa } });
export const empresaRemoveSuccess  = (empresa, empresaDeletado) => ({ type: EMPRESA_REMOVE_SUCCESS, empresaDeletado: empresaDeletado, payload: { empresa } });
export const empresaRemoveFailed   = (error) => ({ type: EMPRESA_REMOVE_FAILED, payload: error  });