import {
    NEW_SOLICITACAO_CORRIDA_ADD,
    NEW_SOLICITACAO_CORRIDA_FAILED,
    NEW_SOLICITACAO_CORRIDA_LIST,
    NEW_SOLICITACAO_CORRIDA_LIST_FAILED,
    NEW_SOLICITACAO_CORRIDA_LIST_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_LOAD,
    NEW_SOLICITACAO_CORRIDA_LOAD_FAILED,
    NEW_SOLICITACAO_CORRIDA_LOAD_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_UPDATE,
    NEW_SOLICITACAO_CORRIDA_UPDATE_FAILED,
    NEW_SOLICITACAO_CORRIDA_UPDATE_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REMOVE,
    NEW_SOLICITACAO_CORRIDA_REMOVE_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REMOVE_FAILED,
    NEW_SOLICITACAO_CORRIDA_APROVAR,
    NEW_SOLICITACAO_CORRIDA_APROVAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_APROVAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_LIBERAR,
    NEW_SOLICITACAO_CORRIDA_LIBERAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_LIBERAR_FAILED,
    NEW_SOLICITACAO_ADD_PARADA,
    NEW_SOLICITACAO_CORRIDA_NEW,
    NEW_SOLICITACAO_CORRIDA_REJEITAR,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_FAILED,
    EVENTOS_SOLICITACAO,
    EVENTOS_SOLICITACAO_SUCCESS,
    EVENTOS_SOLICITACAO_FAILED,
    SHOW_MODAL_EVENTOS_NEW_SOLICITACAO,
    NEW_SOLICITACAO_CORRIDA_REENVIAR,
    NEW_SOLICITACAO_CORRIDA_REENVIAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REENVIAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_REABRIR,
    NEW_SOLICITACAO_CORRIDA_REABRIR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_REABRIR_FAILED,
    NEW_SOLICITACAO_CORRIDA_FINALIZAR,
    NEW_SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_FINALIZAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_CANCELAR,
    NEW_SOLICITACAO_CORRIDA_CANCELAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_CANCELAR_FAILED,
    NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO,
    NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_FAILED,
    NEW_SOLICITACAO_CORRIDA_EM_FILA,
    NEW_SOLICITACAO_CORRIDA_EM_FILA_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_EM_FILA_FAILED,
    HANDLE_NEW_REFERENCIA_PARTIDA,
    NEW_SOLICITACAO_CORRIDA_ENCERRAR,
    NEW_SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS,
    NEW_SOLICITACAO_CORRIDA_ENCERRAR_FAILED,
} from './constants';

export const newSolicitacaoCorridaAdd = (solicitacao) => ({ type: NEW_SOLICITACAO_CORRIDA_ADD, payload: solicitacao });
export const newSolicitacaoCorridaSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaFailed = (erro) => ({ type: NEW_SOLICITACAO_CORRIDA_FAILED, payload: erro });

export const newSolicitacaoCorridaList = (filter) => ({ type: NEW_SOLICITACAO_CORRIDA_LIST, payload: filter });
export const newSolicitacaoCorridaListSuccess = (solicitacoes, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_LIST_SUCCESS,
    payload: { solicitacoes, success },
});
export const newSolicitacaoCorridaListFailed = (erro) => ({ type: NEW_SOLICITACAO_CORRIDA_LIST_FAILED, payload: erro });

export const newSolicitacaoCorridaLoad = (solicitacaoId) => ({
    type: NEW_SOLICITACAO_CORRIDA_LOAD,
    payload: solicitacaoId,
});
export const newSolicitacaoCorridaLoadSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_LOAD_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaLoadFailed = (erro) => ({ type: NEW_SOLICITACAO_CORRIDA_LOAD_FAILED, payload: erro });

export const newSolicitacaoCorridaUpdate = (solicitacao) => ({
    type: NEW_SOLICITACAO_CORRIDA_UPDATE,
    payload: solicitacao,
});
export const newSolicitacaoCorridaUpdateSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_UPDATE_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaUpdateFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_UPDATE_FAILED,
    payload: erro,
});

export const newSolicitacaoCorridaRemove = (solicitacaoId) => ({
    type: NEW_SOLICITACAO_CORRIDA_REMOVE,
    payload: solicitacaoId,
});
export const newSolicitacaoCorridaRemoveSuccess = (success) => ({
    type: NEW_SOLICITACAO_CORRIDA_REMOVE_SUCCESS,
    payload: success,
});
export const newSolicitacaoCorridaRemoveFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_REMOVE_FAILED,
    payload: erro,
});

export const newSolicitacaoCorridaAprovar = (solicitacaoId, aprovacao) => ({
    type: NEW_SOLICITACAO_CORRIDA_APROVAR,
    payload: { solicitacaoId, aprovacao },
});
export const newSolicitacaoCorridaAprovarSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_APROVAR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaAprovarFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_APROVAR_FAILED,
    payload: erro,
});

export const newSolicitacaoCorridaLiberar = (solicitacaoId) => ({
    type: NEW_SOLICITACAO_CORRIDA_LIBERAR,
    payload: solicitacaoId,
});
export const newSolicitacaoCorridaLiberarSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_LIBERAR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaLiberarFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_LIBERAR_FAILED,
    payload: erro,
});

export const newSolicitacaoCorridaRejeitar = (solicitacaoId, motivo) => ({
    type: NEW_SOLICITACAO_CORRIDA_REJEITAR,
    payload: { solicitacaoId, motivo },
});
export const newSolicitacaoCorridaRejeitarSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_REJEITAR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaRejeitarFailed = (error) => ({
    type: NEW_SOLICITACAO_CORRIDA_REJEITAR_FAILED,
    payload: error,
});

export const newSolicitacaoCorridaRejeitarEncerrar = (solicitacaoId, motivo) => ({
    type: NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR,
    payload: { solicitacaoId, motivo },
});
export const newSolicitacaoCorridaRejeitarEncerrarSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaRejeitarEncerrarFailed = (error) => ({
    type: NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_FAILED,
    payload: error,
});

export const newSolicitacaoAddParada = (parada) => ({ type: NEW_SOLICITACAO_ADD_PARADA, payload: parada });

export const newSolicitacaoCorridaNew = () => ({ type: NEW_SOLICITACAO_CORRIDA_NEW });

export const eventosSolicitacao = (solicitacaoId) => ({ type: EVENTOS_SOLICITACAO, payload: solicitacaoId });
export const eventoNewSolicitacaoSuccess = (eventos) => ({ type: EVENTOS_SOLICITACAO_SUCCESS, payload: eventos });
export const eventoNewSolicitacaoFailed = (error) => ({ type: EVENTOS_SOLICITACAO_FAILED, payload: error });

export const showModalEventosNewSolicitacao = () => ({ type: SHOW_MODAL_EVENTOS_NEW_SOLICITACAO });

export const newSolicitacaoCorridaReenviar = (solicitacaoId) => ({
    type: NEW_SOLICITACAO_CORRIDA_REENVIAR,
    payload: solicitacaoId,
});
export const newSolicitacaoCorridaReenviarSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_REENVIAR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaReenviarFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_REENVIAR_FAILED,
    payload: erro,
});

export const newSolicitacaoCorridaReabrir = (solicitacao) => ({
    type: NEW_SOLICITACAO_CORRIDA_REABRIR,
    payload: solicitacao,
});
export const newSolicitacaoCorridaReabrirSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_REABRIR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaReabrirFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_REABRIR_FAILED,
    payload: erro,
});

export const newSolicitacaoCorridaFinalizar = (solicitacaoId) => ({
    type: NEW_SOLICITACAO_CORRIDA_FINALIZAR,
    payload: solicitacaoId,
});
export const newSolicitacaoCorridaFinalizarSuccess = (success) => ({
    type: NEW_SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS,
    payload: success,
});
export const newSolicitacaoCorridaFinalizarFailed = (error) => ({
    type: NEW_SOLICITACAO_CORRIDA_FINALIZAR_FAILED,
    payload: error,
});

export const newSolicitacaoCorridaCancelar = (solicitacaoId, motivo) => ({
    type: NEW_SOLICITACAO_CORRIDA_CANCELAR,
    payload: { solicitacaoId, motivo },
});
export const newSolicitacaoCorridaCancelarSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_CANCELAR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaCancelarFailed = (error) => ({
    type: NEW_SOLICITACAO_CORRIDA_CANCELAR_FAILED,
    payload: error,
});

export const newSolicitacaoCorridaSolicitarCancelamento = (solicitacaoId, motivo) => ({
    type: NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO,
    payload: { solicitacaoId, motivo },
});
export const newSolicitacaoCorridaSolicitarCancelamentoSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaSolicitarCancelamentoFailed = (error) => ({
    type: NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_FAILED,
    payload: error,
});

export const newSolicitacaoCorridaEmFila = (solicitacaoId) => ({
    type: NEW_SOLICITACAO_CORRIDA_EM_FILA,
    payload: solicitacaoId,
});
export const newSolicitacaoCorridaEmFilaSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_EM_FILA_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaEmFilaFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_EM_FILA_FAILED,
    payload: erro,
});

export const handleNewReferenciaPartida = (referencia) => ({
    type: HANDLE_NEW_REFERENCIA_PARTIDA,
    payload: referencia,
});

export const newSolicitacaoCorridaEncerrar = (solicitacaoId) => ({
    type: NEW_SOLICITACAO_CORRIDA_ENCERRAR,
    payload: solicitacaoId,
});
export const newSolicitacaoCorridaEncerrarSuccess = (solicitacao, success) => ({
    type: NEW_SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS,
    payload: { solicitacao, success },
});
export const newSolicitacaoCorridaEncerrarFailed = (erro) => ({
    type: NEW_SOLICITACAO_CORRIDA_ENCERRAR_FAILED,
    payload: erro,
});
