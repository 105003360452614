import React from 'react';
import Zendesk from "./ZendeskApp";
import config from '../config'

export default function ZendeskApp() {

  const setting = {
    color: {
      theme: "#000"
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help"
      }
    },
    contactForm: {
      fields: [
        { id: "description", prefill: { "*": "My pre-filled description" } }
      ]
    }
  };

  const ZENDESK_KEY = config.zendeskKey;

  const loggedInUser = JSON.parse(window.localStorage.getItem('userLoged'))
  const tipoUser = loggedInUser?.usuario?.tipoUsuario;

  var perfis = tipoUser?.split(', ');

  return (
    <React.Fragment>
      {
        (perfis.includes("OperadorCliente") || perfis.includes("GestorCliente")) &&
        <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} />
      }
    </React.Fragment>
  );
}
