import { 
    AJUSTEKM_LIST,
    AJUSTEKM_LIST_SUCCESS,
    AJUSTEKM_LIST_FAILED,
    AJUSTEKM_ADD,
    AJUSTEKM_ADD_SUCCESS,
    AJUSTEKM_ADD_FAILED,
    AJUSTEKM_UPDATE,
    AJUSTEKM_UPDATE_SUCCESS,
    AJUSTEKM_UPDATE_FAILED,
    AJUSTEKM_LOAD,
    AJUSTEKM_LOAD_SUCCESS,
    AJUSTEKM_LOAD_FAILED,
    AJUSTEKM_REMOVE,
    AJUSTEKM_REMOVE_SUCCESS,
    AJUSTEKM_REMOVE_FAILED
} from './constants';

// List
export const ajusteKmList        = (filter) => ({ type: AJUSTEKM_LIST, payload: filter,});
export const ajusteKmListSuccess = (ajusteKms) => ({ type: AJUSTEKM_LIST_SUCCESS, payload: { ajusteKms } });
export const ajusteKmListFailed  = (error) => ({ type: AJUSTEKM_LIST_FAILED, payload: error  });

export const ajusteKmLoad        = (id) => ({ type: AJUSTEKM_LOAD, payload: { id } });
export const ajusteKmLoadSuccess  = (ajusteKm) => ({ type: AJUSTEKM_LOAD_SUCCESS, payload: ajusteKm });
export const ajusteKmLoadFailed   = (error) => ({ type: AJUSTEKM_LOAD_FAILED, payload: error  });

// Add
export const ajusteKmAdd         = (ajusteKm) => ({ type: AJUSTEKM_ADD, payload: ajusteKm });
export const ajusteKmAddSuccess  = (ajusteKm, ajusteKmStatus) => ({ type: AJUSTEKM_ADD_SUCCESS, ajusteKmStatus: ajusteKmStatus, payload: ajusteKm });
export const ajusteKmAddFailed   = (error) => ({ type: AJUSTEKM_ADD_FAILED, payload: error  });

// Update
export const ajusteKmUpdate         = (ajusteKm) => ({ type: AJUSTEKM_UPDATE, payload: ajusteKm });
export const ajusteKmUpdateSuccess  = (ajusteKm, ajusteKmStatus) => ({ type: AJUSTEKM_UPDATE_SUCCESS, ajusteKmStatus: ajusteKmStatus, payload: ajusteKm });
export const ajusteKmUpdateFailed   = (error) => ({ type: AJUSTEKM_UPDATE_FAILED, payload: error  });

// remove
export const ajusteKmRemove         = (ajusteKm) => ({ type: AJUSTEKM_REMOVE, payload: ajusteKm });
export const ajusteKmRemoveSuccess  = (ajusteKm, ajusteKmDeletado) => ({ type: AJUSTEKM_REMOVE_SUCCESS, ajusteKmDeletado: ajusteKmDeletado, payload: ajusteKm });
export const ajusteKmRemoveFailed   = (error) => ({ type: AJUSTEKM_REMOVE_FAILED, payload: error  });