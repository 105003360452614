import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils';

import { TAXI_MACHINE_ESTIMAR_CORRIDA, TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR } from './constants';

import {
    estimarCorridaSuccess,
    estimarCorridaFailed,
    estimarCorridaValorSuccess,
    estimarCorridaValorFailed,
} from './actions';

/**
 * Lista de Solicitações
 * @param {*} payload - pageNumber and pageSize
 */
function* estimarCorrida({ payload: estimarCorrida }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(estimarCorrida),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/taximachine/estimarcorrida', options);
        yield put(estimarCorridaSuccess(response));
    } catch (error) {
        yield put(estimarCorridaFailed(error.message));
    }
}

function* estimarCorridaValor({ payload: estimarCorridaValor }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(estimarCorridaValor),
    };

    try {
        const response = yield call(fetchJSON, '/rate/api/v2/Rate/CalculateSimpleRate', options);
        yield put(estimarCorridaValorSuccess(response));
    } catch (error) {
        yield put(estimarCorridaValorFailed(error.message));
    }
}

export function* watchEstimarCorrida(): any {
    yield takeEvery(TAXI_MACHINE_ESTIMAR_CORRIDA, estimarCorrida);
}
export function* watchEstimarCorridaValor(): any {
    yield takeEvery(TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR, estimarCorridaValor);
}
function* taxiMachine(): any {
    yield all([fork(watchEstimarCorrida), fork(watchEstimarCorridaValor)]);
}

export default taxiMachine;
