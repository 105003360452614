/* LIMITADOR_CORRIDA */
export const LIMITADOR_CORRIDA_LIST           = 'LIMITADOR_CORRIDA_LIST';
export const LIMITADOR_CORRIDA_LIST_SUCCESS   = 'LIMITADOR_CORRIDA_LIST_SUCCESS';
export const LIMITADOR_CORRIDA_LIST_FAILED    = 'LIMITADOR_CORRIDA_LIST_FAILED';
export const LIMITADOR_CORRIDA_ADD            = 'LIMITADOR_CORRIDA_ADD';
export const LIMITADOR_CORRIDA_ADD_SUCCESS    = 'LIMITADOR_CORRIDA_ADD_SUCCESS';
export const LIMITADOR_CORRIDA_ADD_FAILED     = 'LIMITADOR_CORRIDA_ADD_FAILED';
export const LIMITADOR_CORRIDA_UPDATE         = 'LIMITADOR_CORRIDA_UPDATE';
export const LIMITADOR_CORRIDA_UPDATE_SUCCESS = 'LIMITADOR_CORRIDA_UPDATE_SUCCESS';
export const LIMITADOR_CORRIDA_UPDATE_FAILED  = 'LIMITADOR_CORRIDA_UPDATE_FAILED';
export const LIMITADOR_CORRIDA_REMOVE         = 'LIMITADOR_CORRIDA_REMOVE';
export const LIMITADOR_CORRIDA_REMOVE_SUCCESS = 'LIMITADOR_CORRIDA_REMOVE_SUCCESS';
export const LIMITADOR_CORRIDA_REMOVE_FAILED  = 'LIMITADOR_CORRIDA_REMOVE_FAILED';
export const LIMITADOR_CORRIDA_LOAD           = 'LIMITADOR_CORRIDA_LOAD'
export const LIMITADOR_CORRIDA_LOAD_SUCCESS   = 'LIMITADOR_CORRIDA_LOAD_SUCCESS';
export const LIMITADOR_CORRIDA_LOAD_FAILED    = 'LIMITADOR_CORRIDA_LOAD_FAILED';