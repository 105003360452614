import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { authRoutes } from '../helpers/menu/routes/authRoutes';

import {
    cadastroRoute,
    corridasRoute,
    faturamentoRoute,
    auditoriaRoute,
    aprovacaoRoute,
    dashboardRoute,
    financeiroRoute,
    centralNotificacaoRoute,
    motoristaRoute,
    relatorioRoute,
} from '../helpers/menu/routes/menuRoutes';

import {
    OperadorMenu,
    DashboardMenu,
    AdminMenu,
    OperadorMenuCliente,
    GestorMenu,
    GestorClienteMenu,
    MotoristaMenu,
    AuditoriaMenu,
    AprovacaoMenu,
    RelatorioMenu,
} from '../helpers/menu/menus/menu';

import { getLoggedInUser } from '../helpers/authUtils';
import { FaturamentoMenu } from '../helpers/menu/menus/faturamentoMenu';
import { FinanceiroMenu } from '../helpers/menu/menus/financeiroMenu';

// lazy load all the views
const loggedInUser = getLoggedInUser();

// 404
const PageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
// Sem acesso
const PageNotAccess = React.lazy(() => import('../pages/error/PageNotAccess'));

const pageNotAccess = {
    path: '/sem-acesso',
    exact: true,
    component: PageNotAccess,
    route: Route,
};

// 404
const pageNotFound = {
    path: '*',
    component: PageNotFound,
    route: Route,
};

const centralNotificacaoMenu = [
    {
        header: 'Central de Notificações',
        path: '/notificacao',
        name: 'Notificações',
        icon: 'mdi mdi-bell-ring-outline',
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        if (Array.isArray(item)) {
            if (item.length > 0) {
                flatRoutes = [...flatRoutes, ...flattenRoutes(item)];
                return true;
            } else {
                return true;
            }
        }

        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// /**
//  * Método responsável em trazer apenas 1 única registro, para não repetir o menu.
//  * @param {value} value  - Valor atual
//  * @param {index} index  - index do valor atual
//  * @param {self} self    - Lista com o total.
//  */
// function onlyUnique(value, index, self) {
//     if(value.children){
//         value.children = value.children.filter(onlyUnique);
//     }
//     return self.findIndex(x => x.name === value.name) === index;
// }

var rota = '/';

if (loggedInUser) {
    const tipoUser = loggedInUser.usuario.tipoUsuario;

    var perfis = tipoUser.split(',');

    var menus = [];

    for (let index = 0; index < perfis.length; index++) {
        const element = perfis[index].trim();

        switch (element) {
            case 'Master':
                rota = '/dashboard/geral';
                break;
            case 'GestorCentral':
                rota = '/dashboard/geral';
                break;
            case 'GestorCliente':
                rota = '/dashboard/geral';
                break;
            case 'OperadorCentral':
                rota = '/monitoramento/corridas';
                break;
            case 'OperadorCliente':
                rota = '/corrida/acompanhamento';
                break;
            case 'Faturista':
                rota = '/faturamento/fatura';
                break;
            case 'Auditor':
                rota = '/disponibilidade';
                break;
            case 'Aprovador':
                rota = '/aprovacao';
                break;
            case 'Motorista':
                rota = '/solicitacao';
                break;
            default:
                break;
        }
    }
} else {
    rota = '/conta/login';
}

const authProtectedRoutes = () => {

    const loggedInUser = JSON.parse(window.localStorage.getItem('userLoged'))
    const tipoUser = loggedInUser?.usuario?.tipoUsuario;

    var perfis = tipoUser?.split(',');

    var menus = [];

    for (let index = 0; index < perfis.length; index++) {
        const element = perfis[index].trim();

        switch (element) {
            case 'Master':
                menus = menus
                    .concat(DashboardMenu())
                    .concat(AdminMenu())
                    .concat(OperadorMenu())
                    .concat(OperadorMenuCliente())
                    .concat(GestorMenu())
                    .concat(GestorClienteMenu())
                    .concat(FaturamentoMenu())
                    .concat(MotoristaMenu())
                    .concat(AuditoriaMenu())
                    .concat(RelatorioMenu());
                break;
            case 'Administrador':
                menus = menus.concat(AdminMenu()).concat(RelatorioMenu());
                break;
            case 'OperadorCentral':
                menus = menus.concat(OperadorMenu()).concat(RelatorioMenu());
                break;
            case 'GestorCentral':
                menus = menus.concat(DashboardMenu()).concat(GestorMenu()).concat(RelatorioMenu());
                break;
            case 'GestorCliente':
                menus = menus.concat(DashboardMenu()).concat(GestorClienteMenu()).concat(RelatorioMenu());
                break;
            case 'OperadorCliente':
                menus = menus.concat(OperadorMenuCliente());
                break;
            case 'Faturista':
                menus = menus.concat(FaturamentoMenu()).concat(RelatorioMenu());
                break;
            case 'Auditor':
                menus = menus.concat(AuditoriaMenu()).concat(RelatorioMenu());
                break;
            case 'Aprovador':
                menus = menus.concat(AprovacaoMenu()).concat(RelatorioMenu());
                break;
            case 'Motorista':
                menus = menus.concat(MotoristaMenu());
                break;
            case 'Financeiro':
                menus = menus.concat(FinanceiroMenu()).concat(RelatorioMenu());
                break;
            default:
                break;
        }
    }

    return ajustaMenu(menus).concat(centralNotificacaoMenu);
};

const ajustaMenu = (menu) => {
    let menus = [];

    menu.forEach((element) => {
        const index = menus.findIndex((x) => x.header === element.header);
        if (index < 0) menus = [...menus, element];

        if (element.children && element.children.length > 0) {
            element.children.forEach((elementItem) => {
                const indexPai = menus.findIndex((x) => x.header === element.header);
                var menu = menus[indexPai];
                if (menu?.children) {
                    const indexItem = menu.children.findIndex((x) => x.path === elementItem.path);
                    if (indexItem < 0) {
                        if (indexItem < 0) menu.children = [...menu.children, elementItem];
                        else if (elementItem?.children)
                            menus[index].children = [...menus[index].children, ajustaMenu(elementItem.children)];
                    }
                }
            });
        }
    });

    return menus;
};

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to={rota} />,
    route: Route,
};

// All routes
const allRoutes = [
    rootRoute,
    ...cadastroRoute,
    centralNotificacaoRoute,
    corridasRoute,
    aprovacaoRoute,
    auditoriaRoute,
    faturamentoRoute,
    dashboardRoute,
    financeiroRoute,
    motoristaRoute,
    relatorioRoute,
    authRoutes,
    pageNotAccess,
    pageNotFound,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
