import { 
    DOMINIO_LIST,
    DOMINIO_LIST_SUCCESS,
    DOMINIO_LIST_FAILED,
    DOMINIO_ADD,
    DOMINIO_ADD_SUCCESS,
    DOMINIO_ADD_FAILED,
    DOMINIO_UPDATE,
    DOMINIO_UPDATE_SUCCESS,
    DOMINIO_UPDATE_FAILED,
    DOMINIO_LOAD,
    DOMINIO_LOAD_SUCCESS,
    DOMINIO_LOAD_FAILED,
    DOMINIO_REMOVE,
    DOMINIO_REMOVE_SUCCESS,
    DOMINIO_REMOVE_FAILED
} from './constants';

// List
export const dominioList        = (filter) => ({ type: DOMINIO_LIST, payload: filter });
export const dominioListSuccess = (dominios) => ({ type: DOMINIO_LIST_SUCCESS, payload: dominios });
export const dominioListFailed  = (error) => ({ type: DOMINIO_LIST_FAILED, payload: error  });

export const dominioLoad        = (id) => ({ type: DOMINIO_LOAD, payload: id });
export const dominioLoadSuccess  = (dominio) => ({ type: DOMINIO_LOAD_SUCCESS, payload: dominio });
export const dominioLoadFailed   = (error) => ({ type: DOMINIO_LOAD_FAILED, payload: error });

// Add
export const dominioAdd         = (dominio) => ({ type: DOMINIO_ADD, payload: dominio });
export const dominioAddSuccess  = (dominio, dominioStatus) => ({ type: DOMINIO_ADD_SUCCESS, dominioStatus: dominioStatus, payload: dominio });
export const dominioAddFailed   = (error) => ({ type: DOMINIO_ADD_FAILED, payload: error });

// Update
export const dominioUpdate         = (dominio) => ({ type: DOMINIO_UPDATE, payload: dominio });
export const dominioUpdateSuccess  = (dominio, dominioStatus) => ({ type: DOMINIO_UPDATE_SUCCESS, dominioStatus: dominioStatus, payload: dominio });
export const dominioUpdateFailed   = (error) => ({ type: DOMINIO_UPDATE_FAILED, payload: error });

// remove
export const dominioRemove         = (dominio) => ({ type: DOMINIO_REMOVE, payload: dominio });
export const dominioRemoveSuccess  = (dominio, dominioDeletado) => ({ type: DOMINIO_REMOVE_SUCCESS, dominioDeletado: dominioDeletado, payload: dominio });
export const dominioRemoveFailed   = (error) => ({ type: DOMINIO_REMOVE_FAILED, payload: error });