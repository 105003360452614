import {
    CENTRO_CUSTO_INTERNO_LIST,
    CENTRO_CUSTO_INTERNO_LIST_SUCCESS,
    CENTRO_CUSTO_INTERNO_LIST_FAILED,
    CENTRO_CUSTO_INTERNO_ADD,
    CENTRO_CUSTO_INTERNO_ADD_SUCCESS,
    CENTRO_CUSTO_INTERNO_ADD_FAILED,
    CENTRO_CUSTO_INTERNO_UPDATE,
    CENTRO_CUSTO_INTERNO_UPDATE_SUCCESS,
    CENTRO_CUSTO_INTERNO_UPDATE_FAILED,
    CENTRO_CUSTO_INTERNO_LOAD,
    CENTRO_CUSTO_INTERNO_LOAD_SUCCESS,
    CENTRO_CUSTO_INTERNO_LOAD_FAILED,
    CENTRO_CUSTO_INTERNO_REMOVE,
    CENTRO_CUSTO_INTERNO_REMOVE_SUCCESS,
    CENTRO_CUSTO_INTERNO_REMOVE_FAILED,
} from './constants';

// List
export const centroCustoInternoList = (filter) => ({ type: CENTRO_CUSTO_INTERNO_LIST, payload: filter });
export const centroCustoInternoListSuccess = (centroCustosInternos) => ({
    type: CENTRO_CUSTO_INTERNO_LIST_SUCCESS,
    payload: centroCustosInternos,
});
export const centroCustoInternoListFailed = (error) => ({ type: CENTRO_CUSTO_INTERNO_LIST_FAILED, payload: error });

export const centroCustoInternoLoad = (id) => ({ type: CENTRO_CUSTO_INTERNO_LOAD, payload: { id } });
export const centroCustoInternoLoadSuccess = (centroCustoInterno) => ({
    type: CENTRO_CUSTO_INTERNO_LOAD_SUCCESS,
    payload: { centroCustoInterno },
});
export const centroCustoInternoLoadFailed = (error) => ({ type: CENTRO_CUSTO_INTERNO_LOAD_FAILED, payload: error });

// Add
export const centroCustoInternoAdd = (centroCustoInterno) => ({
    type: CENTRO_CUSTO_INTERNO_ADD,
    payload: { centroCustoInterno },
});
export const centroCustoInternoAddSuccess = (centroCustoInterno, centroCustoInternoStatus) => ({
    type: CENTRO_CUSTO_INTERNO_ADD_SUCCESS,
    centroCustoStatus: centroCustoInternoStatus,
    payload: { centroCustoInterno },
});
export const centroCustoInternoAddFailed = (error) => ({ type: CENTRO_CUSTO_INTERNO_ADD_FAILED, payload: error });

// Update
export const centroCustoInternoUpdate = (centroCustoInterno) => ({
    type: CENTRO_CUSTO_INTERNO_UPDATE,
    payload: { centroCustoInterno },
});
export const centroCustoInternoUpdateSuccess = (centroCustoInterno, centroCustoInternoStatus) => ({
    type: CENTRO_CUSTO_INTERNO_UPDATE_SUCCESS,
    centroCustoStatus: centroCustoInternoStatus,
    payload: { centroCustoInterno },
});
export const centroCustoInternoUpdateFailed = (error) => ({ type: CENTRO_CUSTO_INTERNO_UPDATE_FAILED, payload: error });

// remove
export const centroCustoInternoRemove = (centroCustoInternoId) => ({
    type: CENTRO_CUSTO_INTERNO_REMOVE,
    payload: centroCustoInternoId,
});
export const centroCustoInternoRemoveSuccess = (centroCustoInterno, centroCustoInternoDeletado) => ({
    type: CENTRO_CUSTO_INTERNO_REMOVE_SUCCESS,
    centroCustoInternoDeletado: centroCustoInternoDeletado,
    payload: centroCustoInterno,
});
export const centroCustoInternoRemoveFailed = (error) => ({ type: CENTRO_CUSTO_INTERNO_REMOVE_FAILED, payload: error });
