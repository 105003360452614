import { 
    MONITORAMENTO_LIST,
    MONITORAMENTO_LIST_SUCCESS,
    MONITORAMENTO_LIST_FAILED,
    MONITORAMENTO_TOTALIZADORES,
    MONITORAMENTO_TOTALIZADORES_SUCCESS,
    MONITORAMENTO_TOTALIZADORES_FAILED
} from './constants';

// List
export const monitoramentoList        = () => ({ type: MONITORAMENTO_LIST });
export const monitoramentoListSuccess = (success) => ({ type: MONITORAMENTO_LIST_SUCCESS, payload: success });
export const monitoramentoListFailed  = (error) => ({ type: MONITORAMENTO_LIST_FAILED, payload: error });

export const monitoramentoTotalizadores         = () => ({ type: MONITORAMENTO_TOTALIZADORES });
export const monitoramentoTotalizadoresSuccess  = (success) => ({ type: MONITORAMENTO_TOTALIZADORES_SUCCESS, payload: success });
export const monitoramentoTotalizadoresFailed   = (error) => ({ type: MONITORAMENTO_TOTALIZADORES_FAILED, payload: error });