const dashMenu = [
    {
        header: 'Home',
        path: '/dashboard/geral',
        name: 'Dashboard',
        icon: 'mdi mdi-monitor-dashboard',
    },
   
]

export const DashboardMenu = () => dashMenu;
