/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Link } from 'react-router-dom';
// import Link from "next/link";
// import Image from "next/image";
import {
    Navbar_Container,
    Navbar_SectionBrand,
    Navbar_SectionMenu,
    Navbar_SectionUser,
    Navbar_Items,
    Navbar_IconMenu,
    Navbar_IconUser,
    Navbar_Item,
    Divider,
    Navbar_MenuItem,
    Navbar_ItemDisable,
    Navbar_MenuUser,
    Navbar_MenuFAQ,
    Navbar_MenuNotification,
} from './style';

// -> Pages <---------------------------------------------------------------------
import ControlPanel from './section-menus/control-panel';
import PassegerPanel from './section-menus/passeger-panel';
import ContractsPanel from './section-menus/contracts-panel';
import WorkOrderPanel from './section-menus/work-order-panel';

import UserProfile from './section-user/user-profile';
import UserFAQ from './section-user/user-FAQ';

// import Gravatar from "react-gravatar";
// import { useSession } from "next-auth/react";
import FinancialPanel from './section-menus/financial-panel';
import DriversPanel from './section-menus/drivers-panel';
import GlobalRegister from './section-menus/global-registers';
import ProfileDropdown from '../ProfileDropdown';
import profilePic from '../../assets/images/users/avatar-1.jpg';
import { getLoggedInUser } from '../../helpers/authUtils';
import ItemWithIcon from './_menus-items/item-with-icon';
import {
    ItemWithIcon_Container,
    ItemWithIcon_ItemBox,
    ItemWithIcon_ItemIcon,
    ItemWithIcon_ItemLabel,
} from './_menus-items/style';

import { authProtectedRoutes as routes } from '../../routes';
import { allRoutesPath } from '../../helpers/util';

// -> Icon <---------------------------------------------------------------------

const IconUser = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
            </g>
        </svg>
    );
};

const IconControlPanel = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20">
            <path d="M0.596191 11.0833H9.38667V0.25H0.596191V11.0833ZM0.596191 19.75H9.38667V13.25H0.596191V19.75ZM11.5843 19.75H20.3748V8.91667H11.5843V19.75ZM11.5843 0.25V6.75H20.3748V0.25H11.5843Z" />
        </svg>
    );
};

const IconPassegerPanel = () => {
    return (
        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_2116)">
                <path d="M17.5696 16.4693C17.7819 16.6011 17.9889 16.7422 18.1899 16.8925L23.5 13.4999L23 12.9999L17.5696 16.4693Z" />
                <path d="M7.32612 23.8333H6.04351L5 24.4999L5.5 24.9999L7.32612 23.8333Z" />
                <path d="M4.33301 23.8333L5 24.4999L6.04351 23.8333H4.33301Z" />
                <path d="M23 12.9999L22.3332 12.3331L16.642 15.9691C16.9606 16.1167 17.2704 16.2837 17.5696 16.4693L23 12.9999Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.33301 23.8333C4.33301 23.8331 4.33301 23.8334 4.33301 23.8333H6.04351H7.32612H21.6663C21.6663 21.5347 20.7532 19.3303 19.1279 17.705C18.8333 17.4104 18.5197 17.1391 18.1899 16.8925C17.9889 16.7422 17.7819 16.6011 17.5696 16.4693C17.2704 16.2837 16.9606 16.1167 16.642 15.9691L4.33301 23.8333ZM4.45122 22.4065L15.4765 15.528C14.6798 15.2904 13.8461 15.1666 12.9997 15.1666C10.7011 15.1666 8.49673 16.0797 6.87142 17.705C5.58636 18.9901 4.74653 20.6371 4.45122 22.4065ZM6.49967 7.58325C6.49967 11.1745 9.40842 14.0833 12.9997 14.0833C16.5909 14.0833 19.4997 11.1745 19.4997 7.58325C19.4997 3.992 16.5909 1.08325 12.9997 1.08325C9.40842 1.08325 6.49967 3.992 6.49967 7.58325Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_13_2116">
                    <rect width="26" height="25.8834" />
                </clipPath>
            </defs>
        </svg>
    );
};

const IconContracts = () => {
    return (
        <svg viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_2691)">
                <path d="M21.6667 24.8334H4.33333C4.04602 24.8334 3.77047 24.7192 3.5673 24.5161C3.36414 24.3129 3.25 24.0373 3.25 23.75V4.25002C3.25 3.9627 3.36414 3.68715 3.5673 3.48399C3.77047 3.28082 4.04602 3.16669 4.33333 3.16669H21.6667C21.954 3.16669 22.2295 3.28082 22.4327 3.48399C22.6359 3.68715 22.75 3.9627 22.75 4.25002V23.75C22.75 24.0373 22.6359 24.3129 22.4327 24.5161C22.2295 24.7192 21.954 24.8334 21.6667 24.8334ZM7.58333 7.50002V11.8334H11.9167V7.50002H7.58333ZM7.58333 14V16.1667H18.4167V14H7.58333ZM7.58333 18.3334V20.5H18.4167V18.3334H7.58333ZM14.0833 8.58335V10.75H18.4167V8.58335H14.0833Z" />
            </g>
            <defs>
                <clipPath id="clip0_13_2691">
                    <rect width="26" height="26" transform="translate(0 0.883423)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const IconWorkOrderService = () => {
    return (
        <svg viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_3286)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 9.66675V5.33341H4.32575C3.73208 5.33341 3.25 5.8155 3.25 6.40916V23.7577C3.25 24.3513 3.73208 24.8334 4.32575 24.8334H21.6742C22.2679 24.8334 22.75 24.3513 22.75 23.7577V17.5122L17.5944 22.6678L12.9946 22.6613L13.0011 18.0702L22.75 8.32016V6.40916C22.75 5.8155 22.2679 5.33341 21.6742 5.33341H19.5V9.66675H6.5ZM22.75 11.3849L15.1667 18.9682L15.1645 20.4979L16.6985 20.5001L22.75 14.4486V11.3849ZM7.58333 21.5834V19.4167H9.75V21.5834H7.58333ZM7.58333 18.3334V16.1667H9.75V18.3334H7.58333ZM7.58333 15.0834V12.9167H9.75V15.0834H7.58333ZM8.66667 7.50008H17.3333V3.16675H8.66667V7.50008Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.66667 7.50008H17.3333V3.16675H8.66667V7.50008ZM3.25 23.7501V6.40916C3.25 5.8155 3.73208 5.33341 4.32575 5.33341H6.5V9.66675H7.58333H16.25H19.5V5.33341H21.6742C22.2679 5.33341 22.75 5.8155 22.75 6.40916V8.32016L13.0011 18.0702L12.9946 22.6613L17.5944 22.6678L22.75 17.5122V23.7501C22.75 24.3481 22.2647 24.8334 21.6667 24.8334H4.33333C3.73533 24.8334 3.25 24.3481 3.25 23.7501ZM23.5928 10.5421L25.1247 12.0739L22.75 14.4486L16.6985 20.5001L15.1645 20.4979L15.1667 18.9682L22.75 11.3849L23.5928 10.5421ZM7.58333 15.0834V12.9167H9.75V15.0834H7.58333ZM7.58333 16.1667V18.3334H9.75V16.1667H7.58333ZM7.58333 21.5834V19.4167H9.75V21.5834H7.58333Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_13_3286">
                    <rect width="26" height="26" transform="translate(0 0.883423)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const IconExpenses = () => {
    return (
        <svg viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_3106)">
                <path d="M18.4167 18.3334H20.5833V5.33335H9.75V7.50002H18.4167V18.3334ZM18.4167 20.5V23.75C18.4167 24.348 17.9292 24.8334 17.3258 24.8334H4.34092C4.19805 24.8342 4.05643 24.8068 3.92418 24.7528C3.79193 24.6987 3.67167 24.6191 3.5703 24.5184C3.46892 24.4178 3.38844 24.298 3.33348 24.1662C3.27851 24.0343 3.25014 23.8929 3.25 23.75L3.25325 8.58335C3.25325 7.98535 3.74075 7.50002 4.34417 7.50002H7.58333V4.25002C7.58333 3.9627 7.69747 3.68715 7.90063 3.48399C8.1038 3.28082 8.37935 3.16669 8.66667 3.16669H21.6667C21.954 3.16669 22.2295 3.28082 22.4327 3.48399C22.6359 3.68715 22.75 3.9627 22.75 4.25002V19.4167C22.75 19.704 22.6359 19.9796 22.4327 20.1827C22.2295 20.3859 21.954 20.5 21.6667 20.5H18.4167ZM7.58333 18.3334V20.5H9.75V21.5834H11.9167V20.5H12.4583C13.1766 20.5 13.8655 20.2147 14.3734 19.7068C14.8813 19.1989 15.1667 18.51 15.1667 17.7917C15.1667 17.0734 14.8813 16.3845 14.3734 15.8766C13.8655 15.3687 13.1766 15.0834 12.4583 15.0834H9.20833C9.06467 15.0834 8.9269 15.0263 8.82532 14.9247C8.72373 14.8231 8.66667 14.6853 8.66667 14.5417C8.66667 14.398 8.72373 14.2603 8.82532 14.1587C8.9269 14.0571 9.06467 14 9.20833 14H14.0833V11.8334H11.9167V10.75H9.75V11.8334H9.20833C8.49004 11.8334 7.80116 12.1187 7.29325 12.6266C6.78534 13.1345 6.5 13.8234 6.5 14.5417C6.5 15.26 6.78534 15.9489 7.29325 16.4568C7.80116 16.9647 8.49004 17.25 9.20833 17.25H12.4583C12.602 17.25 12.7398 17.3071 12.8414 17.4087C12.9429 17.5103 13 17.648 13 17.7917C13 17.9353 12.9429 18.0731 12.8414 18.1747C12.7398 18.2763 12.602 18.3334 12.4583 18.3334H7.58333Z" />
            </g>
            <defs>
                <clipPath id="clip0_13_3106">
                    <rect width="26" height="26" transform="translate(0 0.883423)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const IconGlobalRegister = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z" />
            </g>
        </svg>
    );
};

export const IconPanelDriver = () => {
    return (
        <svg width="26" height="27" viewBox="0 0 26 27" fillRule="evenodd" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_2295)">
                <path
                    d="M13.0003 3.1665C18.9836 3.1665 23.8337 8.01659 23.8337 13.9998C23.8337 19.9831 18.9836 24.8332 13.0003 24.8332C7.01708 24.8332 2.16699 19.9831 2.16699 13.9998C2.16699 8.01659 7.01708 3.1665 13.0003 3.1665ZM8.66699 15.0832L4.40083 15.0843C4.64218 16.9933 5.51171 18.7679 6.87247 20.1284C8.23322 21.489 10.0079 22.3583 11.917 22.5993V18.3332C11.055 18.3332 10.2284 17.9908 9.6189 17.3813C9.0094 16.7718 8.66699 15.9451 8.66699 15.0832ZM21.5998 15.0843L17.3337 15.0832C17.3337 15.9451 16.9913 16.7718 16.3818 17.3813C15.7723 17.9908 14.9456 18.3332 14.0837 18.3332L14.0847 22.5993C15.9936 22.358 17.7681 21.4887 19.1286 20.1281C20.4892 18.7676 21.3585 16.9932 21.5998 15.0843V15.0843ZM13.0003 5.33317C10.8893 5.33321 8.85091 6.10362 7.2676 7.49984C5.68429 8.89606 4.66493 10.8221 4.40083 12.9165H8.66699C8.66699 12.6292 8.78113 12.3536 8.98429 12.1505C9.18746 11.9473 9.46301 11.8332 9.75033 11.8332H16.2503C16.5376 11.8332 16.8132 11.9473 17.0164 12.1505C17.2195 12.3536 17.3337 12.6292 17.3337 12.9165H21.5998C21.3357 10.8221 20.3164 8.89606 18.7331 7.49984C17.1497 6.10362 15.1113 5.33321 13.0003 5.33317V5.33317Z"
                    fillRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="clip0_13_2295">
                    <rect width="26" height="26" fillRule="evenodd" transform="translate(0 0.883301)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const IconFAQ = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 14v2h2v-2h-2zM8.567 8.813l1.962.393A1.5 1.5 0 1 1 12 11h-1v2h1a3.5 3.5 0 1 0-3.433-4.187z" />
            </g>
        </svg>
    );
};

export const IconNotification = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M22 20H2v-2h1v-6.969C3 6.043 7.03 2 12 2s9 4.043 9 9.031V18h1v2zM9.5 21h5a2.5 2.5 0 1 1-5 0z" />
            </g>
        </svg>
    );
};

export const IconSearch = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </g>
        </svg>
    );
};

const LogoAutonomoz = () => {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27" cy="27" r="27" fill="white" />
            <path
                d="M19.9315 15.907C19.303 15.907 18.7936 16.4166 18.7936 17.0452V34.3191C18.7936 35.2603 18.0272 36.0264 17.0859 36.0264C16.1447 36.0264 15.3792 35.2603 15.3792 34.3191V26.3629C15.3792 25.7344 14.8695 25.2247 14.2406 25.2247C13.6123 25.2247 13.1027 25.7344 13.1027 26.3629V34.3191C13.1027 36.5151 14.8902 38.3023 17.0859 38.3023C19.2823 38.3023 21.0692 36.5151 21.0692 34.3191V17.0452C21.0692 16.4166 20.5598 15.907 19.9315 15.907Z"
                fill="#2C88D9"
            />
            <path
                d="M39.8699 24.3373C39.2413 24.3373 38.732 24.847 38.732 25.4755V34.3191C38.732 35.2603 37.9655 36.0264 37.0243 36.0264C36.0831 36.0264 35.3175 35.2603 35.3175 34.3191V21.2604C35.3175 20.6318 34.8079 20.1221 34.179 20.1221C33.5507 20.1221 33.0411 20.6318 33.0411 21.2604V34.3191C33.0411 36.5151 34.8286 38.3023 37.0243 38.3023C39.2206 38.3023 41.0076 36.5151 41.0076 34.3191V25.4755C41.0076 24.847 40.4982 24.3373 39.8699 24.3373Z"
                fill="#2C88D9"
            />
            <path
                d="M23.5808 17.0162C23.5808 16.4036 24.0774 15.907 24.6901 15.907C25.3027 15.907 25.7993 16.4036 25.7993 17.0162V34.5424C25.7993 35.155 25.3027 35.6517 24.6901 35.6517C24.0774 35.6517 23.5808 35.155 23.5808 34.5424V17.0162Z"
                fill="#2C88D9"
            />
            <path
                d="M8.37256 26.334C8.37256 25.7213 8.86919 25.2247 9.48181 25.2247C10.0944 25.2247 10.5911 25.7213 10.5911 26.334V34.5424C10.5911 35.155 10.0944 35.6517 9.48181 35.6517C8.86919 35.6517 8.37256 35.155 8.37256 34.5424V26.334Z"
                fill="#2C88D9"
            />
            <path
                d="M43.5192 26.1121C43.5192 25.4995 44.0158 25.0028 44.6284 25.0028C45.2411 25.0028 45.7377 25.4995 45.7377 26.1121V34.5424C45.7377 35.155 45.2411 35.6517 44.6284 35.6517C44.0158 35.6517 43.5192 35.155 43.5192 34.5424V26.1121Z"
                fill="#2C88D9"
            />
            <path
                d="M28.3109 17.0162C28.3109 16.4036 28.8076 15.907 29.4202 15.907C30.0328 15.907 30.5294 16.4036 30.5294 17.0162V34.5424C30.5294 35.155 30.0328 35.6517 29.4202 35.6517C28.8076 35.6517 28.3109 35.155 28.3109 34.5424V17.0162Z"
                fill="#2C88D9"
            />
        </svg>
    );
};

const ItemNotification = (props) => {
    return (
        <Link to={props.link} style={{ textDecoration: 'none' }}>
            <Navbar_ItemDisable style={{ cursor: 'pointer' }}>{props.children}</Navbar_ItemDisable>
        </Link>
    );
};

const Navbar = () => {
    const Disable = true;
    // const { data: session } = useSession();

    const loggedInUser = getLoggedInUser();

    const permissions = loggedInUser?.usuario?.tipoUsuario;

    const paths = allRoutesPath();

    return (
        <Navbar_Container>
            <Navbar_SectionBrand>
                <Link to="/" style={{ fontSize: '0', width: '50', height: '50' }}>
                    <LogoAutonomoz />
                </Link>
            </Navbar_SectionBrand>
            <Navbar_SectionMenu>
                <Navbar_Items>
                    {(permissions.includes('Master') ||
                        permissions.includes('Administrador') ||
                        permissions.includes('GestorCentral')) && (
                            <>
                                <Navbar_Item>
                                    <Navbar_IconMenu>
                                        <IconControlPanel />
                                    </Navbar_IconMenu>
                                    <Navbar_MenuItem>
                                        <ControlPanel />
                                    </Navbar_MenuItem>
                                </Navbar_Item>
                            </>
                        )}

                    {(permissions.includes('Master') ||
                        permissions.includes('GestorCliente') ||
                        permissions.includes('OperadorCliente') ||
                        permissions.includes('OperadorCentral') ||
                        permissions.includes('Aprovador') ||
                        permissions.includes('GestorCentral')) && (
                            <Navbar_Item>
                                <Navbar_IconMenu>
                                    <IconPassegerPanel />
                                </Navbar_IconMenu>
                                <Navbar_MenuItem>
                                    <PassegerPanel />
                                </Navbar_MenuItem>
                            </Navbar_Item>
                        )}
                    {(permissions.includes('Master') || permissions.includes('Aprovador')) && (
                        <>
                            <Navbar_Item>
                                <Navbar_IconMenu>
                                    <IconPanelDriver />
                                </Navbar_IconMenu>
                                <Navbar_MenuItem>
                                    <DriversPanel />
                                </Navbar_MenuItem>
                            </Navbar_Item>
                        </>
                    )}

                    {(permissions.includes('Master') ||
                        permissions.includes('GestorCliente') ||
                        permissions.includes('GestorCentral') ||
                        permissions.includes('Administrador') ||
                        permissions.includes('Faturista') ||
                        permissions.includes('Auditor')) && (
                            <>
                                <Navbar_Item>
                                    <Navbar_IconMenu>
                                        <IconContracts />
                                    </Navbar_IconMenu>
                                    <Navbar_MenuItem>
                                        <ContractsPanel />
                                    </Navbar_MenuItem>
                                </Navbar_Item>
                            </>
                        )}
                    {(permissions.includes('Master') ||
                        permissions.includes('Administrador') ||
                        permissions.includes('OperadorCentral') ||
                        permissions.includes('OperadorCliente') ||
                        permissions.includes('GestorCentral') ||
                        permissions.includes('GestorCliente') ||
                        permissions.includes('Auditor') ||
                        permissions.includes('Financeiro')) && (
                            <>
                                <Navbar_Item>
                                    <Navbar_IconMenu>
                                        <IconWorkOrderService />
                                    </Navbar_IconMenu>
                                    <Navbar_MenuItem>
                                        <WorkOrderPanel />
                                    </Navbar_MenuItem>
                                </Navbar_Item>
                            </>
                        )}
                    {(permissions.includes('Master') ||
                        permissions.includes('GestorCentral') ||
                        permissions.includes('Financeiro')) && (
                            <>
                                <Navbar_Item>
                                    <Navbar_IconMenu>
                                        <IconExpenses />
                                    </Navbar_IconMenu>
                                    <Navbar_MenuItem>
                                        <FinancialPanel />
                                    </Navbar_MenuItem>
                                </Navbar_Item>
                            </>
                        )}
                    {(permissions.includes('Master') || permissions.includes('Administrador')) && (
                        <>
                            <Navbar_Item>
                                <Navbar_IconMenu>
                                    <IconGlobalRegister />
                                </Navbar_IconMenu>
                                <Navbar_MenuItem>
                                    <GlobalRegister />
                                </Navbar_MenuItem>
                            </Navbar_Item>
                        </>
                    )}
                </Navbar_Items>
            </Navbar_SectionMenu>

            <Navbar_SectionUser>
                <Divider />

                <Navbar_Items>
                    <Navbar_ItemDisable>
                        <Navbar_IconUser>
                            <IconSearch />
                        </Navbar_IconUser>
                        {/* <Navbar_MenuSearch>
              <UserSearch />
            </Navbar_MenuSearch> */}
                    </Navbar_ItemDisable>

                    <Navbar_Item>
                        <Navbar_IconUser>
                            {getLoggedInUser()?.usuario?.nomeUsuario && (
                                <img
                                    src={profilePic}
                                    style={{ height: '35px', width: '35px', borderRadius: '50px' }}
                                    alt="icone de perfil"
                                />
                            )}

                            {!getLoggedInUser()?.usuario?.nomeUsuario && <IconUser />}
                        </Navbar_IconUser>
                        <Navbar_MenuUser>
                            <UserProfile username="Username" email="username@dominio.com" />
                        </Navbar_MenuUser>
                    </Navbar_Item>

                    <ItemNotification link="/notificacao">
                        <Navbar_IconUser>
                            <IconNotification />
                        </Navbar_IconUser>
                    </ItemNotification>

                    {/* <Navbar_ItemDisable> */}
                    <Navbar_Item>
                        <Navbar_IconUser>
                            <IconFAQ />
                        </Navbar_IconUser>
                        <Navbar_MenuFAQ>
                            <UserFAQ />
                        </Navbar_MenuFAQ>
                    </Navbar_Item>
                    {/* </Navbar_ItemDisable> */}
                </Navbar_Items>
            </Navbar_SectionUser>
        </Navbar_Container>
    );
};

export default Navbar;
