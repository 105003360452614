import {

    FATURAMENTO_CALCULAR,
    FATURAMENTO_CALCULAR_SUCCESS,
    FATURAMENTO_CALCULAR_FAILED,

    FATURA_LIST,
    FATURA_LIST_SUCCESS,
    FATURA_LIST_FAILED,
    FATURA_CLIENTE_LIST,
    FATURA_CLIENTE_LIST_SUCCESS,
    FATURA_CLIENTE_LIST_FAILED,
    FATURA_LOAD,
    FATURA_LOAD_SUCCESS,
    FATURA_LOAD_FAILED,
    ITEM_FATURA_LIST,
    ITEM_FATURA_LIST_SUCCESS,
    ITEM_FATURA_LIST_FAILED,
    FATURA_UPDATE_SUCCESS,
    FATURA_UPDATE,
    FATURA_UPDATE_FAILED,
    ITEM_FATURA_NEW,
    ITEM_FATURA_LIST_ALL,
    ITEM_FATURA_LIST_ALL_SUCCESS,
    ITEM_FATURA_LIST_ALL_FAILED,
    ITEM_FATURA_ADD,
    ITEM_FATURA_ADD_SUCCESS,
    ITEM_FATURA_ADD_FAILED,
    ITEM_FATURA_CANCEL,
    ITEM_FATURA_LOAD,
    ITEM_FATURA_LOAD_SUCCESS,
    ITEM_FATURA_LOAD_FAILED,
    ITEM_FATURA_UPDATE,
    ITEM_FATURA_UPDATE_SUCCESS,
    ITEM_FATURA_UPDATE_FAILED,
    ITEM_FATURA_REMOVE,
    ITEM_FATURA_REMOVE_SUCCESS,
    ITEM_FATURA_REMOVE_FAILED,
    ITEM_FATURA_REMOVE_START,
    FATURA_REMOVE,
    FATURA_REMOVE_FAILED,
    FATURA_REMOVE_SUCCESS,
    FATURA_PUBLICAR,
    FATURA_PUBLICAR_SUCCESS,
    FATURA_PUBLICAR_FAILED,
    FATURA_LIST_CLIENTE,
    FATURA_LIST_CLIENTE_SUCCESS,
    FATURA_LIST_CLIENTE_FAILED,
    FATURA_APROVAR,
    FATURA_APROVAR_SUCCESS,
    FATURA_APROVAR_FAILED,
    FATURA_REJEITAR,
    FATURA_REJEITAR_SUCCESS,
    FATURA_REJEITAR_FAILED,
    FATURAMENTO_CALCULAR_FIXO,
    FATURAMENTO_CALCULAR_FIXO_SUCCESS,
    FATURAMENTO_CALCULAR_FIXO_FAILED,

    AGRUPAMENTO_LIST,
    AGRUPAMENTO_LIST_SUCCESS,
    AGRUPAMENTO_LIST_FAILED,
    
    SHOW_DETALHE_MODAL,
    CORRIDAS_FATURADAS_LIST,
    CORRIDAS_FATURADAS_LIST_SUCCESS,
    DESPESAS_FATURADAS_LIST,
    DESPESAS_FATURADAS_LIST_SUCCESS,
    CORRIDAS_FATURADAS_LIST_FAILED,
    DESPESAS_FATURADAS_LIST_FAILED,

    AGRUPAMENTO_REMOVE,
    AGRUPAMENTO_REMOVE_SUCCESS,
    AGRUPAMENTO_REMOVE_FAILED,
    AGRUPAMENTO_LOAD,
    AGRUPAMENTO_LOAD_SUCCESS,
    AGRUPAMENTO_LOAD_FAILED,
    
    AGRUPAMENTO_CLIENTE_LOAD,
    AGRUPAMENTO_CLIENTE_LOAD_SUCCESS,
    AGRUPAMENTO_CLIENTE_LOAD_FAILED,
    
    RECALCULAR,
    RECALCULAR_SUCCESS,
    RECALCULAR_FAILED,
    DASH_FATURAMENTO,
    DASH_FATURAMENTO_SUCCESS,
    DASH_FATURAMENTO_FAILED,
    DASH_CLIENTE_FATURAMENTO,
    DASH_CLIENTE_FATURAMENTO_SUCCESS,
    DASH_CLIENTE_FATURAMENTO_FAILED,
} from './constants.js'

export const faturamentoCalcular = (parametros) => ({ type: FATURAMENTO_CALCULAR, payload: parametros });
export const faturamentoCalcularSuccess = (result) => ({ type: FATURAMENTO_CALCULAR_SUCCESS, payload: result });
export const faturamentoCalcularFailed = (error) => ({ type: FATURAMENTO_CALCULAR_FAILED, payload: error });

export const faturamentoCalcularFixo = (parametros) => ({ type: FATURAMENTO_CALCULAR_FIXO, payload: parametros });
export const faturamentoCalcularFixoSuccess = (result) => ({ type: FATURAMENTO_CALCULAR_FIXO_SUCCESS, payload: result });
export const faturamentoCalcularFixoFailed = (error) => ({ type: FATURAMENTO_CALCULAR_FIXO_FAILED, payload: error });

// List
export const faturaList        = (filter) => ({ type: FATURA_LIST, payload: filter });
export const faturaListSuccess = (operacoes) => ({ type: FATURA_LIST_SUCCESS, payload: operacoes });
export const faturaListFailed  = (error) => ({ type: FATURA_LIST_FAILED, payload: error  });

// List Cliente
export const faturaClienteList = (filter) => ({ type: FATURA_CLIENTE_LIST, payload: filter });
export const faturaClienteListSuccess = (operacoes) => ({ type: FATURA_CLIENTE_LIST_SUCCESS, payload: operacoes });
export const faturaClienteListFailed = (error) => ({ type: FATURA_CLIENTE_LIST_FAILED, payload: error });

export const faturaLoad        = (id) => ({ type: FATURA_LOAD, payload:  id  });
export const faturaLoadSuccess  = (fatura) => ( { type: FATURA_LOAD_SUCCESS, payload: fatura  });
export const faturaLoadFailed   = (error) => ({ type: FATURA_LOAD_FAILED, payload: error  });

export const faturaUpdate = (fatura) => ({ type: FATURA_UPDATE, payload: fatura })
export const faturaUpdateSuccess = (fatura) => ({ type: FATURA_UPDATE_SUCCESS, payload: fatura })
export const faturaUpdateFailed = (error) => ({ type: FATURA_UPDATE_FAILED, payload: error });

export const itemFaturaList = (faturamentoId) => ({ type:ITEM_FATURA_LIST, payload: faturamentoId });
export const itemFaturaListSuccess = (itensFatura) => ({ type: ITEM_FATURA_LIST_SUCCESS, payload: itensFatura });
export const itemFaturaListFailed = (error) => ({ type: ITEM_FATURA_LIST_FAILED, payload: error })

export const itemFaturaNew = () => ({ type: ITEM_FATURA_NEW });

export const itemFaturaListAll = () => ({ type: ITEM_FATURA_LIST_ALL })
export const itemFaturaListAllSuccess = (itens) => ({ type: ITEM_FATURA_LIST_ALL_SUCCESS, payload: itens })
export const itemFaturaListAllFailed = (error) => ({ type: ITEM_FATURA_LIST_ALL_FAILED, payload: error })

export const itemFaturaAdd = (item) => ({ type: ITEM_FATURA_ADD, payload: item });
export const itemFaturaAddSuccess = () => ({ type: ITEM_FATURA_ADD_SUCCESS });
export const itemFaturaAddFailed = (error) => ({ type: ITEM_FATURA_ADD_FAILED, payload: error });

export const itemFaturaCancel = () => ({ type: ITEM_FATURA_CANCEL })

export const itemFaturaLoad = (itemFaturaId) => ({ type: ITEM_FATURA_LOAD, payload: itemFaturaId })
export const itemFaturaLoadSuccess = (item) => ({ type: ITEM_FATURA_LOAD_SUCCESS, payload: item })
export const itemFaturaLoadFailed = (error) => ({ type: ITEM_FATURA_LOAD_FAILED, payload: error })

export const itemFaturaUpdate = (item) => ({ type: ITEM_FATURA_UPDATE, payload: item });
export const itemFaturaUpdateSuccess = () => ({ type: ITEM_FATURA_UPDATE_SUCCESS });
export const itemFaturaUpdateFailed = (error) => ({ type: ITEM_FATURA_UPDATE_FAILED, payload: error })

export const itemFaturaRemoveStart = (itemFaturaId) => ({ type: ITEM_FATURA_REMOVE_START, payload: itemFaturaId })
export const itemFaturaRemove = (itemFaturaId) => ({ type: ITEM_FATURA_REMOVE, payload: itemFaturaId })
export const itemFaturaRemoveSuccess = () => ({ type: ITEM_FATURA_REMOVE_SUCCESS });
export const itemFaturaRemoveFailed = () => ({ type: ITEM_FATURA_REMOVE_FAILED })

export const faturaRemove = (faturaId) => ({ type: FATURA_REMOVE, payload: faturaId });
export const faturaRemoveSuccess = () => ({ type: FATURA_REMOVE_SUCCESS });
export const FaturaRemoveFailed = (error) => ({ type: FATURA_REMOVE_FAILED, payload: error });

export const publicarFatura = (faturaId) => ({ type: FATURA_PUBLICAR, payload: faturaId });
export const publicarFaturaSuccess = (fatura) => ({ type: FATURA_PUBLICAR_SUCCESS, payload: fatura });
export const publicarFaturaFailed = (error) => ({ type: FATURA_PUBLICAR_FAILED, payload: error });

export const aprovarFatura = (faturaId) => ({ type: FATURA_APROVAR, payload: faturaId });
export const aprovarFaturaSuccess = (fatura) => ({ type: FATURA_APROVAR_SUCCESS, payload: fatura });
export const aprovarFaturaFailed = (error) => ({ type: FATURA_APROVAR_FAILED, payload: error });

export const rejeitarFatura = (faturaId, motivo) => ({ type: FATURA_REJEITAR, payload: { faturaId, motivo } });
export const rejeitarFaturaSuccess = (fatura) => ({ type: FATURA_REJEITAR_SUCCESS, payload: fatura });
export const rejeitarFaturaFailed = (error) => ({ type: FATURA_REJEITAR_FAILED, payload: error });

// List
export const faturaListCliente        = (filter) => ({ type: FATURA_LIST_CLIENTE, payload: filter });
export const faturaListClienteSuccess = (faturas) => ({ type: FATURA_LIST_CLIENTE_SUCCESS, payload: faturas });
export const faturaListClienteFailed  = (error) => ({ type: FATURA_LIST_CLIENTE_FAILED, payload: error  });

// Lis,
export const agrupamentoList        = (filter)       => ({ type: AGRUPAMENTO_LIST        , payload: filter });
export const agrupamentoListSuccess = (agrupamentos) => ({ type: AGRUPAMENTO_LIST_SUCCESS, payload: agrupamentos });
export const agrupamentoListFailed  = (error)        => ({ type: AGRUPAMENTO_LIST_FAILED , payload: error  });

// Lis,
export const agrupamentoRemove        = (id)           => ({ type: AGRUPAMENTO_REMOVE        , payload: id });
export const agrupamentoRemoveSuccess = (success)      => ({ type: AGRUPAMENTO_REMOVE_SUCCESS, payload: success });
export const agrupamentoRemoveFailed  = (error)        => ({ type: AGRUPAMENTO_REMOVE_FAILED , payload: error  });

export const showDetalheModal = () => ({ type: SHOW_DETALHE_MODAL });

export const corridasFaturadasList         = (itemFaturamentoId)   => ({ type: CORRIDAS_FATURADAS_LIST,         payload: itemFaturamentoId });
export const corridasFaturadasListSuccess  = (success)             => ({ type: CORRIDAS_FATURADAS_LIST_SUCCESS, payload: success });
export const corridasFaturadasListFailed  = (error)               => ({ type: CORRIDAS_FATURADAS_LIST_FAILED,  payload: error });

export const despesasFaturadasList         = (itemFaturamentoId)   => ({ type: DESPESAS_FATURADAS_LIST,         payload: itemFaturamentoId });
export const despesasFaturadasListSuccess  = (success)             => ({ type: DESPESAS_FATURADAS_LIST_SUCCESS, payload: success });
export const despesasFaturadasListFailed  = (error)               => ({ type: DESPESAS_FATURADAS_LIST_FAILED,  payload: error });


export const agrupamentoLoad        = (id)           => ({ type: AGRUPAMENTO_LOAD        , payload: id });
export const agrupamentoLoadSuccess = (success)      => ({ type: AGRUPAMENTO_LOAD_SUCCESS, payload: success });
export const agrupamentoLoadFailed  = (error)        => ({ type: AGRUPAMENTO_LOAD_FAILED , payload: error  });

export const agrupamentoClienteLoad = (id) => ({ type: AGRUPAMENTO_CLIENTE_LOAD, payload: id });
export const agrupamentoClienteLoadSuccess = (success) => ({ type: AGRUPAMENTO_CLIENTE_LOAD_SUCCESS, payload: success });
export const agrupamentoClienteLoadFailed = (error) => ({ type: AGRUPAMENTO_CLIENTE_LOAD_FAILED, payload: error });

export const recalcular        = (id)           => ({ type: RECALCULAR        , payload: id });
export const recalcularSuccess = (success)      => ({ type: RECALCULAR_SUCCESS, payload: success });
export const recalcularFailed  = (error)        => ({ type: RECALCULAR_FAILED , payload: error  });

export const dashFaturamento        = (id)       => ({ type: DASH_FATURAMENTO            , payload: id });
export const dashFaturamentoSuccess   = (success)  => ({ type: DASH_FATURAMENTO_SUCCESS    , payload: success });
export const dashFaturamentoFailed   = (error)    => ({ type: DASH_FATURAMENTO_FAILED     , payload: error   });

export const dashClienteFaturamento = (id) => ({ type: DASH_CLIENTE_FATURAMENTO, payload: id });
export const dashClienteFaturamentoSuccess = (success) => ({ type: DASH_CLIENTE_FATURAMENTO_SUCCESS, payload: success });
export const dashClienteFaturamentoFailed = (error) => ({ type: DASH_CLIENTE_FATURAMENTO_FAILED, payload: error });