
import { TURNO_LOAD, TURNO_LOAD_SUCCESS, TURNO_LOAD_FAILED, TURNO_LIST, TURNO_LIST_SUCCESS, TURNO_LIST_FAILED, TURNO_ADD, TURNO_ADD_FAILED, TURNO_ADD_SUCCESS, TURNO_REMOVE, TURNO_REMOVE_FAILED, TURNO_REMOVE_SUCCESS, TURNO_UPDATE, TURNO_UPDATE_SUCCESS, TURNO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    turnos: {},
    turno: {},
    loading: false,
    error: null,
    turnoStatus: null,
    id: {}
};

const TurnoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TURNO_LIST:
            return { ...state, turno: {}, loading: true, error: null, turnoDeletado: null };
        case TURNO_LIST_SUCCESS:
            return { ...state, turnos: action.payload, loading: false, error: null, turnoDeletado: null };
        case TURNO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, turnoDeletado: null };
        case TURNO_LOAD:
            return { ...state, turno: {}, loading: true, error: null, turnoStatus: null, turnoDeletado: null }
        case TURNO_LOAD_SUCCESS:
            return { ...state, turno: action.payload, loading: false, error: null, turnoDeletado: null}
        case TURNO_LOAD_FAILED:
            return { ...state, turno: {}, error: action.payload, loading: false, turnoDeletado: null };
        case TURNO_ADD:
            return { ...state, turno: action.payload, turnoStatus: null, loading: true, error: null, turnoDeletado: null}
        case TURNO_ADD_SUCCESS:
            return { ...state, turno: action.payload,  turnoStatus: "Turno adicionado com sucesso", turnoDeletado: null, loading: false, error: null };
        case TURNO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TURNO_UPDATE:
            return { ...state, turno: action.payload, turnoStatus: null, loading: true, error: null}
        case TURNO_UPDATE_SUCCESS:
            return { ...state, turno: action.payload,  turnoStatus: "Turno atualizado com sucesso", turnoDeletado: null, loading: false, error: null };
        case TURNO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, turnoStatus: null };
        case TURNO_REMOVE:
            return { ...state, turno: action.payload, turnoStatus: null, loading: true, error: null, turnoDeletado: null}
        case TURNO_REMOVE_SUCCESS:
            return { ...state, turno: {}, turnoStatus: null,  turnoDeletado: "Turno removido com sucesso", loading: false, error: null };
        case TURNO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, turnoStatus: null, turnoDeletado: null };
        default:
            return { ...state };
    }
};

export default TurnoReducer;