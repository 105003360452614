import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { CONTRATO_LOAD, CONTRATO_LIST, CONTRATO_ADD, CONTRATO_UPDATE, CONTRATO_REMOVE, CALCULAR_DISPONIBILIDADE } from './constants';

import {
    contratoListSuccess,
    contratoListFailed,
    contratoAddSuccess,
    contratoAddFailed,
    contratoUpdateSuccess,
    contratoUpdateFailed,
    contratoLoadSuccess,
    contratoLoadFailed,
    contratoRemoveSuccess,
    contratoRemoveFailed,
    calcularDisponibilidadeSuccess,
    calcularDisponibilidadeFailed,
    calcularDisponibilidade as actionCalcularDisponibilidade
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* contratoLoad({ payload: id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/contrato/' + id, options);
        yield put(contratoLoadSuccess(response));
    } catch (error) {
        yield put(contratoLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* contratoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/contrato/filter', options);
            yield put(contratoListSuccess(response));
        } catch (error) {
            yield put(contratoListFailed(error.message));
        }    
}


/**
 * contrato
 * @param {*} payload - pageNumber and pageSize
 */
function* contratoAdd({ payload: contrato }) {

    const options = {
        body: JSON.stringify(contrato) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/contrato', options);
        yield put(contratoAddSuccess(response));
        yield call(actionCalcularDisponibilidade(response?.id));
    } catch (error) {
        yield put(contratoAddFailed(error.message));
    }    
}

function* calcularDisponibilidade({ payload: contratoId }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/contrato/calculardisponibilidade/${contratoId}`, options);
        yield put(calcularDisponibilidadeSuccess(response));
    } catch (error) {
        //console.log(error);
        yield put(calcularDisponibilidadeFailed(error.message));
    }    
}

/**
 * contrato
 * @param {*} payload - pageNumber and pageSize
 */
function* contratoUpdate({ payload: contrato  }) {

    const options = {
        body: JSON.stringify(contrato) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/contrato/' + contrato?.id, options);
        yield put(contratoUpdateSuccess(response));
        yield put(actionCalcularDisponibilidade(contrato?.id));
    } catch (error) {
        yield put(contratoUpdateFailed(error.message));
    }    
}

/**
 * contrato
 * @param {*} payload - pageNumber and pageSize
 */
function* contratoRemove({ payload: contrato  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/contrato/' + contrato?.id, options);
        yield put(contratoRemoveSuccess({}));
    } catch (error) {
        yield put(contratoRemoveFailed(error.message));
    }    
}

export function* watchLocalLoad(): any{
    yield takeEvery(CONTRATO_LOAD, contratoLoad);
}

export function* watchLocalList(): any {
    yield takeEvery(CONTRATO_LIST, contratoList);
}

export function* watchLocalAdd(): any {
    yield takeEvery(CONTRATO_ADD, contratoAdd);
}

export function* watchLocalUpdate(): any {
    yield takeEvery(CONTRATO_UPDATE, contratoUpdate);
}

export function* watchLocalRemove(): any {
    yield takeEvery(CONTRATO_REMOVE, contratoRemove);
}

export function* watchCalcularDisponibilidade() : any {
    yield takeEvery(CALCULAR_DISPONIBILIDADE, calcularDisponibilidade);
}

function* contrato(): any {
    yield all([fork(watchLocalList), fork(watchLocalAdd), fork(watchLocalUpdate), fork(watchLocalLoad), fork(watchLocalRemove),
    fork(watchCalcularDisponibilidade)]);
}

export default contrato;