/* PARAMETRO */
export const PARAMETRO_LIST           = 'PARAMETRO_LIST';
export const PARAMETRO_LIST_SUCCESS   = 'PARAMETRO_LIST_SUCCESS';
export const PARAMETRO_LIST_FAILED    = 'PARAMETRO_LIST_FAILED';
export const PARAMETRO_ADD            = 'PARAMETRO_ADD';
export const PARAMETRO_ADD_SUCCESS    = 'PARAMETRO_ADD_SUCCESS';
export const PARAMETRO_ADD_FAILED     = 'PARAMETRO_ADD_FAILED';
export const PARAMETRO_UPDATE         = 'PARAMETRO_UPDATE';
export const PARAMETRO_UPDATE_SUCCESS = 'PARAMETRO_UPDATE_SUCCESS';
export const PARAMETRO_UPDATE_FAILED  = 'PARAMETRO_UPDATE_FAILED';
export const PARAMETRO_REMOVE         = 'PARAMETRO_REMOVE';
export const PARAMETRO_REMOVE_SUCCESS = 'PARAMETRO_REMOVE_SUCCESS';
export const PARAMETRO_REMOVE_FAILED  = 'PARAMETRO_REMOVE_FAILED';
export const PARAMETRO_LOAD           = 'PARAMETRO_LOAD'
export const PARAMETRO_LOAD_SUCCESS   = 'PARAMETRO_LOAD_SUCCESS';
export const PARAMETRO_LOAD_FAILED    = 'PARAMETRO_LOAD_FAILED';