/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import { SectionServices_Container, SectionServices_Label, SectionServices_Items } from '../../navbar-menu';

// -> Icon <--------------------------------------------------------------------------------------

const IconHome = () => {
    return (
        <svg viewBox="0 0 48 49" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.3334 37.3333C37.3334 37.7753 37.1578 38.1992 36.8453 38.5118C36.5327 38.8244 36.1088 38.9999 35.6667 38.9999H12.3334C11.8914 38.9999 11.4675 38.8244 11.1549 38.5118C10.8423 38.1992 10.6667 37.7753 10.6667 37.3333V22.3333H5.66675L22.8784 6.68661C23.1853 6.40741 23.5852 6.25269 24.0001 6.25269C24.4149 6.25269 24.8149 6.40741 25.1217 6.68661L42.3334 22.3333H37.3334V37.3333ZM22.3334 25.6666V35.6666H25.6667V25.6666H22.3334Z" />
        </svg>
    );
};

const AlterarEndPassIcon = () => {
    return (
        <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.3334 31.3333C32.3334 31.7753 32.1578 32.1992 31.8453 32.5118C31.5327 32.8244 31.1088 32.9999 30.6667 32.9999H7.33341C6.89139 32.9999 6.46746 32.8244 6.1549 32.5118C5.84234 32.1992 5.66675 31.7753 5.66675 31.3333V16.3333H0.666748L17.8784 0.686615C18.1853 0.407411 18.5852 0.252686 19.0001 0.252686C19.4149 0.252686 19.8149 0.407411 20.1217 0.686615L37.3334 16.3333H32.3334V31.3333ZM17.3334 19.6666V29.6666H20.6667V19.6666H17.3334Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const SectionServices = () => {
    return (
        <SectionServices_Container>
            <SectionServices_Label>Aprovações</SectionServices_Label>
            <SectionServices_Items>
                <ItemWithIcon link="/alterarendpass" label="Alteração de residência" disable={false}>
                    <AlterarEndPassIcon />
                </ItemWithIcon>
            </SectionServices_Items>
        </SectionServices_Container>
    );
};

export default SectionServices;
