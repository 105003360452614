import { 
    TURNO_LIST,
    TURNO_LIST_SUCCESS,
    TURNO_LIST_FAILED,
    TURNO_ADD,
    TURNO_ADD_SUCCESS,
    TURNO_ADD_FAILED,
    TURNO_UPDATE,
    TURNO_UPDATE_SUCCESS,
    TURNO_UPDATE_FAILED,
    TURNO_LOAD,
    TURNO_LOAD_SUCCESS,
    TURNO_LOAD_FAILED,
    TURNO_REMOVE,
    TURNO_REMOVE_SUCCESS,
    TURNO_REMOVE_FAILED
} from './constants';

// List
export const turnoList        = (filter) => ({ type: TURNO_LIST, payload: filter,});
export const turnoListSuccess = (turnos) => ({ type: TURNO_LIST_SUCCESS, payload: turnos });
export const turnoListFailed  = (error) => ({ type: TURNO_LIST_FAILED, payload: error  });

export const turnoLoad        = (id) => ({ type: TURNO_LOAD, payload: id });
export const turnoLoadSuccess  = (turno) => ({ type: TURNO_LOAD_SUCCESS, payload: turno });
export const turnoLoadFailed   = (error) => ({ type: TURNO_LOAD_FAILED, payload: error  });

// Add
export const turnoAdd         = (turno) => ({ type: TURNO_ADD, payload: turno });
export const turnoAddSuccess  = (turno, turnoStatus) => ({ type: TURNO_ADD_SUCCESS, turnoStatus: turnoStatus, payload: turno });
export const turnoAddFailed   = (error) => ({ type: TURNO_ADD_FAILED, payload: error  });

// Update
export const turnoUpdate         = (turno) => ({ type: TURNO_UPDATE, payload: turno });
export const turnoUpdateSuccess  = (turno, turnoStatus) => ({ type: TURNO_UPDATE_SUCCESS, turnoStatus: turnoStatus, payload: turno });
export const turnoUpdateFailed   = (error) => ({ type: TURNO_UPDATE_FAILED, payload: error  });

// remove
export const turnoRemove         = (turno) => ({ type: TURNO_REMOVE, payload: turno });
export const turnoRemoveSuccess  = (turno, turnoDeletado) => ({ type: TURNO_REMOVE_SUCCESS, turnoDeletado: turnoDeletado, payload: turno });
export const turnoRemoveFailed   = (error) => ({ type: TURNO_REMOVE_FAILED, payload: error  });