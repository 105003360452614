import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { 
        SOLICITACAO_LIST, 
        SOLICITACAO_LISTALL,
        SOLICITACAO_APROVAR,
        SOLICITACAO_REJEITAR,
        SOLICITACAO_DOWNLOAD
 } from './constants';

import {
    solicitacaoListSuccess,
    solicitacaoListFailed,
    solicitacaoListAllSuccess,
    solicitacaoListAllFailed,
    solicitacaoAprovarSuccess,
    solicitacaoAprovarFailed,
    solicitacaoRejeitarSuccess,
    solicitacaoRejeitarFailed,
    solicitacaoDownloadSuccess,
    solicitacaoDownloadFailed
} from './actions';
import { URL_BASE } from '../../helpers/url';


/**
 * Lista de Solicitações
 * @param {*} payload - pageNumber and pageSize
 */
function* solicitacaoList({ payload: filter }) {

        const options = {
            method: 'post',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacao/filter', options);
            yield put(solicitacaoListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(solicitacaoListFailed(message));
        }    
}

/**
 * Lista de Solicitações
 * @param {*} payload - pageNumber and pageSize
 */
function* solicitacaoListAll({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacao/solicitacoes', options);
        yield put(solicitacaoListAllSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(solicitacaoListAllFailed(message));
    }    
}


/**
 * Lista de Solicitações
 * @param {*} payload - pageNumber and pageSize
 */
function* solicitacaoAprovar({ payload: id }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacao/aprovar/${id}`, options);
        yield put(solicitacaoAprovarSuccess(response));
    } catch (error) {
        yield put(solicitacaoAprovarFailed(error.message));
    }    
}

function* solicitacaoRejeitar({ payload: { id, motivo } }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(motivo)
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacao/rejeitar/${id}`, options);
        yield put(solicitacaoRejeitarSuccess(response));
    } catch (error) {
        yield put(solicitacaoRejeitarFailed(error.message));
    }    
}


/**
 * download de arquivo
 * @param {*} documentoId - ID do Reembolso
 */
function* solicitacaoDownload({ payload: { id, nomeArquivo } }) {

    try {

        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
        };

        var response =  yield call(fetch, URL_BASE + '/central/v1/api/v1/solicitacao/download/' + id, options);

       response.blob().then(blob => {

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeArquivo);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })

        yield put(solicitacaoDownloadSuccess(response));
    } catch (error) {
        yield put(solicitacaoDownloadFailed(error.message));
    }    
}

export function* watchSolicitacaoList(): any {
    yield takeEvery(SOLICITACAO_LIST, solicitacaoList);
}

export function* watchSolicitacaoListAll(): any {
    yield takeEvery(SOLICITACAO_LISTALL, solicitacaoListAll);
}

export function* watchSolicitacaoAprovar(): any {
    yield takeEvery(SOLICITACAO_APROVAR, solicitacaoAprovar);
}

export function* watchSolicitacaoRejeitar(): any {
    yield takeEvery(SOLICITACAO_REJEITAR, solicitacaoRejeitar);
}

export function* watchSolicitacaoDownload(): any {
    yield takeEvery(SOLICITACAO_DOWNLOAD, solicitacaoDownload);
}

function* solicitacao(): any {
    yield all([
                fork(watchSolicitacaoList), 
                fork(watchSolicitacaoListAll),
                fork(watchSolicitacaoAprovar),
                fork(watchSolicitacaoRejeitar),
                fork(watchSolicitacaoDownload)
            ]);
}

export default solicitacao;