/* PASSAGEIRO */
export const PASSAGEIRO_LIST           = 'PASSAGEIRO_LIST';
export const PASSAGEIRO_LIST_SUCCESS   = 'PASSAGEIRO_LIST_SUCCESS';
export const PASSAGEIRO_LIST_FAILED    = 'PASSAGEIRO_LIST_FAILED';
export const PASSAGEIRO_ADD            = 'PASSAGEIRO_ADD';
export const PASSAGEIRO_ADD_SUCCESS    = 'PASSAGEIRO_ADD_SUCCESS';
export const PASSAGEIRO_ADD_FAILED     = 'PASSAGEIRO_ADD_FAILED';
export const PASSAGEIRO_UPDATE         = 'PASSAGEIRO_UPDATE';
export const PASSAGEIRO_UPDATE_SUCCESS = 'PASSAGEIRO_UPDATE_SUCCESS';
export const PASSAGEIRO_UPDATE_FAILED  = 'PASSAGEIRO_UPDATE_FAILED';
export const PASSAGEIRO_REMOVE         = 'PASSAGEIRO_REMOVE';
export const PASSAGEIRO_REMOVE_SUCCESS = 'PASSAGEIRO_REMOVE_SUCCESS';
export const PASSAGEIRO_REMOVE_FAILED  = 'PASSAGEIRO_REMOVE_FAILED';
export const PASSAGEIRO_LOAD           = 'PASSAGEIRO_LOAD'
export const PASSAGEIRO_LOAD_SUCCESS   = 'PASSAGEIRO_LOAD_SUCCESS';
export const PASSAGEIRO_LOAD_FAILED    = 'PASSAGEIRO_LOAD_FAILED';
export const PASSAGEIRO_ENDERECOS      = 'PASSAGEIRO_ENDERECOS'
export const PASSAGEIRO_ENDERECOS_SUCCESS     = 'PASSAGEIRO_ENDERECOS_SUCCESS'
export const PASSAGEIRO_ENDERECOS_FAILED      = 'PASSAGEIRO_ENDERECOS_FAILED'
