
import { DESPESAAPROVADA_LOAD, DESPESAAPROVADA_LOAD_SUCCESS, DESPESAAPROVADA_LOAD_FAILED, DESPESAAPROVADA_LIST, DESPESAAPROVADA_LIST_SUCCESS, DESPESAAPROVADA_LIST_FAILED, DESPESAAPROVADA_ADD, DESPESAAPROVADA_ADD_FAILED, DESPESAAPROVADA_ADD_SUCCESS, DESPESAAPROVADA_REMOVE, DESPESAAPROVADA_REMOVE_FAILED, DESPESAAPROVADA_REMOVE_SUCCESS, DESPESAAPROVADA_UPDATE, DESPESAAPROVADA_UPDATE_SUCCESS, DESPESAAPROVADA_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    despesaAprovadas: {},
    despesaAprovada: {},
    loading: false,
    error: null,
    despesaAprovadaStatus: null,
    id: {}
};

const DespesaAprovadaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DESPESAAPROVADA_LIST:
            return { ...state, loading: true, error: null, despesaAprovadaDeletado: null };
        case DESPESAAPROVADA_LIST_SUCCESS:
            return { ...state, despesaAprovadas: action.payload, loading: false, error: null, despesaAprovadaDeletado: null };
        case DESPESAAPROVADA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, despesaAprovadaDeletado: null };
        case DESPESAAPROVADA_LOAD:
            return { ...state, despesaAprovada: {}, loading: true, error: null, despesaAprovadaStatus: null, despesaAprovadaDeletado: null }
        case DESPESAAPROVADA_LOAD_SUCCESS:
            return { ...state, despesaAprovada: action.payload, loading: false, error: null, despesaAprovadaDeletado: null}
        case DESPESAAPROVADA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, despesaAprovadaDeletado: null };
        case DESPESAAPROVADA_ADD:
            return { ...state, despesaAprovada: action.payload, despesaAprovadaStatus: null, loading: true, error: null, despesaAprovadaDeletado: null}
        case DESPESAAPROVADA_ADD_SUCCESS:
            return { ...state, despesaAprovada: action.payload,  despesaAprovadaStatus: "Solicitação criada com sucesso", despesaAprovadaDeletado: null, loading: false, error: null };
        case DESPESAAPROVADA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DESPESAAPROVADA_UPDATE:
            return { ...state, despesaAprovada: action.payload, despesaAprovadaStatus: null, loading: true, error: null}
        case DESPESAAPROVADA_UPDATE_SUCCESS:
            return { ...state, despesaAprovada: action.payload,  despesaAprovadaStatus: "Solicitação atualizada com sucesso", despesaAprovadaDeletado: null, loading: false, error: null };
        case DESPESAAPROVADA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, despesaAprovadaStatus: null };
        case DESPESAAPROVADA_REMOVE:
            return { ...state, despesaAprovada: action.payload, despesaAprovadaStatus: null, loading: true, error: null, despesaAprovadaDeletado: null}
        case DESPESAAPROVADA_REMOVE_SUCCESS:
            return { ...state, despesaAprovada: {}, despesaAprovadaStatus: null,  despesaAprovadaDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case DESPESAAPROVADA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, despesaAprovadaStatus: null, despesaAprovadaDeletado: null };
        default:
            return { ...state };
    }
};

export default DespesaAprovadaReducer;