import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { AJUSTEKM_LOAD, AJUSTEKM_LIST, AJUSTEKM_ADD, AJUSTEKM_UPDATE, AJUSTEKM_REMOVE } from './constants';

import {
    ajusteKmListSuccess,
    ajusteKmListFailed,
    ajusteKmAddSuccess,
    ajusteKmAddFailed,
    ajusteKmUpdateSuccess,
    ajusteKmUpdateFailed,
    ajusteKmLoadSuccess,
    ajusteKmLoadFailed,
    ajusteKmRemoveSuccess,
    ajusteKmRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* ajusteKmLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/ajustekm/' + id, options);
        yield put(ajusteKmLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(ajusteKmLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* ajusteKmList({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/ajusteKm/filter', options);
        yield put(ajusteKmListSuccess(response));
    } catch (error) {
        yield put(ajusteKmListFailed(error.message));
    }
}


/**
 * ajusteKm
 * @param {*} payload - pageNumber and pageSize
 */
function* ajusteKmAdd({ payload:  ajusteKm }) {

    const options = {
        body: JSON.stringify(ajusteKm) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/ajusteKm', options);
        yield put(ajusteKmAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(ajusteKmAddFailed(message));
    }    
}

/**
 * ajusteKm
 * @param {*} payload - pageNumber and pageSize
 */
function* ajusteKmUpdate({ payload: ajusteKm }) {

    const options = {
        body: JSON.stringify(ajusteKm) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/ajusteKm/' + ajusteKm.id, options);
        yield put(ajusteKmUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(ajusteKmUpdateFailed(message));
    }    
}

/**
 * ajusteKm
 * @param {*} payload - pageNumber and pageSize
 */
function* ajusteKmRemove({ payload:  ajusteKm  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/ajusteKm/' + ajusteKm.id, options);
        yield put(ajusteKmRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(ajusteKmRemoveFailed(message));
    }    
}

export function* watchajusteKmLoad(): any{
    yield takeEvery(AJUSTEKM_LOAD, ajusteKmLoad);
}

export function* watchajusteKmList(): any {
    yield takeEvery(AJUSTEKM_LIST, ajusteKmList);
}

export function* watchajusteKmAdd(): any {
    yield takeEvery(AJUSTEKM_ADD, ajusteKmAdd);
}

export function* watchajusteKmUpdate(): any {
    yield takeEvery(AJUSTEKM_UPDATE, ajusteKmUpdate);
}

export function* watchajusteKmRemove(): any {
    yield takeEvery(AJUSTEKM_REMOVE, ajusteKmRemove);
}

function* ajusteKm(): any {
    yield all([fork(watchajusteKmList), fork(watchajusteKmAdd), fork(watchajusteKmUpdate), fork(watchajusteKmLoad), fork(watchajusteKmRemove)]);
}

export default ajusteKm;