import { 
    CORRIDA_LIST,
    CORRIDA_LIST_SUCCESS,
    CORRIDA_LIST_FAILED,
    CORRIDA_ADD,
    CORRIDA_ADD_SUCCESS,
    CORRIDA_ADD_FAILED,
    CORRIDA_UPDATE,
    CORRIDA_UPDATE_SUCCESS,
    CORRIDA_UPDATE_FAILED,
    CORRIDA_LOAD,
    CORRIDA_LOAD_SUCCESS,
    CORRIDA_LOAD_FAILED,
    CORRIDA_REMOVE,
    CORRIDA_REMOVE_SUCCESS,
    CORRIDA_REMOVE_FAILED,
    CORRIDA_FINALIZAR,
    CORRIDA_FINALIZAR_SUCCESS,
    CORRIDA_FINALIZAR_FAILED,
    CORRIDA_CANCELAR,
    CORRIDA_CANCELAR_SUCCESS,
    CORRIDA_CANCELAR_FAILED,
    CORRIDA_MODAL,
    CORRIDA_REENVIAR,
    CORRIDA_REENVIAR_SUCCESS,
    CORRIDA_REENVIAR_FAILED,
    CORRIDA_MOTORISTA_LIST,
    CORRIDA_MOTORISTA_LIST_SUCCESS,
    CORRIDA_MOTORISTA_LIST_FAILED,

    /// ACOMPANHAMENTO
    ACOMPANHAMENTO,
    ACOMPANHAMENTO_SUCCESS,
    ACOMPANHAMENTO_FAILED
} from './constants';

// List
export const corridaList        = (filter) => ({ type: CORRIDA_LIST, payload: filter });
export const corridaListSuccess = (corridas) => ({ type: CORRIDA_LIST_SUCCESS, payload: corridas });
export const corridaListFailed  = (error) => ({ type: CORRIDA_LIST_FAILED, payload: error  });

export const corridaLoad        = (id) => ({ type: CORRIDA_LOAD, payload: id });
export const corridaLoadSuccess  = (corrida) => ({ type: CORRIDA_LOAD_SUCCESS, payload: corrida });
export const corridaLoadFailed   = (error) => ({ type: CORRIDA_LOAD_FAILED, payload: error  });

// Add
export const corridaAdd         = (corrida) => ({ type: CORRIDA_ADD, payload: corrida });
export const corridaAddSuccess  = (corrida, corridaStatus) => ({ type: CORRIDA_ADD_SUCCESS, corridaStatus: corridaStatus, payload: corrida });
export const corridaAddFailed   = (error) => ({ type: CORRIDA_ADD_FAILED, payload: error  });

// Update
export const corridaUpdate         = (corrida) => ({ type: CORRIDA_UPDATE, payload: corrida });
export const corridaUpdateSuccess  = (corrida, corridaStatus) => ({ type: CORRIDA_UPDATE_SUCCESS, corridaStatus: corridaStatus, payload: corrida });
export const corridaUpdateFailed   = (error) => ({ type: CORRIDA_UPDATE_FAILED, payload: error  });

// remove
export const corridaRemove         = (corrida) => ({ type: CORRIDA_REMOVE, payload: corrida });
export const corridaRemoveSuccess  = (corrida, corridaDeletado) => ({ type: CORRIDA_REMOVE_SUCCESS, corridaDeletado: corridaDeletado, payload: corrida });
export const corridaRemoveFailed   = (error) => ({ type: CORRIDA_REMOVE_FAILED, payload: error  });

// finalizar
export const corridaFinalizar         = (corridaId) => ({ type: CORRIDA_FINALIZAR, payload: corridaId });
export const corridaFinalizarSuccess  = (success) => ({ type: CORRIDA_FINALIZAR_SUCCESS, payload: success });
export const corridaFinalizarFailed   = (error) => ({ type: CORRIDA_FINALIZAR_FAILED, payload: error  });

export const corridaCancelar         = (corridaId) => ({ type: CORRIDA_CANCELAR, payload: corridaId });
export const corridaCancelarSuccess  = (success) => ({ type: CORRIDA_CANCELAR_SUCCESS, payload: success });
export const corridaCancelarFailed   = (error) => ({ type: CORRIDA_CANCELAR_FAILED, payload: error  });

export const showModalCorrida = () => ({ type: CORRIDA_MODAL });

export const corridaReenviar         = (corridaId) => ({ type: CORRIDA_REENVIAR, payload: corridaId });
export const corridaReenviarSuccess  = (success) => ({ type: CORRIDA_REENVIAR_SUCCESS, payload: success });
export const corridaReenviarFailed   = (error) => ({ type: CORRIDA_REENVIAR_FAILED, payload: error  });

export const corridaMotoristaList        = (motoristaId, data) => ({ type: CORRIDA_MOTORISTA_LIST, payload: { motoristaId, data } });
export const corridaMotoristaListSuccess = (corridas) => ({ type: CORRIDA_MOTORISTA_LIST_SUCCESS, payload: corridas });
export const corridaMotoristaListFailed  = (error) => ({ type: CORRIDA_MOTORISTA_LIST_FAILED, payload: error  });

/// ACOMPANHAMENTO
export const acompanhamento = () => ({ type: ACOMPANHAMENTO });
export const acompanhamentoSuccess = (data) => ({ type: ACOMPANHAMENTO_SUCCESS, payload: data })
export const acompanhamentoFailed = (error) => ({ type: ACOMPANHAMENTO_FAILED, payload: error })