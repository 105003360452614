import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { VEICULOCOR_LOAD, VEICULOCOR_LIST, VEICULOCOR_ADD, VEICULOCOR_UPDATE, VEICULOCOR_REMOVE } from './constants';

import {
    veiculoCorListSuccess,
    veiculoCorListFailed,
    veiculoCorAddSuccess,
    veiculoCorAddFailed,
    veiculoCorUpdateSuccess,
    veiculoCorUpdateFailed,
    veiculoCorLoadSuccess,
    veiculoCorLoadFailed,
    veiculoCorRemoveSuccess,
    veiculoCorRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoCorLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculocor/' + id, options);
        yield put(veiculoCorLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoCorLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoCorList({ payload: filter }) {

        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/veiculocor', options);
            yield put(veiculoCorListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(veiculoCorListFailed(message));
        }    
}


/**
 * veiculoCor
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoCorAdd({ payload:  veiculoCor   }) {

    const options = {
        body: JSON.stringify(veiculoCor) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculocor', options);
        yield put(veiculoCorAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoCorAddFailed(message));
    }    
}

/**
 * veiculoCor
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoCorUpdate({ payload:  veiculoCor   }) {

    const options = {
        body: JSON.stringify(veiculoCor) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculocor/' + veiculoCor.id, options);
        yield put(veiculoCorUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoCorUpdateFailed(message));
    }    
}

/**
 * veiculoCor
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoCorRemove({ payload:  veiculoCor   }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/veiculocor/' + veiculoCor.id, options);
        yield put(veiculoCorRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(veiculoCorRemoveFailed(message));
    }    
}

export function* watchveiculoCorLoad(): any{
    yield takeEvery(VEICULOCOR_LOAD, veiculoCorLoad);
}

export function* watchveiculoCorList(): any {
    yield takeEvery(VEICULOCOR_LIST, veiculoCorList);
}

export function* watchveiculoCorAdd(): any {
    yield takeEvery(VEICULOCOR_ADD, veiculoCorAdd);
}

export function* watchveiculoCorUpdate(): any {
    yield takeEvery(VEICULOCOR_UPDATE, veiculoCorUpdate);
}

export function* watchveiculoCorRemove(): any {
    yield takeEvery(VEICULOCOR_REMOVE, veiculoCorRemove);
}

function* veiculoCor(): any {
    yield all([fork(watchveiculoCorList), fork(watchveiculoCorAdd), fork(watchveiculoCorUpdate), fork(watchveiculoCorLoad), fork(watchveiculoCorRemove)]);
}

export default veiculoCor;