/* VEICULO */
export const ATUALIZACAODOCUMENTO_LIST                = 'ATUALIZACAODOCUMENTO_LIST';
export const ATUALIZACAODOCUMENTO_LIST_SUCCESS        = 'ATUALIZACAODOCUMENTO_LIST_SUCCESS';
export const ATUALIZACAODOCUMENTO_LIST_FAILED         = 'ATUALIZACAODOCUMENTO_LIST_FAILED';
export const ATUALIZACAODOCUMENTO_ADD                 = 'ATUALIZACAODOCUMENTO_ADD';
export const ATUALIZACAODOCUMENTO_ADD_SUCCESS         = 'ATUALIZACAODOCUMENTO_ADD_SUCCESS';
export const ATUALIZACAODOCUMENTO_ADD_FAILED          = 'ATUALIZACAODOCUMENTO_ADD_FAILED';
export const ATUALIZACAODOCUMENTO_UPDATE              = 'ATUALIZACAODOCUMENTO_UPDATE';
export const ATUALIZACAODOCUMENTO_UPDATE_SUCCESS      = 'ATUALIZACAODOCUMENTO_UPDATE_SUCCESS';
export const ATUALIZACAODOCUMENTO_UPDATE_FAILED       = 'ATUALIZACAODOCUMENTO_UPDATE_FAILED';
export const ATUALIZACAODOCUMENTO_REMOVE              = 'ATUALIZACAODOCUMENTO_REMOVE';
export const ATUALIZACAODOCUMENTO_REMOVE_SUCCESS      = 'ATUALIZACAODOCUMENTO_REMOVE_SUCCESS';
export const ATUALIZACAODOCUMENTO_REMOVE_FAILED       = 'ATUALIZACAODOCUMENTO_REMOVE_FAILED';
export const ATUALIZACAODOCUMENTO_LOAD                = 'ATUALIZACAODOCUMENTO_LOAD'
export const ATUALIZACAODOCUMENTO_LOAD_SUCCESS        = 'ATUALIZACAODOCUMENTO_LOAD_SUCCESS';
export const ATUALIZACAODOCUMENTO_LOAD_FAILED         = 'ATUALIZACAODOCUMENTO_LOAD_FAILED';
export const ATUALIZACAODOCUMENTO_UPLOAD              = 'ATUALIZACAODOCUMENTO_UPLOAD'
export const ATUALIZACAODOCUMENTO_UPLOAD_SUCCESS      = 'ATUALIZACAODOCUMENTO_UPLOAD_SUCCESS';
export const ATUALIZACAODOCUMENTO_UPLOAD_FAILED       = 'ATUALIZACAODOCUMENTO_UPLOAD_FAILED';
export const ATUALIZACAODOCUMENTO_DOWNLOAD            = 'ATUALIZACAODOCUMENTO_DOWNLOAD'
export const ATUALIZACAODOCUMENTO_DOWNLOAD_SUCCESS    = 'ATUALIZACAODOCUMENTO_DOWNLOAD_SUCCESS'
export const ATUALIZACAODOCUMENTO_DOWNLOAD_FAILED     = 'ATUALIZACAODOCUMENTO_DOWNLOAD_FAILED'
export const ATUALIZACAODOCUMENTO_REMOVEANEXO         = 'ATUALIZACAODOCUMENTO_REMOVEANEXO'
export const ATUALIZACAODOCUMENTO_REMOVEANEXO_SUCCESS = 'ATUALIZACAODOCUMENTO_REMOVEANEXO_SUCCESS'
export const ATUALIZACAODOCUMENTO_REMOVEANEXO_FAILED  = 'ATUALIZACAODOCUMENTO_REMOVEANEXO_FAILED'