import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils';

import {
    DASH_KM_LINEAR,
    DASH_MOTORISTA_HORAS_ONLINE,
    DASH_TOTALIZADOR_LIST,
    DASH_TOTAL_CENTRO_CUSTO,
    DASH_TOTAL_CIDADE,
    MOTORISTA_EM_ATIVIDADE,
    QUANTIDADE_CORRIDA_EM_FILA_LIST,
    QUANTIDADE_CORRIDA_SLA_LIST,
} from './constants';

import {
    dashTotalizadorListSuccess,
    dashTotalizadorListFailed,
    dashCentroCustoListSuccess,
    dashCentroCustoListFailed,
    dashCidadeListSuccess,
    dashCidadeListFailed,
    dashKmLinearListSuccess,
    dashKmLinearListFailed,
    motoristaEmAtividadeSuccess,
    motoristaEmAtividadeFailed,
    quantidadeCorridaSlaSuccess,
    quantidadeCorridaSlaFailed,
    quantidadeCorridaEmFilaSuccess,
    quantidadeCorridaEmFilaFailed,
    dashMotoristaHorasOnlineSuccess,
    dashMotoristaHorasOnlineFailed,
} from './actions';

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* dashTotalizadorList({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corridadash/totalizador/', options);
        yield put(dashTotalizadorListSuccess(response));
    } catch (error) {
        yield put(dashTotalizadorListFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* dashTotalCentroCustoList({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corridadash/totalcentrocusto/', options);
        yield put(dashCentroCustoListSuccess(response));
    } catch (error) {
        yield put(dashCentroCustoListFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* dashTotalCidadeList({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corridadash/totalcidade/', options);
        yield put(dashCidadeListSuccess(response));
    } catch (error) {
        yield put(dashCidadeListFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* dashKmLinearList({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corridadash/mediakmlinear/', options);
        yield put(dashKmLinearListSuccess(response));
    } catch (error) {
        yield put(dashKmLinearListFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* motoristaEmAndamento() {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corridadash/motoristaprodutivo/', options);
        yield put(motoristaEmAtividadeSuccess(response));
    } catch (error) {
        yield put(motoristaEmAtividadeFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* quantidadeCorridaSlaList({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corridadash/quantidadecorridaporsla/', options);
        yield put(quantidadeCorridaSlaSuccess(response));
    } catch (error) {
        yield put(quantidadeCorridaSlaFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* quantidadeCorridaEmFila({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corridadash/quantidadecorridaemfila/', options);
        yield put(quantidadeCorridaEmFilaSuccess(response));
    } catch (error) {
        yield put(quantidadeCorridaEmFilaFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
 function* dashMotoristaHorasOnline() {
    const options = {
        method: 'POST',
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/MotoristaDash/', options);
        yield put(dashMotoristaHorasOnlineSuccess(response));
    } catch (error) {
        yield put(dashMotoristaHorasOnlineFailed(error.message));
    }
}

export function* watchTotalizadoresList(): any {
    yield takeEvery(DASH_TOTALIZADOR_LIST, dashTotalizadorList);
}

export function* watchTotalCentroCustoList(): any {
    yield takeEvery(DASH_TOTAL_CENTRO_CUSTO, dashTotalCentroCustoList);
}

export function* watchTotalCidadeList(): any {
    yield takeEvery(DASH_TOTAL_CIDADE, dashTotalCidadeList);
}

export function* watchKmLinearList(): any {
    yield takeEvery(DASH_KM_LINEAR, dashKmLinearList);
}

export function* watchMotoristaEmAndamento(): any {
    yield takeEvery(MOTORISTA_EM_ATIVIDADE, motoristaEmAndamento);
}

export function* watchQuantidadeCorridaSlaList(): any {
    yield takeEvery(QUANTIDADE_CORRIDA_SLA_LIST, quantidadeCorridaSlaList);
}

export function* watchQuantidadeCorridaEmFilaList(): any {
    yield takeEvery(QUANTIDADE_CORRIDA_EM_FILA_LIST, quantidadeCorridaEmFila);
}

export function* watchDashMotoristaHorasOnline(): any {
    yield takeEvery(DASH_MOTORISTA_HORAS_ONLINE, dashMotoristaHorasOnline);
}

function* dashboard(): any {
    yield all([
        fork(watchTotalizadoresList),
        fork(watchTotalCentroCustoList),
        fork(watchTotalCidadeList),
        fork(watchKmLinearList),
        fork(watchMotoristaEmAndamento),
        fork(watchQuantidadeCorridaSlaList),
        fork(watchQuantidadeCorridaEmFilaList),
        fork(watchDashMotoristaHorasOnline),
    ]);
}

export default dashboard;
