import { 
    LIMITADOR_CORRIDA_LIST,
    LIMITADOR_CORRIDA_LIST_SUCCESS,
    LIMITADOR_CORRIDA_LIST_FAILED,
    LIMITADOR_CORRIDA_ADD,
    LIMITADOR_CORRIDA_ADD_SUCCESS,
    LIMITADOR_CORRIDA_ADD_FAILED,
    LIMITADOR_CORRIDA_UPDATE,
    LIMITADOR_CORRIDA_UPDATE_SUCCESS,
    LIMITADOR_CORRIDA_UPDATE_FAILED,
    LIMITADOR_CORRIDA_LOAD,
    LIMITADOR_CORRIDA_LOAD_SUCCESS,
    LIMITADOR_CORRIDA_LOAD_FAILED,
    LIMITADOR_CORRIDA_REMOVE,
    LIMITADOR_CORRIDA_REMOVE_SUCCESS,
    LIMITADOR_CORRIDA_REMOVE_FAILED
} from './constants';

// List
export const limitadorCorridaList        = (filter) => ({ type: LIMITADOR_CORRIDA_LIST, payload: filter});
export const limitadorCorridaListSuccess = (limitadorCorridas) => ({ type: LIMITADOR_CORRIDA_LIST_SUCCESS, payload: limitadorCorridas });
export const limitadorCorridaListFailed  = (error) => ({ type: LIMITADOR_CORRIDA_LIST_FAILED, payload: error });

export const limitadorCorridaLoad        = (id) => ({ type: LIMITADOR_CORRIDA_LOAD, payload: id });
export const limitadorCorridaLoadSuccess  = (limitadorCorrida) => ({ type: LIMITADOR_CORRIDA_LOAD_SUCCESS, payload: limitadorCorrida });
export const limitadorCorridaLoadFailed   = (error) => ({ type: LIMITADOR_CORRIDA_LOAD_FAILED, payload: error });

// Add
export const limitadorCorridaAdd         = (limitadorCorrida) => ({ type: LIMITADOR_CORRIDA_ADD, payload: limitadorCorrida });
export const limitadorCorridaAddSuccess  = (limitadorCorridaStatus) => ({ type: LIMITADOR_CORRIDA_ADD_SUCCESS,  payload: limitadorCorridaStatus });
export const limitadorCorridaAddFailed   = (error) => ({ type: LIMITADOR_CORRIDA_ADD_FAILED, payload: error });

// Update
export const limitadorCorridaUpdate         = (limitadorCorrida) => ({ type: LIMITADOR_CORRIDA_UPDATE, payload: limitadorCorrida });
export const limitadorCorridaUpdateSuccess  = (limitadorCorridaStatus) => ({ type: LIMITADOR_CORRIDA_UPDATE_SUCCESS, payload: limitadorCorridaStatus});
export const limitadorCorridaUpdateFailed   = (error) => ({ type: LIMITADOR_CORRIDA_UPDATE_FAILED, payload: error });

// remove
export const limitadorCorridaRemove         = (limitadorCorrida) => ({ type: LIMITADOR_CORRIDA_REMOVE, payload: limitadorCorrida });
export const limitadorCorridaRemoveSuccess  = (limitadorCorrida, limitadorCorridaDeletado) => ({ type: LIMITADOR_CORRIDA_REMOVE_SUCCESS, limitadorCorridaDeletado: limitadorCorridaDeletado, payload: limitadorCorrida });
export const limitadorCorridaRemoveFailed   = (error) => ({ type: LIMITADOR_CORRIDA_REMOVE_FAILED, payload: error });