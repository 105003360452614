import { 
    VEICULO_LIST,
    VEICULO_LIST_SUCCESS,
    VEICULO_LIST_FAILED,
    VEICULO_ADD,
    VEICULO_ADD_SUCCESS,
    VEICULO_ADD_FAILED,
    VEICULO_UPDATE,
    VEICULO_UPDATE_SUCCESS,
    VEICULO_UPDATE_FAILED,
    VEICULO_LOAD,
    VEICULO_LOAD_SUCCESS,
    VEICULO_LOAD_FAILED,
    VEICULO_REMOVE,
    VEICULO_REMOVE_SUCCESS,
    VEICULO_REMOVE_FAILED
} from './constants';

// List
export const veiculoList        = (filter) => ({ type: VEICULO_LIST, payload: filter});
export const veiculoListSuccess = (veiculos) => ({ type: VEICULO_LIST_SUCCESS, payload: veiculos  });
export const veiculoListFailed  = (error) => ({ type: VEICULO_LIST_FAILED, payload: error  });

export const veiculoLoad        = (id) => ({ type: VEICULO_LOAD, payload: { id } });
export const veiculoLoadSuccess  = (veiculo) => ({ type: VEICULO_LOAD_SUCCESS, payload:  veiculo  });
export const veiculoLoadFailed   = (error) => ({ type: VEICULO_LOAD_FAILED, payload: error  });

// Add
export const veiculoAdd         = (veiculo) => ({ type: VEICULO_ADD, payload:  veiculo  });
export const veiculoAddSuccess  = (veiculo, veiculoStatus) => ({ type: VEICULO_ADD_SUCCESS, veiculoStatus: veiculoStatus, payload:  veiculo  });
export const veiculoAddFailed   = (error) => ({ type: VEICULO_ADD_FAILED, payload: error  });

// Update
export const veiculoUpdate         = (veiculo) => ({ type: VEICULO_UPDATE, payload:  veiculo  });
export const veiculoUpdateSuccess  = (veiculo, veiculoStatus) => ({ type: VEICULO_UPDATE_SUCCESS, veiculoStatus: veiculoStatus, payload:  veiculo  });
export const veiculoUpdateFailed   = (error) => ({ type: VEICULO_UPDATE_FAILED, payload: error  });

// remove
export const veiculoRemove         = (veiculo) => ({ type: VEICULO_REMOVE, payload:  veiculo  });
export const veiculoRemoveSuccess  = (veiculo, veiculoDeletado) => ({ type: VEICULO_REMOVE_SUCCESS, veiculoDeletado: veiculoDeletado, payload:  veiculo });
export const veiculoRemoveFailed   = (error) => ({ type: VEICULO_REMOVE_FAILED, payload: error  });