import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { 
    AUDITORIA_LOAD, 
    AUDITORIA_LIST, 
    AUDITORIA_ADD, 
    AUDITORIA_UPDATE, 
    AUDITORIA_REMOVE,
   
} from './constants';

import {
     auditoriaListSuccess,
     auditoriaListFailed,
     auditoriaAddSuccess,
     auditoriaAddFailed,
     auditoriaUpdateSuccess,
     auditoriaUpdateFailed,
     auditoriaLoadSuccess,
     auditoriaLoadFailed,
     auditoriaRemoveSuccess,
     auditoriaRemoveFailed,
   

} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function*  auditoriaLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/naoconformidade/' + id, options);
        yield put( auditoriaLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put( auditoriaLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function*  auditoriaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/naoconformidade/filter', options);
            yield put(auditoriaListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put( auditoriaListFailed(message));
        }    
}


/**
 *  auditoria
 * @param {*} payload - pageNumber and pageSize
 */
function*  auditoriaAdd({ payload: { auditoria }  }) {

    const options = {
        body: JSON.stringify(auditoria) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/naoconformidade', options);
        yield put(auditoriaAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(auditoriaAddFailed(message));
    }    
}

/**
 * auditoria
 * @param {*} payload - pageNumber and pageSize
 */
function* auditoriaUpdate({ payload: { auditoria }  }) {

    const options = {
        body: JSON.stringify(auditoria) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/naoconformidade/' + auditoria?.id, options);
        yield put(auditoriaUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(auditoriaUpdateFailed(message));
    }    
}

/**
 * alterarEndPass
 * @param {*} payload - pageNumber and pageSize
 */
function* auditoriaRemove({ payload: { auditoria }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/naoconformidade/' + auditoria?.id, options);
        yield put(auditoriaRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(auditoriaRemoveFailed(message));
    }    
}



export function* watchauditoriaLoad(): any{
    yield takeEvery(AUDITORIA_LOAD, auditoriaLoad);
}

export function* watchauditoriaList(): any {
    yield takeEvery(AUDITORIA_LIST, auditoriaList);
}

export function* watchauditoriaAdd(): any {
    yield takeEvery(AUDITORIA_ADD, auditoriaAdd);
}

export function* watchauditoriaUpdate(): any {
    yield takeEvery(AUDITORIA_UPDATE, auditoriaUpdate);
}

export function* watchauditoriaRemove(): any {
    yield takeEvery(AUDITORIA_REMOVE, auditoriaRemove);
}


function* alterarEndPass(): any {
    yield all([
                fork(watchauditoriaList), 
                fork(watchauditoriaAdd), 
                fork(watchauditoriaUpdate), 
                fork(watchauditoriaLoad), 
                fork(watchauditoriaRemove),
              ]);
}

export default alterarEndPass;