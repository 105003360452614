import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from './privateRoute';

const AcompanhamentoCorrida = React.lazy(() => import('../../../pages/ebooking/corrida/AcompanhamentoCorrida'));
const SolicitacaoCorrida = React.lazy(() => import('../../../pages/ebooking/solicitacao/SolicitacaoCorrida'));
const NewSolicitacaoCorrida = React.lazy(() => import('../../../pages/ebooking/solicitacao/NewSolicitacaoCorrida'));
const Monitoramento = React.lazy(() => import('../../../pages/monitoramento/monitoramento'));
const Corridas = React.lazy(() => import('../../../pages/monitoramento/corridas/corridas'));
const Horas = React.lazy(() => import('../../../pages/backoffice/auditoria/NewListaHorasOnline'));
const Acompanhamento = React.lazy(() => import('../../../pages/ebooking/acompanhamento'));

export const corridasRoute = [
    // Solicitacao
    {
        path: '/corrida/solicitacao/aprovar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'operadorcentral'],
        component: SolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/historico',
        exact: true,
        roles: ['master', 'gestorcentral', 'operadorcentral', 'operadorcliente', 'gestorcliente'],
        component: AcompanhamentoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/acompanhamento',
        exact: true,
        roles: ['master', 'gestorcentral', 'operadorcentral', 'operadorcliente', 'gestorcliente'],
        component: Acompanhamento,
        route: PrivateRoute,
    },
    {
        path: '/corrida/solicitacao/adicionar',
        exact: true,
        roles: ['master'],
        component: SolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/solicitacao/atualizar/:id',
        exact: true,
        roles: ['master'],
        component: SolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/solicitacao/visualizar/:id',
        exact: true,
        roles: ['master'],
        component: SolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/solicitacao/deletar/:id',
        exact: true,
        roles: ['master'],
        component: SolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/solicitacao/liberar/:id',
        exact: true,
        roles: ['master'],
        component: SolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/monitoramento/full',
        exact: true,
        roles: ['master', 'gestorcentral', 'operadorcentral'],
        component: Monitoramento,
        route: Route,
    },
    {
        path: '/monitoramento/corridas',
        exact: true,
        roles: ['master', 'gestorcentral', 'operadorcentral'],
        component: Corridas,
        route: PrivateRoute,
    },
    {
        path: '/monitoramento/horas',
        exact: true,
        roles: ['master', 'gestorcentral', 'operadorcentral'],
        component: Horas,
        route: PrivateRoute,
    },
    // NewSolicitacao
    {
        path: '/corrida/newsolicitacao/aprovar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'operadorcentral'],
        component: NewSolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/newsolicitacao/adicionar',
        exact: true,
        roles: ['master', 'gestorcentral', 'gestorcliente', 'operadorcentral', 'operadorcliente'],
        component: NewSolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/newsolicitacao/atualizar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'gestorcliente', 'operadorcentral', 'operadorcliente'],
        component: NewSolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/newsolicitacao/visualizar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'gestorcliente', 'operadorcentral', 'operadorcliente'],
        component: NewSolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/newsolicitacao/deletar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'gestorcliente', 'operadorcentral', 'operadorcliente'],
        component: NewSolicitacaoCorrida,
        route: PrivateRoute,
    },
    {
        path: '/corrida/newsolicitacao/liberar/:id',
        exact: true,
        roles: ['master', 'gestorcliente'],
        component: NewSolicitacaoCorrida,
        route: PrivateRoute,
    },
];
