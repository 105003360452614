import { 
    TROCAVEICULO_LIST,
    TROCAVEICULO_LIST_SUCCESS,
    TROCAVEICULO_LIST_FAILED,
    TROCAVEICULO_ADD,
    TROCAVEICULO_ADD_SUCCESS,
    TROCAVEICULO_ADD_FAILED,
    TROCAVEICULO_UPDATE,
    TROCAVEICULO_UPDATE_SUCCESS,
    TROCAVEICULO_UPDATE_FAILED,
    TROCAVEICULO_LOAD,
    TROCAVEICULO_LOAD_SUCCESS,
    TROCAVEICULO_LOAD_FAILED,
    TROCAVEICULO_REMOVE,
    TROCAVEICULO_REMOVE_SUCCESS,
    TROCAVEICULO_REMOVE_FAILED
} from './constants';

// List
export const trocaVeiculoList        = (filter) => ({ type: TROCAVEICULO_LIST, payload: filter,});
export const trocaVeiculoListSuccess = (trocaVeiculos) => ({ type: TROCAVEICULO_LIST_SUCCESS, payload: trocaVeiculos  });
export const trocaVeiculoListFailed  = (error) => ({ type: TROCAVEICULO_LIST_FAILED, payload: error  });

export const trocaVeiculoLoad        = (id) => ({ type: TROCAVEICULO_LOAD, payload: { id } });
export const trocaVeiculoLoadSuccess  = (trocaVeiculo) => ({ type: TROCAVEICULO_LOAD_SUCCESS, payload:  trocaVeiculo  });
export const trocaVeiculoLoadFailed   = (error) => ({ type: TROCAVEICULO_LOAD_FAILED, payload: error  });

// Add
export const trocaVeiculoAdd         = (trocaVeiculo) => ({ type: TROCAVEICULO_ADD, payload: trocaVeiculo  });
export const trocaVeiculoAddSuccess  = (trocaVeiculo, trocaVeiculoStatus) => ({ type: TROCAVEICULO_ADD_SUCCESS, trocaVeiculoStatus: trocaVeiculoStatus, payload: { trocaVeiculo } });
export const trocaVeiculoAddFailed   = (error) => ({ type: TROCAVEICULO_ADD_FAILED, payload: error  });

// Update
export const trocaVeiculoUpdate         = (trocaVeiculo) => ({ type: TROCAVEICULO_UPDATE, payload:  trocaVeiculo  });
export const trocaVeiculoUpdateSuccess  = (trocaVeiculo, trocaVeiculoStatus) => ({ type: TROCAVEICULO_UPDATE_SUCCESS, trocaVeiculoStatus: trocaVeiculoStatus, payload: { trocaVeiculo } });
export const trocaVeiculoUpdateFailed   = (error) => ({ type: TROCAVEICULO_UPDATE_FAILED, payload: error  });

// remove
export const trocaVeiculoRemove         = (trocaVeiculo) => ({ type: TROCAVEICULO_REMOVE, payload:  trocaVeiculo  });
export const trocaVeiculoRemoveSuccess  = (trocaVeiculo, trocaVeiculoDeletado) => ({ type: TROCAVEICULO_REMOVE_SUCCESS, trocaVeiculoDeletado: trocaVeiculoDeletado, payload: { trocaVeiculo } });
export const trocaVeiculoRemoveFailed   = (error) => ({ type: TROCAVEICULO_REMOVE_FAILED, payload: error  });