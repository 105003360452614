
import { TIPO_LOCAL_LOAD, TIPO_LOCAL_LOAD_SUCCESS, TIPO_LOCAL_LOAD_FAILED, TIPO_LOCAL_LIST, TIPO_LOCAL_LIST_SUCCESS, TIPO_LOCAL_LIST_FAILED, TIPO_LOCAL_ADD, TIPO_LOCAL_ADD_FAILED, TIPO_LOCAL_ADD_SUCCESS, TIPO_LOCAL_REMOVE, TIPO_LOCAL_REMOVE_FAILED, TIPO_LOCAL_REMOVE_SUCCESS, TIPO_LOCAL_UPDATE, TIPO_LOCAL_UPDATE_SUCCESS, TIPO_LOCAL_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    tipoLocais: {},
    tipoLocal: {},
    loading: false,
    error: null,
    tipoLocalStatus: null,
    id: {}
};

const TipoLocalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TIPO_LOCAL_LIST:
            return { ...state, loading: true, error: null, tipoLocalDeletado: null };
        case TIPO_LOCAL_LIST_SUCCESS:
            return { ...state, tipoLocais: action.payload, loading: false, error: null, tipoLocalDeletado: null };
        case TIPO_LOCAL_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, tipoLocalDeletado: null };
        case TIPO_LOCAL_LOAD:
            return { ...state, loading: true, error: null, tipoLocalStatus: null, tipoLocalDeletado: null }
        case TIPO_LOCAL_LOAD_SUCCESS:
            return { ...state, tipoLocal: action.payload, loading: false, error: null, tipoLocalDeletado: null}
        case TIPO_LOCAL_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, tipoLocalDeletado: null };
        case TIPO_LOCAL_ADD:
            return { ...state, tipoLocal: action.payload, tipoLocalStatus: null, loading: true, error: null, tipoLocalDeletado: null}
        case TIPO_LOCAL_ADD_SUCCESS:
            return { ...state, tipoLocal: action.payload,  tipoLocalStatus: "Passageiro adicionado com sucesso", tipoLocalDeletado: null, loading: false, error: null };
        case TIPO_LOCAL_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TIPO_LOCAL_UPDATE:
            return { ...state, tipoLocal: action.payload, tipoLocalStatus: null, loading: true, error: null}
        case TIPO_LOCAL_UPDATE_SUCCESS:
            return { ...state, tipoLocal: action.payload,  tipoLocalStatus: "Passageiro atualizado com sucesso", tipoLocalDeletado: null, loading: false, error: null };
        case TIPO_LOCAL_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, tipoLocalStatus: null };
        case TIPO_LOCAL_REMOVE:
            return { ...state, tipoLocal: action.payload, tipoLocalStatus: null, loading: true, error: null, tipoLocalDeletado: null}
        case TIPO_LOCAL_REMOVE_SUCCESS:
            return { ...state, tipoLocal: {}, tipoLocalStatus: null,  tipoLocalDeletado: "Passageiro removido com sucesso", loading: false, error: null };
        case TIPO_LOCAL_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, tipoLocalStatus: null, tipoLocalDeletado: null };
        default:
            return { ...state };
    }
};

export default TipoLocalReducer;