import { 
    PARAMETRO_LIST,
    PARAMETRO_LIST_SUCCESS,
    PARAMETRO_LIST_FAILED,
    PARAMETRO_ADD,
    PARAMETRO_ADD_SUCCESS,
    PARAMETRO_ADD_FAILED,
    PARAMETRO_UPDATE,
    PARAMETRO_UPDATE_SUCCESS,
    PARAMETRO_UPDATE_FAILED,
    PARAMETRO_LOAD,
    PARAMETRO_LOAD_SUCCESS,
    PARAMETRO_LOAD_FAILED,
    PARAMETRO_REMOVE,
    PARAMETRO_REMOVE_SUCCESS,
    PARAMETRO_REMOVE_FAILED
} from './constants';

// List
export const parametroList        = (filter) => ({ type: PARAMETRO_LIST, payload: filter });
export const parametroListSuccess = (parametros) => ({ type: PARAMETRO_LIST_SUCCESS, payload: parametros });
export const parametroListFailed  = (error) => ({ type: PARAMETRO_LIST_FAILED, payload: error  });

export const parametroLoad        = (id) => ({ type: PARAMETRO_LOAD, payload: id });
export const parametroLoadSuccess  = (parametro) => ({ type: PARAMETRO_LOAD_SUCCESS, payload: parametro });
export const parametroLoadFailed   = (error) => ({ type: PARAMETRO_LOAD_FAILED, payload: error });

// Add
export const parametroAdd         = (parametro) => ({ type: PARAMETRO_ADD, payload: parametro });
export const parametroAddSuccess  = (parametro, parametroStatus) => ({ type: PARAMETRO_ADD_SUCCESS, parametroStatus: parametroStatus, payload: parametro });
export const parametroAddFailed   = (error) => ({ type: PARAMETRO_ADD_FAILED, payload: error });

// Update
export const parametroUpdate         = (parametro) => ({ type: PARAMETRO_UPDATE, payload: parametro });
export const parametroUpdateSuccess  = (parametro, parametroStatus) => ({ type: PARAMETRO_UPDATE_SUCCESS, parametroStatus: parametroStatus, payload: parametro });
export const parametroUpdateFailed   = (error) => ({ type: PARAMETRO_UPDATE_FAILED, payload: error });

// remove
export const parametroRemove         = (parametro) => ({ type: PARAMETRO_REMOVE, payload: parametro });
export const parametroRemoveSuccess  = (parametro, parametroDeletado) => ({ type: PARAMETRO_REMOVE_SUCCESS, parametroDeletado: parametroDeletado, payload: parametro });
export const parametroRemoveFailed   = (error) => ({ type: PARAMETRO_REMOVE_FAILED, payload: error });