/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import {
    SectionRegistrations_Container,
    SectionRegistrations_Label,
    SectionRegistrations_Items,
} from '../../navbar-menu';
import { URL_PORTAL_V2 } from '../../../../helpers/url';
// -> Icon <--------------------------------------------------------------------------------------

const IconOrder = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 13.3333V6.66668H6.655C5.74167 6.66668 5 7.40834 5 8.32168V35.0117C5.00349 35.4494 5.17903 35.8698 5.4887 36.1792C5.79836 36.4885 6.2173 36.6636 6.655 36.6667H20.425C18.9538 34.5558 18.2217 32.0178 18.3426 29.4477C18.4635 26.8776 19.4306 24.4195 21.0935 22.4561C22.7563 20.4926 25.0215 19.1339 27.5367 18.5915C30.0518 18.049 32.6757 18.3532 35 19.4567V8.32168C35 7.40834 34.2583 6.66668 33.345 6.66668H30V13.3333H10ZM11.6667 28.3333H15V31.6667H11.6667V28.3333ZM11.6667 23.3333H15V26.6667H11.6667V23.3333ZM11.6667 18.3333H15V21.6667H11.6667V18.3333Z"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.6667 10V3.33334H13.3333V10H26.6667Z" />
            <path d="M30 38.3333C25.3975 38.3333 21.6667 34.6025 21.6667 30C21.6667 25.3975 25.3975 21.6667 30 21.6667C34.6025 21.6667 38.3333 25.3975 38.3333 30C38.3333 34.6025 34.6025 38.3333 30 38.3333ZM29.1667 29.1667H25.8333V30.8333H29.1667V34.1667H30.8333V30.8333H34.1667V29.1667H30.8333V25.8333H29.1667V29.1667Z" />
        </svg>
    );
};

const IconCancelCause = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29 39C27.1435 39 25.363 38.2625 24.0503 36.9497C22.7375 35.637 22 33.8565 22 32C22 30.1435 22.7375 28.363 24.0503 27.0503C25.363 25.7375 27.1435 25 29 25C30.8565 25 32.637 25.7375 33.9497 27.0503C35.2625 28.363 36 30.1435 36 32C36 33.8565 35.2625 35.637 33.9497 36.9497C32.637 38.2625 30.8565 39 29 39ZM27.1898 35.7912C27.9737 36.1663 28.8547 36.289 29.7113 36.1423C30.5679 35.9956 31.3579 35.5869 31.9724 34.9724C32.5869 34.3579 32.9956 33.5679 33.1423 32.7113C33.289 31.8547 33.1663 30.9737 32.7912 30.1898L27.1898 35.7912ZM25.2088 33.8102L30.8102 28.2102C30.0263 27.8351 29.1453 27.7124 28.2887 27.8591C27.4321 28.0058 26.6421 28.4145 26.0276 29.029C25.4131 29.6435 25.0044 30.4335 24.8577 31.2901C24.711 32.1467 24.8337 33.0277 25.2088 33.8116V33.8102Z"
                fill="#F0F2F4"
            />
            <path
                d="M4.20695 18.2098L11.5299 29.6898C11.794 29.9524 12.1521 30.0999 12.5256 30.1H18.3806C19.1978 24.8321 23.753 20.8 29.25 20.8C29.5871 20.8 29.9206 20.8152 30.25 20.8448V7.875C30.25 6.70139 29.2986 5.75 28.125 5.75C26.9514 5.75 26 6.70139 26 7.875V14.875L25.5 14.875V5C25.5 3.75736 24.4926 2.75 23.25 2.75C22.0074 2.75 21 3.75736 21 5V14.125H20.5V4.30017C20.5 3.02982 19.4702 2 18.1998 2C16.9199 2 15.8861 3.04484 15.8998 4.3247L16 13.7189H15.5L15.5 6.716C15.5 5.63021 14.6198 4.75 13.534 4.75C12.422 4.75 11.5313 5.67148 11.5691 6.78282L11.9426 17.7643V18.25H13.75C16.5114 18.25 18.75 20.4886 18.75 23.25C18.75 23.8023 18.3023 24.25 17.75 24.25H17.5483C17.1074 24.25 16.75 23.8926 16.75 23.4517C16.75 21.2471 14.9629 19.46 12.7583 19.46H11.9426L7.25303 15.3258C6.84878 15.2234 6.42308 15.2423 6.02961 15.38C5.63613 15.5178 5.29251 15.7683 5.04205 16.1L4.14076 17.2942C4.03899 17.4291 3.98965 17.5961 4.00181 17.7643C4.01397 17.9325 4.08682 18.0907 4.20695 18.2098Z"
                fill="#F0F2F4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3508 32.9V37.1C13.3508 37.4713 13.4992 37.8274 13.7633 38.09C14.0274 38.3525 14.3856 38.5 14.7591 38.5H20.4492C19.2325 36.9179 18.5124 34.9954 18.3043 32.9H13.3508Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const IconRejectionReason = () => {
    return (
        <svg width="33" height="37" viewBox="0 0 33 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.206952 16.2098L7.52993 27.6898C7.79397 27.9524 8.15211 28.0999 8.52558 28.1H14.3806C15.1978 22.8321 19.753 18.8 25.25 18.8C25.5871 18.8 25.9206 18.8152 26.25 18.8448V5.875C26.25 4.70139 25.2986 3.75 24.125 3.75C22.9514 3.75 22 4.70139 22 5.875V12.875L21.5 12.875V3C21.5 1.75736 20.4926 0.75 19.25 0.75C18.0074 0.75 17 1.75736 17 3V12.125H16.5V2.30017C16.5 1.02982 15.4702 -5.55286e-08 14.1998 0C12.9199 5.59479e-08 11.8861 1.04484 11.8998 2.3247L12 11.7189H11.5L11.5 4.716C11.5 3.63021 10.6198 2.75 9.534 2.75C8.42201 2.75 7.53134 3.67148 7.56913 4.78282L7.94255 15.7643V16.25H9.75C12.5114 16.25 14.75 18.4886 14.75 21.25C14.75 21.8023 14.3023 22.25 13.75 22.25H13.5483C13.1074 22.25 12.75 21.8926 12.75 21.4517C12.75 19.2471 10.9629 17.46 8.75834 17.46H7.94255L3.25303 13.3258C2.84878 13.2234 2.42308 13.2423 2.02961 13.38C1.63613 13.5178 1.29251 13.7683 1.04205 14.1L0.140763 15.2942C0.0389933 15.4291 -0.0103485 15.5961 0.00181224 15.7643C0.0139729 15.9325 0.0868184 16.0907 0.206952 16.2098Z"
                fill="#F0F2F4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.35082 30.9V35.1C9.35082 35.4713 9.49919 35.8274 9.76329 36.09C10.0274 36.3525 10.3856 36.5 10.7591 36.5H16.4492C15.2325 34.9179 14.5124 32.9954 14.3043 30.9H9.35082Z"
                fill="#F0F2F4"
            />
            <path
                d="M25.25 36.8C21.3839 36.8 18.25 33.6661 18.25 29.8C18.25 25.9339 21.3839 22.8 25.25 22.8C29.1161 22.8 32.25 25.9339 32.25 29.8C32.25 33.6661 29.1161 36.8 25.25 36.8ZM25.25 28.8102L23.2704 26.8299L22.2799 27.8204L24.2602 29.8L22.2799 31.7796L23.2704 32.7701L25.25 30.7898L27.2296 32.7701L28.2201 31.7796L26.2398 29.8L28.2201 27.8204L27.2296 26.8299L25.25 28.8102Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const IconEvaluationReasons = () => {
    return (
        <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.333252 32.5L7.75825 26.6667H31.9999C32.4419 26.6667 32.8659 26.4911 33.1784 26.1785C33.491 25.866 33.6666 25.442 33.6666 25V1.66667C33.6666 1.22464 33.491 0.800716 33.1784 0.488155C32.8659 0.175595 32.4419 0 31.9999 0H1.99992C1.55789 0 1.13397 0.175595 0.821407 0.488155C0.508847 0.800716 0.333252 1.22464 0.333252 1.66667V32.5ZM11.8501 20L16.9999 17.272L22.1497 20L20.9997 14.5218L25.3333 10.7297L19.4722 10.0719L16.9999 5L14.5276 10.0719L8.66658 10.7297L13.0001 14.5218L11.8501 20Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const SectionRegistrations = () => {
    return (
        <SectionRegistrations_Container>
            <SectionRegistrations_Label>Cadastros</SectionRegistrations_Label>
            <SectionRegistrations_Items>
                <ItemWithIcon
                    link="/corrida/newsolicitacao/adicionar"
                    label="Abertura de OS"
                    aLink={true}
                    disable={false}>
                    <IconOrder />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/work-order-panel/_registrations/cancellation-reason`}
                    label="Motivo Cancelamento"
                    disable={false}>
                    <IconCancelCause />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/work-order-panel/_registrations/rejection-reason`}
                    label="Motivo Rejeição"
                    disable={false}>
                    <IconRejectionReason />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/work-order-panel/_registrations/evaluation-reasons`}
                    label="Motivo de Avaliação"
                    disable={false}>
                    <IconEvaluationReasons />
                </ItemWithIcon>
            </SectionRegistrations_Items>
        </SectionRegistrations_Container>
    );
};

export default SectionRegistrations;
