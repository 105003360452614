import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../../helpers/api';
import { getLoggedInUser } from '../../../helpers/authUtils';
import {
    NEW_SOLICITACAO_CORRIDA_ADD,
    NEW_SOLICITACAO_CORRIDA_LIST,
    NEW_SOLICITACAO_CORRIDA_LOAD,
    NEW_SOLICITACAO_CORRIDA_UPDATE,
    NEW_SOLICITACAO_CORRIDA_REMOVE,
    NEW_SOLICITACAO_CORRIDA_APROVAR,
    NEW_SOLICITACAO_CORRIDA_LIBERAR,
    NEW_SOLICITACAO_CORRIDA_REJEITAR,
    NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR,
    EVENTOS_SOLICITACAO,
    NEW_SOLICITACAO_CORRIDA_REENVIAR,
    NEW_SOLICITACAO_CORRIDA_REABRIR,
    NEW_SOLICITACAO_CORRIDA_FINALIZAR,
    NEW_SOLICITACAO_CORRIDA_CANCELAR,
    NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO,
    NEW_SOLICITACAO_CORRIDA_EM_FILA,
    NEW_SOLICITACAO_CORRIDA_ENCERRAR,
} from './constants';
import {
    eventoNewSolicitacaoFailed,
    eventoNewSolicitacaoSuccess,
    newSolicitacaoCorridaAprovarFailed,
    newSolicitacaoCorridaAprovarSuccess,
    newSolicitacaoCorridaCancelarFailed,
    newSolicitacaoCorridaCancelarSuccess,
    newSolicitacaoCorridaSolicitarCancelamentoSuccess,
    newSolicitacaoCorridaSolicitarCancelamentoFailed,
    newSolicitacaoCorridaEmFilaFailed,
    newSolicitacaoCorridaEmFilaSuccess,
    newSolicitacaoCorridaEncerrarFailed,
    newSolicitacaoCorridaEncerrarSuccess,
    newSolicitacaoCorridaFailed,
    newSolicitacaoCorridaFinalizarFailed,
    newSolicitacaoCorridaFinalizarSuccess,
    newSolicitacaoCorridaLiberarFailed,
    newSolicitacaoCorridaLiberarSuccess,
    newSolicitacaoCorridaListFailed,
    newSolicitacaoCorridaListSuccess,
    newSolicitacaoCorridaLoadFailed,
    newSolicitacaoCorridaLoadSuccess,
    newSolicitacaoCorridaReabrirFailed,
    newSolicitacaoCorridaReabrirSuccess,
    newSolicitacaoCorridaRejeitarFailed,
    newSolicitacaoCorridaRejeitarSuccess,
    newSolicitacaoCorridaRejeitarEncerrarFailed,
    newSolicitacaoCorridaRejeitarEncerrarSuccess,
    newSolicitacaoCorridaRemoveFailed,
    newSolicitacaoCorridaRemoveSuccess,
    newSolicitacaoCorridaSuccess,
    newSolicitacaoCorridaUpdateFailed,
    newSolicitacaoCorridaUpdateSuccess,
} from './actions';

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* add({ payload: solicitacao }) {
    const options = {
        body: JSON.stringify(solicitacao),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoCorridaNova', options);
        yield put(
            newSolicitacaoCorridaSuccess(response, 'Solicitação de Corrida gerada com sucesso. Nº ' + response.codigo)
        );
    } catch (error) {
        yield put(newSolicitacaoCorridaFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* List({ payload: filter }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(filter),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoCorridaNova/filter', options);
        yield put(newSolicitacaoCorridaListSuccess(response));
    } catch (error) {
        yield put(newSolicitacaoCorridaListFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Load({ payload: solicitacaoId }) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaLoadSuccess(response));
    } catch (error) {
        yield put(newSolicitacaoCorridaLoadFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Update({ payload: solicitacao }) {
    const options = {
        body: JSON.stringify(solicitacao),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/${solicitacao?.id}`, options);
        yield put(newSolicitacaoCorridaUpdateSuccess(response, response.mensagem));
        yield call(Load, { payload: solicitacao?.id });
    } catch (error) {
        yield put(newSolicitacaoCorridaUpdateFailed(error.message));
        yield call(Load, { payload: solicitacao?.id });
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Remove({ payload: solicitacaoId }) {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaRemoveSuccess(response, 'Solicitação removida com sucesso'));
    } catch (error) {
        yield put(newSolicitacaoCorridaRemoveFailed(error.message));
    }
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Aprovar({ payload: { solicitacaoId, aprovacao } }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(aprovacao),
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/aprovar/${solicitacaoId}`, options);
        yield put(
            newSolicitacaoCorridaAprovarSuccess(response, `Solicitação ${aprovacao.codigo} aprovada com sucesso`)
        );
    } catch (error) {
        yield put(newSolicitacaoCorridaAprovarFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Liberar({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/aprovarliberacao/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaLiberarSuccess(response, 'Solicitação liberada com sucesso'));
    } catch (error) {
        yield put(newSolicitacaoCorridaLiberarFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* Rejeitar({ payload: { solicitacaoId, motivo } }) {
    const options = {
        body: JSON.stringify(motivo),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/rejeitar/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaRejeitarSuccess(response, 'Solicitação rejeitada'));
    } catch (error) {
        yield put(newSolicitacaoCorridaRejeitarFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* RejeitarEncerrar({ payload: { solicitacaoId, motivo } }) {
    const options = {
        body: JSON.stringify(motivo),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(
            fetchJSON,
            `/central/v1/api/v1/solicitacaoCorridaNova/rejeitarAutomatico/${solicitacaoId}`,
            options
        );
        yield put(newSolicitacaoCorridaRejeitarEncerrarSuccess(response, 'Solicitação rejeitada e encerrada'));
    } catch (error) {
        yield put(newSolicitacaoCorridaRejeitarEncerrarFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* EventosSolicitacao({ payload: solicitacaoId }) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/eventos/${solicitacaoId}`, options);
        yield put(eventoNewSolicitacaoSuccess(response));
    } catch (error) {
        yield put(eventoNewSolicitacaoFailed(error.message));
    }
}

/**
 * Muda o status da solicitação de encerrada para reaberta.
 * @param {solicitacaoId} payload
 */
function* Reabrir({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const solicitacao = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/reabrir/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaReabrirSuccess(solicitacao, `Solicitação reaberta com sucesso`));
    } catch (error) {
        yield put(newSolicitacaoCorridaReabrirFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Atualiza as informações da corrida e gera uma nova corrida na integração
 * @param {solicitacaoId} payload
 */
function* Reenviar({ payload: solicitacao }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(solicitacao),
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/reenviar/${solicitacao?.id}`, options);
        yield put(newSolicitacaoCorridaReabrirSuccess(response, `Solicitação reaberta com sucesso`));
    } catch (error) {
        yield put(newSolicitacaoCorridaReabrirFailed(error.message));
    }
    yield call(Load, { payload: solicitacao?.id });
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* finalizar({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/finalizar/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaFinalizarSuccess(response));
    } catch (error) {
        yield put(newSolicitacaoCorridaFinalizarFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* cancelar({ payload: { solicitacaoId, motivo } }) {
    const options = {
        body: JSON.stringify(motivo),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/cancelar/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaCancelarSuccess(response));
    } catch (error) {
        yield put(newSolicitacaoCorridaCancelarFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* solicitarCancelamento({ payload: { solicitacaoId, motivo } }) {
    const options = {
        body: JSON.stringify(motivo),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/solicitarCancelamento/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaSolicitarCancelamentoSuccess(response));
    } catch (error) {
        yield put(newSolicitacaoCorridaSolicitarCancelamentoFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Gera uma nova solicitação de corrida.
 * @param {solicitacao} payload
 */
function* EmFila({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/emfila/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaEmFilaSuccess(response, 'Solicitação colocada em fila'));
    } catch (error) {
        yield put(newSolicitacaoCorridaEmFilaFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* encerrar({ payload: solicitacaoId }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/solicitacaoCorridaNova/encerrar/${solicitacaoId}`, options);
        yield put(newSolicitacaoCorridaEncerrarSuccess(response));
    } catch (error) {
        yield put(newSolicitacaoCorridaEncerrarFailed(error.message));
    }
    yield call(Load, { payload: solicitacaoId });
}

export function* watchNewAdd() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_ADD, add);
}

export function* wahtchNewList() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_LIST, List);
}

export function* wahtchNewLoad() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_LOAD, Load);
}

export function* wahtchNewUpdate() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_UPDATE, Update);
}

export function* wahtchNewRemove() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_REMOVE, Remove);
}

export function* wahtchNewAprovar() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_APROVAR, Aprovar);
}

export function* wahtchNewLiberar() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_LIBERAR, Liberar);
}

export function* watchNewRejeitar() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_REJEITAR, Rejeitar);
}

export function* watchNewRejeitarEncerrar() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR, RejeitarEncerrar);
}

export function* watchEventosSolicitacao() {
    yield takeEvery(EVENTOS_SOLICITACAO, EventosSolicitacao);
}

export function* watchNewSolicitacaoCorridaReabrir() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_REABRIR, Reabrir);
}

export function* watchNewSolicitacaoCorridaReenviar() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_REENVIAR, Reenviar);
}

export function* watchNewSolicitacaoCorridaFinalizar() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_FINALIZAR, finalizar);
}

export function* watchNewSolicitacaoCorridaCancelar() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_CANCELAR, cancelar);
}

export function* watchNewSolicitacaoCorridaSolicitarCancelamento() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO, solicitarCancelamento);
}

export function* watchNewSolicitacaoCorridaEmFila() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_EM_FILA, EmFila);
}

export function* watchSolicitacaoCorridaEncerra() {
    yield takeEvery(NEW_SOLICITACAO_CORRIDA_ENCERRAR, encerrar);
}

function* SolicitacaoCorridaSaga() {
    yield all([
        fork(watchNewAdd),
        fork(wahtchNewList),
        fork(wahtchNewLoad),
        fork(wahtchNewUpdate),
        fork(wahtchNewRemove),
        fork(wahtchNewAprovar),
        fork(wahtchNewLiberar),
        fork(watchNewRejeitar),
        fork(watchNewRejeitarEncerrar),
        fork(watchEventosSolicitacao),
        fork(watchNewSolicitacaoCorridaReabrir),
        fork(watchNewSolicitacaoCorridaReenviar),
        fork(watchNewSolicitacaoCorridaFinalizar),
        fork(watchNewSolicitacaoCorridaCancelar),
        fork(watchNewSolicitacaoCorridaSolicitarCancelamento),
        fork(watchNewSolicitacaoCorridaEmFila),
        fork(watchSolicitacaoCorridaEncerra),
    ]);
}

export default SolicitacaoCorridaSaga;
