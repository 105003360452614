import { 
    MOTORISTA_LIST,
    MOTORISTA_LIST_SUCCESS,
    MOTORISTA_LIST_FAILED,
    MOTORISTA_ADD,
    MOTORISTA_ADD_SUCCESS,
    MOTORISTA_ADD_FAILED,
    MOTORISTA_UPDATE,
    MOTORISTA_UPDATE_SUCCESS,
    MOTORISTA_UPDATE_FAILED,
    MOTORISTA_LOAD,
    MOTORISTA_LOAD_SUCCESS,
    MOTORISTA_LOAD_FAILED,
    MOTORISTA_REMOVE,
    MOTORISTA_REMOVE_SUCCESS,
    MOTORISTA_REMOVE_FAILED,
    MOTORISTA_LIST_DOCUMENTOS,
    MOTORISTA_LIST_DOCUMENTOS_SUCCESS,
    MOTORISTA_LIST_DOCUMENTOS_FAILED
} from './constants';

// List
export const motoristaList        = (filter) => ({ type: MOTORISTA_LIST, payload: filter });
export const motoristaListSuccess = (motoristas) => ({ type: MOTORISTA_LIST_SUCCESS, payload: motoristas });
export const motoristaListFailed  = (error) => ({ type: MOTORISTA_LIST_FAILED, payload: error });

export const motoristaLoad        = (id) => ({ type: MOTORISTA_LOAD, payload: id });
export const motoristaLoadSuccess  = (motorista) => ({ type: MOTORISTA_LOAD_SUCCESS, payload: motorista });
export const motoristaLoadFailed   = (error) => ({ type: MOTORISTA_LOAD_FAILED, payload: error });

// Add
export const motoristaAdd         = (motorista) => ({ type: MOTORISTA_ADD, payload: motorista });
export const motoristaAddSuccess  = (motorista, motoristaStatus) => ({ type: MOTORISTA_ADD_SUCCESS, motoristaStatus: motoristaStatus, payload: motorista });
export const motoristaAddFailed   = (error) => ({ type: MOTORISTA_ADD_FAILED, payload: error });

// Update
export const motoristaUpdate         = (motorista) => ({ type: MOTORISTA_UPDATE, payload: motorista });
export const motoristaUpdateSuccess  = (motorista, motoristaStatus) => ({ type: MOTORISTA_UPDATE_SUCCESS, motoristaStatus: motoristaStatus, payload: motorista });
export const motoristaUpdateFailed   = (error) => ({ type: MOTORISTA_UPDATE_FAILED, payload: error });

// remove
export const motoristaRemove         = (motorista) => ({ type: MOTORISTA_REMOVE, payload: motorista });
export const motoristaRemoveSuccess  = (motorista, motoristaDeletado) => ({ type: MOTORISTA_REMOVE_SUCCESS, motoristaDeletado: motoristaDeletado, payload: motorista });
export const motoristaRemoveFailed   = (error) => ({ type: MOTORISTA_REMOVE_FAILED, payload: error });

// List
export const motoristaListDocumentos        = (filter) => ({ type: MOTORISTA_LIST_DOCUMENTOS, payload: filter });
export const motoristaListDocumentosSuccess = (motoristas) => ({ type: MOTORISTA_LIST_DOCUMENTOS_SUCCESS, payload: motoristas });
export const motoristaListDocumentosFailed  = (error) => ({ type: MOTORISTA_LIST_DOCUMENTOS_FAILED, payload: error });