import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { GRUPOACESSO_LOAD, GRUPOACESSO_LIST, GRUPOACESSO_ADD, GRUPOACESSO_UPDATE, GRUPOACESSO_REMOVE } from './constants';

import {
    grupoAcessoListSuccess,
    grupoAcessoListFailed,
    grupoAcessoAddSuccess,
    grupoAcessoAddFailed,
    grupoAcessoUpdateSuccess,
    grupoAcessoUpdateFailed,
    grupoAcessoLoadSuccess,
    grupoAcessoLoadFailed,
    grupoAcessoRemoveSuccess,
    grupoAcessoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoAcessoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/grupoacesso/' + id, options);
        yield put(grupoAcessoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(grupoAcessoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoAcessoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/admin/grupoacesso', options);
            yield put(grupoAcessoListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(grupoAcessoListFailed(message));
        }    
}


/**
 * grupoAcesso
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoAcessoAdd({ payload: { grupoAcesso }  }) {

    const options = {
        body: JSON.stringify(grupoAcesso) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/grupoacesso', options);
        yield put(grupoAcessoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(grupoAcessoAddFailed(message));
    }    
}

/**
 * grupoAcesso
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoAcessoUpdate({ payload: { grupoAcesso }  }) {

    const options = {
        body: JSON.stringify(grupoAcesso) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/grupoacesso/' + grupoAcesso?.id, options);
        yield put(grupoAcessoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(grupoAcessoUpdateFailed(message));
    }    
}

/**
 * grupoAcesso
 * @param {*} payload - pageNumber and pageSize
 */
function* grupoAcessoRemove({ payload: { grupoAcesso }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/admin/grupoacesso/' + grupoAcesso?.id, options);
        yield put(grupoAcessoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(grupoAcessoRemoveFailed(message));
    }    
}

export function* watchGrupoAcessoLoad(): any{
    yield takeEvery(GRUPOACESSO_LOAD, grupoAcessoLoad);
}

export function* watchGrupoAcessoList(): any {
    yield takeEvery(GRUPOACESSO_LIST, grupoAcessoList);
}

export function* watchGrupoAcessoAdd(): any {
    yield takeEvery(GRUPOACESSO_ADD, grupoAcessoAdd);
}

export function* watchGrupoAcessoUpdate(): any {
    yield takeEvery(GRUPOACESSO_UPDATE, grupoAcessoUpdate);
}

export function* watchGrupoAcessoRemove(): any {
    yield takeEvery(GRUPOACESSO_REMOVE, grupoAcessoRemove);
}

function* grupoAcesso(): any {
    yield all([fork(watchGrupoAcessoList), fork(watchGrupoAcessoAdd), fork(watchGrupoAcessoUpdate), fork(watchGrupoAcessoLoad), fork(watchGrupoAcessoRemove)]);
}

export default grupoAcesso;