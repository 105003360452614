
import { ESTACAO_LOAD, ESTACAO_LOAD_SUCCESS, ESTACAO_LOAD_FAILED, ESTACAO_LIST, ESTACAO_LIST_SUCCESS, ESTACAO_LIST_FAILED, ESTACAO_ADD, ESTACAO_ADD_FAILED, ESTACAO_ADD_SUCCESS, ESTACAO_REMOVE, ESTACAO_REMOVE_FAILED, ESTACAO_REMOVE_SUCCESS, ESTACAO_UPDATE, ESTACAO_UPDATE_SUCCESS, ESTACAO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    estacoes: {},
    estacao: {},
    loading: false,
    error: null,
    estacaoStatus: null,
    id: {}
};

const EstacaoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ESTACAO_LIST:
            return { ...state, loading: true, error: null, estacaoDeletado: null };
        case ESTACAO_LIST_SUCCESS:
            return { ...state, estacoes: action.payload, loading: false, error: null, estacaoDeletado: null };
        case ESTACAO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, estacaoDeletado: null };
        case ESTACAO_LOAD:
            return { ...state, loading: true, error: null, estacaoStatus: null, estacaoDeletado: null }
        case ESTACAO_LOAD_SUCCESS:
            return { ...state, estacao: action.payload, loading: false, error: null, estacaoDeletado: null}
        case ESTACAO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, estacaoDeletado: null };
        case ESTACAO_ADD:
            return { ...state, estacao: action.payload, estacaoStatus: null, loading: true, error: null, estacaoDeletado: null}
        case ESTACAO_ADD_SUCCESS:
            return { ...state, estacao: action.payload,  estacaoStatus: "Estação criada com sucesso", estacaoDeletado: null, loading: false, error: null };
        case ESTACAO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ESTACAO_UPDATE:
            return { ...state, estacao: action.payload, estacaoStatus: null, loading: true, error: null}
        case ESTACAO_UPDATE_SUCCESS:
            return { ...state, estacao: action.payload,  estacaoStatus: "Estação atualizada com sucesso", estacaoDeletado: null, loading: false, error: null };
        case ESTACAO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, estacaoStatus: null };
        case ESTACAO_REMOVE:
            return { ...state, estacao: action.payload, estacaoStatus: null, loading: true, error: null, estacaoDeletado: null}
        case ESTACAO_REMOVE_SUCCESS:
            return { ...state, estacao: {}, estacaoStatus: null,  estacaoDeletado: "Estação removida com sucesso", loading: false, error: null };
        case ESTACAO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, estacaoStatus: null, estacaoDeletado: null };
        default:
            return { ...state };
    }
};

export default EstacaoReducer;