import { 
    GRUPOVEICULO_LIST,
    GRUPOVEICULO_LIST_SUCCESS,
    GRUPOVEICULO_LIST_FAILED,
    GRUPOVEICULO_ADD,
    GRUPOVEICULO_ADD_SUCCESS,
    GRUPOVEICULO_ADD_FAILED,
    GRUPOVEICULO_UPDATE,
    GRUPOVEICULO_UPDATE_SUCCESS,
    GRUPOVEICULO_UPDATE_FAILED,
    GRUPOVEICULO_LOAD,
    GRUPOVEICULO_LOAD_SUCCESS,
    GRUPOVEICULO_LOAD_FAILED,
    GRUPOVEICULO_REMOVE,
    GRUPOVEICULO_REMOVE_SUCCESS,
    GRUPOVEICULO_REMOVE_FAILED
} from './constants';

// List
export const grupoVeiculoList        = (filter) => ({ type: GRUPOVEICULO_LIST, payload: filter });
export const grupoVeiculoListSuccess = (grupoVeiculos) => ({ type: GRUPOVEICULO_LIST_SUCCESS, payload: grupoVeiculos });
export const grupoVeiculoListFailed  = (error) => ({ type: GRUPOVEICULO_LIST_FAILED, payload: error });

export const grupoVeiculoLoad        = (id) => ({ type: GRUPOVEICULO_LOAD, payload:  id });
export const grupoVeiculoLoadSuccess  = (grupoVeiculo) => ({ type: GRUPOVEICULO_LOAD_SUCCESS, payload: grupoVeiculo });
export const grupoVeiculoLoadFailed   = (error) => ({ type: GRUPOVEICULO_LOAD_FAILED, payload: error });

// Add
export const grupoVeiculoAdd         = (grupoVeiculo) => ({ type: GRUPOVEICULO_ADD, payload: grupoVeiculo });
export const grupoVeiculoAddSuccess  = (grupoVeiculo, grupoVeiculoStatus) => ({ type: GRUPOVEICULO_ADD_SUCCESS, grupoVeiculoStatus: grupoVeiculoStatus, payload: grupoVeiculo });
export const grupoVeiculoAddFailed   = (error) => ({ type: GRUPOVEICULO_ADD_FAILED, payload: error });

// Update
export const grupoVeiculoUpdate         = (grupoVeiculo) => ({ type: GRUPOVEICULO_UPDATE, payload: grupoVeiculo });
export const grupoVeiculoUpdateSuccess  = (grupoVeiculo, grupoVeiculoStatus) => ({ type: GRUPOVEICULO_UPDATE_SUCCESS, grupoVeiculoStatus: grupoVeiculoStatus, payload: grupoVeiculo });
export const grupoVeiculoUpdateFailed   = (error) => ({ type: GRUPOVEICULO_UPDATE_FAILED, payload: error });

// remove
export const grupoVeiculoRemove         = (grupoVeiculo) => ({ type: GRUPOVEICULO_REMOVE, payload: grupoVeiculo });
export const grupoVeiculoRemoveSuccess  = (grupoVeiculo, grupoVeiculoDeletado) => ({ type: GRUPOVEICULO_REMOVE_SUCCESS, grupoVeiculoDeletado: grupoVeiculoDeletado, payload: grupoVeiculo });
export const grupoVeiculoRemoveFailed   = (error) => ({ type: GRUPOVEICULO_REMOVE_FAILED, payload: error });