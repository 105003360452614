import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils';

import { ESTIMATIVA_CORRIDA } from './constants';

import { estimativaCorridaFailed, estimativaCorridaSuccess } from './actions';

function* estimarCorrida({ payload: estimativaCorrida }) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser().token,
        },
        body: JSON.stringify(estimativaCorrida),
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/solicitacaoCorridaNova/estimar', options);
        yield put(estimativaCorridaSuccess(response));
    } catch (error) {
        yield put(estimativaCorridaFailed(error.message));
    }
}

export function* watchEstimativaCorrida(): any {
    yield takeEvery(ESTIMATIVA_CORRIDA, estimarCorrida);
}

function* estimativaCorridaAll(): any {
    yield all([fork(watchEstimativaCorrida)]);
}

export default estimativaCorridaAll;
