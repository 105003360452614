import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { MOTORISTA_LOAD, MOTORISTA_LIST, MOTORISTA_ADD, MOTORISTA_UPDATE, MOTORISTA_REMOVE, MOTORISTA_LIST_DOCUMENTOS } from './constants';

import {
    motoristaListSuccess,
    motoristaListFailed,
    motoristaAddSuccess,
    motoristaAddFailed,
    motoristaUpdateSuccess,
    motoristaUpdateFailed,
    motoristaLoadSuccess,
    motoristaLoadFailed,
    motoristaRemoveSuccess,
    motoristaRemoveFailed,
    motoristaListDocumentosSuccess,
    motoristaListDocumentosFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* motoristaLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/motorista/' + id, options);
        yield put(motoristaLoadSuccess(response));
    } catch (error) {
        yield put(motoristaLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* motoristaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
                const response = yield call(fetchJSON, '/central/v1/api/v1/motorista/filter', options);
                yield put(motoristaListSuccess(response));
        } catch (error) {
            yield put(motoristaListFailed(error.message));
        }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* motoristaListDocumentos({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/motorista/documentos/filter', options);
            yield put(motoristaListDocumentosSuccess(response));
    } catch (error) {
        yield put(motoristaListDocumentosFailed(error.message));
    }    
}

/**
 * motorista
 * @param {*} payload - pageNumber and pageSize
 */
function* motoristaAdd({ payload: motorista  }) {

    const options = {
        body: JSON.stringify(motorista) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/motorista', options);
        yield put(motoristaAddSuccess(response));
        yield call(motoristaLoad, { payload: response?.id });
    } catch (error) {
        yield put(motoristaAddFailed(error.message));
    }    
}

/**
 * motorista
 * @param {*} payload - pageNumber and pageSize
 */
function* motoristaUpdate({ payload: motorista  }) {

    const options = {
        body: JSON.stringify(motorista) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/motorista/' + motorista?.id, options);
        yield put(motoristaUpdateSuccess(response));
        yield call(motoristaLoad, { payload: motorista?.id });
    } catch (error) {
        yield put(motoristaUpdateFailed(error.message));
    }    
}

/**
 * motorista
 * @param {*} payload - pageNumber and pageSize
 */
function* motoristaRemove({ payload: motorista  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/motorista/' + motorista?.id, options);
        yield put(motoristaRemoveSuccess({}));
    } catch (error) {
        yield put(motoristaRemoveFailed(error.message));
    }    
}

export function* watchmotoristaLoad(): any{
    yield takeEvery(MOTORISTA_LOAD, motoristaLoad);
}

export function* watchmotoristaList(): any {
    yield takeEvery(MOTORISTA_LIST, motoristaList);
}

export function* watchmotoristaAdd(): any {
    yield takeEvery(MOTORISTA_ADD, motoristaAdd);
}

export function* watchmotoristaUpdate(): any {
    yield takeEvery(MOTORISTA_UPDATE, motoristaUpdate);
}

export function* watchmotoristaRemove(): any {
    yield takeEvery(MOTORISTA_REMOVE, motoristaRemove);
}


export function* watchMotoristaListDocumentos(): any {
    yield takeEvery(MOTORISTA_LIST_DOCUMENTOS, motoristaListDocumentos);
}


function* motorista(): any {
    yield all([fork(watchmotoristaList), fork(watchmotoristaAdd), fork(watchmotoristaUpdate), fork(watchmotoristaLoad), fork(watchmotoristaRemove), fork(watchMotoristaListDocumentos)]);
}

export default motorista;