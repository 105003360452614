import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { DESPESAAPROVADA_LOAD, DESPESAAPROVADA_LIST, DESPESAAPROVADA_ADD, DESPESAAPROVADA_UPDATE, DESPESAAPROVADA_REMOVE } from './constants';

import {
    despesaAprovadaListSuccess,
    despesaAprovadaListFailed,
    despesaAprovadaAddSuccess,
    despesaAprovadaAddFailed,
    despesaAprovadaUpdateSuccess,
    despesaAprovadaUpdateFailed,
    despesaAprovadaLoadSuccess,
    despesaAprovadaLoadFailed,
    despesaAprovadaRemoveSuccess,
    despesaAprovadaRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* despesaAprovadaLoad({ payload: id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/despesaaprovada/' + id, options);
        yield put(despesaAprovadaLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(despesaAprovadaLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* despesaAprovadaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
                const response = yield call(fetchJSON, '/central/v1/api/v1/despesaAprovada/filter', options);
                yield put(despesaAprovadaListSuccess(response));
        } catch (error) {
            yield put(despesaAprovadaListFailed(error.message));
        }    
}


/**
 * despesaAprovada
 * @param {*} payload - pageNumber and pageSize
 */
function* despesaAprovadaAdd({ payload: despesaAprovada  }) {

    const options = {
        body: JSON.stringify(despesaAprovada) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/despesaAprovada', options);
        yield put(despesaAprovadaAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(despesaAprovadaAddFailed(message));
    }    
}

/**
 * despesaAprovada
 * @param {*} payload - pageNumber and pageSize
 */
function* despesaAprovadaUpdate({ payload: despesaAprovada  }) {

    const options = {
        body: JSON.stringify(despesaAprovada) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/despesaAprovada/' + despesaAprovada?.id, options);
        yield put(despesaAprovadaUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(despesaAprovadaUpdateFailed(message));
    }    
}

/**
 * despesaAprovada
 * @param {*} payload - pageNumber and pageSize
 */
function* despesaAprovadaRemove({ payload: despesaAprovada  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/despesaAprovada/' + despesaAprovada?.id, options);
        yield put(despesaAprovadaRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(despesaAprovadaRemoveFailed(message));
    }    
}

export function* watchdespesaAprovadaLoad(): any{
    yield takeEvery(DESPESAAPROVADA_LOAD, despesaAprovadaLoad);
}

export function* watchdespesaAprovadaList(): any {
    yield takeEvery(DESPESAAPROVADA_LIST, despesaAprovadaList);
}

export function* watchdespesaAprovadaAdd(): any {
    yield takeEvery(DESPESAAPROVADA_ADD, despesaAprovadaAdd);
}

export function* watchdespesaAprovadaUpdate(): any {
    yield takeEvery(DESPESAAPROVADA_UPDATE, despesaAprovadaUpdate);
}

export function* watchdespesaAprovadaRemove(): any {
    yield takeEvery(DESPESAAPROVADA_REMOVE, despesaAprovadaRemove);
}

function* despesaAprovada(): any {
    yield all([fork(watchdespesaAprovadaList), fork(watchdespesaAprovadaAdd), fork(watchdespesaAprovadaUpdate), fork(watchdespesaAprovadaLoad), fork(watchdespesaAprovadaRemove)]);
}

export default despesaAprovada;