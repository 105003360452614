import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { PARAMETRO_LOAD, PARAMETRO_LIST, PARAMETRO_ADD, PARAMETRO_UPDATE, PARAMETRO_REMOVE } from './constants';

import {
    parametroListSuccess,
    parametroListFailed,
    parametroAddSuccess,
    parametroAddFailed,
    parametroUpdateSuccess,
    parametroUpdateFailed,
    parametroLoadSuccess,
    parametroLoadFailed,
    parametroRemoveSuccess,
    parametroRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* parametroLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers: 
            {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/parametro/' + id, options);
        yield put(parametroLoadSuccess(response));
    } catch (error) {
        yield put(parametroLoadFailed(error.message));
    }    
}

/**
 * Lista parametros
 * @param {*} payload - pageNumber and pageSize
 */
function* parametroList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
                const response = yield call(fetchJSON, '/central/v1/api/v1/admin/parametro/filter', options);
                yield put(parametroListSuccess(response));
        } catch (error) {
            yield put(parametroListFailed(error.message));
        }    
}


/**
 * parametro
 * @param {*} payload - pageNumber and pageSize
 */
function* parametroAdd({ payload: parametro  }) {

    const options = {
        body: JSON.stringify(parametro) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/parametro', options);
        yield put(parametroAddSuccess(response));
    } catch (error) {
        yield put(parametroAddFailed(error.message));
    }    
}

/**
 * parametro
 * @param {*} payload - pageNumber and pageSize
 */
function* parametroUpdate({ payload: parametro  }) {

    const options = {
        body: JSON.stringify(parametro) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/parametro/' + parametro?.id, options);
        yield put(parametroUpdateSuccess(response));
    } catch (error) {
        yield put(parametroUpdateFailed(error.message));
    }    
}

/**
 * parametro
 * @param {*} payload - pageNumber and pageSize
 */
function* parametroRemove({ payload: parametro  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/admin/parametro/' + parametro?.id, options);
        yield put(parametroRemoveSuccess({}));
    } catch (error) {
        yield put(parametroRemoveFailed(error.message));
    }    
}

export function* watchParametroLoad(): any{
    yield takeEvery(PARAMETRO_LOAD, parametroLoad);
}

export function* watchParametroList(): any {
    yield takeEvery(PARAMETRO_LIST, parametroList);
}

export function* watchParametroAdd(): any {
    yield takeEvery(PARAMETRO_ADD, parametroAdd);
}

export function* watchParametroUpdate(): any {
    yield takeEvery(PARAMETRO_UPDATE, parametroUpdate);
}

export function* watchParametroRemove(): any {
    yield takeEvery(PARAMETRO_REMOVE, parametroRemove);
}

function* parametro(): any {
    yield all([fork(watchParametroList), fork(watchParametroAdd), fork(watchParametroUpdate), fork(watchParametroLoad), fork(watchParametroRemove)]);
}

export default parametro;