const gestorClienteMenu = [
    {
        header: 'Painel Administrativo',
        path: '/',
        name: 'Cadastros',
        icon: 'dripicons-document',
        children: [
            { name: 'Passageiros', path: '/passageiro' },
            { name: 'Locais', path: '/local' },
        ],
    },
    {
        header: 'Painel Operacional',
        path: '/corrida/solicitacao',
        name: 'Corridas',
        icon: 'mdi mdi-car-connected',
        children: [
            { path: '/corrida/acompanhamento', name: 'Acompanhamento' },
            { path: '/corrida/historico', name: 'Histórico' },
        ],
    },
    {
        header: 'Painel Faturamento',
        path: '/faturamento/',
        name: 'Faturamento',
        icon: 'uil-calculator-alt',
        children: [{ path: '/faturamento/fatura', name: 'Faturas' }],
    },
    {
        header: 'Painel Administrativo',
        path: '/admin',
        name: 'Cadastros',
        icon: 'dripicons-document',
        children: [
            {
                name: 'Limitador de Corridas',
                path: '/limitadorcorrida',
            },
        ],
    },
];

export const GestorClienteMenu = () => gestorClienteMenu;
