
import { TROCAVEICULO_LOAD, TROCAVEICULO_LOAD_SUCCESS, TROCAVEICULO_LOAD_FAILED, TROCAVEICULO_LIST, TROCAVEICULO_LIST_SUCCESS, TROCAVEICULO_LIST_FAILED, TROCAVEICULO_ADD, TROCAVEICULO_ADD_FAILED, TROCAVEICULO_ADD_SUCCESS, TROCAVEICULO_REMOVE, TROCAVEICULO_REMOVE_FAILED, TROCAVEICULO_REMOVE_SUCCESS, TROCAVEICULO_UPDATE, TROCAVEICULO_UPDATE_SUCCESS, TROCAVEICULO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    trocaVeiculos: {},
    trocaVeiculo: {},
    loading: false,
    error: null,
    trocaVeiculoStatus: null,
    id: {}
};

const TrocaVeiculoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TROCAVEICULO_LIST:
            return { ...state, trocaVeiculo: {}, loading: true, error: null, trocaVeiculoDeletado: null };
        case TROCAVEICULO_LIST_SUCCESS:
            return { ...state, trocaVeiculos: action.payload, loading: false, error: null, trocaVeiculoDeletado: null };
        case TROCAVEICULO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, trocaVeiculoDeletado: null };
        case TROCAVEICULO_LOAD:
            return { ...state, trocaVeiculo: {}, loading: true, error: null, trocaVeiculoStatus: null, trocaVeiculoDeletado: null }
        case TROCAVEICULO_LOAD_SUCCESS:
            return { ...state, trocaVeiculo: action.payload, loading: false, error: null, trocaVeiculoDeletado: null}
        case TROCAVEICULO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, trocaVeiculoDeletado: null };
        case TROCAVEICULO_ADD:
            return { ...state, trocaVeiculo: action.payload, trocaVeiculoStatus: null, loading: true, error: null, trocaVeiculoDeletado: null}
        case TROCAVEICULO_ADD_SUCCESS:
            return { ...state, trocaVeiculo: action.payload,  trocaVeiculoStatus: "Solicitação criada com sucesso", trocaVeiculoDeletado: null, loading: false, error: null };
        case TROCAVEICULO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TROCAVEICULO_UPDATE:
            return { ...state, trocaVeiculo: action.payload, trocaVeiculoStatus: null, loading: true, error: null}
        case TROCAVEICULO_UPDATE_SUCCESS:
            return { ...state, trocaVeiculo: action.payload,  trocaVeiculoStatus: "Solicitação atualizada com sucesso", trocaVeiculoDeletado: null, loading: false, error: null };
        case TROCAVEICULO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, trocaVeiculoStatus: null };
        case TROCAVEICULO_REMOVE:
            return { ...state, trocaVeiculo: action.payload, trocaVeiculoStatus: null, loading: true, error: null, trocaVeiculoDeletado: null}
        case TROCAVEICULO_REMOVE_SUCCESS:
            return { ...state, trocaVeiculo: {}, trocaVeiculoStatus: null,  trocaVeiculoDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case TROCAVEICULO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, trocaVeiculoStatus: null, trocaVeiculoDeletado: null };
        default:
            return { ...state };
    }
};

export default TrocaVeiculoReducer;