import { NOTIFICACAO_NAOLIDAS, NOTIFICACAO_NAOLIDAS_SUCCESS, NOTIFICACAO_NAOLIDAS_FAILED, NOTIFICACAO_LOAD, NOTIFICACAO_LOAD_SUCCESS, NOTIFICACAO_LOAD_FAILED, NOTIFICACAO_LIST, NOTIFICACAO_LIST_SUCCESS, NOTIFICACAO_LIST_FAILED, NOTIFICACAO_ADD, NOTIFICACAO_ADD_FAILED, NOTIFICACAO_ADD_SUCCESS, NOTIFICACAO_REMOVE, NOTIFICACAO_REMOVE_FAILED, NOTIFICACAO_REMOVE_SUCCESS, NOTIFICACAO_UPDATE, NOTIFICACAO_UPDATE_SUCCESS, NOTIFICACAO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    notificacoesNaoLidas: [],
    notificacoes: [],
    notificacao: {},
    loading: false,
    error: null,
    notificacaoStatus: null,
    id: {}
};

const NotificacaoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTIFICACAO_LIST:
            return { ...state, loading: true, error: null, notificacaoDeletado: null, notificacaoStatus: null };
        case NOTIFICACAO_LIST_SUCCESS:
            return { ...state, notificacoes: action.payload, loading: false, error: null, notificacaoDeletado: null, notificacaoStatus: null };
        case NOTIFICACAO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, notificacaoDeletado: null, notificacaoStatus: null };
        case NOTIFICACAO_LOAD:
            return { ...state, loading: true, error: null, notificacaoDeletado: null, notificacaoStatus: null }
        case NOTIFICACAO_LOAD_SUCCESS:
            return { ...state, notificacao: action.payload, loading: false, error: null, notificacaoDeletado: null, notificacaoStatus: null}
        case NOTIFICACAO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, notificacaoDeletado: null, notificacaoStatus: null };
        case NOTIFICACAO_ADD:

            var newN = state.notificacoesNaoLidas;

            if (newN && Array.isArray(newN)){
                newN = {
                    notificacoesNaoLidas: {
                        data: []
                    }
                }
            }

            newN.notificacoesNaoLidas.data = [...newN.notificacoesNaoLidas.data, action.payload.notificacao];

            return { ...state,  notificacoesNaoLidas: newN, notificacao: action.payload, loading: true, error: null, notificacaoDeletado: null, notificacaoStatus: null}
        case NOTIFICACAO_ADD_SUCCESS:
            return { ...state, notificacao: action.payload,  notificacaoStatus: "Usuário adicionado com sucesso", notificacaoDeletado: null, loading: false, error: null };
        case NOTIFICACAO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false, notificacaoStatus: null };
        case NOTIFICACAO_UPDATE:
            return { ...state, notificacao: action.payload, loading: true, error: null, notificacaoStatus: null}
        case NOTIFICACAO_UPDATE_SUCCESS:
            return { ...state, notificacao: action.payload,  notificacaoStatus: "Notificação atualizada com sucesso", notificacaoDeletado: null, loading: false, error: null };
        case NOTIFICACAO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, notificacaoStatus: null };
        case NOTIFICACAO_REMOVE:
            return { ...state, notificacao: action.payload, loading: true, error: null, notificacaoDeletado: null}
        case NOTIFICACAO_REMOVE_SUCCESS:
            return { ...state, notificacao: {}, notificacaoStatus: null,  notificacaoDeletado: "Notificalçai removida com sucesso", loading: false, error: null };
        case NOTIFICACAO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, notificacaoStatus: null, notificacaoDeletado: null };
        case NOTIFICACAO_NAOLIDAS:
            return { ...state, loading: true, error: null, notificacaoDeletado: null, notificacaoStatus: null };
        case NOTIFICACAO_NAOLIDAS_SUCCESS:
            return { ...state, notificacoesNaoLidas: action.payload, loading: false, error: null, notificacaoDeletado: null, notificacaoStatus: null };
        case NOTIFICACAO_NAOLIDAS_FAILED:
            return { ...state, error: action.payload, loading: false, notificacaoDeletado: null, notificacaoStatus: null };
        default:
            return { ...state };
    }
};

export default NotificacaoReducer;