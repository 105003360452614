import React from 'react';
import { PrivateRoute } from './privateRoute';

const CorridaRelatorio = React.lazy(() => import('../../../pages/relatorios/CorridaRelatorio'));
const Relatorios = React.lazy(() => import('../../../pages/relatorios/Relatorios'));

export const relatorioRoute = [
    {
        path: '/relatorios/',
        exact: true,
        roles: ['master', 'financeiro', 'auditor', 'gestorcentral', 'operador', 'gestorcliente'],
        component: Relatorios,
        route: PrivateRoute,
    },
    {
        path: '/relatorios/corridas',
        exact: true,
        roles: ['master', 'financeiro', 'auditor', 'gestorcentral', 'operador', 'gestorcliente'],
        component: CorridaRelatorio,
        route: PrivateRoute,
    },
];
