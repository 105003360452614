/* VEICULO */
export const REEMBOLSO_LIST                = 'REEMBOLSO_LIST';
export const REEMBOLSO_LIST_SUCCESS        = 'REEMBOLSO_LIST_SUCCESS';
export const REEMBOLSO_LIST_FAILED         = 'REEMBOLSO_LIST_FAILED';
export const REEMBOLSO_ADD                 = 'REEMBOLSO_ADD';
export const REEMBOLSO_ADD_SUCCESS         = 'REEMBOLSO_ADD_SUCCESS';
export const REEMBOLSO_ADD_FAILED          = 'REEMBOLSO_ADD_FAILED';
export const REEMBOLSO_UPDATE              = 'REEMBOLSO_UPDATE';
export const REEMBOLSO_UPDATE_SUCCESS      = 'REEMBOLSO_UPDATE_SUCCESS';
export const REEMBOLSO_UPDATE_FAILED       = 'REEMBOLSO_UPDATE_FAILED';
export const REEMBOLSO_REMOVE              = 'REEMBOLSO_REMOVE';
export const REEMBOLSO_REMOVE_SUCCESS      = 'REEMBOLSO_REMOVE_SUCCESS';
export const REEMBOLSO_REMOVE_FAILED       = 'REEMBOLSO_REMOVE_FAILED';
export const REEMBOLSO_LOAD                = 'REEMBOLSO_LOAD'
export const REEMBOLSO_LOAD_SUCCESS        = 'REEMBOLSO_LOAD_SUCCESS';
export const REEMBOLSO_LOAD_FAILED         = 'REEMBOLSO_LOAD_FAILED';
export const REEMBOLSO_UPLOAD              = 'REEMBOLSO_UPLOAD'
export const REEMBOLSO_UPLOAD_SUCCESS      = 'REEMBOLSO_UPLOAD_SUCCESS';
export const REEMBOLSO_UPLOAD_FAILED       = 'REEMBOLSO_UPLOAD_FAILED';
export const REEMBOLSO_DOWNLOAD            = 'REEMBOLSO_DOWNLOAD'
export const REEMBOLSO_DOWNLOAD_SUCCESS    = 'REEMBOLSO_DOWNLOAD_SUCCESS'
export const REEMBOLSO_DOWNLOAD_FAILED     = 'REEMBOLSO_DOWNLOAD_FAILED'
export const REEMBOLSO_REMOVEANEXO         = 'REEMBOLSO_REMOVEANEXO'
export const REEMBOLSO_REMOVEANEXO_SUCCESS = 'REEMBOLSO_REMOVEANEXO_SUCCESS'
export const REEMBOLSO_REMOVEANEXO_FAILED  = 'REEMBOLSO_REMOVEANEXO_FAILED'
