import styled from 'styled-components';
import theme from '../../../../../src/styles/theme';

export const UserProfile_Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const UserProfile_Settings = styled.div`
    width: 100%;
`;

export const UserProfile_SettingsIcon = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    div {
        fill: ${theme.colors.grey};
        width: 16px;
        height: 16px;
    }
`;

export const UserProfile_Info = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
//TODO
//  background-color: ${props => props.theme.colors.grey};
export const UserProfile_InfoPhoto = styled.div`
    background-color: ${theme.colors.grey};
    width: 64px;
    height: 64px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const UserProfile_InfoIconPhoto = styled.div`
    fill: ${theme.colors.dark};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        border-radius: 50px;
    }
`;

export const UserProfile_InfoName = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: ${theme.colors.dark};
    margin: 8px 0px 0px 0px;
`;
export const UserProfile_InfoEmail = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${theme.colors.grey};
`;

export const UserProfile_Logout = styled.div`
    cursor: pointer;
    display: flex;
    gap: 4px;
`;

export const UserProfile_LogoutLabel = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: ${theme.colors.secondary};
`;

export const UserProfile_LogoutIcon = styled.div`
    fill: ${theme.colors.secondary};
    width: 18px;
    height: 18px;
`;
