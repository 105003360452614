/* VEICULO */
export const DESPESAAPROVADA_LIST           = 'DESPESAAPROVADA_LIST';
export const DESPESAAPROVADA_LIST_SUCCESS   = 'DESPESAAPROVADA_LIST_SUCCESS';
export const DESPESAAPROVADA_LIST_FAILED    = 'DESPESAAPROVADA_LIST_FAILED';
export const DESPESAAPROVADA_ADD            = 'DESPESAAPROVADA_ADD';
export const DESPESAAPROVADA_ADD_SUCCESS    = 'DESPESAAPROVADA_ADD_SUCCESS';
export const DESPESAAPROVADA_ADD_FAILED     = 'DESPESAAPROVADA_ADD_FAILED';
export const DESPESAAPROVADA_UPDATE         = 'DESPESAAPROVADA_UPDATE';
export const DESPESAAPROVADA_UPDATE_SUCCESS = 'DESPESAAPROVADA_UPDATE_SUCCESS';
export const DESPESAAPROVADA_UPDATE_FAILED  = 'DESPESAAPROVADA_UPDATE_FAILED';
export const DESPESAAPROVADA_REMOVE         = 'DESPESAAPROVADA_REMOVE';
export const DESPESAAPROVADA_REMOVE_SUCCESS = 'DESPESAAPROVADA_REMOVE_SUCCESS';
export const DESPESAAPROVADA_REMOVE_FAILED  = 'DESPESAAPROVADA_REMOVE_FAILED';
export const DESPESAAPROVADA_LOAD           = 'DESPESAAPROVADA_LOAD'
export const DESPESAAPROVADA_LOAD_SUCCESS   = 'DESPESAAPROVADA_LOAD_SUCCESS';
export const DESPESAAPROVADA_LOAD_FAILED    = 'DESPESAAPROVADA_LOAD_FAILED';