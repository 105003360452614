
import { AJUSTEKM_LOAD, AJUSTEKM_LOAD_SUCCESS, AJUSTEKM_LOAD_FAILED, AJUSTEKM_LIST, AJUSTEKM_LIST_SUCCESS, AJUSTEKM_LIST_FAILED, AJUSTEKM_ADD, AJUSTEKM_ADD_FAILED, AJUSTEKM_ADD_SUCCESS, AJUSTEKM_REMOVE, AJUSTEKM_REMOVE_FAILED, AJUSTEKM_REMOVE_SUCCESS, AJUSTEKM_UPDATE, AJUSTEKM_UPDATE_SUCCESS, AJUSTEKM_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    ajusteKms: {},
    ajusteKm: {},
    loading: false,
    error: null,
    ajusteKmStatus: null,
    id: {}
};

const AjusteKmReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AJUSTEKM_LIST:
            return { ...state, ajusteKm: {}, loading: true, error: null, ajusteKmDeletado: null };
        case AJUSTEKM_LIST_SUCCESS:
            return { ...state, ajusteKms: action.payload, loading: false, error: null, ajusteKmDeletado: null };
        case AJUSTEKM_LIST_FAILED:
            return { ...state, ajusteKm: {}, error: action.payload, loading: false, ajusteKmDeletado: null };
        case AJUSTEKM_LOAD:
            return { ...state, ajusteKm: {}, loading: true, error: null, ajusteKmStatus: null, ajusteKmDeletado: null }
        case AJUSTEKM_LOAD_SUCCESS:
            return { ...state, ajusteKm: action.payload, loading: false, error: null, ajusteKmDeletado: null}
        case AJUSTEKM_LOAD_FAILED:
            return { ...state, ajusteKm: {}, error: action.payload, loading: false, ajusteKmDeletado: null };
        case AJUSTEKM_ADD:
            return { ...state, ajusteKm: action.payload, ajusteKmStatus: null, loading: true, error: null, ajusteKmDeletado: null}
        case AJUSTEKM_ADD_SUCCESS:
            return { ...state, ajusteKm: action.payload,  ajusteKmStatus: "Solicitação criada com sucesso", ajusteKmDeletado: null, loading: false, error: null };
        case AJUSTEKM_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case AJUSTEKM_UPDATE:
            return { ...state, ajusteKm: action.payload, ajusteKmStatus: null, loading: true, error: null}
        case AJUSTEKM_UPDATE_SUCCESS:
            return { ...state, ajusteKm: action.payload,  ajusteKmStatus: "Solicitação atualizada com sucesso", ajusteKmDeletado: null, loading: false, error: null };
        case AJUSTEKM_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, ajusteKmStatus: null };
        case AJUSTEKM_REMOVE:
            return { ...state, ajusteKm: action.payload, ajusteKmStatus: null, loading: true, error: null, ajusteKmDeletado: null}
        case AJUSTEKM_REMOVE_SUCCESS:
            return { ...state, ajusteKm: {}, ajusteKmStatus: null,  ajusteKmDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case AJUSTEKM_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, ajusteKmStatus: null, ajusteKmDeletado: null };
        default:
            return { ...state };
    }
};

export default AjusteKmReducer;