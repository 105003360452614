import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { VEICULO_LOAD, VEICULO_LIST, VEICULO_ADD, VEICULO_UPDATE, VEICULO_REMOVE } from './constants';

import {
    veiculoListSuccess,
    veiculoListFailed,
    veiculoAddSuccess,
    veiculoAddFailed,
    veiculoUpdateSuccess,
    veiculoUpdateFailed,
    veiculoLoadSuccess,
    veiculoLoadFailed,
    veiculoRemoveSuccess,
    veiculoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculo/' + id, options);
        yield put(veiculoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
                const response = yield call(fetchJSON, '/central/v1/api/v1/veiculo/filter', options);
                yield put(veiculoListSuccess(response));
        } catch (error) {
            yield put(veiculoListFailed(error.message));
        }    
}


/**
 * veiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoAdd({ payload:  veiculo   }) {

    const options = {
        body: JSON.stringify(veiculo) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculo', options);
        yield put(veiculoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoAddFailed(message));
    }    
}

/**
 * veiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoUpdate({ payload:  veiculo   }) {

    const options = {
        body: JSON.stringify(veiculo) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculo/' + veiculo?.id, options);
        yield put(veiculoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoUpdateFailed(message));
    }    
}

/**
 * veiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoRemove({ payload:  veiculo   }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/veiculo/' + veiculo?.id, options);
        yield put(veiculoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(veiculoRemoveFailed(message));
    }    
}

export function* watchveiculoLoad(): any{
    yield takeEvery(VEICULO_LOAD, veiculoLoad);
}

export function* watchveiculoList(): any {
    yield takeEvery(VEICULO_LIST, veiculoList);
}

export function* watchveiculoAdd(): any {
    yield takeEvery(VEICULO_ADD, veiculoAdd);
}

export function* watchveiculoUpdate(): any {
    yield takeEvery(VEICULO_UPDATE, veiculoUpdate);
}

export function* watchveiculoRemove(): any {
    yield takeEvery(VEICULO_REMOVE, veiculoRemove);
}

function* veiculo(): any {
    yield all([fork(watchveiculoList), fork(watchveiculoAdd), fork(watchveiculoUpdate), fork(watchveiculoLoad), fork(watchveiculoRemove)]);
}

export default veiculo;