import { URL_PORTAL_V2 } from '../../../helpers/url';

const adminMenu = [
    {
        header: 'Painel Administrativo',
        path: '/admin',
        name: 'Cadastros',
        icon: 'dripicons-document',
        children: [
            {
                name: 'Usuários',
                path: '/admin/usuario',
            },
            {
                name: 'Organizações local',
                path: '/admin/dominio',
            },
            {
                name: 'Organizações Identity',
                path: `${URL_PORTAL_V2}/contracts-panel/_registrations/organizations`,
            },
            {
                name: 'Empresas',
                path: '/admin/empresa',
            },
            {
                name: 'Tarifas',
                path: '/tarifa',
            },
            {
                name: 'Parâmetros',
                path: '/admin/parametro',
            },
            {
                name: 'Limitador de Corridas',
                path: '/limitadorcorrida',
            },
            {
                name: 'Feriado',
                path: '/feriado',
            },
            {
                name: 'Tipo de Operação',
                path: '/tipooperacao',
            },
            {
                name: 'Turnos',
                path: '/turno',
            },
            // {
            //     name: 'Blocking-reasons',
            //     path: `${URL_PORTAL_V2}/drivers-panel/_registrations/reason/block`,
            // },
            {
                name: 'Rate-Identity',
                path: `${URL_PORTAL_V2}/financial-panel/_registrations/rate`,
            },
            {
                name: 'SLA',
                path: `${URL_PORTAL_V2}/contracts-panel/_registrations/sla`,
            },
            {
                name: 'Notification-alert',
                path: `${URL_PORTAL_V2}/control-panel/_registrations/notification-alert`,
            },
            {
                name: 'Cancellation-reason',
                path: `${URL_PORTAL_V2}/work-order-panel/_registrations/cancellation-reason`,
            },
            {
                name: 'Rejection-reason',
                path: `${URL_PORTAL_V2}/work-order-panel/_registrations/rejection-reason`,
            },
            {
                name: 'Evaluation-reasons',
                path: `${URL_PORTAL_V2}/work-order-panel/_registrations/evaluation-reasons`,
            },
            {
                name: 'Person',
                path: `${URL_PORTAL_V2}/global-registers/_registrations/person`,
            },
            {
                name: 'Profiles',
                path: `${URL_PORTAL_V2}/global-registers/_registrations/profiles`,
            },
            {
                name: 'Permissions',
                path: `${URL_PORTAL_V2}/global-registers/_registrations/permissions`,
            },
            {
                name: 'Category',
                path: `${URL_PORTAL_V2}/control-panel/_registrations/category`,
            },
        ],
    },
];

export const AdminMenu = () => adminMenu;
