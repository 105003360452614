
import { 
    REEMBOLSO_LOAD, 
    REEMBOLSO_LOAD_SUCCESS, 
    REEMBOLSO_LOAD_FAILED, 
    REEMBOLSO_LIST, 
    REEMBOLSO_LIST_SUCCESS, 
    REEMBOLSO_LIST_FAILED, 
    REEMBOLSO_ADD, 
    REEMBOLSO_ADD_FAILED,
    REEMBOLSO_ADD_SUCCESS, 
    REEMBOLSO_REMOVE, 
    REEMBOLSO_REMOVE_FAILED, 
    REEMBOLSO_REMOVE_SUCCESS, 
    REEMBOLSO_UPDATE, 
    REEMBOLSO_UPDATE_SUCCESS, 
    REEMBOLSO_UPDATE_FAILED ,
    REEMBOLSO_UPLOAD,
    REEMBOLSO_UPLOAD_SUCCESS,
    REEMBOLSO_UPLOAD_FAILED,
    REEMBOLSO_DOWNLOAD,
    REEMBOLSO_DOWNLOAD_SUCCESS,
    REEMBOLSO_DOWNLOAD_FAILED,
    REEMBOLSO_REMOVEANEXO,
    REEMBOLSO_REMOVEANEXO_SUCCESS,
    REEMBOLSO_REMOVEANEXO_FAILED,
   
} from './constants';

const INIT_STATE = {
    reembolsos: {},
    reembolso: {},
    loading: false,
    error: null,
    reembolsoStatus: null,
    id: {}
};

const ReembolsoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case REEMBOLSO_LIST:
            return { ...state, reembolso: {}, loading: true, error: null, reembolsoDeletado: null };
        case REEMBOLSO_LIST_SUCCESS:
            return { ...state, reembolsos: action.payload, loading: false, error: null, reembolsoDeletado: null };
        case REEMBOLSO_LIST_FAILED:
            return { ...state, reembolso: {}, error: action.payload, loading: false, reembolsoDeletado: null };
        case REEMBOLSO_LOAD:
            return { ...state, reembolso: {}, loading: true, error: null, reembolsoStatus: null, reembolsoDeletado: null }
        case REEMBOLSO_LOAD_SUCCESS:
            return { ...state, reembolso: action.payload, loading: false, error: null, reembolsoDeletado: null}
        case REEMBOLSO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, reembolsoDeletado: null };
        case REEMBOLSO_ADD:
            return { ...state, reembolso: action.payload, reembolsoStatus: null, loading: true, error: null, reembolsoDeletado: null}
        case REEMBOLSO_ADD_SUCCESS:
            return { ...state, reembolso: action.payload,  reembolsoStatus: "Solicitação criada com sucesso", reembolsoDeletado: null, loading: false, error: null };
        case REEMBOLSO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REEMBOLSO_UPDATE:
            return { ...state, reembolso: action.payload, reembolsoStatus: null, loading: true, error: null}
        case REEMBOLSO_UPDATE_SUCCESS:
            return { ...state, reembolso: action.payload,  reembolsoStatus: "Solicitação atualizada com sucesso", reembolsoDeletado: null, loading: false, error: null };
        case REEMBOLSO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, reembolsoStatus: null };
        case REEMBOLSO_REMOVE:
            return { ...state, reembolso: action.payload, reembolsoStatus: null, loading: true, error: null, reembolsoDeletado: null}
        case REEMBOLSO_REMOVE_SUCCESS:
            return { ...state, reembolso: {}, reembolsoStatus: null,  reembolsoDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case REEMBOLSO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, reembolsoStatus: null, reembolsoDeletado: null };
        case REEMBOLSO_UPLOAD:
            return { ...state, reembolsoStatus: null, loading: true, error: null, reembolsoDeletado: null}
        case REEMBOLSO_UPLOAD_SUCCESS:
            return { ...state, reembolso: action.payload,  reembolsoStatus: "Arquivo adicionado com sucesso", reembolsoDeletado: null, loading: false, error: null };
        case REEMBOLSO_UPLOAD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REEMBOLSO_DOWNLOAD:
            return { ...state, reembolsoStatus: null, loading: true, error: null, reembolsoDeletado: null}
        case REEMBOLSO_DOWNLOAD_SUCCESS:
            return { ...state, reembolso: action.payload,  reembolsoStatus: "Download enviado", reembolsoDeletado: null, loading: false, error: null };
        case REEMBOLSO_DOWNLOAD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REEMBOLSO_REMOVEANEXO:
            return { ...state, reembolsoStatus: null, loading: true, error: null, reembolsoDeletado: null}
        case REEMBOLSO_REMOVEANEXO_SUCCESS:
            let reembolsoNew = state.reembolso;
            const itemIndexRemove = reembolsoNew.reembolso.solicitacaoAnexos.findIndex(x => x.id === action.payload);
            reembolsoNew.reembolso.solicitacaoAnexos.splice(itemIndexRemove, 1);
            return { ...state, reembolso: reembolsoNew, reembolsoStatus: "Anexo removido com sucesso", reembolsoDeletado: null, loading: false, error: null };
        case REEMBOLSO_REMOVEANEXO_FAILED:
            return { ...state, error: action.payload, loading: false };
       
        default:
            return { ...state };
    }
};

export default ReembolsoReducer;