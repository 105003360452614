import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { TURNO_LOAD, TURNO_LIST, TURNO_ADD, TURNO_UPDATE, TURNO_REMOVE } from './constants';

import {
    turnoListSuccess,
    turnoListFailed,
    turnoAddSuccess,
    turnoAddFailed,
    turnoUpdateSuccess,
    turnoUpdateFailed,
    turnoLoadSuccess,
    turnoLoadFailed,
    turnoRemoveSuccess,
    turnoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* turnoLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/turno/' + id, options);
        yield put(turnoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(turnoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* turnoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/turno/filter', options);
            yield put(turnoListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(turnoListFailed(message));
        }    
}


/**
 * turno
 * @param {*} payload - pageNumber and pageSize
 */
function* turnoAdd({ payload: turno  }) {

    const options = {
        body: JSON.stringify(turno) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/turno', options);
        yield put(turnoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(turnoAddFailed(message));
    }    
}

/**
 * turno
 * @param {*} payload - pageNumber and pageSize
 */
function* turnoUpdate({ payload: turno  }) {

    const options = {
        body: JSON.stringify(turno) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/turno/' + turno?.id, options);
        yield put(turnoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(turnoUpdateFailed(message));
    }    
}

/**
 * turno
 * @param {*} payload - pageNumber and pageSize
 */
function* turnoRemove({ payload: turno  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/turno/' + turno?.id, options);
        yield put(turnoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(turnoRemoveFailed(message));
    }    
}

export function* watchTurnoLoad(): any{
    yield takeEvery(TURNO_LOAD, turnoLoad);
}

export function* watchTurnoList(): any {
    yield takeEvery(TURNO_LIST, turnoList);
}

export function* watchTurnoAdd(): any {
    yield takeEvery(TURNO_ADD, turnoAdd);
}

export function* watchTurnoUpdate(): any {
    yield takeEvery(TURNO_UPDATE, turnoUpdate);
}

export function* watchTurnoRemove(): any {
    yield takeEvery(TURNO_REMOVE, turnoRemove);
}

function* turno(): any {
    yield all([fork(watchTurnoList), fork(watchTurnoAdd), fork(watchTurnoUpdate), fork(watchTurnoLoad), fork(watchTurnoRemove)]);
}

export default turno;