import {
    TAXI_MACHINE_ESTIMAR_CORRIDA,
    TAXI_MACHINE_ESTIMAR_CORRIDA_SUCCESS,
    TAXI_MACHINE_ESTIMAR_CORRIDA_FAILED,
    TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR,
    TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_SUCCESS,
    TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_FAILED,
} from './constants';

const INIT_STATE = {
    estimarCorrida: {},
    estimarCorridaValor: {},
    loading: false,
    erro: null,
};

const TaxiMachineReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TAXI_MACHINE_ESTIMAR_CORRIDA:
            return { ...state, loading: true, erro: null };
        case TAXI_MACHINE_ESTIMAR_CORRIDA_SUCCESS:
            return { ...state, estimarCorrida: action.payload, loading: false, erro: null };
        case TAXI_MACHINE_ESTIMAR_CORRIDA_FAILED:
            return { ...state, estimarCorrida: {}, loading: false, erro: action.payload };
        case TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR:
            return { ...state, loading: true, erro: null };
        case TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_SUCCESS:
            return { ...state, estimarCorridaValor: action.payload, loading: false, erro: null };
        case TAXI_MACHINE_ESTIMAR_CORRIDA_VALOR_FAILED:
            return { ...state, estimarCorridaValor: {}, loading: false, erro: action.payload };
        default:
            return { ...state };
    }
};

export default TaxiMachineReducer;
