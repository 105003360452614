const relatorioMenu = [
    {
        header: 'Relatórios',
        path: '/relatorios',
        name: 'Relatórios', 
        icon: 'uil-print'
    }
]

export const RelatorioMenu = () => relatorioMenu;

