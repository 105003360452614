
import { DOMINIO_LOAD, DOMINIO_LOAD_SUCCESS, DOMINIO_LOAD_FAILED, DOMINIO_LIST, DOMINIO_LIST_SUCCESS, DOMINIO_LIST_FAILED, DOMINIO_ADD, DOMINIO_ADD_FAILED, DOMINIO_ADD_SUCCESS, DOMINIO_REMOVE, DOMINIO_REMOVE_FAILED, DOMINIO_REMOVE_SUCCESS, DOMINIO_UPDATE, DOMINIO_UPDATE_SUCCESS, DOMINIO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    dominios: {},
    dominio: {},
    loading: false,
    error: null,
    dominioStatus: null,
    id: {}
};

const DominioReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DOMINIO_LIST:
            return { ...state, loading: true, error: null, dominioDeletado: null };
        case DOMINIO_LIST_SUCCESS:
            return { ...state, dominios: action.payload, loading: false, error: null, dominioDeletado: null };
        case DOMINIO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, dominioDeletado: null };
        case DOMINIO_LOAD:
            return { ...state, dominio: {}, loading: true, error: null, dominioStatus: null, dominioDeletado: null }
        case DOMINIO_LOAD_SUCCESS:
            return { ...state, dominio: action.payload, loading: false, error: null, dominioDeletado: null}
        case DOMINIO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, dominioDeletado: null };
        case DOMINIO_ADD:
            return { ...state, dominio: action.payload, dominioStatus: null, loading: true, error: null, dominioDeletado: null}
        case DOMINIO_ADD_SUCCESS:
            return { ...state, dominio: action.payload,  dominioStatus: "Domínio adicionado com sucesso", dominioDeletado: null, loading: false, error: null };
        case DOMINIO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DOMINIO_UPDATE:
            return { ...state, dominio: action.payload, dominioStatus: null, loading: true, error: null}
        case DOMINIO_UPDATE_SUCCESS:
            return { ...state, dominio: action.payload,  dominioStatus: "Domínio atualizado com sucesso", dominioDeletado: null, loading: false, error: null };
        case DOMINIO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, dominioStatus: null };
        case DOMINIO_REMOVE:
            return { ...state, dominio: action.payload, dominioStatus: null, loading: true, error: null, dominioDeletado: null}
        case DOMINIO_REMOVE_SUCCESS:
            return { ...state, dominio: {}, dominioStatus: null,  dominioDeletado: "Domínio removido com sucesso", loading: false, error: null };
        case DOMINIO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, dominioStatus: null, dominioDeletado: null };
        default:
            return { ...state };
    }
};

export default DominioReducer;