import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { TROCAVEICULO_LOAD, TROCAVEICULO_LIST, TROCAVEICULO_ADD, TROCAVEICULO_UPDATE, TROCAVEICULO_REMOVE } from './constants';

import {
    trocaVeiculoListSuccess,
    trocaVeiculoListFailed,
    trocaVeiculoAddSuccess,
    trocaVeiculoAddFailed,
    trocaVeiculoUpdateSuccess,
    trocaVeiculoUpdateFailed,
    trocaVeiculoLoadSuccess,
    trocaVeiculoLoadFailed,
    trocaVeiculoRemoveSuccess,
    trocaVeiculoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* trocaVeiculoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/trocaVeiculo/' + id, options);
        yield put(trocaVeiculoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(trocaVeiculoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* trocaVeiculoList({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/trocaVeiculo/filter', options);
        yield put(trocaVeiculoListSuccess(response));
    } catch (error) {
        yield put(trocaVeiculoListFailed(error.message));
    }    
}


/**
 * trocaVeiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* trocaVeiculoAdd({ payload: trocaVeiculo  }) {

    const options = {
        body: JSON.stringify(trocaVeiculo) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/trocaVeiculo', options);
        yield put(trocaVeiculoAddSuccess(response));
        yield call(trocaVeiculoLoad, { payload: { id: response?.id } });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(trocaVeiculoAddFailed(message));
    }    
}

/**
 * trocaVeiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* trocaVeiculoUpdate({ payload: trocaVeiculo  }) {

    const options = {
        body: JSON.stringify(trocaVeiculo) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/trocaVeiculo/' + trocaVeiculo?.id, options);
        yield put(trocaVeiculoUpdateSuccess(response));
        yield call(trocaVeiculoLoad, { payload: { id: trocaVeiculo?.id } });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(trocaVeiculoUpdateFailed(message));
    }    
}

/**
 * trocaVeiculo
 * @param {*} payload - pageNumber and pageSize
 */
function* trocaVeiculoRemove({ payload: trocaVeiculo }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/trocaVeiculo/' + trocaVeiculo?.id, options);
        yield put(trocaVeiculoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(trocaVeiculoRemoveFailed(message));
    }    
}

export function* watchtrocaVeiculoLoad(): any{
    yield takeEvery(TROCAVEICULO_LOAD, trocaVeiculoLoad);
}

export function* watchtrocaVeiculoList(): any {
    yield takeEvery(TROCAVEICULO_LIST, trocaVeiculoList);
}

export function* watchtrocaVeiculoAdd(): any {
    yield takeEvery(TROCAVEICULO_ADD, trocaVeiculoAdd);
}

export function* watchtrocaVeiculoUpdate(): any {
    yield takeEvery(TROCAVEICULO_UPDATE, trocaVeiculoUpdate);
}

export function* watchtrocaVeiculoRemove(): any {
    yield takeEvery(TROCAVEICULO_REMOVE, trocaVeiculoRemove);
}

function* trocaVeiculo(): any {
    yield all([fork(watchtrocaVeiculoList), fork(watchtrocaVeiculoAdd), fork(watchtrocaVeiculoUpdate), fork(watchtrocaVeiculoLoad), fork(watchtrocaVeiculoRemove)]);
}

export default trocaVeiculo;