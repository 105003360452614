import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { PASSAGEIRO_LOAD, PASSAGEIRO_LIST, PASSAGEIRO_ADD, PASSAGEIRO_UPDATE, PASSAGEIRO_REMOVE, PASSAGEIRO_ENDERECOS } from './constants';

import {
    passageiroListSuccess,
    passageiroListFailed,
    passageiroAddSuccess,
    passageiroAddFailed,
    passageiroUpdateSuccess,
    passageiroUpdateFailed,
    passageiroLoadSuccess,
    passageiroLoadFailed,
    passageiroRemoveSuccess,
    passageiroRemoveFailed,

    passageiroEnderecosSuccess,
    passageiroEnderecosFailed
} from './actions';

/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* passageiroLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/passageiro/' + id, options);
        yield put(passageiroLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(passageiroLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* passageiroList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/passageiro/filter', options);
            yield put(passageiroListSuccess(response));
        } catch (error) {
            yield put(passageiroListFailed(error.message));
        }    
}


/**
 * passageiro
 * @param {*} payload - pageNumber and pageSize
 */
function* passageiroAdd({ payload: { passageiro }  }) {

    if (!!passageiro.telefoneCelular) {
        passageiro['telefoneCelular'] = `+55${
            passageiro.telefoneCelular
                .replace(' ', '')
                .replace(/[^\d]+/g, '')
        }`;
    }

    const options = {
        body: JSON.stringify(passageiro) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/passageiro', options);
        yield put(passageiroAddSuccess(response));
    } catch (error) {
        yield put(passageiroAddFailed(error.message));
    }    
}

/**
 * passageiro
 * @param {*} payload - pageNumber and pageSize
 */
function* passageiroUpdate({ payload: { passageiro }  }) {

    if (!!passageiro.telefoneCelular) {
        passageiro['telefoneCelular'] = `+55${
            passageiro.telefoneCelular
                .replace(' ', '')
                .replace(/[^\d]+/g, '')
        }`;
    }

    const options = {
        body: JSON.stringify(passageiro) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/passageiro/' + passageiro?.id, options);
        yield put(passageiroUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(passageiroUpdateFailed(message));
    }    
}

/**
 * passageiro
 * @param {*} payload - pageNumber and pageSize
 */
function* passageiroRemove({ payload: { passageiro }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/passageiro/' + passageiro?.id, options);
        yield put(passageiroRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(passageiroRemoveFailed(message));
    }    
}

/**
 * Carrega todos os endereços do passageiro
 * @param {*} payload - passageiroId
 */
function* passageiroEnderecos({ payload: { passageiroId, centroCustoId } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/passageiro/${passageiroId}/enderecos/${centroCustoId}`, options);
        yield put(passageiroEnderecosSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(passageiroEnderecosFailed(message));
    }    
}

export function* watchPassageiroLoad(): any{
    yield takeEvery(PASSAGEIRO_LOAD, passageiroLoad);
}

export function* watchPassageiroList(): any {
    yield takeEvery(PASSAGEIRO_LIST, passageiroList);
}

export function* watchPassageiroAdd(): any {
    yield takeEvery(PASSAGEIRO_ADD, passageiroAdd);
}

export function* watchPassageiroUpdate(): any {
    yield takeEvery(PASSAGEIRO_UPDATE, passageiroUpdate);
}

export function* watchPassageiroRemove(): any {
    yield takeEvery(PASSAGEIRO_REMOVE, passageiroRemove);
}

export function* watchPassageiroEnderecos(): any {
    yield takeEvery(PASSAGEIRO_ENDERECOS, passageiroEnderecos);
}

function* passageiro(): any {
    yield all([
                fork(watchPassageiroList), 
                fork(watchPassageiroAdd), 
                fork(watchPassageiroUpdate), 
                fork(watchPassageiroLoad), 
                fork(watchPassageiroRemove),
                fork(watchPassageiroEnderecos)
            ]);
}

export default passageiro;