import { GRUPOACESSO_LOAD, GRUPOACESSO_LOAD_SUCCESS, GRUPOACESSO_LOAD_FAILED, GRUPOACESSO_LIST, GRUPOACESSO_LIST_SUCCESS, GRUPOACESSO_LIST_FAILED, GRUPOACESSO_ADD, GRUPOACESSO_ADD_FAILED, GRUPOACESSO_ADD_SUCCESS, GRUPOACESSO_REMOVE, GRUPOACESSO_REMOVE_FAILED, GRUPOACESSO_REMOVE_SUCCESS, GRUPOACESSO_UPDATE, GRUPOACESSO_UPDATE_SUCCESS, GRUPOACESSO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    grupoAcessos: [],
    grupoAcesso: {},
    loading: false,
    error: null,
    grupoAcessoStatus: null
};

const GrupoAcessoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GRUPOACESSO_LIST:
            return { ...state, loading: true, error: null, grupoAcessoDeletado: null };
        case GRUPOACESSO_LIST_SUCCESS:
            return { ...state, grupoAcessos: action.payload, loading: false, error: null, grupoAcessoDeletado: null };
        case GRUPOACESSO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, grupoAcessoDeletado: null };
        case GRUPOACESSO_LOAD:
            return { ...state, loading: true, error: null, grupoAcessoStatus: null, grupoAcessoDeletado: null }
        case GRUPOACESSO_LOAD_SUCCESS:
            return { ...state, grupoAcesso: action.payload, loading: false, error: null, grupoAcessoDeletado: null}
        case GRUPOACESSO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, grupoAcessoDeletado: null };
        case GRUPOACESSO_ADD:
            return { ...state, grupoAcesso: action.payload, grupoAcessoStatus: null, loading: true, error: null, grupoAcessoDeletado: null}
        case GRUPOACESSO_ADD_SUCCESS:
            return { ...state, grupoAcesso: action.payload,  grupoAcessoStatus: "Grupo de acesso adicionado com sucesso", grupoAcessoDeletado: null, loading: false, error: null };
        case GRUPOACESSO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GRUPOACESSO_UPDATE:
            return { ...state, grupoAcesso: action.payload, grupoAcessoStatus: null, loading: true, error: null}
        case GRUPOACESSO_UPDATE_SUCCESS:
            return { ...state, grupoAcesso: action.payload,  grupoAcessoStatus: "Grupo de acesso atualizado com sucesso", grupoAcessoDeletado: null, loading: false, error: null };
        case GRUPOACESSO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, grupoAcessoStatus: null };
        case GRUPOACESSO_REMOVE:
            return { ...state, grupoAcesso: action.payload, grupoAcessoStatus: null, loading: true, error: null, grupoAcessoDeletado: null}
        case GRUPOACESSO_REMOVE_SUCCESS:
            return { ...state, grupoAcesso: {}, grupoAcessoStatus: null,  grupoAcessoDeletado: "Grupo de acesso removido com sucesso", loading: false, error: null };
        case GRUPOACESSO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, grupoAcessoStatus: null, grupoAcessoDeletado: null };
        default:
            return { ...state };
    }
};

export default GrupoAcessoReducer;