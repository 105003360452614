import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { 
        ATUALIZACAODOCUMENTO_LOAD, 
        ATUALIZACAODOCUMENTO_LIST, 
        ATUALIZACAODOCUMENTO_ADD, 
        ATUALIZACAODOCUMENTO_UPDATE, 
        ATUALIZACAODOCUMENTO_REMOVE,
        ATUALIZACAODOCUMENTO_UPLOAD, 
        ATUALIZACAODOCUMENTO_DOWNLOAD, 
        ATUALIZACAODOCUMENTO_REMOVEANEXO
    } from './constants';

import {
    atualizacaoDocumentoListSuccess,
    atualizacaoDocumentoListFailed,
    atualizacaoDocumentoAddSuccess,
    atualizacaoDocumentoAddFailed,
    atualizacaoDocumentoUpdateSuccess,
    atualizacaoDocumentoUpdateFailed,
    atualizacaoDocumentoLoadSuccess,
    atualizacaoDocumentoLoadFailed,
    atualizacaoDocumentoRemoveSuccess,
    atualizacaoDocumentoRemoveFailed,
    atualizacaoDocumentoUploadSuccess,
    atualizacaoDocumentoUploadFailed, 
    atualizacaoDocumentoDownloadSuccess, 
    atualizacaoDocumentoDownloadFailed, 
    atualizacaoDocumentoRemoveAnexoSuccess, 
    atualizacaoDocumentoRemoveAnexoFailed
} from './actions';

import { URL_BASE } from '../../../helpers/url'

/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* atualizacaoDocumentoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/atualizacaodocumento/' + id, options);
        yield put(atualizacaoDocumentoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(atualizacaoDocumentoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* atualizacaoDocumentoList({ payload: filter }) {

    const options = {
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/atualizacaodocumento/filter', options);
        yield put(atualizacaoDocumentoListSuccess(response));
    } catch (error) {
        yield put(atualizacaoDocumentoListFailed(error.message));
    }
}


/**
 * atualizacaoDocumento
 * @param {*} payload - pageNumber and pageSize
 */
function* atualizacaoDocumentoAdd({ payload: { atualizacaoDocumento }  }) {

    const options = {
        body: JSON.stringify(atualizacaoDocumento) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/atualizacaodocumento', options);
        yield put(atualizacaoDocumentoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(atualizacaoDocumentoAddFailed(message));
    }    
}
  

/**
 * atualizacaoDocumento
 * @param {*} payload - pageNumber and pageSize
 */
function* atualizacaoDocumentoUpdate({ payload: { atualizacaoDocumento }  }) {

    const options = {
        body: JSON.stringify(atualizacaoDocumento) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/atualizacaodocumento/' + atualizacaoDocumento?.id, options);
        yield put(atualizacaoDocumentoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(atualizacaoDocumentoUpdateFailed(message));
    }    
}

/**
 * atualizacaoDocumento
 * @param {*} payload - pageNumber and pageSize
 */
function* atualizacaoDocumentoRemove({ payload: { atualizacaoDocumento }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/atualizacaodocumento/' + atualizacaoDocumento?.id, options);
        yield put(atualizacaoDocumentoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(atualizacaoDocumentoRemoveFailed(message));
    }    
}

/**
 * atualizacaoDocumento
 * @param {*} reembolsId - ID do Reembolso
 * @param {*} arquivo - Arquivo para upload
 */
function* atualizacaoDocumentoUpload({ payload: { atualizacaoDocumentoId, arquivo }  }) {

    try {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getLoggedInUser().token);

        var formdata = new FormData();
        formdata.append("arquivo", arquivo, arquivo.name);    

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        var response =  yield fetchJSON("v1/atualizacaodocumento/uploadfile/" + atualizacaoDocumentoId, requestOptions);
        yield put(atualizacaoDocumentoUploadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(atualizacaoDocumentoUploadFailed(message));
    }    
}

/**
 * download de arquivo
 * @param {*} documentoId - ID do Reembolso
 */
function* atualizacaoDocumentoDownload({ payload: { arquivoId, nomeArquivo }  }) {

    try {

        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
        };

        var response =  yield call(fetch, URL_BASE + '/central/v1/api/v1/atualizacaodocumento/download/' + arquivoId, options);

       response.blob().then(blob => {

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeArquivo);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })

        yield put(atualizacaoDocumentoDownloadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        
        yield put(atualizacaoDocumentoDownloadFailed(message));
    }    
}

/**
 * download de arquivo
 * @param {*} documentoId - ID do Reembolso
 */
function* atualizacaoDocumentoRemoveAnexo({ payload: { arquivoId }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/atualizacaodocumento/removeanexo/' + arquivoId, options);
        yield put(atualizacaoDocumentoRemoveAnexoSuccess(arquivoId));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(atualizacaoDocumentoRemoveAnexoFailed(message));
    }    
}

export function* watchatualizacaoDocumentoLoad(): any{
    yield takeEvery(ATUALIZACAODOCUMENTO_LOAD, atualizacaoDocumentoLoad);
}

export function* watchatualizacaoDocumentoList(): any {
    yield takeEvery(ATUALIZACAODOCUMENTO_LIST, atualizacaoDocumentoList);
}

export function* watchatualizacaoDocumentoAdd(): any {
    yield takeEvery(ATUALIZACAODOCUMENTO_ADD, atualizacaoDocumentoAdd);
}

export function* watchatualizacaoDocumentoUpload(): any {
    yield takeEvery(ATUALIZACAODOCUMENTO_UPLOAD, atualizacaoDocumentoUpload);
}

export function* watchatualizacaoDocumentoUpdate(): any {
    yield takeEvery(ATUALIZACAODOCUMENTO_UPDATE, atualizacaoDocumentoUpdate);
}

export function* watchatualizacaoDocumentoRemove(): any {
    yield takeEvery(ATUALIZACAODOCUMENTO_REMOVE, atualizacaoDocumentoRemove);
}

export function* watchatualizacaoDocumentoDownload(): any {
    yield takeEvery(ATUALIZACAODOCUMENTO_DOWNLOAD, atualizacaoDocumentoDownload);
}

export function* watchatualizacaoDocumentoRemoveAnexo(): any {
    yield takeEvery(ATUALIZACAODOCUMENTO_REMOVEANEXO, atualizacaoDocumentoRemoveAnexo);
}

function* atualizacaoDocumento(): any {
    yield all([fork(watchatualizacaoDocumentoList), fork(watchatualizacaoDocumentoAdd), fork(watchatualizacaoDocumentoUpdate), fork(watchatualizacaoDocumentoLoad), fork(watchatualizacaoDocumentoRemove), fork(watchatualizacaoDocumentoUpload), fork(watchatualizacaoDocumentoDownload), fork(watchatualizacaoDocumentoRemoveAnexo)]);
}

export default atualizacaoDocumento;