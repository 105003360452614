import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { LIMITADOR_CORRIDA_LOAD, LIMITADOR_CORRIDA_LIST, LIMITADOR_CORRIDA_ADD, LIMITADOR_CORRIDA_UPDATE, LIMITADOR_CORRIDA_REMOVE } from './constants';

import {
    limitadorCorridaListSuccess,
    limitadorCorridaListFailed,
    limitadorCorridaAddSuccess,
    limitadorCorridaAddFailed,
    limitadorCorridaUpdateSuccess,
    limitadorCorridaUpdateFailed,
    limitadorCorridaLoadSuccess,
    limitadorCorridaLoadFailed,
    limitadorCorridaRemoveSuccess,
    limitadorCorridaRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* limitadorCorridaLoad({ payload: id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/limitadorCorrida/' + id, options);
        yield put(limitadorCorridaLoadSuccess(response));
    } catch (error) {
        yield put(limitadorCorridaLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* limitadorCorridaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/limitadorCorrida/filter', options);
            yield put(limitadorCorridaListSuccess(response));
        } catch (error) {
            yield put(limitadorCorridaListFailed(error.message));
        }    
}


/**
 * limitadorCorrida
 * @param {*} payload - pageNumber and pageSize
 */
function* limitadorCorridaAdd({ payload: limitadorCorrida }) {

    const options = {
        body: JSON.stringify(limitadorCorrida) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/limitadorCorrida', options);
        yield put(limitadorCorridaAddSuccess(response));
    } catch (error) {
        yield put(limitadorCorridaAddFailed(error.message));
    }    
}

/**
 * limitadorCorrida
 * @param {*} payload - pageNumber and pageSize
 */
function* limitadorCorridaUpdate({ payload: limitadorCorrida  }) {

    const options = {
        body: JSON.stringify(limitadorCorrida) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/limitadorCorrida/' + limitadorCorrida?.id, options);
        yield put(limitadorCorridaUpdateSuccess(response));
    } catch (error) {
        yield put(limitadorCorridaUpdateFailed(error.message));
    }    
}

/**
 * limitadorCorrida
 * @param {*} payload - pageNumber and pageSize
 */
function* limitadorCorridaRemove({ payload: limitadorCorrida  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/limitadorCorrida/' + limitadorCorrida?.id, options);
        yield put(limitadorCorridaRemoveSuccess({}));
    } catch (error) {
        yield put(limitadorCorridaRemoveFailed(error.message));
    }    
}

export function* watchTarifaLoad(): any{
    yield takeEvery(LIMITADOR_CORRIDA_LOAD, limitadorCorridaLoad);
}

export function* watchTarifaList(): any {
    yield takeEvery(LIMITADOR_CORRIDA_LIST, limitadorCorridaList);
}

export function* watchTarifaAdd(): any {
    yield takeEvery(LIMITADOR_CORRIDA_ADD, limitadorCorridaAdd);
}

export function* watchTarifaUpdate(): any {
    yield takeEvery(LIMITADOR_CORRIDA_UPDATE, limitadorCorridaUpdate);
}

export function* watchTarifaRemove(): any {
    yield takeEvery(LIMITADOR_CORRIDA_REMOVE, limitadorCorridaRemove);
}

function* limitadorCorrida(): any {
    yield all([fork(watchTarifaList), fork(watchTarifaAdd), fork(watchTarifaUpdate), fork(watchTarifaLoad), fork(watchTarifaRemove)]);
}

export default limitadorCorrida;