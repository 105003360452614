import {
    SOLICITACAO_CORRIDA_ADD,
    SOLICITACAO_CORRIDA_FAILED,
    SOLICITACAO_CORRIDA_LIST,
    SOLICITACAO_CORRIDA_LIST_FAILED,
    SOLICITACAO_CORRIDA_LIST_SUCCESS,
    SOLICITACAO_CORRIDA_LOAD,
    SOLICITACAO_CORRIDA_LOAD_FAILED,
    SOLICITACAO_CORRIDA_LOAD_SUCCESS,
    SOLICITACAO_CORRIDA_SUCCESS,
    SOLICITACAO_CORRIDA_UPDATE,
    SOLICITACAO_CORRIDA_UPDATE_FAILED,
    SOLICITACAO_CORRIDA_UPDATE_SUCCESS,
    SOLICITACAO_CORRIDA_REMOVE,
    SOLICITACAO_CORRIDA_REMOVE_SUCCESS,
    SOLICITACAO_CORRIDA_REMOVE_FAILED,
    SOLICITACAO_CORRIDA_APROVAR,
    SOLICITACAO_CORRIDA_APROVAR_SUCCESS,
    SOLICITACAO_CORRIDA_APROVAR_FAILED,
    SOLICITACAO_CORRIDA_LIBERAR,
    SOLICITACAO_CORRIDA_LIBERAR_SUCCESS,
    SOLICITACAO_CORRIDA_LIBERAR_FAILED,
    SOLICITACAO_ADD_PARADA,
    SOLICITACAO_CORRIDA_NEW,
    SOLICITACAO_CORRIDA_REJEITAR,
    SOLICITACAO_CORRIDA_REJEITAR_SUCCESS,
    SOLICITACAO_CORRIDA_REJEITAR_FAILED,
    EVENTOS_SOLICITACAO,
    EVENTOS_SOLICITACAO_SUCCESS,
    EVENTOS_SOLICITACAO_FAILED,
    SHOW_MODAL_EVENTOS_SOLICITACAO,
    SOLICITACAO_CORRIDA_REENVIAR,
    SOLICITACAO_CORRIDA_REENVIAR_SUCCESS,
    SOLICITACAO_CORRIDA_REENVIAR_FAILED,
    SOLICITACAO_CORRIDA_REABRIR,
    SOLICITACAO_CORRIDA_REABRIR_SUCCESS,
    SOLICITACAO_CORRIDA_REABRIR_FAILED,
    SOLICITACAO_CORRIDA_FINALIZAR,
    SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS,
    SOLICITACAO_CORRIDA_FINALIZAR_FAILED,
    SOLICITACAO_CORRIDA_CANCELAR,
    SOLICITACAO_CORRIDA_CANCELAR_SUCCESS,
    SOLICITACAO_CORRIDA_CANCELAR_FAILED,
    SOLICITACAO_CORRIDA_EM_FILA,
    SOLICITACAO_CORRIDA_EM_FILA_SUCCESS,
    SOLICITACAO_CORRIDA_EM_FILA_FAILED,
    HANDLE_REFERENCIA_PARTIDA,
    SOLICITACAO_CORRIDA_ENCERRAR,
    SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS,
    SOLICITACAO_CORRIDA_ENCERRAR_FAILED,
} from './constants';

export const solicitacaoCorridaAdd = (solicitacao) => ({ type: SOLICITACAO_CORRIDA_ADD, payload: solicitacao });
export const solicitacaoCorridaSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_FAILED, payload: erro });

export const solicitacaoCorridaList = (filter) => ({ type: SOLICITACAO_CORRIDA_LIST, payload: filter });
export const solicitacaoCorridaListSuccess = (solicitacoes, success) => ({
    type: SOLICITACAO_CORRIDA_LIST_SUCCESS,
    payload: { solicitacoes, success },
});
export const solicitacaoCorridaListFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_LIST_FAILED, payload: erro });

export const solicitacaoCorridaLoad = (solicitacaoId) => ({ type: SOLICITACAO_CORRIDA_LOAD, payload: solicitacaoId });
export const solicitacaoCorridaLoadSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_LOAD_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaLoadFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_LOAD_FAILED, payload: erro });

export const solicitacaoCorridaUpdate = (solicitacao) => ({ type: SOLICITACAO_CORRIDA_UPDATE, payload: solicitacao });
export const solicitacaoCorridaUpdateSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_UPDATE_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaUpdateFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_UPDATE_FAILED, payload: erro });

export const solicitacaoCorridaRemove = (solicitacaoId) => ({
    type: SOLICITACAO_CORRIDA_REMOVE,
    payload: solicitacaoId,
});
export const solicitacaoCorridaRemoveSuccess = (success) => ({
    type: SOLICITACAO_CORRIDA_REMOVE_SUCCESS,
    payload: success,
});
export const solicitacaoCorridaRemoveFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_REMOVE_FAILED, payload: erro });

export const solicitacaoCorridaAprovar = (solicitacaoId, aprovacao) => ({
    type: SOLICITACAO_CORRIDA_APROVAR,
    payload: { solicitacaoId, aprovacao },
});
export const solicitacaoCorridaAprovarSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_APROVAR_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaAprovarFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_APROVAR_FAILED, payload: erro });

export const solicitacaoCorridaLiberar = (solicitacaoId) => ({
    type: SOLICITACAO_CORRIDA_LIBERAR,
    payload: solicitacaoId,
});
export const solicitacaoCorridaLiberarSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_LIBERAR_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaLiberarFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_LIBERAR_FAILED, payload: erro });

export const solicitacaoCorridaRejeitar = (solicitacaoId, motivo) => ({
    type: SOLICITACAO_CORRIDA_REJEITAR,
    payload: { solicitacaoId, motivo },
});
export const solicitacaoCorridaRejeitarSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_REJEITAR_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaRejeitarFailed = (error) => ({
    type: SOLICITACAO_CORRIDA_REJEITAR_FAILED,
    payload: error,
});

export const solicitacaoAddParada = (parada) => ({ type: SOLICITACAO_ADD_PARADA, payload: parada });

export const solicitacaoCorridaNew = () => ({ type: SOLICITACAO_CORRIDA_NEW });

export const eventoSolicitacao = (solicitacaoId) => ({ type: EVENTOS_SOLICITACAO, payload: solicitacaoId });
export const eventoSolicitacaoSuccess = (eventos) => ({ type: EVENTOS_SOLICITACAO_SUCCESS, payload: eventos });
export const eventoSolicitacaoFailed = (error) => ({ type: EVENTOS_SOLICITACAO_FAILED, payload: error });

export const showModalEventosSolicitacao = () => ({ type: SHOW_MODAL_EVENTOS_SOLICITACAO });

export const solicitacaoCorridaReenviar = (solicitacaoId) => ({
    type: SOLICITACAO_CORRIDA_REENVIAR,
    payload: solicitacaoId,
});
export const solicitacaoCorridaReenviarSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_REENVIAR_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaReenviarFailed = (erro) => ({
    type: SOLICITACAO_CORRIDA_REENVIAR_FAILED,
    payload: erro,
});

export const solicitacaoCorridaReabrir = (solicitacao) => ({ type: SOLICITACAO_CORRIDA_REABRIR, payload: solicitacao });
export const solicitacaoCorridaReabrirSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_REABRIR_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaReabrirFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_REABRIR_FAILED, payload: erro });

export const solicitacaoCorridaFinalizar = (solicitacaoId) => ({
    type: SOLICITACAO_CORRIDA_FINALIZAR,
    payload: solicitacaoId,
});
export const solicitacaoCorridaFinalizarSuccess = (success) => ({
    type: SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS,
    payload: success,
});
export const solicitacaoCorridaFinalizarFailed = (error) => ({
    type: SOLICITACAO_CORRIDA_FINALIZAR_FAILED,
    payload: error,
});

export const solicitacaoCorridaCancelar = (solicitacaoId) => ({
    type: SOLICITACAO_CORRIDA_CANCELAR,
    payload: solicitacaoId,
});
export const solicitacaoCorridaCancelarSuccess = (success) => ({
    type: SOLICITACAO_CORRIDA_CANCELAR_SUCCESS,
    payload: success,
});
export const solicitacaoCorridaCancelarFailed = (error) => ({
    type: SOLICITACAO_CORRIDA_CANCELAR_FAILED,
    payload: error,
});

export const solicitacaoCorridaEmFila = (solicitacaoId) => ({
    type: SOLICITACAO_CORRIDA_EM_FILA,
    payload: solicitacaoId,
});
export const solicitacaoCorridaEmFilaSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_EM_FILA_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaEmFilaFailed = (erro) => ({ type: SOLICITACAO_CORRIDA_EM_FILA_FAILED, payload: erro });

export const handleReferenciaPartida = (referencia) => ({ type: HANDLE_REFERENCIA_PARTIDA, payload: referencia });

export const solicitacaoCorridaEncerrar = (solicitacaoId) => ({
    type: SOLICITACAO_CORRIDA_ENCERRAR,
    payload: solicitacaoId,
});
export const solicitacaoCorridaEncerrarSuccess = (solicitacao, success) => ({
    type: SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS,
    payload: { solicitacao, success },
});
export const solicitacaoCorridaEncerrarFailed = (erro) => ({
    type: SOLICITACAO_CORRIDA_ENCERRAR_FAILED,
    payload: erro,
});
