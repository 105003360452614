import { 
    ESTADO_LIST,
    ESTADO_LIST_SUCCESS,
    ESTADO_LIST_FAILED,
    ESTADO_ADD,
    ESTADO_ADD_SUCCESS,
    ESTADO_ADD_FAILED,
    ESTADO_UPDATE,
    ESTADO_UPDATE_SUCCESS,
    ESTADO_UPDATE_FAILED,
    ESTADO_LOAD,
    ESTADO_LOAD_SUCCESS,
    ESTADO_LOAD_FAILED,
    ESTADO_REMOVE,
    ESTADO_REMOVE_SUCCESS,
    ESTADO_REMOVE_FAILED
} from './constants';

// List
export const estadoList        = (filter) => ({ type: ESTADO_LIST, payload: filter,});
export const estadoListSuccess = (estados) => ({ type: ESTADO_LIST_SUCCESS, payload: estados });
export const estadoListFailed  = (error) => ({ type: ESTADO_LIST_FAILED, payload: error });

export const estadoLoad        = (id) => ({ type: ESTADO_LOAD, payload: { id } });
export const estadoLoadSuccess  = (estado) => ({ type: ESTADO_LOAD_SUCCESS, payload: { estado } });
export const estadoLoadFailed   = (error) => ({ type: ESTADO_LOAD_FAILED, payload: error });

// Add
export const estadoAdd         = (estado) => ({ type: ESTADO_ADD, payload: { estado } });
export const estadoAddSuccess  = (estado, estadoStatus) => ({ type: ESTADO_ADD_SUCCESS, estadoStatus: estadoStatus, payload: { estado } });
export const estadoAddFailed   = (error) => ({ type: ESTADO_ADD_FAILED, payload: error });

// Update
export const estadoUpdate         = (estado) => ({ type: ESTADO_UPDATE, payload: { estado } });
export const estadoUpdateSuccess  = (estado, estadoStatus) => ({ type: ESTADO_UPDATE_SUCCESS, estadoStatus: estadoStatus, payload: { estado } });
export const estadoUpdateFailed   = (error) => ({ type: ESTADO_UPDATE_FAILED, payload: error });

// remove
export const estadoRemove         = (estado) => ({ type: ESTADO_REMOVE, payload: { estado } });
export const estadoRemoveSuccess  = (estado, estadoDeletado) => ({ type: ESTADO_REMOVE_SUCCESS, estadoDeletado: estadoDeletado, payload: { estado } });
export const estadoRemoveFailed   = (error) => ({ type: ESTADO_REMOVE_FAILED, payload: error });