/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { SectionServices_Container, SectionServices_Items, SectionServices_Label } from '../../navbar-menu';
import ItemWithIcon from '../../_menus-items/item-with-icon';
import { URL_PORTAL_V2 } from '../../../../helpers/url';

const IconRouteReview = () => {
    return (
        <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0017 14.4999C15.0017 12.5108 15.7919 10.6031 17.1984 9.19662C18.4067 7.9883 19.9848 7.23487 21.6668 7.04651C21.672 8.20162 21.8732 9.31047 22.2386 10.3416C21.2296 10.4053 20.2746 10.8345 19.5554 11.5536C18.774 12.335 18.335 13.3949 18.335 14.4999V26.1666C18.335 28.1557 17.5448 30.0634 16.1383 31.4699C14.7318 32.8764 12.8241 33.6666 10.835 33.6666C8.84589 33.6666 6.93823 32.8764 5.53171 31.4699C4.12519 30.0634 3.33501 28.1557 3.33501 26.1666V15.0499C2.22252 14.6567 1.28485 13.8829 0.687744 12.8652C0.0906344 11.8476 -0.127467 10.6515 0.0719917 9.4886C0.27145 8.32566 0.875626 7.27068 1.77772 6.51013C2.67982 5.74958 3.82175 5.33244 5.00168 5.33244C6.1816 5.33244 7.32353 5.74958 8.22563 6.51013C9.12773 7.27068 9.7319 8.32566 9.93136 9.4886C10.1308 10.6515 9.91272 11.8476 9.31561 12.8652C8.7185 13.8829 7.78083 14.6567 6.66834 15.0499V26.1666C6.66834 27.2717 7.10733 28.3315 7.88873 29.1129C8.67013 29.8943 9.72994 30.3333 10.835 30.3333C11.9401 30.3333 12.9999 29.8943 13.7813 29.1129C14.5627 28.3315 15.0017 27.2717 15.0017 26.1666V14.4999Z"
                fill="#F0F2F4"
            />
            <path
                d="M21.6683 25.3333H26.6683V15.6631C27.6807 16.2484 28.8052 16.6614 30.0017 16.8619V25.3333H35.0017L28.335 33.6666L21.6683 25.3333Z"
                fill="#F0F2F4"
            />
            <path
                d="M36.8374 11.1265L40 14.2884L38.9552 15.3333L35.7933 12.1707C34.6168 13.1138 33.1535 13.6267 31.6457 13.6246C27.9773 13.6246 25 10.6473 25 6.97892C25 3.31051 27.9773 0.333252 31.6457 0.333252C35.3141 0.333252 38.2913 3.31051 38.2913 6.97892C38.2935 8.48674 37.7805 9.95007 36.8374 11.1265ZM35.3562 10.5786C36.2933 9.61494 36.8166 8.32313 36.8145 6.97892C36.8145 4.12276 34.5011 1.81007 31.6457 1.81007C28.7895 1.81007 26.4768 4.12276 26.4768 6.97892C26.4768 9.83434 28.7895 12.1478 31.6457 12.1478C32.9899 12.1499 34.2817 11.6265 35.2454 10.6894L35.3562 10.5786ZM32.5155 4.15525C32.2978 4.2536 32.1071 4.40331 31.96 4.59154C31.8128 4.77976 31.7136 5.00088 31.6706 5.23591C31.6277 5.47095 31.6425 5.71288 31.7137 5.94096C31.7848 6.16904 31.9102 6.37644 32.0792 6.54539C32.2481 6.71433 32.4555 6.83976 32.6836 6.91091C32.9117 6.98207 33.1536 6.99683 33.3887 6.95393C33.6237 6.91102 33.8448 6.81173 34.033 6.66458C34.2213 6.51743 34.371 6.32681 34.4693 6.10907C34.6634 6.73939 34.6409 7.41653 34.4054 8.03259C34.17 8.64864 33.7351 9.16817 33.1701 9.5084C32.6052 9.84863 31.9426 9.98997 31.2879 9.90992C30.6333 9.82986 30.0243 9.53301 29.5579 9.06666C29.0916 8.60031 28.7947 7.9913 28.7147 7.33666C28.6346 6.68202 28.776 6.01942 29.1162 5.45443C29.4564 4.88945 29.9759 4.45459 30.592 4.21914C31.208 3.98369 31.8852 3.96119 32.5155 4.15525Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const IconInformVehicleChange = () => {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.74599 5.67662C10.3994 2.46148 15.116 0.689412 20 0.697046C31.046 0.697046 40 9.57894 40 20.536C40.0065 24.5909 38.7544 28.5495 36.414 31.874L31 20.536H36C36 17.3803 35.0517 14.2963 33.2762 11.6778C31.5007 9.05934 28.9786 7.02534 26.032 5.83564C23.0855 4.64595 19.8483 4.35456 16.734 4.99871C13.6197 5.64286 10.7697 7.1933 8.54799 9.45197L6.74799 5.67861L6.74599 5.67662ZM33.254 35.3954C29.6006 38.6105 24.8839 40.3826 20 40.3749C8.95399 40.3749 0 31.493 0 20.536C0 16.3202 1.326 12.4119 3.586 9.19803L8.99999 20.536H3.99999C3.99994 23.6917 4.94824 26.7757 6.72375 29.3942C8.49927 32.0126 11.0214 34.0466 13.9679 35.2363C16.9145 36.426 20.1517 36.7174 23.266 36.0733C26.3803 35.4291 29.2303 33.8787 31.452 31.62L33.252 35.3934L33.254 35.3954Z"
                fill="#F0F2F4"
            />
            <path
                d="M27 26.4877H13V27.4796C13 27.7427 12.8946 27.995 12.7071 28.181C12.5196 28.3671 12.2652 28.4716 12 28.4716H11C10.7348 28.4716 10.4804 28.3671 10.2929 28.181C10.1053 27.995 9.99999 27.7427 9.99999 27.4796V18.5521L12.513 11.9041C12.6559 11.5258 12.9118 11.1999 13.2465 10.9698C13.5812 10.7397 13.9788 10.6165 14.386 10.6165H25.614C26.0212 10.6165 26.4187 10.7397 26.7534 10.9698C27.0881 11.1999 27.344 11.5258 27.487 11.9041L30 18.5521V27.4796C30 27.7427 29.8946 27.995 29.7071 28.181C29.5195 28.3671 29.2652 28.4716 29 28.4716H28C27.7347 28.4716 27.4804 28.3671 27.2929 28.181C27.1053 27.995 27 27.7427 27 27.4796V26.4877ZM12.136 18.5521H27.864L25.614 12.6004H14.386L12.136 18.5521ZM14.5 23.5118C14.8978 23.5118 15.2793 23.3551 15.5606 23.076C15.8419 22.797 16 22.4185 16 22.0239C16 21.6293 15.8419 21.2508 15.5606 20.9718C15.2793 20.6928 14.8978 20.536 14.5 20.536C14.1022 20.536 13.7206 20.6928 13.4393 20.9718C13.158 21.2508 13 21.6293 13 22.0239C13 22.4185 13.158 22.797 13.4393 23.076C13.7206 23.3551 14.1022 23.5118 14.5 23.5118ZM25.5 23.5118C25.8978 23.5118 26.2793 23.3551 26.5606 23.076C26.8419 22.797 27 22.4185 27 22.0239C27 21.6293 26.8419 21.2508 26.5606 20.9718C26.2793 20.6928 25.8978 20.536 25.5 20.536C25.1021 20.536 24.7206 20.6928 24.4393 20.9718C24.158 21.2508 24 21.6293 24 22.0239C24 22.4185 24.158 22.797 24.4393 23.076C24.7206 23.3551 25.1021 23.5118 25.5 23.5118Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const IconDocumentUpdate = () => {
    return (
        <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 16.4566C27.6757 15.3531 25.0518 15.0489 22.5367 15.5914C21.2733 15.8639 20.0729 16.3423 18.9803 16.9999H6.66667V20.3333H15.4193C14.7085 21.3549 14.1679 22.4807 13.8148 23.6666H6.66667V26.9999H13.3297C13.3305 29.3796 14.0593 31.7071 15.425 33.6666H1.655C1.2173 33.6635 0.798362 33.4884 0.488696 33.1791C0.17903 32.8697 0.00349108 32.451 0 32.0133V1.98659C0 1.07325 0.741667 0.333252 1.655 0.333252H21.6667L30 8.66658V16.4566ZM6.66667 6.99992V13.6666H13.3333V6.99992H6.66667ZM16.6667 11.9999V8.66658H23.3333V11.9999H16.6667Z"
                fill="#F0F2F4"
            />
            <path
                d="M25.0003 35.3333C20.3978 35.3333 16.667 31.6025 16.667 27C16.667 22.3975 20.3978 18.6667 25.0003 18.6667C29.6028 18.6667 33.3337 22.3975 33.3337 27C33.3337 31.6025 29.6028 35.3333 25.0003 35.3333ZM29.017 31.23C30.0229 30.2766 30.6565 28.9961 30.8042 27.618C30.9519 26.2399 30.6042 24.8543 29.8232 23.7093C29.0422 22.5643 27.8791 21.7347 26.5421 21.3693C25.2052 21.0039 23.7818 21.1264 22.527 21.715L23.3395 23.1775C23.9736 22.9019 24.6663 22.7884 25.3552 22.8473C26.0441 22.9062 26.7075 23.1356 27.2856 23.5148C27.8638 23.894 28.3384 24.4112 28.6669 25.0196C28.9953 25.628 29.1672 26.3086 29.167 27H26.667L29.017 31.23ZM27.4737 32.285L26.6612 30.8225C26.0271 31.0981 25.3343 31.2116 24.6454 31.1527C23.9565 31.0938 23.2931 30.8644 22.715 30.4852C22.1369 30.106 21.6622 29.5888 21.3338 28.9804C21.0053 28.372 20.8335 27.6914 20.8337 27H23.3337L20.9837 22.77C19.9777 23.7234 19.3442 25.0039 19.1964 26.382C19.0487 27.7601 19.3965 29.1457 20.1775 30.2907C20.9584 31.4357 22.1216 32.2653 23.4585 32.6307C24.7955 32.9961 26.2188 32.8736 27.4737 32.285Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const IconRefundRequest = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.5-6H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V6h2v2h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2z" />
        </svg>
    );
};

const SectionServices = () => {
    return (
        <SectionServices_Container>
            <SectionServices_Label>Serviços</SectionServices_Label>
            <SectionServices_Items>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/drivers-panel/_services/route-adjustment-request`}
                    label="Revisão de Trajeto"
                    disable={false}>
                    <IconRouteReview />
                </ItemWithIcon>
                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/drivers-panel/_services/refund-request`}
                    label="Revisão de Reembolso"
                    disable={false}>
                    <IconRefundRequest />
                </ItemWithIcon>
            </SectionServices_Items>
        </SectionServices_Container>
    );
};

export default SectionServices;
