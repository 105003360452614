
import { VEICULOMARCA_LOAD, VEICULOMARCA_LOAD_SUCCESS, VEICULOMARCA_LOAD_FAILED, VEICULOMARCA_LIST, VEICULOMARCA_LIST_SUCCESS, VEICULOMARCA_LIST_FAILED, VEICULOMARCA_ADD, VEICULOMARCA_ADD_FAILED, VEICULOMARCA_ADD_SUCCESS, VEICULOMARCA_REMOVE, VEICULOMARCA_REMOVE_FAILED, VEICULOMARCA_REMOVE_SUCCESS, VEICULOMARCA_UPDATE, VEICULOMARCA_UPDATE_SUCCESS, VEICULOMARCA_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    veiculoMarcas: {},
    veiculoMarca: {},
    loading: false,
    error: null,
    veiculoMarcaStatus: null,
    id: {}
};

const VeiculoMarcaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VEICULOMARCA_LIST:
            return { ...state, loading: true, error: null, veiculoMarcaDeletado: null };
        case VEICULOMARCA_LIST_SUCCESS:
            return { ...state, veiculoMarcas: action.payload, loading: false, error: null, veiculoMarcaDeletado: null };
        case VEICULOMARCA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoMarcaDeletado: null };
        case VEICULOMARCA_LOAD:
            return { ...state, loading: true, error: null, veiculoMarcaStatus: null, veiculoMarcaDeletado: null }
        case VEICULOMARCA_LOAD_SUCCESS:
            return { ...state, veiculoMarca: action.payload, loading: false, error: null, veiculoMarcaDeletado: null}
        case VEICULOMARCA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoMarcaDeletado: null };
        case VEICULOMARCA_ADD:
            return { ...state, veiculoMarca: action.payload, veiculoMarcaStatus: null, loading: true, error: null, veiculoMarcaDeletado: null}
        case VEICULOMARCA_ADD_SUCCESS:
            return { ...state, veiculoMarca: action.payload,  veiculoMarcaStatus: "Marca adicionado com sucesso", veiculoMarcaDeletado: null, loading: false, error: null };
        case VEICULOMARCA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case VEICULOMARCA_UPDATE:
            return { ...state, veiculoMarca: action.payload, veiculoMarcaStatus: null, loading: true, error: null}
        case VEICULOMARCA_UPDATE_SUCCESS:
            return { ...state, veiculoMarca: action.payload,  veiculoMarcaStatus: "Marca atualizado com sucesso", veiculoMarcaDeletado: null, loading: false, error: null };
        case VEICULOMARCA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoMarcaStatus: null };
        case VEICULOMARCA_REMOVE:
            return { ...state, veiculoMarca: action.payload, veiculoMarcaStatus: null, loading: true, error: null, veiculoMarcaDeletado: null}
        case VEICULOMARCA_REMOVE_SUCCESS:
            return { ...state, veiculoMarca: {}, veiculoMarcaStatus: null,  veiculoMarcaDeletado: "Marca removido com sucesso", loading: false, error: null };
        case VEICULOMARCA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoMarcaStatus: null, veiculoMarcaDeletado: null };
        default:
            return { ...state };
    }
};

export default VeiculoMarcaReducer;