import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { PERMISSAO_LOAD, PERMISSAO_LIST, PERMISSAO_ADD, PERMISSAO_UPDATE, PERMISSAO_REMOVE } from './constants';

import {
    permissaoListSuccess,
    permissaoListFailed,
    permissaoAddSuccess,
    permissaoAddFailed,
    permissaoUpdateSuccess,
    permissaoUpdateFailed,
    permissaoLoadSuccess,
    permissaoLoadFailed,
    permissaoRemoveSuccess,
    permissaoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* permissaoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/permissao/' + id, options);
        yield put(permissaoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(permissaoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* permissaoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/admin/permissao', options);
            yield put(permissaoListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(permissaoListFailed(message));
        }    
}


/**
 * permissao
 * @param {*} payload - pageNumber and pageSize
 */
function* permissaoAdd({ payload: { permissao }  }) {

    const options = {
        body: JSON.stringify(permissao) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/permissao', options);
        yield put(permissaoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(permissaoAddFailed(message));
    }    
}

/**
 * permissao
 * @param {*} payload - pageNumber and pageSize
 */
function* permissaoUpdate({ payload: { permissao }  }) {

    const options = {
        body: JSON.stringify(permissao) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/permissao/' + permissao?.id, options);
        yield put(permissaoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(permissaoUpdateFailed(message));
    }    
}

/**
 * permissao
 * @param {*} payload - pageNumber and pageSize
 */
function* permissaoRemove({ payload: { permissao }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/admin/permissao/' + permissao?.id, options);
        yield put(permissaoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(permissaoRemoveFailed(message));
    }    
}

export function* watchPermissaoLoad(): any{
    yield takeEvery(PERMISSAO_LOAD, permissaoLoad);
}

export function* watchPermissaoList(): any {
    yield takeEvery(PERMISSAO_LIST, permissaoList);
}

export function* watchPermissaoAdd(): any {
    yield takeEvery(PERMISSAO_ADD, permissaoAdd);
}

export function* watchPermissaoUpdate(): any {
    yield takeEvery(PERMISSAO_UPDATE, permissaoUpdate);
}

export function* watchPermissaoRemove(): any {
    yield takeEvery(PERMISSAO_REMOVE, permissaoRemove);
}

function* permissao(): any {
    yield all([fork(watchPermissaoList), fork(watchPermissaoAdd), fork(watchPermissaoUpdate), fork(watchPermissaoLoad), fork(watchPermissaoRemove)]);
}

export default permissao;