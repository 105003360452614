import { TIPO_OPERACAO_LOAD, TIPO_OPERACAO_LOAD_SUCCESS, TIPO_OPERACAO_LOAD_FAILED, TIPO_OPERACAO_LIST, TIPO_OPERACAO_LIST_SUCCESS, TIPO_OPERACAO_LIST_FAILED, TIPO_OPERACAO_ADD, TIPO_OPERACAO_ADD_FAILED, TIPO_OPERACAO_ADD_SUCCESS, TIPO_OPERACAO_REMOVE, TIPO_OPERACAO_REMOVE_FAILED, TIPO_OPERACAO_REMOVE_SUCCESS, TIPO_OPERACAO_UPDATE, TIPO_OPERACAO_UPDATE_SUCCESS, TIPO_OPERACAO_UPDATE_FAILED } from './constants';

// List
export const tipoOperacaoList        = (filter) => ({ type: TIPO_OPERACAO_LIST, payload: filter });
export const tipoOperacaoListSuccess = (tipoOperacoes) => ({ type: TIPO_OPERACAO_LIST_SUCCESS, payload: tipoOperacoes });
export const tipoOperacaoListFailed  = (error) => ({ type: TIPO_OPERACAO_LIST_FAILED, payload: error });

export const tipoOperacaoLoad        = (id) => ({ type: TIPO_OPERACAO_LOAD, payload: { id } });
export const tipoOperacaoLoadSuccess  = (tipoOperacao) => ({ type: TIPO_OPERACAO_LOAD_SUCCESS, payload: tipoOperacao });
export const tipoOperacaoLoadFailed   = (error) => ({ type: TIPO_OPERACAO_LOAD_FAILED, payload: error  });

// Add
export const tipoOperacaoAdd         = (tipoOperacao) => ({ type: TIPO_OPERACAO_ADD, payload: tipoOperacao });
export const tipoOperacaoAddSuccess  = (tipoOperacao, tipoOperacaoStatus) => ({ type: TIPO_OPERACAO_ADD_SUCCESS, tipoOperacaoStatus: tipoOperacaoStatus, payload: tipoOperacao });
export const tipoOperacaoAddFailed   = (error) => ({ type: TIPO_OPERACAO_ADD_FAILED, payload: error  });

// Update
export const tipoOperacaoUpdate         = (tipoOperacao) => ({ type: TIPO_OPERACAO_UPDATE, payload: tipoOperacao });
export const tipoOperacaoUpdateSuccess  = (tipoOperacao, tipoOperacaoStatus) => ({ type: TIPO_OPERACAO_UPDATE_SUCCESS, tipoOperacaoStatus: tipoOperacaoStatus, payload: tipoOperacao  });
export const tipoOperacaoUpdateFailed   = (error) => ({ type: TIPO_OPERACAO_UPDATE_FAILED, payload: error  });

// remove
export const tipoOperacaoRemove         = (tipoOperacaoId) => ({ type: TIPO_OPERACAO_REMOVE, payload: tipoOperacaoId});
export const tipoOperacaoRemoveSuccess  = (tipoOperacao, tipoOperacaoDeletado) => ({ type: TIPO_OPERACAO_REMOVE_SUCCESS, tipoOperacaoDeletado: tipoOperacaoDeletado, payload: tipoOperacao  });
export const tipoOperacaoRemoveFailed   = (error) => ({ type: TIPO_OPERACAO_REMOVE_FAILED, payload: error  });