/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import {
    SectionRegistrations_Container,
    SectionRegistrations_Items,
    SectionRegistrations_Label,
} from '../../navbar-menu';
import ItemWithIcon from '../../_menus-items/item-with-icon';
import { URL_PORTAL_V2, URL_PORTAL_DRIVER } from '../../../../helpers/url';
// import { useRouter } from "next/router";

const IconPortalDriver = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3196_4369)">
                <path d="M19.9999 3.33325C29.2049 3.33325 36.6666 10.7949 36.6666 19.9999C36.6666 29.2049 29.2049 36.6666 19.9999 36.6666C10.7949 36.6666 3.33325 29.2049 3.33325 19.9999C3.33325 10.7949 10.7949 3.33325 19.9999 3.33325ZM13.3333 21.6666L6.76992 21.6683C7.14124 24.6053 8.47897 27.3353 10.5724 29.4285C12.6659 31.5217 15.3962 32.859 18.3333 33.2299V26.6666C17.0072 26.6666 15.7354 26.1398 14.7977 25.2021C13.86 24.2644 13.3333 22.9927 13.3333 21.6666ZM33.2299 21.6683L26.6666 21.6666C26.6666 22.9927 26.1398 24.2644 25.2021 25.2021C24.2644 26.1398 22.9927 26.6666 21.6666 26.6666L21.6683 33.2299C24.605 32.8587 27.3349 31.5212 29.428 29.428C31.5212 27.3349 32.8587 24.605 33.2299 21.6683V21.6683ZM19.9999 6.66659C16.7522 6.66664 13.6162 7.8519 11.1803 9.99992C8.74447 12.148 7.17623 15.1111 6.76992 18.3333H13.3333C13.3333 17.8912 13.5088 17.4673 13.8214 17.1547C14.134 16.8422 14.5579 16.6666 14.9999 16.6666H24.9999C25.4419 16.6666 25.8659 16.8422 26.1784 17.1547C26.491 17.4673 26.6666 17.8912 26.6666 18.3333H33.2299C32.8236 15.1111 31.2554 12.148 28.8195 9.99992C26.3836 7.8519 23.2476 6.66664 19.9999 6.66659V6.66659Z" fill="#F0F2F4" />
            </g>
            <defs>
                <clipPath id="clip0_3196_4369">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

const IconBlockingReasons = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M29 38C27.1435 38 25.363 37.2625 24.0503 35.9497C22.7375 34.637 22 32.8565 22 31C22 29.1435 22.7375 27.363 24.0503 26.0503C25.363 24.7375 27.1435 24 29 24C30.8565 24 32.637 24.7375 33.9497 26.0503C35.2625 27.363 36 29.1435 36 31C36 32.8565 35.2625 34.637 33.9497 35.9497C32.637 37.2625 30.8565 38 29 38ZM27.1898 34.7912C27.9737 35.1663 28.8547 35.289 29.7113 35.1423C30.5679 34.9956 31.3579 34.5869 31.9724 33.9724C32.5869 33.3579 32.9956 32.5679 33.1423 31.7113C33.289 30.8547 33.1663 29.9737 32.7912 29.1898L27.1898 34.7912ZM25.2088 32.8102L30.8102 27.2102C30.0263 26.8351 29.1453 26.7124 28.2887 26.8591C27.4321 27.0058 26.6421 27.4145 26.0276 28.029C25.4131 28.6435 25.0044 29.4335 24.8577 30.2901C24.711 31.1467 24.8337 32.0277 25.2088 32.8116V32.8102Z" fill="#F0F2F4" />
            <path d="M4.20695 17.2098L11.5299 28.6898C11.794 28.9524 12.1521 29.0999 12.5256 29.1H18.3806C19.1978 23.8321 23.753 19.8 29.25 19.8C29.5871 19.8 29.9206 19.8152 30.25 19.8448V6.875C30.25 5.70139 29.2986 4.75 28.125 4.75C26.9514 4.75 26 5.70139 26 6.875V13.875L25.5 13.875V4C25.5 2.75736 24.4926 1.75 23.25 1.75C22.0074 1.75 21 2.75736 21 4V13.125H20.5V3.30017C20.5 2.02982 19.4702 1 18.1998 1C16.9199 1 15.8861 2.04484 15.8998 3.3247L16 12.7189H15.5L15.5 5.716C15.5 4.63021 14.6198 3.75 13.534 3.75C12.422 3.75 11.5313 4.67148 11.5691 5.78282L11.9426 16.7643V17.25H13.75C16.5114 17.25 18.75 19.4886 18.75 22.25C18.75 22.8023 18.3023 23.25 17.75 23.25H17.5483C17.1074 23.25 16.75 22.8926 16.75 22.4517C16.75 20.2471 14.9629 18.46 12.7583 18.46H11.9426L7.25303 14.3258C6.84878 14.2234 6.42308 14.2423 6.02961 14.38C5.63613 14.5178 5.29251 14.7683 5.04205 15.1L4.14076 16.2942C4.03899 16.4291 3.98965 16.5961 4.00181 16.7643C4.01397 16.9325 4.08682 17.0907 4.20695 17.2098Z" fill="#F0F2F4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3508 31.9V36.1C13.3508 36.4713 13.4992 36.8274 13.7633 37.09C14.0274 37.3525 14.3856 37.5 14.7591 37.5H20.4492C19.2325 35.9179 18.5124 33.9954 18.3043 31.9H13.3508Z" fill="#F0F2F4" />
        </svg>
    );
};

const IconSuspensionReasons = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M4.20695 18.2098L11.5299 29.6898C11.794 29.9524 12.1521 30.0999 12.5256 30.1H18.3806C19.1978 24.8321 23.753 20.8 29.25 20.8C29.5871 20.8 29.9206 20.8152 30.25 20.8448V7.875C30.25 6.70139 29.2986 5.75 28.125 5.75C26.9514 5.75 26 6.70139 26 7.875V14.875L25.5 14.875V5C25.5 3.75736 24.4926 2.75 23.25 2.75C22.0074 2.75 21 3.75736 21 5V14.125H20.5V4.30017C20.5 3.02982 19.4702 2 18.1998 2C16.9199 2 15.8861 3.04484 15.8998 4.3247L16 13.7189H15.5L15.5 6.716C15.5 5.63021 14.6198 4.75 13.534 4.75C12.422 4.75 11.5313 5.67148 11.5691 6.78282L11.9426 17.7643V18.25H13.75C16.5114 18.25 18.75 20.4886 18.75 23.25C18.75 23.8023 18.3023 24.25 17.75 24.25H17.5483C17.1074 24.25 16.75 23.8926 16.75 23.4517C16.75 21.2471 14.9629 19.46 12.7583 19.46H11.9426L7.25303 15.3258C6.84878 15.2234 6.42308 15.2423 6.02961 15.38C5.63613 15.5178 5.29251 15.7683 5.04205 16.1L4.14076 17.2942C4.03899 17.4291 3.98965 17.5961 4.00181 17.7643C4.01397 17.9325 4.08682 18.0907 4.20695 18.2098Z" fill="#F0F2F4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3508 32.9V37.1C13.3508 37.4713 13.4992 37.8274 13.7633 38.09C14.0274 38.3525 14.3856 38.5 14.7591 38.5H20.4492C19.2325 36.9179 18.5124 34.9954 18.3043 32.9H13.3508Z" fill="#F0F2F4" />
            <path d="M29.25 38.8C25.3839 38.8 22.25 35.6661 22.25 31.8C22.25 27.9339 25.3839 24.8 29.25 24.8C33.1161 24.8 36.25 27.9339 36.25 31.8C36.25 35.6661 33.1161 38.8 29.25 38.8ZM29.25 30.8102L27.2704 28.8299L26.2799 29.8204L28.2602 31.8L26.2799 33.7796L27.2704 34.7701L29.25 32.7898L31.2296 34.7701L32.2201 33.7796L30.2398 31.8L32.2201 29.8204L31.2296 28.8299L29.25 30.8102Z" fill="#F0F2F4" />
        </svg>
    );
};

const SectionDrivers = () => {
    return (
        <SectionRegistrations_Container>
            <SectionRegistrations_Label>Motoristas</SectionRegistrations_Label>
            <SectionRegistrations_Items>
                <ItemWithIcon
                    link={`${URL_PORTAL_DRIVER}/accreditation/accreditations`}
                    label="Portal Motorista"
                    disable={false}>
                    <IconPortalDriver />
                </ItemWithIcon>

                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/drivers-panel/_registrations/reason/block`}
                    label="Motivos de bloqueio"
                    disable={false}>
                    <IconBlockingReasons />
                </ItemWithIcon>

                <ItemWithIcon
                    link={`${URL_PORTAL_V2}/drivers-panel/_registrations/reason/suspension`}
                    label="Motivos de suspensão"
                    disable={false}>
                    <IconSuspensionReasons />
                </ItemWithIcon>
            </SectionRegistrations_Items>
        </SectionRegistrations_Container>
    );
};

export default SectionDrivers;