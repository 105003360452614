import { URL_PORTAL_V2 } from '../../../helpers/url';

const operadorMenu = [
    {
        header: 'Painel Administrativo',
        path: '/admin',
        name: 'Cadastros',
        icon: 'dripicons-document',
        children: [
            { name: 'Passageiros', path: '/passageiro' },
            { name: 'Locais', path: '/local' },
        ],
    },
    {
        header: 'Painel Operacional',
        path: '/operacional',
        name: 'Corridas',
        icon: 'mdi mdi-car-connected',
        children: [
            { name: 'Acompanhamento', path: '/corrida/acompanhamento' },
            { name: 'Histórico', path: '/corrida/historico' },
        ],
    },
    {
        name: 'Region',
        path: `${URL_PORTAL_V2}/control-panel/_services/region`,
    },
];

export const OperadorMenuCliente = () => operadorMenu;
