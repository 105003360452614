const theme = {
    colors: {
        primary: '#2C88D9',
        primaryDark: '#005CA7',
        primaryLight: '#6EB7FF',
        secondary: '#FD9843',
        secondaryDark: '#C56910',
        secondaryLight: '#FFC972',
        dark: '#3B3F4E',
        white: '#FFFEFB',
        grey: '#B4B4B4',
        greyLight: '#F0F2F4',
        greyLightAlternative: '#CCC',
        red: '#E71537',
        redDark: '#AC0011',
        redLight: '#FF5B61',
        blue: '#2C88D9',
        blueDark: '#005CA7',
        blueLight: '#6EB7FF',
        green: '#96CE45',
        greenDark: '#649D07',
        greenLight: '#CAFF76',
        yellow: '#F7B83D',
        yellowDark: '#C08800',
        yellowLight: '#FFEA6F',
        success: '#28A745',
        successDark: '#007717',
        successLight: '#64DA73',
        successUltraLight: '#B1ECB7',
        danger: '#DC3545',
        dangerDark: '#A3001E',
        dangerLight: '#FF6B70',
        dangerUltraLight: '#FFB5B5',
        warning: '#FFC107',
        warningDark: '#C79100',
        warningLight: '#FFF350',
        warningUltraLight: '#FFF8A5',
        info: '#17A2B8',
        infoDark: '#007388',
        infoLight: '#60D4EA',
        infoUltraLight: '#AFE9F3',

        transparent: 'rgba(255, 255, 255, 0)',
        backgroundDefault: '#E6E8EB',

        actions: {
            positive: '#64DA73',
            negative: '#FF6B70',
            informative: '#60D4EA',
            return: '#3B3F4E',
            upload: '#727CF5',
        },
    },
};

export default theme;
