import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { isUserAuthenticated, getLoggedInUser, getRoles } from '../../authUtils';

const loggedInUser = getLoggedInUser();

// handle auth and authorization

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/conta/login', state: { from: props.location } }} />;
            }

            const userRole = getRoles();

            if (roles) {
                var i;
                for (i = 0; i < roles.length; i++) {
                    if (userRole && userRole.indexOf(roles[i]) >= 0) {
                        if (!loggedInUser.usuario.dominioId) {
                            return <Redirect to="/sem-acesso" />;
                        }
                        // authorised so return component
                        return <Component {...props} />;
                    }
                }

                // role not authorised so redirect to home page
                return <Redirect to="/sem-acesso" />;
            }
        }}
    />
);
