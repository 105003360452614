import React from 'react';
import { PrivateRoute } from './privateRoute';

const ListaHorasOnline = React.lazy(() => import('../../../pages/backoffice/auditoria/ListaHorasOnline'));
const NewListaHorasOnline = React.lazy(() => import('../../../pages/backoffice/auditoria/NewListaHorasOnline.js'));
const AuditoriaList = React.lazy(() => import('../../../pages/backoffice/auditoria/AuditoriaList'));
const AuditoriaCadastro = React.lazy(() => import('../../../pages/backoffice/auditoria/AuditoriaCadastro'));

export const auditoriaRoute = [
    {
        path: '/monitoramento/horas',
        exact: true,
        roles: ['master', 'auditor', 'operadorcentral'],
        component: NewListaHorasOnline,
        route: PrivateRoute,
    },
    {
        path: '/motorista/oldhoras',
        exact: true,
        roles: ['master', 'auditor'],
        component: ListaHorasOnline,
        route: PrivateRoute,
    },
    {
        path: '/nao-conformidade',
        exact: true,
        roles: ['master', 'auditor'],
        component: AuditoriaList,
        route: PrivateRoute,
    },
    {
        path: '/nao-conformidade/adicionar',
        exact: true,
        roles: ['master', 'auditor'],
        component: AuditoriaCadastro,
        route: PrivateRoute,
    },
];
