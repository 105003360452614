import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { MALHA_LOAD, MALHA_LIST, MALHA_ADD, MALHA_UPDATE, MALHA_REMOVE } from './constants';

import {
    malhaListSuccess,
    malhaListFailed,
    malhaAddSuccess,
    malhaAddFailed,
    malhaUpdateSuccess,
    malhaUpdateFailed,
    malhaLoadSuccess,
    malhaLoadFailed,
    malhaRemoveSuccess,
    malhaRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* malhaLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/malha/' + id, options);
        yield put(malhaLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(malhaLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* malhaList({ payload: { pageNumber, pageSize, rota, motoristaId } }) {

        const options = {
            method: 'GET',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
        };
    
        try {

            if(rota !== null && rota !== undefined && rota !== ""){
                const response = yield call(fetchJSON, rota, options);            
                yield put(malhaListSuccess(response));
            }else if((pageNumber !== null && pageNumber !== undefined) && (pageSize !== null && pageSize !== undefined)){
                const response = yield call(fetchJSON, '/central/v1/api/v1/malha?pageNumber=' + pageNumber + '&pageSize=' + pageSize, options);
                yield put(malhaListSuccess(response));
            }else if (motoristaId){
                const response = yield call(fetchJSON, '/central/v1/api/v1/malha?motoristaId=' + motoristaId, options);
                yield put(malhaListSuccess(response));
            }
            else{
                const response = yield call(fetchJSON, '/central/v1/api/v1/malha', options);
                yield put(malhaListSuccess(response));
            }
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(malhaListFailed(message));
        }    
}


/**
 * malha
 * @param {*} payload - pageNumber and pageSize
 */
function* malhaAdd({ payload: { malha }  }) {

    const options = {
        body: JSON.stringify(malha) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/malha', options);
        yield put(malhaAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(malhaAddFailed(message));
    }    
}

/**
 * malha
 * @param {*} payload - pageNumber and pageSize
 */
function* malhaUpdate({ payload: { malha }  }) {

    const options = {
        body: JSON.stringify(malha) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/malha/' + malha?.id, options);
        yield put(malhaUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(malhaUpdateFailed(message));
    }    
}

/**
 * malha
 * @param {*} payload - pageNumber and pageSize
 */
function* malhaRemove({ payload: { malha }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/malha/' + malha?.id, options);
        yield put(malhaRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(malhaRemoveFailed(message));
    }    
}

export function* watchMalhaLoad(): any{
    yield takeEvery(MALHA_LOAD, malhaLoad);
}

export function* watchMalhaList(): any {
    yield takeEvery(MALHA_LIST, malhaList);
}

export function* watchMalhaAdd(): any {
    yield takeEvery(MALHA_ADD, malhaAdd);
}

export function* watchMalhaUpdate(): any {
    yield takeEvery(MALHA_UPDATE, malhaUpdate);
}

export function* watchMalhaRemove(): any {
    yield takeEvery(MALHA_REMOVE, malhaRemove);
}

function* malha(): any {
    yield all([fork(watchMalhaList), fork(watchMalhaAdd), fork(watchMalhaUpdate), fork(watchMalhaLoad), fork(watchMalhaRemove)]);
}

export default malha;