/* VEICULO */
export const ALTERARENDPASS_LIST           = 'ALTERARENDPASS_LIST';
export const ALTERARENDPASS_LIST_SUCCESS   = 'ALTERARENDPASS_LIST_SUCCESS';
export const ALTERARENDPASS_LIST_FAILED    = 'ALTERARENDPASS_LIST_FAILED';
export const ALTERARENDPASS_ADD            = 'ALTERARENDPASS_ADD';
export const ALTERARENDPASS_ADD_SUCCESS    = 'ALTERARENDPASS_ADD_SUCCESS';
export const ALTERARENDPASS_ADD_FAILED     = 'ALTERARENDPASS_ADD_FAILED';
export const ALTERARENDPASS_UPDATE         = 'ALTERARENDPASS_UPDATE';
export const ALTERARENDPASS_UPDATE_SUCCESS = 'ALTERARENDPASS_UPDATE_SUCCESS';
export const ALTERARENDPASS_UPDATE_FAILED  = 'ALTERARENDPASS_UPDATE_FAILED';
export const ALTERARENDPASS_REMOVE         = 'ALTERARENDPASS_REMOVE';
export const ALTERARENDPASS_REMOVE_SUCCESS = 'ALTERARENDPASS_REMOVE_SUCCESS';
export const ALTERARENDPASS_REMOVE_FAILED  = 'ALTERARENDPASS_REMOVE_FAILED';
export const ALTERARENDPASS_LOAD           = 'ALTERARENDPASS_LOAD'
export const ALTERARENDPASS_LOAD_SUCCESS   = 'ALTERARENDPASS_LOAD_SUCCESS';
export const ALTERARENDPASS_LOAD_FAILED    = 'ALTERARENDPASS_LOAD_FAILED';
export const ALTERARENDPASS_APROVAR             = 'ALTERARENDPASS_APROVAR'
export const ALTERARENDPASS_APROVAR_SUCCESS     = 'ALTERARENDPASS_APROVAR_SUCCESS'
export const ALTERARENDPASS_APROVAR_FAILED      = 'ALTERARENDPASS_APROVAR_FAILED'
export const ALTERARENDPASS_REJEITAR            = 'ALTERARENDPASS_REJEITAR'
export const ALTERARENDPASS_REJEITAR_SUCCESS    = 'ALTERARENDPASS_REJEITAR_SUCCESS'
export const ALTERARENDPASS_REJEITAR_FAILED     = 'ALTERARENDPASS_REJEITAR_FAILED'
export const ALTERARENDPASS_DOWNLOAD = 'ALTERARENDPASS_DOWNLOAD'
export const ALTERARENDPASS_DOWNLOAD_SUCCESS = 'ALTERARENDPASS_DOWNLOAD_SUCCESS'
export const ALTERARENDPASS_DOWNLOAD_FAILED = 'ALTERARENDPASS_DOWNLOAD_FAILED'