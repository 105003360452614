
import { MONITORAMENTO_LIST, MONITORAMENTO_LIST_FAILED, MONITORAMENTO_LIST_SUCCESS, MONITORAMENTO_TOTALIZADORES, MONITORAMENTO_TOTALIZADORES_FAILED, MONITORAMENTO_TOTALIZADORES_SUCCESS } from './constants';

const INIT_STATE = {
    error: null,
    success: null, 
    corridas: [],
    totalizadores: []
};

const MonitoramentoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case MONITORAMENTO_LIST: 
            return { ...state, loading: true, error: false, success: false }
        case MONITORAMENTO_LIST_SUCCESS:
            return { ...state, loading: false, error: false, success: true, corridas: action.payload }
        case MONITORAMENTO_LIST_FAILED:
            return { ...state, loading: false, error: action.payload, success: false }

        case MONITORAMENTO_TOTALIZADORES: 
            return { ...state, loading: true, error: false, success: false }
        case MONITORAMENTO_TOTALIZADORES_SUCCESS:
            return { ...state, loading: false, error: false, success: true, totalizadores: action.payload }
        case MONITORAMENTO_TOTALIZADORES_FAILED:
            return { ...state, loading: false, error: action.payload, success: false }

        default:
            return { ...state };
    }
};

export default MonitoramentoReducer;