/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import { SectionServices_Container, SectionServices_Label, SectionServices_Items } from '../../navbar-menu';

import { URL_PORTAL_V2 } from '../../../../helpers/url';

const RegionIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 34.5L6 37.5V10.5L18 7.5"
                stroke="#E5E5E5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M30 40.5L18 34.5V7.5L30 13.5V40.5Z"
                stroke="#E5E5E5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M30 13.5L42 10.5V37.5L30 40.5"
                stroke="#E5E5E5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const SectionServices = () => {
    return (
        <SectionServices_Container>
            <SectionServices_Label>Serviços</SectionServices_Label>
            <SectionServices_Items>
                <ItemWithIcon link={`${URL_PORTAL_V2}/control-panel/_services/region`} label="`Região" disable={false}>
                    <RegionIcon />
                </ItemWithIcon>
            </SectionServices_Items>
        </SectionServices_Container>
    );
};

export default SectionServices;
