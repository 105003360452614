/* VEICULO */
export const CENTRO_CUSTO_INTERNO_LIST = 'CENTRO_CUSTO_INTERNO_LIST';
export const CENTRO_CUSTO_INTERNO_LIST_SUCCESS = 'CENTRO_CUSTO_INTERNO_LIST_SUCCESS';
export const CENTRO_CUSTO_INTERNO_LIST_FAILED = 'CENTRO_CUSTO_INTERNO_LIST_FAILED';
export const CENTRO_CUSTO_INTERNO_ADD = 'CENTRO_CUSTO_INTERNO_ADD';
export const CENTRO_CUSTO_INTERNO_ADD_SUCCESS = 'CENTRO_CUSTO_INTERNO_ADD_SUCCESS';
export const CENTRO_CUSTO_INTERNO_ADD_FAILED = 'CENTRO_CUSTO_INTERNO_ADD_FAILED';
export const CENTRO_CUSTO_INTERNO_UPDATE = 'CENTRO_CUSTO_INTERNO_UPDATE';
export const CENTRO_CUSTO_INTERNO_UPDATE_SUCCESS = 'CENTRO_CUSTO_INTERNO_UPDATE_SUCCESS';
export const CENTRO_CUSTO_INTERNO_UPDATE_FAILED = 'CENTRO_CUSTO_INTERNO_UPDATE_FAILED';
export const CENTRO_CUSTO_INTERNO_REMOVE = 'CENTRO_CUSTO_INTERNO_REMOVE';
export const CENTRO_CUSTO_INTERNO_REMOVE_SUCCESS = 'CENTRO_CUSTO_INTERNO_REMOVE_SUCCESS';
export const CENTRO_CUSTO_INTERNO_REMOVE_FAILED = 'CENTRO_CUSTO_INTERNO_REMOVE_FAILED';
export const CENTRO_CUSTO_INTERNO_LOAD = 'CENTRO_CUSTO_INTERNO_LOAD';
export const CENTRO_CUSTO_INTERNO_LOAD_SUCCESS = 'CENTRO_CUSTO_INTERNO_LOAD_SUCCESS';
export const CENTRO_CUSTO_INTERNO_LOAD_FAILED = 'CENTRO_CUSTO_INTERNO_LOAD_FAILED';
