
import { TIPO_OPERACAO_LOAD, TIPO_OPERACAO_LOAD_SUCCESS, TIPO_OPERACAO_LOAD_FAILED, TIPO_OPERACAO_LIST, TIPO_OPERACAO_LIST_SUCCESS, TIPO_OPERACAO_LIST_FAILED, TIPO_OPERACAO_ADD, TIPO_OPERACAO_ADD_FAILED, TIPO_OPERACAO_ADD_SUCCESS, TIPO_OPERACAO_REMOVE, TIPO_OPERACAO_REMOVE_FAILED, TIPO_OPERACAO_REMOVE_SUCCESS, TIPO_OPERACAO_UPDATE, TIPO_OPERACAO_UPDATE_SUCCESS, TIPO_OPERACAO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    tipoOperacoes: {},
    tipoOperacao: {},
    loading: false,
    error: null,
    tipoOperacaoStatus: null,
    id: {}
};

const MalhaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TIPO_OPERACAO_LIST:
            return { ...state, loading: true, error: null, tipoOperacaoDeletado: null };
        case TIPO_OPERACAO_LIST_SUCCESS:
            return { ...state, tipoOperacoes: action.payload, loading: false, error: null, tipoOperacaoDeletado: null };
        case TIPO_OPERACAO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, tipoOperacaoDeletado: null };
        case TIPO_OPERACAO_LOAD:
            return { ...state, tipoOperacao: {}, loading: true, error: null, tipoOperacaoStatus: null, tipoOperacaoDeletado: null }
        case TIPO_OPERACAO_LOAD_SUCCESS:
            return { ...state, tipoOperacao: action.payload, loading: false, error: null, tipoOperacaoDeletado: null}
        case TIPO_OPERACAO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, tipoOperacaoDeletado: null };
        case TIPO_OPERACAO_ADD:
            return { ...state, tipoOperacao: action.payload, tipoOperacaoStatus: null, loading: true, error: null, tipoOperacaoDeletado: null}
        case TIPO_OPERACAO_ADD_SUCCESS:
            return { ...state, tipoOperacao: action.payload,  tipoOperacaoStatus: "Tipo de Operação criado com sucesso", tipoOperacaoDeletado: null, loading: false, error: null };
        case TIPO_OPERACAO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TIPO_OPERACAO_UPDATE:
            return { ...state,  tipoOperacao: action.payload, tipoOperacaoStatus: null, loading: true, error: null}
        case TIPO_OPERACAO_UPDATE_SUCCESS:
            return { ...state, tipoOperacao: action.payload,  tipoOperacaoStatus: "Tipo de Operação atualizado com sucesso", tipoOperacaoDeletado: null, loading: false, error: null };
        case TIPO_OPERACAO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, tipoOperacaoStatus: null };
        case TIPO_OPERACAO_REMOVE:
            return { ...state, tipoOperacaoId: action.payload, tipoOperacaoStatus: null, loading: true, error: null, tipoOperacaoDeletado: null}
        case TIPO_OPERACAO_REMOVE_SUCCESS:
            return { ...state, tipoOperacaoStatus: null,  tipoOperacaoDeletado: "Tipo de Operação removida com sucesso", loading: false, error: null };
        case TIPO_OPERACAO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, tipoOperacaoStatus: null, tipoOperacaoDeletado: null };

        default:
            return { ...state };
    }
};

export default MalhaReducer;