// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import usuarioSaga from './admin/usuario/saga';
import empresaSaga from './admin/empresa/saga';
import grupoAcessoSaga from './admin/grupoacesso/saga';
import permissaoSaga from './admin/permissao/saga';
import notificacaoSaga from './notificacao/saga';
import motoristaSaga from './motorista/saga';
import veiculoSaga from './veiculo/saga';
import trocaVeiculoSaga from './solicitacao/trocaveiculo/saga';
import ajusteKmSaga from './solicitacao/ajutekm/saga';
import corridaSaga from './ebooking/corrida/saga';
import reembolsoSaga from './solicitacao/reembolso/saga';
import tipoDespesaSaga from './solicitacao/tipodespesa/saga';
import atualizacaoDocumentoSaga from './solicitacao/atualizacaodocumento/saga';
import solicitacaoSaga from './solicitacao/saga';
import veiculoMarcaSaga from './veiculomarca/saga';
import veiculoCorSaga from './veiculocor/saga';
import despesaAprovadaSaga from './backoffice/despesaaprovada/saga';
import operacaoSaga from './operacao/saga';
import estacaoSaga from './backoffice/estacao/saga';
import estadoSaga from './backoffice/estado/saga';
import malhaSaga from './backoffice/malha/saga';
import auditoriaSaga from './backoffice/auditoria/saga';
import centroCustoSaga from './backoffice/centrocusto/saga';
import centroCustoInternoSaga from './backoffice/centrocustointerno/saga';
import grupoVeiculoSaga from './grupoveiculo/saga';
import passageiroSaga from './passageiro/saga';
import alterarEnderecoPassSaga from './backoffice/alterarenderecopass/saga';
import turnoSaga from './turno/saga';
import LocalSaga from './local/saga';
import TipoLocalSaga from './tipolocal/saga';
import TaxiMachineSaga from './taximachime/saga';
import SolicitacaCorridasSaga from './ebooking/solicitacaocorrida/saga';
import FaturamentoSaga from './backoffice/faturamento/saga';
import DominioSaga from './admin/dominio/saga';
import TarifaSaga from './ebooking/tarifa/saga';
import ParametroSaga from './admin/parametro/saga';
import LimitadorCorridaSaga from './ebooking/limitadorCorrida/saga';
import DashboardSaga from './dashboard/saga';
import ContratoSaga from './contrato/saga';
import DisponibilidadeSaga from './disponibilidade/saga';
import TipoOperacaoSaga from './backoffice/tipoOperacao/saga';
import FeriadoSaga from './backoffice/feriado/saga';
import MonitoramentoSaga from './monitoramento/saga';
import NewSolicitacaCorridasSaga from './ebooking/newSolicitacaoCorrida/saga';
import EstimativaCorridaSaga from './estimativaCorrida/saga';

export default function* rootSaga(getState: any): any {
    yield all([
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        usuarioSaga(),
        empresaSaga(),
        grupoAcessoSaga(),
        permissaoSaga(),
        notificacaoSaga(),
        motoristaSaga(),
        veiculoSaga(),
        trocaVeiculoSaga(),
        ajusteKmSaga(),
        corridaSaga(),
        reembolsoSaga(),
        tipoDespesaSaga(),
        atualizacaoDocumentoSaga(),
        solicitacaoSaga(),
        veiculoMarcaSaga(),
        veiculoCorSaga(),
        despesaAprovadaSaga(),
        estacaoSaga(),
        estadoSaga(),
        malhaSaga(),
        operacaoSaga(),
        grupoVeiculoSaga(),
        passageiroSaga(),
        alterarEnderecoPassSaga(),
        centroCustoSaga(),
        centroCustoInternoSaga(),
        turnoSaga(),
        LocalSaga(),
        TipoLocalSaga(),
        TaxiMachineSaga(),
        SolicitacaCorridasSaga(),
        auditoriaSaga(),
        FaturamentoSaga(),
        DominioSaga(),
        TarifaSaga(),
        ParametroSaga(),
        LimitadorCorridaSaga(),
        DashboardSaga(),
        ContratoSaga(),
        DisponibilidadeSaga(),
        TipoOperacaoSaga(),
        FeriadoSaga(),
        MonitoramentoSaga(),
        NewSolicitacaCorridasSaga(),
        EstimativaCorridaSaga(),
    ]);
}
