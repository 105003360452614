/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
// import InputText2 from '../../../input-forms/input-text-2'
import ItemWithoutIcon from '../../_menus-items/item-without-icon';

import { Divider, NavbarMenu_Container } from '../../navbar-menu';

import { UserFAQ_Container, UserFAQ_Label, UserFAQ_Icon } from './style';

// import IconSearch from '../../../../assets/icons/system/search-line.svg'
// import Link from 'next/link'
// import theme from '../../../../styles/theme'

const IconFAQ = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 14v2h2v-2h-2zM8.567 8.813l1.962.393A1.5 1.5 0 1 1 12 11h-1v2h1a3.5 3.5 0 1 0-3.433-4.187z" />
            </g>
        </svg>
    );
};

const UserFAQ = () => {
    return (
        <NavbarMenu_Container>
            <UserFAQ_Container>
                <UserFAQ_Label>Como podemos ajudar?</UserFAQ_Label>
                <Divider />
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        gap: '8px',
                    }}>
                    <UserFAQ_Icon>
                        <IconFAQ />
                    </UserFAQ_Icon>
                    {/* <InputText2 placeholder="Digite sua pesquisa..." /> */}
                    <ItemWithoutIcon link="/faq/pipe" label="Abrir um novo chamado" disable={false} />
                </div>
            </UserFAQ_Container>
        </NavbarMenu_Container>
    );
};

export default UserFAQ;
