/* DISPONIBILIDADE */
export const DISPONIBILIDADE_LIST           = 'DISPONIBILIDADE_LIST';
export const DISPONIBILIDADE_LIST_SUCCESS   = 'DISPONIBILIDADE_LIST_SUCCESS';
export const DISPONIBILIDADE_LIST_FAILED    = 'DISPONIBILIDADE_LIST_FAILED';
export const DISPONIBILIDADE_ADD            = 'DISPONIBILIDADE_ADD';
export const DISPONIBILIDADE_ADD_SUCCESS    = 'DISPONIBILIDADE_ADD_SUCCESS';
export const DISPONIBILIDADE_ADD_FAILED     = 'DISPONIBILIDADE_ADD_FAILED';
export const DISPONIBILIDADE_UPDATE         = 'DISPONIBILIDADE_UPDATE';
export const DISPONIBILIDADE_UPDATE_SUCCESS = 'DISPONIBILIDADE_UPDATE_SUCCESS';
export const DISPONIBILIDADE_UPDATE_FAILED  = 'DISPONIBILIDADE_UPDATE_FAILED';
export const DISPONIBILIDADE_REMOVE         = 'DISPONIBILIDADE_REMOVE';
export const DISPONIBILIDADE_REMOVE_SUCCESS = 'DISPONIBILIDADE_REMOVE_SUCCESS';
export const DISPONIBILIDADE_REMOVE_FAILED  = 'DISPONIBILIDADE_REMOVE_FAILED';
export const DISPONIBILIDADE_LOAD           = 'DISPONIBILIDADE_LOAD'
export const DISPONIBILIDADE_LOAD_SUCCESS   = 'DISPONIBILIDADE_LOAD_SUCCESS';
export const DISPONIBILIDADE_LOAD_FAILED    = 'DISPONIBILIDADE_LOAD_FAILED';

export const DISPONIBILIDADE_LIST_PIVOT_SUCCESS = 'DISPONIBILIDADE_LIST_PIVOT_SUCCESS';

export const DISPONIBILIDADE_CLEAR = 'DISPONIBILIDADE_CLEAR';