/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import ItemWithoutIcon from '../../_menus-items/item-without-icon';

import { SectionReports_Container, SectionReports_Label, SectionReports_Items } from '../../navbar-menu';

const SectionReports = () => {
    return (
        <SectionReports_Container>
            <SectionReports_Label>Relatórios</SectionReports_Label>
            <SectionReports_Items>
                <ItemWithoutIcon link="/motorista/documentos" label="Relação de Documentos Vencidos" disable={false} />
            </SectionReports_Items>
        </SectionReports_Container>
    );
};

export default SectionReports;
