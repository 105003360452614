/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';
import { URL_PORTAL_V2 } from '../../../../helpers/url';

import { SectionServices_Container, SectionServices_Label, SectionServices_Items } from '../../navbar-menu';

// -> Icon <--------------------------------------------------------------------------------------

const IconExpenses = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33317 19.6665C8.7752 19.6665 9.19912 19.8421 9.51168 20.1547C9.82424 20.4672 9.99984 20.8911 9.99984 21.3332C12.6184 21.3293 15.1615 22.2103 17.2165 23.8332H20.8332C23.0548 23.8332 25.0498 24.7998 26.4232 26.3332H31.6665C33.2419 26.3327 34.7852 26.7789 36.1174 27.6199C37.4496 28.4609 38.5161 29.6623 39.1932 31.0848C35.2515 36.2865 28.8698 39.6665 21.6665 39.6665C17.0165 39.6665 13.0832 38.6615 9.89984 36.9032C9.78324 37.2249 9.57024 37.5029 9.28987 37.6992C9.00949 37.8954 8.67541 38.0004 8.33317 37.9998H3.33317C2.89114 37.9998 2.46722 37.8242 2.15466 37.5117C1.8421 37.1991 1.6665 36.7752 1.6665 36.3332V21.3332C1.6665 20.8911 1.8421 20.4672 2.15466 20.1547C2.46722 19.8421 2.89114 19.6665 3.33317 19.6665H8.33317ZM10.0015 24.6665L9.99984 33.0365L10.0748 33.0898C13.0665 35.1898 16.9632 36.3332 21.6665 36.3332C26.6732 36.3332 31.3315 34.4065 34.7248 31.1165L34.9465 30.8948L34.7465 30.7282C33.9595 30.1118 33.0055 29.746 32.0082 29.6782L31.6665 29.6665H28.1482C28.2682 30.2032 28.3332 30.7598 28.3332 31.3332V32.9998H13.3332V29.6665L24.6498 29.6648L24.5932 29.5348C24.2738 28.8674 23.7825 28.297 23.1697 27.8823C22.5569 27.4675 21.8448 27.2233 21.1065 27.1748L20.8332 27.1665H15.9498C15.1754 26.3744 14.2503 25.7451 13.229 25.3158C12.2078 24.8865 11.111 24.6657 10.0032 24.6665H10.0015ZM6.6665 22.9998H4.99984V34.6665H6.6665V22.9998Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 1.4C12 1.0287 12.1463 0.672601 12.4068 0.41005C12.6673 0.1475 13.0205 0 13.3889 0H35.6111C35.9795 0 36.3327 0.1475 36.5932 0.41005C36.8537 0.672601 37 1.0287 37 1.4V25.6882C35.7759 25.0189 34.4007 24.6663 33 24.6667H27.7567C26.7809 23.5772 25.4913 22.7738 24.0296 22.4H31.4444V19.6H17.5556V21.4627C15.8877 20.4089 13.9757 19.7954 12 19.6848V1.4ZM17.5556 14V16.8H31.4444V14H17.5556ZM25.8889 7V9.8H31.4444V7H25.8889ZM16.8889 11.3333V9.66667H21.7778C21.8957 9.66667 22.0087 9.62277 22.092 9.54463C22.1754 9.46649 22.2222 9.36051 22.2222 9.25C22.2222 9.13949 22.1754 9.03351 22.092 8.95537C22.0087 8.87723 21.8957 8.83333 21.7778 8.83333H18.2222C17.6329 8.83333 17.0676 8.61384 16.6509 8.22314C16.2341 7.83244 16 7.30254 16 6.75C16 6.19747 16.2341 5.66756 16.6509 5.27686C17.0676 4.88616 17.6329 4.66667 18.2222 4.66667H19.1111V3H20.8889V4.66667H23.1111V6.33333H18.2222C18.1043 6.33333 17.9913 6.37723 17.908 6.45537C17.8246 6.53351 17.7778 6.63949 17.7778 6.75C17.7778 6.86051 17.8246 6.96649 17.908 7.04463C17.9913 7.12277 18.1043 7.16667 18.2222 7.16667H21.7778C22.3671 7.16667 22.9324 7.38616 23.3491 7.77686C23.7659 8.16756 24 8.69747 24 9.25C24 9.80253 23.7659 10.3324 23.3491 10.7231C22.9324 11.1138 22.3671 11.3333 21.7778 11.3333H20.8889V13H19.1111V11.3333H16.8889Z"
            />
        </svg>
    );
};

const SectionServices = () => {
    return (
        <SectionServices_Container>
            <SectionServices_Label>Serviços</SectionServices_Label>
            <SectionServices_Items>
                <ItemWithIcon link="/despesaaprovada" label="Despesas" disable={false}>
                    <IconExpenses />
                </ItemWithIcon>
            </SectionServices_Items>
        </SectionServices_Container>
    );
};

export default SectionServices;
