import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { FERIADO_LOAD, FERIADO_LIST, FERIADO_ADD, FERIADO_UPDATE, FERIADO_REMOVE } from './constants';

import {
    feriadoListSuccess,
    feriadoListFailed,
    feriadoAddSuccess,
    feriadoAddFailed,
    feriadoUpdateSuccess,
    feriadoUpdateFailed,
    feriadoLoadSuccess,
    feriadoLoadFailed,
    feriadoRemoveSuccess,
    feriadoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* feriadoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/feriado/' + id, options);
        yield put(feriadoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(feriadoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* feriadoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/feriado/filter', options);
            yield put(feriadoListSuccess(response));
        } catch (error) {
            yield put(feriadoListFailed(error.message));
        }    
}

/**
 * feriado
 * @param {*} payload - pageNumber and pageSize
 */
function* feriadoAdd({ payload: feriado  }) {

    const options = {
        body: JSON.stringify(feriado) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/feriado', options);
        yield put(feriadoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(feriadoAddFailed(message));
    }    
}

/**
 * feriado
 * @param {*} payload - pageNumber and pageSize
 */
function* feriadoUpdate({ payload: feriado  }) {

    const options = {
        body: JSON.stringify(feriado) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/feriado/' + feriado?.id, options);
        yield put(feriadoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(feriadoUpdateFailed(message));
    }    
}

/**
 * feriado
 * @param {*} payload - pageNumber and pageSize
 */
function* feriadoRemove({ payload: feriadoId  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/feriado/' + feriadoId, options);
        yield put(feriadoRemoveSuccess(feriadoId));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(feriadoRemoveFailed(message));
    }    
}

export function* watchCentroCustoLoad(): any{
    yield takeEvery(FERIADO_LOAD, feriadoLoad);
}

export function* watchCentroCustoList(): any {
    yield takeEvery([FERIADO_LIST], feriadoList);
}

export function* watchCentroCustoAdd(): any {
    yield takeEvery(FERIADO_ADD, feriadoAdd);
}

export function* watchCentroCustoUpdate(): any {
    yield takeEvery(FERIADO_UPDATE, feriadoUpdate);
}

export function* watchCentroCustoRemove(): any {
    yield takeEvery(FERIADO_REMOVE, feriadoRemove);
}

function* feriado(): any {
    yield all([
        fork(watchCentroCustoAdd), 
        fork(watchCentroCustoUpdate), 
        fork(watchCentroCustoLoad), 
        fork(watchCentroCustoRemove), 
        fork(watchCentroCustoList)
    ]);
}

export default feriado;