/* NOTIFICACAO */   
export const NOTIFICACAO_LIST              = 'NOTIFICACAO_LIST';
export const NOTIFICACAO_LIST_SUCCESS      = 'NOTIFICACAO_LIST_SUCCESS';
export const NOTIFICACAO_LIST_FAILED       = 'NOTIFICACAO_LIST_FAILED';
export const NOTIFICACAO_ADD               = 'NOTIFICACAO_ADD';
export const NOTIFICACAO_ADD_SUCCESS       = 'NOTIFICACAO_ADD_SUCCESS';
export const NOTIFICACAO_ADD_FAILED        = 'NOTIFICACAO_ADD_FAILED';
export const NOTIFICACAO_UPDATE            = 'NOTIFICACAO_UPDATE';
export const NOTIFICACAO_UPDATE_SUCCESS    = 'NOTIFICACAO_UPDATE_SUCCESS';
export const NOTIFICACAO_UPDATE_FAILED     = 'NOTIFICACAO_UPDATE_FAILED';
export const NOTIFICACAO_REMOVE            = 'NOTIFICACAO_REMOVE';
export const NOTIFICACAO_REMOVE_SUCCESS    = 'NOTIFICACAO_REMOVE_SUCCESS';
export const NOTIFICACAO_REMOVE_FAILED     = 'NOTIFICACAO_REMOVE_FAILED';
export const NOTIFICACAO_LOAD              = 'NOTIFICACAO_LOAD'
export const NOTIFICACAO_LOAD_SUCCESS      = 'NOTIFICACAO_LOAD_SUCCESS';
export const NOTIFICACAO_LOAD_FAILED       = 'NOTIFICACAO_LOAD_FAILED';
export const NOTIFICACAO_NAOLIDAS          = 'NOTIFICACAO_NAOLIDAS'
export const NOTIFICACAO_NAOLIDAS_SUCCESS  = 'NOTIFICACAO_NAOLIDAS_SUCCESS'
export const NOTIFICACAO_NAOLIDAS_FAILED   = 'NOTIFICACAO_NAOLIDAS_FAILED'