/* MOTORISTA */
export const OPERACAO_LIST           = 'OPERACAO_LIST';
export const OPERACAO_LIST_SUCCESS   = 'OPERACAO_LIST_SUCCESS';
export const OPERACAO_LIST_FAILED    = 'OPERACAO_LIST_FAILED';
export const OPERACAO_ADD            = 'OPERACAO_ADD';
export const OPERACAO_ADD_SUCCESS    = 'OPERACAO_ADD_SUCCESS';
export const OPERACAO_ADD_FAILED     = 'OPERACAO_ADD_FAILED';
export const OPERACAO_UPDATE         = 'OPERACAO_UPDATE';
export const OPERACAO_UPDATE_SUCCESS = 'OPERACAO_UPDATE_SUCCESS';
export const OPERACAO_UPDATE_FAILED  = 'OPERACAO_UPDATE_FAILED';
export const OPERACAO_REMOVE         = 'OPERACAO_REMOVE';
export const OPERACAO_REMOVE_SUCCESS = 'OPERACAO_REMOVE_SUCCESS';
export const OPERACAO_REMOVE_FAILED  = 'OPERACAO_REMOVE_FAILED';
export const OPERACAO_LOAD           = 'OPERACAO_LOAD'
export const OPERACAO_LOAD_SUCCESS   = 'OPERACAO_LOAD_SUCCESS';
export const OPERACAO_LOAD_FAILED    = 'OPERACAO_LOAD_FAILED';

export const OPERACAO_DETALHE_LOAD   = 'OPERACAO_DETALHE_LOAD'
export const OPERACAO_DETALHE_NEW    = 'OPERACAO_DETALHE_NEW'

export const OPERACAO_DETALHE_ADD    = 'OPERACAO_DETALHE_ADD'
export const OPERACAO_DETALHE_ADD_SUCCESS = 'OPERACAO_DETALHE_ADD_SUCCESS'
export const OPERACAO_DETALHE_ADD_FAILED = 'OPERACAO_DETALHE_ADD_FAILED'

export const OPERACAO_DETALHE_UPDATE = 'OPERACAO_DETALHE_UPDATE'
export const OPERACAO_DETALHE_UPDATE_SUCCESS = 'OPERACAO_DETALHE_UPDATE_SUCCESS'
export const OPERACAO_DETALHE_UPDATE_FAILED  = 'OPERACAO_DETALHE_UPDATE_FAILED'

export const OPERACAO_DETALHE_REMOVE = 'OPERACAO_DETALHE_REMOVE'
export const OPERACAO_DETALHE_REMOVE_SUCCESS = 'OPERACAO_DETALHE_REMOVE_SUCCESS'
export const OPERACAO_DETALHE_REMOVE_FAILED  = 'OPERACAO_DETALHE_REMOVE_FAILED'
export const OPERACAO_DETALHE_REMOVE_START   = 'OPERACAO_DETALHE_REMOVE_START'

export const OPERACAO_DETALHE_CANCEL = 'OPERACAO_DETALHE_CANCEL'

export const OPERACAO_DETALHE_LIST = 'OPERACAO_DETALHE_LIST'
export const OPERACAO_DETALHE_LIST_SUCCESS = 'OPERACAO_DETALHE_LIST_SUCCESS'
export const OPERACAO_DETALHE_LIST_FAILED = 'OPERACAO_DETALHE_LIST_FAILED'