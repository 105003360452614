/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
// import Link from "next/link";

import { ItemWithIcon_Container, ItemWithIcon_ItemBox, ItemWithIcon_ItemIcon, ItemWithIcon_ItemLabel } from './style';
import theme from '../../../styles/theme';
import { useEffect } from 'react';

// interface IItemWithIcon {
//   link: string;
//   children: React.ReactNode;
//   label: string;
//   disable: boolean;
// }

// const initialProps: IItemWithIcon = {
//   link: "/",
//   children: "",
//   label: "",
//   disable: false,
// };

import { allRoutesPath } from '../../../helpers/util';

const ItemWithIcon = (props) => {
    const paths = allRoutesPath();
    return (
        <>
            {(props.aLink || props.link.includes('http')) ? (
                <Link to={{ pathname: props.link }} target="_parent" style={{ textDecoration: 'none' }} href={props.link}>
                    <ItemWithIcon_Container
                        style={{
                            cursor: props.disable ? 'default' : 'cursor',
                            display: paths.includes(props.link) ? 'flex' : 'none',
                        }}>
                        <ItemWithIcon_ItemBox style={props.disable ? { background: theme.colors.grey } : {}}>
                            <ItemWithIcon_ItemIcon>{props.children}</ItemWithIcon_ItemIcon>
                        </ItemWithIcon_ItemBox>
                        <ItemWithIcon_ItemLabel style={props.disable ? { color: theme.colors.grey } : {}}>
                            {props.label}
                        </ItemWithIcon_ItemLabel>
                    </ItemWithIcon_Container>
                </Link>
            ) : (
                <Link to={props.link} style={{ textDecoration: 'none' }}>
                    <ItemWithIcon_Container
                        style={{
                            cursor: props.disable ? 'default' : 'cursor',
                            display: paths.includes(props.link) ? 'flex' : 'none',
                        }}>
                        <ItemWithIcon_ItemBox style={props.disable ? { background: theme.colors.grey } : {}}>
                            <ItemWithIcon_ItemIcon>{props.children}</ItemWithIcon_ItemIcon>
                        </ItemWithIcon_ItemBox>
                        <ItemWithIcon_ItemLabel style={props.disable ? { color: theme.colors.grey } : {}}>
                            {props.label}
                        </ItemWithIcon_ItemLabel>
                    </ItemWithIcon_Container>
                </Link>
            )}
        </>
    );
};
export default ItemWithIcon;
