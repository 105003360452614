import { 
    MALHA_LIST,
    MALHA_LIST_SUCCESS,
    MALHA_LIST_FAILED,
    MALHA_ADD,
    MALHA_ADD_SUCCESS,
    MALHA_ADD_FAILED,
    MALHA_UPDATE,
    MALHA_UPDATE_SUCCESS,
    MALHA_UPDATE_FAILED,
    MALHA_LOAD,
    MALHA_LOAD_SUCCESS,
    MALHA_LOAD_FAILED,
    MALHA_REMOVE,
    MALHA_REMOVE_SUCCESS,
    MALHA_REMOVE_FAILED
} from './constants';

// List
export const malhaList        = (pageNumber, pageSize, rota, motoristaId) => ({ type: MALHA_LIST, payload: { pageNumber, pageSize, rota, motoristaId },});
export const malhaListSuccess = (malhas) => ({ type: MALHA_LIST_SUCCESS, payload: { malhas } });
export const malhaListFailed  = (error) => ({ type: MALHA_LIST_FAILED, payload: error  });

export const malhaLoad        = (id) => ({ type: MALHA_LOAD, payload: { id } });
export const malhaLoadSuccess  = (malha) => ({ type: MALHA_LOAD_SUCCESS, payload: { malha } });
export const malhaLoadFailed   = (error) => ({ type: MALHA_LOAD_FAILED, payload: error  });

// Add
export const malhaAdd         = (malha) => ({ type: MALHA_ADD, payload: { malha } });
export const malhaAddSuccess  = (malha, malhaStatus) => ({ type: MALHA_ADD_SUCCESS, malhaStatus: malhaStatus, payload: { malha } });
export const malhaAddFailed   = (error) => ({ type: MALHA_ADD_FAILED, payload: error  });

// Update
export const malhaUpdate         = (malha) => ({ type: MALHA_UPDATE, payload: { malha } });
export const malhaUpdateSuccess  = (malha, malhaStatus) => ({ type: MALHA_UPDATE_SUCCESS, malhaStatus: malhaStatus, payload: { malha } });
export const malhaUpdateFailed   = (error) => ({ type: MALHA_UPDATE_FAILED, payload: error  });

// remove
export const malhaRemove         = (malha) => ({ type: MALHA_REMOVE, payload: { malha } });
export const malhaRemoveSuccess  = (malha, malhaDeletado) => ({ type: MALHA_REMOVE_SUCCESS, malhaDeletado: malhaDeletado, payload: { malha } });
export const malhaRemoveFailed   = (error) => ({ type: MALHA_REMOVE_FAILED, payload: error  });