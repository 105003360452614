/* eslint-disable react/jsx-pascal-case */
import React from 'react';

/* 
  Components
*/

import ItemWithIcon from '../../_menus-items/item-with-icon';
import { SectionServices_Container, SectionServices_Label, SectionServices_Items } from '../../navbar-menu';

/* 
  Icons
 */

const IconInvoice = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.0003 38.3334C25.3978 38.3334 21.667 34.6026 21.667 30.0001C21.667 25.3976 25.3978 21.6667 30.0003 21.6667C34.6028 21.6667 38.3337 25.3976 38.3337 30.0001C38.3337 34.6026 34.6028 38.3334 30.0003 38.3334ZM34.017 34.2301C35.0229 33.2766 35.6565 31.9962 35.8042 30.6181C35.9519 29.24 35.6042 27.8543 34.8232 26.7093C34.0422 25.5643 32.8791 24.7348 31.5421 24.3694C30.2052 24.004 28.7818 24.1265 27.527 24.7151L28.3395 26.1776C28.9736 25.902 29.6663 25.7885 30.3552 25.8474C31.0441 25.9063 31.7075 26.1357 32.2856 26.5149C32.8638 26.8941 33.3384 27.4112 33.6669 28.0197C33.9953 28.6281 34.1672 29.3087 34.167 30.0001H31.667L34.017 34.2301ZM32.4737 35.2851L31.6612 33.8226C31.0271 34.0982 30.3343 34.2116 29.6454 34.1528C28.9565 34.0939 28.2931 33.8645 27.715 33.4852C27.1369 33.106 26.6622 32.5889 26.3338 31.9805C26.0053 31.3721 25.8335 30.6915 25.8337 30.0001H28.3337L25.9837 25.7701C24.9777 26.7235 24.3442 28.004 24.1964 29.3821C24.0487 30.7602 24.3965 32.1458 25.1775 33.2908C25.9584 34.4358 27.1216 35.2653 28.4585 35.6308C29.7955 35.9962 31.2188 35.8736 32.4737 35.2851Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66667 36.6667C6.62171 36.6667 6.57694 36.6649 6.53247 36.6613C6.5731 36.6646 6.61397 36.6664 6.655 36.6667H6.66667ZM5.4887 36.1791C5.17614 35.8666 5 35.442 5 35V35.0133C5.00349 35.451 5.17903 35.8698 5.4887 36.1791ZM5 4.99999C5 4.55797 5.17559 4.13404 5.48816 3.82148C5.80072 3.50892 6.22464 3.33333 6.66667 3.33333H6.655C5.74167 3.33333 5 4.07333 5 4.98666V4.99999Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5367 18.5915C30.0518 18.049 32.6757 18.3532 35 19.4567V11.6667L26.6667 3.33333H6.66667C6.22464 3.33333 5.80072 3.50892 5.48816 3.82148C5.17559 4.13404 5 4.55797 5 4.99999V35C5 35.442 5.17614 35.8666 5.4887 36.1791C5.76947 36.4599 6.13966 36.6297 6.53247 36.6613C6.57694 36.6649 6.62171 36.6667 6.66667 36.6667H20.425C19.0593 34.7072 18.3305 32.3796 18.3297 30H11.6667V26.6667H18.8148C19.1679 25.4808 19.7085 24.3549 20.4193 23.3333H11.6667V20H23.9803C25.0729 19.3424 26.2733 18.864 27.5367 18.5915ZM21.6667 15V11.6667H28.3333V15H21.6667ZM12.6667 15.6667V14.3333H16.3333C16.4217 14.3333 16.5065 14.2982 16.569 14.2357C16.6315 14.1732 16.6667 14.0884 16.6667 14C16.6667 13.9116 16.6315 13.8268 16.569 13.7643C16.5065 13.7018 16.4217 13.6667 16.3333 13.6667H13.6667C13.2246 13.6667 12.8007 13.4911 12.4882 13.1785C12.1756 12.8659 12 12.442 12 12C12 11.558 12.1756 11.134 12.4882 10.8215C12.8007 10.5089 13.2246 10.3333 13.6667 10.3333H14.3333V8.99999H15.6667V10.3333H17.3333V11.6667H13.6667C13.5783 11.6667 13.4935 11.7018 13.431 11.7643C13.3685 11.8268 13.3333 11.9116 13.3333 12C13.3333 12.0884 13.3685 12.1732 13.431 12.2357C13.4935 12.2982 13.5783 12.3333 13.6667 12.3333H16.3333C16.7754 12.3333 17.1993 12.5089 17.5118 12.8215C17.8244 13.134 18 13.558 18 14C18 14.442 17.8244 14.8659 17.5118 15.1785C17.1993 15.4911 16.7754 15.6667 16.3333 15.6667H15.6667V17H14.3333V15.6667H12.6667Z"
            />
        </svg>
    );
};

const IconCalendarAvailable = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.3333 4.99991H34.9999C35.4419 4.99991 35.8659 5.17551 36.1784 5.48807C36.491 5.80063 36.6666 6.22455 36.6666 6.66658V14.9999H3.33325V6.66658C3.33325 6.22455 3.50885 5.80063 3.82141 5.48807C4.13397 5.17551 4.55789 4.99991 4.99992 4.99991H11.6666V1.66658H14.9999V4.99991H24.9999V1.66658H28.3333V4.99991Z" />
            <path d="M3.33325 18.3332H36.6666V23.8947C35.0077 21.5389 32.2669 19.9999 29.1666 19.9999C24.1038 19.9999 19.9999 24.1038 19.9999 29.1666C19.9999 31.3827 20.7862 33.415 22.0951 34.9999H4.99992C4.55789 34.9999 4.13397 34.8243 3.82141 34.5118C3.50885 34.1992 3.33325 33.7753 3.33325 33.3332V18.3332Z" />
            <path d="M29.5 35.9999C25.9101 35.9999 23 33.0899 23 29.4999C23 25.91 25.9101 22.9999 29.5 22.9999C33.0899 22.9999 36 25.91 36 29.4999C36 33.0899 33.0899 35.9999 29.5 35.9999ZM28.852 32.0999L33.4474 27.5038L32.5283 26.5847L28.852 30.2617L27.0131 28.4229L26.094 29.342L28.852 32.0999Z" />
        </svg>
    );
};

const IconInvoiceHistory = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 1.99991C34.4184 1.99991 38 5.58151 38 9.99991C38 14.4183 34.4184 17.9999 30 17.9999C25.5816 17.9999 22 14.4183 22 9.99991H23.6C23.6 13.5343 26.4656 16.3999 30 16.3999C33.5344 16.3999 36.4 13.5343 36.4 9.99991C36.4 6.46551 33.5344 3.59991 30 3.59991C28.0288 3.59991 26.2656 4.49111 25.092 5.89191L26.8 7.59991H22V2.79991L23.9576 4.75671C25.424 3.06871 27.5872 1.99991 30 1.99991ZM30.8 5.99991V9.66791L33.3944 12.2623L32.2624 13.3943L29.2 10.3303V5.99991H30.8Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3333 9.99991H18.3297C18.3297 10.0977 18.3309 10.1956 18.3333 10.2935V9.99991Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.3333 36.6666H6.66667L6.63753 36.6663C6.20605 36.6588 5.79384 36.4841 5.48816 36.1784C5.17559 35.8659 5 35.4419 5 34.9999V4.99991C5 4.55788 5.17559 4.13396 5.48816 3.8214C5.67283 3.6369 5.89657 3.49987 6.13994 3.41866C6.30464 3.3637 6.47834 3.33431 6.655 3.33308H20.425C19.0593 5.29261 18.3305 7.62022 18.3297 9.99991C18.3297 10.0977 18.3309 10.1956 18.3333 10.2935C18.3355 10.3797 18.3386 10.4658 18.3426 10.5521C18.4635 13.1222 19.4306 15.5802 21.0935 17.5437C21.9211 18.5209 22.898 19.3484 23.9806 19.9999H11.6667V23.3332H28.3333V21.5514C30.6004 21.8779 32.9201 21.5305 35 20.5431V34.9999C35 35.4419 34.8244 35.8659 34.5118 36.1784C34.1993 36.491 33.7754 36.6666 33.3333 36.6666ZM11.6667 26.6666V29.9999H28.3333V26.6666H11.6667ZM11.7778 16.4999V14.9999H16.0556C16.1587 14.9999 16.2576 14.9604 16.3305 14.8901C16.4035 14.8197 16.4444 14.7244 16.4444 14.6249C16.4444 14.5255 16.4035 14.4301 16.3305 14.3597C16.2576 14.2894 16.1587 14.2499 16.0556 14.2499H12.9444C12.4287 14.2499 11.9342 14.0524 11.5695 13.7007C11.2049 13.3491 11 12.8722 11 12.3749C11 11.8776 11.2049 11.4007 11.5695 11.0491C11.9342 10.6975 12.4287 10.4999 12.9444 10.4999H13.7222V8.99991H15.2778V10.4999H17.2222V11.9999H12.9444C12.8413 11.9999 12.7424 12.0394 12.6695 12.1097C12.5965 12.1801 12.5556 12.2755 12.5556 12.3749C12.5556 12.4744 12.5965 12.5697 12.6695 12.6401C12.7424 12.7104 12.8413 12.7499 12.9444 12.7499H16.0556C16.5713 12.7499 17.0658 12.9475 17.4305 13.2991C17.7951 13.6507 18 14.1276 18 14.6249C18 15.1222 17.7951 15.5991 17.4305 15.9507C17.0658 16.3024 16.5713 16.4999 16.0556 16.4999H15.2778V17.9999H13.7222V16.4999H11.7778Z"
            />
        </svg>
    );
};

const SectionServices = () => {
    return (
        <SectionServices_Container>
            <SectionServices_Label>Serviços</SectionServices_Label>
            <SectionServices_Items>
                <ItemWithIcon link="/faturamento/calcularfaturamento" label="Gerar Faturamento Rede" disable={false}>
                    <IconInvoice />
                </ItemWithIcon>
                <ItemWithIcon
                    link="/faturamento/calcularfaturamentofixo"
                    label="Gerar Faturamento Fixo"
                    disable={false}>
                    <IconInvoice />
                </ItemWithIcon>

                <ItemWithIcon link="/disponibilidade" label="Disponiblidade" disable={false}>
                    <IconCalendarAvailable />
                </ItemWithIcon>

                <ItemWithIcon link="/faturamento/fatura" label="Histórico das Faturas" disable={false}>
                    <IconInvoiceHistory />
                </ItemWithIcon>
            </SectionServices_Items>
        </SectionServices_Container>
    );
};

export default SectionServices;
