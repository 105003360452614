import React from 'react';
import { PrivateRoute } from './privateRoute';

const AlterarEnderecoPassageiroList = React.lazy(() =>
    import('../../../pages/backoffice/alterarenderecopass/AlterarEnderecoPassList')
);
const aprovacaoAlterarEnderecoPassageiro = React.lazy(() =>
    import('../../../pages/backoffice/alterarenderecopass/aprovacaoAlterarEndPass')
);
const AprovacaoList = React.lazy(() => import('../../../pages/backoffice/aprovacao/AprovacaoList'));
const AprovacaoReembolso = React.lazy(() => import('../../../pages/backoffice/aprovacao/reembolso/AprovacaoReembolso'));
const AprovacaoTrocaVeiculo = React.lazy(() =>
    import('../../../pages/backoffice/aprovacao/trocaVeiculo/aprovacaoTrocaVeiculo')
);
const AprovacaoAjusteKm = React.lazy(() => import('../../../pages/backoffice/aprovacao/ajusteKm/aprovacaoAjusteKm'));
const AprovacaoAtualizacaoDoc = React.lazy(() =>
    import('../../../pages/backoffice/aprovacao/atualizacaoDocumentos/aprovacaoAtualizacaoDoc')
);
const ListaDocumentos = React.lazy(() => import('../../../pages/documentos/ListaDocumentos'));
const MotoristaAprovacao = React.lazy(() => import('../../../pages/motorista/MotoristaAprovacao'));

export const aprovacaoRoute = [
    {
        path: '/alterarendpass',
        exact: true,
        roles: ['master', 'aprovador'],
        component: AlterarEnderecoPassageiroList,
        route: PrivateRoute,
    },
    {
        path: '/alterarendpass/aprovacao/:id',
        exact: true,
        roles: ['master', 'aprovador'],
        component: aprovacaoAlterarEnderecoPassageiro,
        route: PrivateRoute,
    },
    { path: '/aprovacao', exact: true, roles: ['master', 'aprovador'], component: AprovacaoList, route: PrivateRoute },
    {
        path: '/reembolso/aprovar/:id',
        exact: true,
        roles: ['master', 'aprovador'],
        component: AprovacaoReembolso,
        route: PrivateRoute,
    },
    {
        path: '/trocaveiculo/aprovar/:id',
        exact: true,
        roles: ['master', 'aprovador'],
        component: AprovacaoTrocaVeiculo,
        route: PrivateRoute,
    },
    {
        path: '/ajustekm/aprovar/:id',
        exact: true,
        roles: ['master', 'aprovador'],
        component: AprovacaoAjusteKm,
        route: PrivateRoute,
    },
    {
        path: '/atualizacaodocumento/aprovar/:id',
        exact: true,
        roles: ['master', 'aprovador'],
        component: AprovacaoAtualizacaoDoc,
        route: PrivateRoute,
    },
    {
        path: '/motorista/documentos',
        exact: true,
        roles: ['master', 'aprovador'],
        component: ListaDocumentos,
        route: PrivateRoute,
    },
    {
        path: '/motorista/aprovacoes',
        exact: true,
        roles: ['master', 'aprovador'],
        component: MotoristaAprovacao,
        route: PrivateRoute,
    },
];
