import { 
    TARIFA_LIST,
    TARIFA_LIST_SUCCESS,
    TARIFA_LIST_FAILED,
    TARIFA_ADD,
    TARIFA_ADD_SUCCESS,
    TARIFA_ADD_FAILED,
    TARIFA_UPDATE,
    TARIFA_UPDATE_SUCCESS,
    TARIFA_UPDATE_FAILED,
    TARIFA_LOAD,
    TARIFA_LOAD_SUCCESS,
    TARIFA_LOAD_FAILED,
    TARIFA_REMOVE,
    TARIFA_REMOVE_SUCCESS,
    TARIFA_REMOVE_FAILED
} from './constants';

// List
export const tarifaList        = (filter) => ({ type: TARIFA_LIST, payload: filter});
export const tarifaListSuccess = (tarifas) => ({ type: TARIFA_LIST_SUCCESS, payload: tarifas });
export const tarifaListFailed  = (error) => ({ type: TARIFA_LIST_FAILED, payload: error });

export const tarifaLoad        = (id) => ({ type: TARIFA_LOAD, payload: id });
export const tarifaLoadSuccess  = (tarifa) => ({ type: TARIFA_LOAD_SUCCESS, payload: tarifa });
export const tarifaLoadFailed   = (error) => ({ type: TARIFA_LOAD_FAILED, payload: error });

// Add
export const tarifaAdd         = (tarifa) => ({ type: TARIFA_ADD, payload: tarifa });
export const tarifaAddSuccess  = (tarifa, tarifaStatus) => ({ type: TARIFA_ADD_SUCCESS, tarifaStatus: tarifaStatus, payload: tarifa });
export const tarifaAddFailed   = (error) => ({ type: TARIFA_ADD_FAILED, payload: error });

// Update
export const tarifaUpdate         = (tarifa) => ({ type: TARIFA_UPDATE, payload: tarifa });
export const tarifaUpdateSuccess  = (tarifa, tarifaStatus) => ({ type: TARIFA_UPDATE_SUCCESS, tarifaStatus: tarifaStatus, payload: tarifa});
export const tarifaUpdateFailed   = (error) => ({ type: TARIFA_UPDATE_FAILED, payload: error });

// remove
export const tarifaRemove         = (tarifa) => ({ type: TARIFA_REMOVE, payload: tarifa });
export const tarifaRemoveSuccess  = (tarifa, tarifaDeletado) => ({ type: TARIFA_REMOVE_SUCCESS, tarifaDeletado: tarifaDeletado, payload: tarifa });
export const tarifaRemoveFailed   = (error) => ({ type: TARIFA_REMOVE_FAILED, payload: error });