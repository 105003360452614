import styled from "styled-components";
import theme from '../../../../styles/theme'

export const UserFAQ_Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const UserFAQ_Label = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.colors.secondary};
  margin-bottom: 15px;
`

export const UserFAQ_Icon = styled.div`
  fill: ${theme.colors.secondary};
  width: 24px;
  height: 24px;
`
