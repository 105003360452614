import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { CORRIDA_LOAD, CORRIDA_LIST, CORRIDA_FINALIZAR, CORRIDA_CANCELAR, CORRIDA_UPDATE, CORRIDA_REENVIAR, CORRIDA_MOTORISTA_LIST, ACOMPANHAMENTO } from './constants';

import {
    corridaListSuccess,
    corridaListFailed,
    corridaLoadSuccess,
    corridaLoadFailed,
    corridaFinalizarSuccess,
    corridaFinalizarFailed,
    corridaCancelarSuccess,
    corridaCancelarFailed,
    corridaUpdateSuccess,
    corridaUpdateFailed,
    corridaReenviarSuccess,
    corridaReenviarFailed,
    corridaMotoristaListSuccess,
    corridaMotoristaListFailed,
    acompanhamentoSuccess,
    acompanhamentoFailed
} from './actions';
import { solicitacaoCorridaLoad } from '../solicitacaocorrida/actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* corridaLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corrida/' + id, options);
        yield put(corridaLoadSuccess(response));
    } catch (error) {
        yield put(corridaLoadFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* corridaList({ payload: filter }) {
    const options = {
        method: 'POST',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
        body: JSON.stringify(filter)
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/corrida/filter', options);
        yield put(corridaListSuccess(response));
    } catch (error) {
        yield put(corridaListFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* corridaFinalizar({ payload: corridaId }) {
    const options = {
        method: 'POST',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/corrida/finalizar/${corridaId}`, options);
        yield put(corridaFinalizarSuccess(response));
    } catch (error) {
        yield put(corridaFinalizarFailed(error.message));
    }
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* corridaCancelar({ payload: corridaId }) {
    const options = {
        method: 'POST',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/corrida/cancelar/${corridaId}`, options);
        yield put(corridaCancelarSuccess(response));
    } catch (error) {
        yield put(corridaCancelarFailed(error.message));
    }
}

function* corridaUpdate({ payload: corrida }) {
    const options = {
        method: 'PUT',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        },
        body: JSON.stringify(corrida)
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/corrida/${corrida?.id}`, options);
        yield put(corridaUpdateSuccess(response));
    } catch (error) {
        yield put(corridaUpdateFailed(error.message));
    }
}


/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* corridaReenviar({ payload: corridaId }) {
    const options = {
        method: 'POST',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/corrida/reenviar/${corridaId}`, options);
        yield put(corridaReenviarSuccess(response));
    } catch (error) {
        yield put(corridaReenviarFailed(error.message));
    }
}

/**
 * Busca corridas de um determinado motorista por data
 * @param {*} payload - motoristaId: ID do motorita and data: Data da corrida.
 */
function* corridaMotoristaList({ payload: { motoristaId, data } }) {
    const options = {
        method: 'GET',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/corrida/motorista/${motoristaId}/${data}`, options);
        yield put(corridaMotoristaListSuccess(response));
    } catch (error) {
        yield put(corridaMotoristaListFailed(error.message));
    }
}

/// ACOMPANHAMENTO

/**
 * Lista de corridas de acompanhamento
 */
function* acompanhamento() {
    const options = {
        method: 'GET',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getLoggedInUser().token
        }
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/corrida/acompanhamento`, options);
        yield put(acompanhamentoSuccess(response));
    } catch (error) {
        yield put(acompanhamentoFailed(error.message));
    }
}


export function* watchcorridaLoad(): any {
    yield takeEvery(CORRIDA_LOAD, corridaLoad);
}

export function* watchcorridaList(): any {
    yield takeEvery(CORRIDA_LIST, corridaList);
}

export function* wathCorridaFinalizar(): any {
    yield takeEvery(CORRIDA_FINALIZAR, corridaFinalizar);
}

export function* wathCorridaCancelar(): any {
    yield takeEvery(CORRIDA_CANCELAR, corridaCancelar);
}

export function* wathCorridaUpdate(): any {
    yield takeEvery(CORRIDA_UPDATE, corridaUpdate);
}

export function* wathCorridaReenviar(): any {
    yield takeEvery(CORRIDA_REENVIAR, corridaReenviar);
}

export function* watchCorridaMotoristaList(): any {
    yield takeEvery(CORRIDA_MOTORISTA_LIST, corridaMotoristaList);
}

export function* watchAcompanhamento(): any {
    yield takeEvery(ACOMPANHAMENTO, acompanhamento);
}

function* corrida(): any {
    yield all([
        fork(watchcorridaList),
        fork(watchcorridaLoad),
        fork(wathCorridaFinalizar),
        fork(wathCorridaCancelar),
        fork(wathCorridaUpdate),
        fork(wathCorridaReenviar),
        fork(watchCorridaMotoristaList),
        fork(watchAcompanhamento)
    ]);
}

export default corrida;