import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { TIPO_LOCAL_LOAD, TIPO_LOCAL_LIST, TIPO_LOCAL_ADD, TIPO_LOCAL_UPDATE, TIPO_LOCAL_REMOVE } from './constants';

import {
    tipoLocalListSuccess,
    tipoLocalListFailed,
    tipoLocalAddSuccess,
    tipoLocalAddFailed,
    tipoLocalUpdateSuccess,
    tipoLocalUpdateFailed,
    tipoLocalLoadSuccess,
    tipoLocalLoadFailed,
    tipoLocalRemoveSuccess,
    tipoLocalRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoLocalLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipoLocal/' + id, options);
        yield put(tipoLocalLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoLocalLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoLocalList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/tipoLocal/filter', options);
            yield put(tipoLocalListSuccess(response));
        } catch (error) {
            yield put(tipoLocalListFailed(error.message));
        }    
}


/**
 * tipoLocal
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoLocalAdd({ payload: { tipoLocal }  }) {

    const options = {
        body: JSON.stringify(tipoLocal) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipoLocal', options);
        yield put(tipoLocalAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoLocalAddFailed(message));
    }    
}

/**
 * tipoLocal
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoLocalUpdate({ payload: { tipoLocal }  }) {

    const options = {
        body: JSON.stringify(tipoLocal) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/tipoLocal/' + tipoLocal.id, options);
        yield put(tipoLocalUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(tipoLocalUpdateFailed(message));
    }    
}

/**
 * tipoLocal
 * @param {*} payload - pageNumber and pageSize
 */
function* tipoLocalRemove({ payload: { tipoLocal }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/tipoLocal/' + tipoLocal.id, options);
        yield put(tipoLocalRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(tipoLocalRemoveFailed(message));
    }    
}

export function* watchTipoLocalLoad(): any{
    yield takeEvery(TIPO_LOCAL_LOAD, tipoLocalLoad);
}

export function* watchTipoLocalList(): any {
    yield takeEvery(TIPO_LOCAL_LIST, tipoLocalList);
}

export function* watchTipoLocalAdd(): any {
    yield takeEvery(TIPO_LOCAL_ADD, tipoLocalAdd);
}

export function* watchTipoLocalUpdate(): any {
    yield takeEvery(TIPO_LOCAL_UPDATE, tipoLocalUpdate);
}

export function* watchTipoLocalRemove(): any {
    yield takeEvery(TIPO_LOCAL_REMOVE, tipoLocalRemove);
}

function* tipoLocal(): any {
    yield all([fork(watchTipoLocalList), fork(watchTipoLocalAdd), fork(watchTipoLocalUpdate), fork(watchTipoLocalLoad), fork(watchTipoLocalRemove)]);
}

export default tipoLocal;