/* TIPO_LOCAL */
export const TIPO_LOCAL_LIST           = 'TIPO_LOCAL_LIST';
export const TIPO_LOCAL_LIST_SUCCESS   = 'TIPO_LOCAL_LIST_SUCCESS';
export const TIPO_LOCAL_LIST_FAILED    = 'TIPO_LOCAL_LIST_FAILED';
export const TIPO_LOCAL_ADD            = 'TIPO_LOCAL_ADD';
export const TIPO_LOCAL_ADD_SUCCESS    = 'TIPO_LOCAL_ADD_SUCCESS';
export const TIPO_LOCAL_ADD_FAILED     = 'TIPO_LOCAL_ADD_FAILED';
export const TIPO_LOCAL_UPDATE         = 'TIPO_LOCAL_UPDATE';
export const TIPO_LOCAL_UPDATE_SUCCESS = 'TIPO_LOCAL_UPDATE_SUCCESS';
export const TIPO_LOCAL_UPDATE_FAILED  = 'TIPO_LOCAL_UPDATE_FAILED';
export const TIPO_LOCAL_REMOVE         = 'TIPO_LOCAL_REMOVE';
export const TIPO_LOCAL_REMOVE_SUCCESS = 'TIPO_LOCAL_REMOVE_SUCCESS';
export const TIPO_LOCAL_REMOVE_FAILED  = 'TIPO_LOCAL_REMOVE_FAILED';
export const TIPO_LOCAL_LOAD           = 'TIPO_LOCAL_LOAD'
export const TIPO_LOCAL_LOAD_SUCCESS   = 'TIPO_LOCAL_LOAD_SUCCESS';
export const TIPO_LOCAL_LOAD_FAILED    = 'TIPO_LOCAL_LOAD_FAILED';