/* DOMINIO */
export const DOMINIO_LIST           = 'DOMINIO_LIST';
export const DOMINIO_LIST_SUCCESS   = 'DOMINIO_LIST_SUCCESS';
export const DOMINIO_LIST_FAILED    = 'DOMINIO_LIST_FAILED';
export const DOMINIO_ADD            = 'DOMINIO_ADD';
export const DOMINIO_ADD_SUCCESS    = 'DOMINIO_ADD_SUCCESS';
export const DOMINIO_ADD_FAILED     = 'DOMINIO_ADD_FAILED';
export const DOMINIO_UPDATE         = 'DOMINIO_UPDATE';
export const DOMINIO_UPDATE_SUCCESS = 'DOMINIO_UPDATE_SUCCESS';
export const DOMINIO_UPDATE_FAILED  = 'DOMINIO_UPDATE_FAILED';
export const DOMINIO_REMOVE         = 'DOMINIO_REMOVE';
export const DOMINIO_REMOVE_SUCCESS = 'DOMINIO_REMOVE_SUCCESS';
export const DOMINIO_REMOVE_FAILED  = 'DOMINIO_REMOVE_FAILED';
export const DOMINIO_LOAD           = 'DOMINIO_LOAD'
export const DOMINIO_LOAD_SUCCESS   = 'DOMINIO_LOAD_SUCCESS';
export const DOMINIO_LOAD_FAILED    = 'DOMINIO_LOAD_FAILED';