import { URL_PORTAL_V2, URL_PORTAL_DRIVER } from '../../../helpers/url';

const aprovacaoMenu = [
    {
        header: 'Painel Aprovação',
        path: '/aprovacao',
        name: 'Aprovações',
        icon: 'uil-check-square',
        children: [
            { name: 'Endereço passageiro', path: '/alterarendpass' },
            { name: 'Motoristas pendentes', path: '/motorista/aprovacoes' },
            {
                name: 'Portal-driver',
                path: `${URL_PORTAL_DRIVER}/accreditation/accreditations`,
            },
            {
                name: 'Blocking-reasons',
                path: `${URL_PORTAL_V2}/drivers-panel/_registrations/reason/block`,
            },
            {
                name: 'Suspension-reasons',
                path: `${URL_PORTAL_V2}/drivers-panel/_registrations/reason/suspension`,
            },
            {
                name: 'Route-adjustment-request',
                path: `${URL_PORTAL_V2}/drivers-panel/_services/route-adjustment-request`,
            },
        ],
    },
];

export const AprovacaoMenu = () => aprovacaoMenu;