import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { 
            OPERACAO_LOAD, 
            OPERACAO_LIST, 
            OPERACAO_ADD, 
            OPERACAO_UPDATE, 
            OPERACAO_REMOVE,
            OPERACAO_DETALHE_ADD,
            OPERACAO_DETALHE_UPDATE,
            OPERACAO_DETALHE_REMOVE,
            OPERACAO_DETALHE_LIST
 } from './constants';

import {
    operacaoListSuccess,
    operacaoListFailed,
    operacaoAddSuccess,
    operacaoAddFailed,
    operacaoUpdateSuccess,
    operacaoUpdateFailed,
    operacaoLoadSuccess,
    operacaoLoadFailed,
    operacaoRemoveSuccess,
    operacaoRemoveFailed,
    operacaoDetalheAddSuccess,
    operacaoDetalheAddFailed,
    operacaoDetalheUpdateSuccess,
    operacaoDetalheUpdateFailed,
    operacaoDetalheRemoveSuccess,
    operacaoDetalheRemoveFailed,
    operacaoDetalheListSuccess,
    operacaoDetalheListFailed
} from './actions';

import { estadoList } from '../backoffice/estado/actions'
import { turnoList } from '../turno/actions'

/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoLoad({ payload:  id  }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/operacao/' + id, options);
        yield put(operacaoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(operacaoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/operacao', options);
            yield put(operacaoListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(operacaoListFailed(message));
        }    
}

/**
 * operacao
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoAdd({ payload: operacao  }) {

    const options = {
        body: JSON.stringify(operacao) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/operacao', options);
        yield put(operacaoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(operacaoAddFailed(message));
    }    
}

/**
 * operacao
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoUpdate({ payload: operacao   }) {

    const options = {
        body: JSON.stringify(operacao) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/operacao/' + operacao?.id, options);
        yield put(operacaoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(operacaoUpdateFailed(message));
    }    
}

/**
 * operacao
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoRemove({ payload: operacao  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/operacao/' + operacao?.id, options);
        yield put(operacaoRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(operacaoRemoveFailed(message));
    }    
}

// Operação detalhe
/**
 * operacao
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoDetalheAdd({ payload: operacaoDetalhe  }) {

    const options = {
        body: JSON.stringify(operacaoDetalhe) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {

        const response = yield call(fetchJSON, '/central/v1/api/v1/operacaodetalhe', options);

        yield put(operacaoDetalheAddSuccess(response));

        // Se estiver enviando uma nova cidade, forço a atualização do contexto de Estados.
        if (!operacaoDetalhe.cidadeId && operacaoDetalhe.cidade){
            yield(put(estadoList()))
        }

        // Se estiver enviando um novo turno, forço a atualização do contexto de Turnos.
        if (!operacaoDetalhe.turnoId && operacaoDetalhe.turno){
            yield(put(turnoList()))
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(operacaoDetalheAddFailed(message));
    }    
}

// Operação detalhe
/**
 * operacao
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoDetalheUpdate({ payload: operacaoDetalhe  }) {

    const options = {
        body: JSON.stringify(operacaoDetalhe) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        
        const response = yield call(fetchJSON, '/central/v1/api/v1/operacaodetalhe/' + operacaoDetalhe?.id, options);

        yield put(operacaoDetalheUpdateSuccess(response));

        // Se estiver enviando uma nova cidade, forço a atualização do contexto de Estados.
        if (!operacaoDetalhe.cidadeId && operacaoDetalhe.cidade){
            yield(put(estadoList()))
        }

        // Se estiver enviando um novo turno, forço a atualização do contexto de Turnos.
        if (!operacaoDetalhe.turnoId && operacaoDetalhe.turno){
            yield(put(turnoList()))
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(operacaoDetalheUpdateFailed(message));
    }    
}

// Operação detalhe
/**
 * operacao
 * @param {*} payload - pageNumber and pageSize
 */
function* operacaoDetalheRemove({ payload: operacaoDetalheId  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        
        yield call(fetchJSON, '/central/v1/api/v1/operacaodetalhe/' + operacaoDetalheId, options);
        yield put(operacaoDetalheRemoveSuccess(operacaoDetalheId));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(operacaoDetalheRemoveFailed(message));
    }    
}

function* operacaoDetalheList({ payload: centroCustoId }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        
        var response = yield call(fetchJSON, '/central/v1/api/v1/operacaodetalhe/centrocustoId' + centroCustoId, options);
        yield put(operacaoDetalheListSuccess(response));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(operacaoDetalheListFailed(message));
    }    

}

export function* watchoperacaoLoad(): any{
    yield takeEvery(OPERACAO_LOAD, operacaoLoad);
}

export function* watchoperacaoList(): any {
    yield takeEvery(OPERACAO_LIST, operacaoList);
}

export function* watchoperacaoAdd(): any {
    yield takeEvery(OPERACAO_ADD, operacaoAdd);
}

export function* watchOperacaoDetalheAdd(): any {
    yield takeEvery(OPERACAO_DETALHE_ADD, operacaoDetalheAdd);
}

export function* watchOperacaoDetalheUpdate(): any {
    yield takeEvery(OPERACAO_DETALHE_UPDATE, operacaoDetalheUpdate);
}

export function* watchOperacaoDetalheRemove(): any {
    yield takeEvery(OPERACAO_DETALHE_REMOVE, operacaoDetalheRemove);
}

export function* watchoperacaoUpdate(): any {
    yield takeEvery(OPERACAO_UPDATE, operacaoUpdate);
}

export function* watchoperacaoRemove(): any {
    yield takeEvery(OPERACAO_REMOVE, operacaoRemove);
}

export function* watchOperacaoDetalheList() : any{
    yield takeEvery(OPERACAO_DETALHE_LIST, operacaoDetalheList);
}

function* operacao(): any {
    yield all([
                fork(watchoperacaoList), 
                fork(watchoperacaoAdd), 
                fork(watchoperacaoUpdate), 
                fork(watchoperacaoLoad), 
                fork(watchoperacaoRemove),
                fork(watchOperacaoDetalheAdd),
                fork(watchOperacaoDetalheUpdate),
                fork(watchOperacaoDetalheRemove),
                fork(watchOperacaoDetalheList)
    ]);
}

export default operacao;