import { 
    PERMISSAO_LIST,
    PERMISSAO_LIST_SUCCESS,
    PERMISSAO_LIST_FAILED,
    PERMISSAO_ADD,
    PERMISSAO_ADD_SUCCESS,
    PERMISSAO_ADD_FAILED,
    PERMISSAO_UPDATE,
    PERMISSAO_UPDATE_SUCCESS,
    PERMISSAO_UPDATE_FAILED,
    PERMISSAO_LOAD,
    PERMISSAO_LOAD_SUCCESS,
    PERMISSAO_LOAD_FAILED,
    PERMISSAO_REMOVE,
    PERMISSAO_REMOVE_SUCCESS,
    PERMISSAO_REMOVE_FAILED
} from './constants';

// List
export const permissaoList        = (filter) => ({ type: PERMISSAO_LIST, payload: filter,});
export const permissaoListSuccess = (permissoes) => ({ type: PERMISSAO_LIST_SUCCESS, payload: { permissoes } });
export const permissaoListFailed  = (error) => ({ type: PERMISSAO_LIST_FAILED, payload: error  });

export const permissaoLoad        = (id) => ({ type: PERMISSAO_LOAD, payload: { id } });
export const permissaoLoadSuccess  = (permissao) => ({ type: PERMISSAO_LOAD_SUCCESS, payload: { permissao } });
export const permissaoLoadFailed   = (error) => ({ type: PERMISSAO_LOAD_FAILED, payload: error  });

// Add
export const permissaoAdd         = (permissao) => ({ type: PERMISSAO_ADD, payload: { permissao } });
export const permissaoAddSuccess  = (permissao, permissaoStatus) => ({ type: PERMISSAO_ADD_SUCCESS, permissaoStatus: permissaoStatus, payload: { permissao } });
export const permissaoAddFailed   = (error) => ({ type: PERMISSAO_ADD_FAILED, payload: error  });

// Update
export const permissaoUpdate         = (permissao) => ({ type: PERMISSAO_UPDATE, payload: { permissao } });
export const permissaoUpdateSuccess  = (permissao, permissaoStatus) => ({ type: PERMISSAO_UPDATE_SUCCESS, permissaoStatus: permissaoStatus, payload: { permissao } });
export const permissaoUpdateFailed   = (error) => ({ type: PERMISSAO_UPDATE_FAILED, payload: error  });

// remove
export const permissaoRemove         = (permissao) => ({ type: PERMISSAO_REMOVE, payload: { permissao } });
export const permissaoRemoveSuccess  = (permissao, permissaoDeletado) => ({ type: PERMISSAO_REMOVE_SUCCESS, permissaoDeletado: permissaoDeletado, payload: { permissao } });
export const permissaoRemoveFailed   = (error) => ({ type: PERMISSAO_REMOVE_FAILED, payload: error  });