/* USUARIO */
export const USUARIO_LIST           = 'USUARIO_LIST';
export const USUARIO_LIST_SUCCESS   = 'USUARIO_LIST_SUCCESS';
export const USUARIO_LIST_FAILED    = 'USUARIO_LIST_FAILED';
export const USUARIO_ADD            = 'USUARIO_ADD';
export const USUARIO_ADD_SUCCESS    = 'USUARIO_ADD_SUCCESS';
export const USUARIO_ADD_FAILED     = 'USUARIO_ADD_FAILED';
export const USUARIO_UPDATE         = 'USUARIO_UPDATE';
export const USUARIO_UPDATE_SUCCESS = 'USUARIO_UPDATE_SUCCESS';
export const USUARIO_UPDATE_FAILED  = 'USUARIO_UPDATE_FAILED';
export const USUARIO_REMOVE         = 'USUARIO_REMOVE';
export const USUARIO_REMOVE_SUCCESS = 'USUARIO_REMOVE_SUCCESS';
export const USUARIO_REMOVE_FAILED  = 'USUARIO_REMOVE_FAILED';
export const USUARIO_LOAD           = 'USUARIO_LOAD'
export const USUARIO_LOAD_SUCCESS   = 'USUARIO_LOAD_SUCCESS';
export const USUARIO_LOAD_FAILED    = 'USUARIO_LOAD_FAILED';