
import { TARIFA_LOAD, TARIFA_LOAD_SUCCESS, TARIFA_LOAD_FAILED, TARIFA_LIST, TARIFA_LIST_SUCCESS, TARIFA_LIST_FAILED, TARIFA_ADD, TARIFA_ADD_FAILED, TARIFA_ADD_SUCCESS, TARIFA_REMOVE, TARIFA_REMOVE_FAILED, TARIFA_REMOVE_SUCCESS, TARIFA_UPDATE, TARIFA_UPDATE_SUCCESS, TARIFA_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    tarifas: [],
    tarifa: {},
    loading: false,
    error: null,
    tarifaStatus: null,
    id: {}
};

const TarifaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TARIFA_LIST:
            return { ...state, loading: true, error: null, tarifaDeletado: null };
        case TARIFA_LIST_SUCCESS:
            return { ...state, tarifas: action.payload, loading: false, error: null, tarifaDeletado: null };
        case TARIFA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, tarifaDeletado: null };
        case TARIFA_LOAD:
            return { ...state, tarifa: {}, loading: true, error: null, tarifaStatus: null, tarifaDeletado: null }
        case TARIFA_LOAD_SUCCESS:
            return { ...state, tarifa: action.payload, loading: false, error: null, tarifaDeletado: null}
        case TARIFA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, tarifaDeletado: null };
        case TARIFA_ADD:
            return { ...state, tarifa: action.payload, tarifaStatus: null, loading: true, error: null, tarifaDeletado: null}
        case TARIFA_ADD_SUCCESS:
            return { ...state, tarifa: action.payload,  tarifaStatus: "Local adicionado com sucesso", tarifaDeletado: null, loading: false, error: null };
        case TARIFA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TARIFA_UPDATE:
            return { ...state, tarifa: action.payload, tarifaStatus: null, loading: true, error: null}
        case TARIFA_UPDATE_SUCCESS:
            return { ...state, tarifa: action.payload,  tarifaStatus: "Local atualizado com sucesso", tarifaDeletado: null, loading: false, error: null };
        case TARIFA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, tarifaStatus: null };
        case TARIFA_REMOVE:
            return { ...state, tarifa: action.payload, tarifaStatus: null, loading: true, error: null, tarifaDeletado: null}
        case TARIFA_REMOVE_SUCCESS:
            return { ...state, tarifa: {}, tarifaStatus: null,  tarifaDeletado: "Local removido com sucesso", loading: false, error: null };
        case TARIFA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, tarifaStatus: null, tarifaDeletado: null };
        default:
            return { ...state };
    }
};

export default TarifaReducer;