import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { getLoggedInUser } from '../../helpers/authUtils'

import { VEICULOMARCA_LOAD, VEICULOMARCA_LIST, VEICULOMARCA_ADD, VEICULOMARCA_UPDATE, VEICULOMARCA_REMOVE } from './constants';

import {
    veiculoMarcaListSuccess,
    veiculoMarcaListFailed,
    veiculoMarcaAddSuccess,
    veiculoMarcaAddFailed,
    veiculoMarcaUpdateSuccess,
    veiculoMarcaUpdateFailed,
    veiculoMarcaLoadSuccess,
    veiculoMarcaLoadFailed,
    veiculoMarcaRemoveSuccess,
    veiculoMarcaRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoMarcaLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculomarca/' + id, options);
        yield put(veiculoMarcaLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoMarcaLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoMarcaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/veiculomarca/filter', options);
            yield put(veiculoMarcaListSuccess(response));
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Algo deu errado! Por favor, tente novamente.';
                    break;
                case 401:
                    message = 'Seus dados podem estar incorretos.';
                    break;
                case 403:
                    message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                    break;
                case 400:
                    message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                    break;
                default:
                    message = error.message;
            }
            yield put(veiculoMarcaListFailed(message));
        }    
}


/**
 * veiculoMarca
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoMarcaAdd({ payload: { veiculoMarca }  }) {

    const options = {
        body: JSON.stringify(veiculoMarca) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculomarca', options);
        yield put(veiculoMarcaAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoMarcaAddFailed(message));
    }    
}

/**
 * veiculoMarca
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoMarcaUpdate({ payload: { veiculoMarca }  }) {

    const options = {
        body: JSON.stringify(veiculoMarca) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/veiculomarca/' + veiculoMarca?.id, options);
        yield put(veiculoMarcaUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(veiculoMarcaUpdateFailed(message));
    }    
}

/**
 * veiculoMarca
 * @param {*} payload - pageNumber and pageSize
 */
function* veiculoMarcaRemove({ payload: { veiculoMarca }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/veiculomarca/' + veiculoMarca?.id, options);
        yield put(veiculoMarcaRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(veiculoMarcaRemoveFailed(message));
    }    
}

export function* watchveiculoMarcaLoad(): any{
    yield takeEvery(VEICULOMARCA_LOAD, veiculoMarcaLoad);
}

export function* watchveiculoMarcaList(): any {
    yield takeEvery(VEICULOMARCA_LIST, veiculoMarcaList);
}

export function* watchveiculoMarcaAdd(): any {
    yield takeEvery(VEICULOMARCA_ADD, veiculoMarcaAdd);
}

export function* watchveiculoMarcaUpdate(): any {
    yield takeEvery(VEICULOMARCA_UPDATE, veiculoMarcaUpdate);
}

export function* watchveiculoMarcaRemove(): any {
    yield takeEvery(VEICULOMARCA_REMOVE, veiculoMarcaRemove);
}

function* veiculoMarca(): any {
    yield all([fork(watchveiculoMarcaList), fork(watchveiculoMarcaAdd), fork(watchveiculoMarcaUpdate), fork(watchveiculoMarcaLoad), fork(watchveiculoMarcaRemove)]);
}

export default veiculoMarca;