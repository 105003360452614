import { 
    DISPONIBILIDADE_LIST,
    DISPONIBILIDADE_LIST_SUCCESS,
    DISPONIBILIDADE_LIST_FAILED,
    DISPONIBILIDADE_ADD,
    DISPONIBILIDADE_ADD_SUCCESS,
    DISPONIBILIDADE_ADD_FAILED,
    DISPONIBILIDADE_UPDATE,
    DISPONIBILIDADE_UPDATE_SUCCESS,
    DISPONIBILIDADE_UPDATE_FAILED,
    DISPONIBILIDADE_LOAD,
    DISPONIBILIDADE_LOAD_SUCCESS,
    DISPONIBILIDADE_LOAD_FAILED,
    DISPONIBILIDADE_REMOVE,
    DISPONIBILIDADE_REMOVE_SUCCESS,
    DISPONIBILIDADE_REMOVE_FAILED,
    DISPONIBILIDADE_LIST_PIVOT_SUCCESS,
    DISPONIBILIDADE_CLEAR
} from './constants';

// List
export const disponibilidadeList        = (filter) => ({ type: DISPONIBILIDADE_LIST, payload: filter });
export const disponibilidadeListSuccess = (disponibilidades) => ({ type: DISPONIBILIDADE_LIST_SUCCESS, payload: disponibilidades });
export const disponibilidadeListFailed  = (error) => ({ type: DISPONIBILIDADE_LIST_FAILED, payload: error  });

export const disponibilidadeLoad        = (id) => ({ type: DISPONIBILIDADE_LOAD, payload: id });
export const disponibilidadeLoadSuccess  = (disponibilidade) => ({ type: DISPONIBILIDADE_LOAD_SUCCESS, payload: disponibilidade });
export const disponibilidadeLoadFailed   = (error) => ({ type: DISPONIBILIDADE_LOAD_FAILED, payload: error  });

// Add
export const disponibilidadeAdd         = (disponibilidade) => ({ type: DISPONIBILIDADE_ADD, payload: disponibilidade });
export const disponibilidadeAddSuccess  = (disponibilidade, disponibilidadeStatus) => ({ type: DISPONIBILIDADE_ADD_SUCCESS, disponibilidadeStatus: disponibilidadeStatus, payload: disponibilidade });
export const disponibilidadeAddFailed   = (error) => ({ type: DISPONIBILIDADE_ADD_FAILED, payload: error  });

// Update
export const disponibilidadeUpdate         = (disponibilidade) => ({ type: DISPONIBILIDADE_UPDATE, payload: disponibilidade });
export const disponibilidadeUpdateSuccess  = (disponibilidade, disponibilidadeStatus) => ({ type: DISPONIBILIDADE_UPDATE_SUCCESS, disponibilidadeStatus: disponibilidadeStatus, payload: disponibilidade });
export const disponibilidadeUpdateFailed   = (error) => ({ type: DISPONIBILIDADE_UPDATE_FAILED, payload: error  });

// remove
export const disponibilidadeRemove         = (disponibilidade) => ({ type: DISPONIBILIDADE_REMOVE, payload: disponibilidade });
export const disponibilidadeRemoveSuccess  = (disponibilidade, disponibilidadeDeletado) => ({ type: DISPONIBILIDADE_REMOVE_SUCCESS, disponibilidadeDeletado: disponibilidadeDeletado, payload: disponibilidade });
export const disponibilidadeRemoveFailed   = (error) => ({ type: DISPONIBILIDADE_REMOVE_FAILED, payload: error  });

export const disponibilidadePivotListSuccess = (disponibilidades) => ({ type: DISPONIBILIDADE_LIST_PIVOT_SUCCESS, payload: disponibilidades });

export const disponibilidadeClear = () => ({ type: DISPONIBILIDADE_CLEAR });