import {
    SOLICITACAO_PARADA_CORRIDA_ADD,
    SOLICITACAO_PARADA_CORRIDA_LOAD,
    SOLICITACAO_PARADA_CORRIDA_UPDATE,
    SOLICITACAO_PARADA_CORRIDA_REMOVE,
    SOLICITACAO_PARADA_CORRIDA_NEW,
    SOLICITACAO_PARADA_CORRIDA_CANCEL,
    SOLICITACAO_PARADA_CORRIDA_LIST,
} from './constants';

export const solicitacaoParadaCorridaAdd = (solicitacaoParada) => ({
    type: SOLICITACAO_PARADA_CORRIDA_ADD,
    payload: solicitacaoParada,
});

export const solicitacaoParadaCorridaLoad = (solicitacaoParadaId) => ({
    type: SOLICITACAO_PARADA_CORRIDA_LOAD,
    payload: solicitacaoParadaId,
});

export const solicitacaoParadaCorridaUpdate = (solicitacaoParada) => ({
    type: SOLICITACAO_PARADA_CORRIDA_UPDATE,
    payload: solicitacaoParada,
});

export const solicitacaoParadaCorridaRemove = (solicitacaoParadaId) => ({
    type: SOLICITACAO_PARADA_CORRIDA_REMOVE,
    payload: solicitacaoParadaId,
});

export const solicitacaoParadaCorridaNew = (solicitacaoCorridaId) => ({
    type: SOLICITACAO_PARADA_CORRIDA_NEW,
    payload: solicitacaoCorridaId,
});

export const solicitacaoParadaCorridaCancel = () => ({ type: SOLICITACAO_PARADA_CORRIDA_CANCEL });

export const solicitacaoParadaCorridaList = (list) => ({ type: SOLICITACAO_PARADA_CORRIDA_LIST, payload: list });
