import { URL_BASE } from './url';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = async (url, options = {}) => {
    try {
        
        let json;
        let urlBase = URL_BASE

        // Se a url já contém o caminho completo não adiciona o prefixo. 
        if(url.includes('http')){
            urlBase = "";
        }

        const response = await fetch(urlBase + url, options);

        if (response.status !== 200 && response.status !== 201) {

            if(response.status === 403){
                json = { status: 403,  message: "Acesso não permitido" };
                throw json;
            }

            if(response.status === 204){
                json = { status: 204,  message: "Nenhum conteúdo foi encontrado" }
                throw json;
            }

            if(response.status === 404){
                json = { status: 404,  message: "Não conseguimos se conectar :(" }
                throw json;
            }

            json = await getTextJson(response)
            throw json;
        }

        json  = await response.json();

        if (!json){
            json = await getTextJson(response);
        }
        return json;
    } catch (error) {
        if (error.status === undefined){
            const retornoError = "Algo inesperado aconteceu, tente novamente mais tarde." 
            throw retornoError;
        }
        throw error;
    }
};

const getTextJson = async (response) => {

    let json;
    const text = await response.text();
    const data = await JSON.parse(text);
    if(data.mensagem){
        json = { status: response.status, message: data.mensagem };
    }else{
        json = { status: response.status, data: data }
    }

    return json;
}

export { fetchJSON };