
import { 
    CONTRATO_LOAD, 
    CONTRATO_LOAD_SUCCESS, 
    CONTRATO_LOAD_FAILED, 
    CONTRATO_LIST, 
    CONTRATO_LIST_SUCCESS, CONTRATO_LIST_FAILED, CONTRATO_ADD, CONTRATO_ADD_FAILED, CONTRATO_ADD_SUCCESS, CONTRATO_REMOVE, CONTRATO_REMOVE_FAILED, CONTRATO_REMOVE_SUCCESS, CONTRATO_UPDATE, CONTRATO_UPDATE_SUCCESS, CONTRATO_UPDATE_FAILED, CONTRATO_VEICULO_ADD, CONTRATO_VEICULO_REMOVE, CONTRATO_TIPO_ADD, CALCULAR_DISPONIBILIDADE, CALCULAR_DISPONIBILIDADE_SUCCESS, CALCULAR_DISPONIBILIDADE_FAILED } from './constants';

const INIT_STATE = {
    contratos: [],
    contrato: {},
    itensContrato: [],
    contratoTipos: [],
    loading: false,
    error: null,
    success: null,
    contratoDeletado: null,
    id: {},
    loadingDisponibilidade: false
};

const ContratoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONTRATO_LIST:
            return { ...state, contrato: {}, loading: true, error: null, contratoDeletado: null, contrato: {}, itensContrato: [], contratoTipos: [] };
        case CONTRATO_LIST_SUCCESS:
            return { ...state, contratos: action.payload, loading: false, error: null, contratoDeletado: null };
        case CONTRATO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, contratoDeletado: null };
        case CONTRATO_LOAD:
            return { ...state, contrato: {}, loading: true, error: null, success: null, contratoDeletado: null }
        case CONTRATO_LOAD_SUCCESS:
            return { ...state, contrato: action.payload, itensContrato: action.payload.itensContrato, contratoTipos: action.payload.tipos, loading: false, error: null, contratoDeletado: null}
        case CONTRATO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, contratoDeletado: null };
        case CONTRATO_ADD:
            return { ...state, contrato: action.payload, success: null, loading: true, error: null, contratoDeletado: null}
        case CONTRATO_ADD_SUCCESS:
            return { ...state, contrato: action.payload,  success: "Contrato adicionado com sucesso", contratoDeletado: null, loading: false, error: null };
        case CONTRATO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CONTRATO_UPDATE:
            return { ...state, contrato: action.payload, success: null, loading: true, error: null}
        case CONTRATO_UPDATE_SUCCESS:
            return { ...state, contrato: action.payload,  success: "Contrato atualizado com sucesso", contratoDeletado: null, loading: false, error: null };
        case CONTRATO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, success: null };
        case CONTRATO_REMOVE:
            return { ...state, contrato: action.payload, success: null, loading: true, error: null, contratoDeletado: null}
        case CONTRATO_REMOVE_SUCCESS:
            return { ...state, contrato: {}, success: null,  contratoDeletado: "Contrato removido com sucesso", loading: false, error: null };
        case CONTRATO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, success: null, contratoDeletado: null };

        case CONTRATO_VEICULO_ADD:

            var index = state.itensContrato.findIndex(item => item.id === action.payload.id);

            if(index >= 0){
                var newArray = [...state.itensContrato];
                newArray[index] = action.payload;
                return { ...state, itensContrato: newArray };
            }

            action.payload["id"] = state.itensContrato.length + 1;
            
            return { ...state, itensContrato: [...state.itensContrato, action.payload]}

        case CONTRATO_VEICULO_REMOVE:
            return { ...state, itensContrato: [...state.itensContrato.filter(item => item.id !== action.payload.id)]}

        case CONTRATO_TIPO_ADD:

            var indexTipo = state.contratoTipos.findIndex(item => item.id === action.payload.id);

            if(indexTipo >= 0){
                var newArrayTipo = [...state.contratoTipos];
                newArrayTipo[indexTipo] = action.payload;
                return { ...state, contratoTipos: newArrayTipo };
            }

            action.payload["id"] = state.contratoTipos.length + 1;
            
            return { ...state, contratoTipos: [...state.contratoTipos, action.payload]}

        case CALCULAR_DISPONIBILIDADE:
            return { ...state, loadingDisponibilidade: true, success: null };
        
        case CALCULAR_DISPONIBILIDADE_SUCCESS:
            return { ...state, success: "Disponibilidade calculada com sucesso", loadingDisponibilidade: false }

        case CALCULAR_DISPONIBILIDADE_FAILED:
            return { ...state, error: action.error, loadingDisponibilidade: false }

        default:
            return { ...state };
    }
};

export default ContratoReducer;