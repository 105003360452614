
import { VEICULO_LOAD, VEICULO_LOAD_SUCCESS, VEICULO_LOAD_FAILED, VEICULO_LIST, VEICULO_LIST_SUCCESS, VEICULO_LIST_FAILED, VEICULO_ADD, VEICULO_ADD_FAILED, VEICULO_ADD_SUCCESS, VEICULO_REMOVE, VEICULO_REMOVE_FAILED, VEICULO_REMOVE_SUCCESS, VEICULO_UPDATE, VEICULO_UPDATE_SUCCESS, VEICULO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    veiculos: {},
    veiculo: {},
    loading: false,
    error: null,
    veiculoStatus: null,
    id: {}
};

const EmpresaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VEICULO_LIST:
            return { ...state, loading: true, error: null, veiculoDeletado: null };
        case VEICULO_LIST_SUCCESS:
            return { ...state, veiculos: action.payload, loading: false, error: null, veiculoDeletado: null };
        case VEICULO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoDeletado: null };
        case VEICULO_LOAD:
            return { ...state, loading: true, error: null, veiculoStatus: null, veiculoDeletado: null }
        case VEICULO_LOAD_SUCCESS:
            return { ...state, veiculo: action.payload, loading: false, error: null, veiculoDeletado: null}
        case VEICULO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoDeletado: null };
        case VEICULO_ADD:
            return { ...state, veiculo: action.payload, veiculoStatus: null, loading: true, error: null, veiculoDeletado: null}
        case VEICULO_ADD_SUCCESS:
            return { ...state, veiculo: action.payload,  veiculoStatus: "Veículo adicionado com sucesso", veiculoDeletado: null, loading: false, error: null };
        case VEICULO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case VEICULO_UPDATE:
            return { ...state, veiculo: action.payload, veiculoStatus: null, loading: true, error: null}
        case VEICULO_UPDATE_SUCCESS:
            return { ...state, veiculo: action.payload,  veiculoStatus: "Veículo atualizado com sucesso", veiculoDeletado: null, loading: false, error: null };
        case VEICULO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoStatus: null };
        case VEICULO_REMOVE:
            return { ...state, veiculo: action.payload, veiculoStatus: null, loading: true, error: null, veiculoDeletado: null}
        case VEICULO_REMOVE_SUCCESS:
            return { ...state, veiculo: {}, veiculoStatus: null,  veiculoDeletado: "Veículo removido com sucesso", loading: false, error: null };
        case VEICULO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, veiculoStatus: null, veiculoDeletado: null };
        default:
            return { ...state };
    }
};

export default EmpresaReducer;