export const SOLICITACAO_CORRIDA_ADD             = "SOLICITACAO_CORRIDA_ADD";
export const SOLICITACAO_CORRIDA_SUCCESS         = "SOLICITACAO_CORRIDA_SUCCESS";
export const SOLICITACAO_CORRIDA_FAILED          = "SOLICITACAO_CORRIDA_FAILED";
 
export const SOLICITACAO_CORRIDA_LIST            = "SOLICITACAO_CORRIDA_LIST";
export const SOLICITACAO_CORRIDA_LIST_SUCCESS    = "SOLICITACAO_CORRIDA_LIST_SUCCESS";
export const SOLICITACAO_CORRIDA_LIST_FAILED     = "SOLICITACAO_CORRIDA_LIST_FAILED";
 
export const SOLICITACAO_CORRIDA_LOAD            = "SOLICITACAO_CORRIDA_LOAD";
export const SOLICITACAO_CORRIDA_LOAD_SUCCESS    = "SOLICITACAO_CORRIDA_LOAD_SUCCESS";
export const SOLICITACAO_CORRIDA_LOAD_FAILED     = "SOLICITACAO_CORRIDA_LOAD_FAILED";

export const SOLICITACAO_CORRIDA_UPDATE           = "SOLICITACAO_CORRIDA_UPDATE";
export const SOLICITACAO_CORRIDA_UPDATE_SUCCESS   = "SOLICITACAO_CORRIDA_UPDATE_SUCCESS";
export const SOLICITACAO_CORRIDA_UPDATE_FAILED    = "SOLICITACAO_CORRIDA_UPDATE_FAILED";

export const SOLICITACAO_CORRIDA_REMOVE           = "SOLICITACAO_CORRIDA_REMOVE";
export const SOLICITACAO_CORRIDA_REMOVE_SUCCESS   = "SOLICITACAO_CORRIDA_REMOVE_SUCCESS";
export const SOLICITACAO_CORRIDA_REMOVE_FAILED    = "SOLICITACAO_CORRIDA_REMOVE_FAILED";

export const SOLICITACAO_CORRIDA_APROVAR           = "SOLICITACAO_CORRIDA_APROVAR";
export const SOLICITACAO_CORRIDA_APROVAR_SUCCESS   = "SOLICITACAO_CORRIDA_APROVAR_SUCCESS";
export const SOLICITACAO_CORRIDA_APROVAR_FAILED    = "SOLICITACAO_CORRIDA_APROVAR_FAILED";

export const SOLICITACAO_CORRIDA_LIBERAR           = "SOLICITACAO_CORRIDA_LIBERAR";
export const SOLICITACAO_CORRIDA_LIBERAR_SUCCESS   = "SOLICITACAO_CORRIDA_LIBERAR_SUCCESS";
export const SOLICITACAO_CORRIDA_LIBERAR_FAILED    = "SOLICITACAO_CORRIDA_LIBERAR_FAILED";

export const SOLICITACAO_CORRIDA_REJEITAR           = 'SOLICITACAO_CORRIDA_REJEITAR';
export const SOLICITACAO_CORRIDA_REJEITAR_SUCCESS   = 'SOLICITACAO_CORRIDA_REJEITAR_SUCCESS';
export const SOLICITACAO_CORRIDA_REJEITAR_FAILED    = 'SOLICITACAO_CORRIDA_REJEITAR_FAILED';

export const SOLICITACAO_ADD_PARADA = 'SOLICITACAO_ADD_PARADA';

export const SOLICITACAO_CORRIDA_NEW = 'SOLICITACAO_CORRIDA_NEW';

export const EVENTOS_SOLICITACAO = 'EVENTOS_SOLICITACAO';
export const EVENTOS_SOLICITACAO_SUCCESS = 'EVENTOS_SOLICITACAO_SUCCESS';
export const EVENTOS_SOLICITACAO_FAILED = 'EVENTOS_SOLICITACAO_FAILED';

export const SHOW_MODAL_EVENTOS_SOLICITACAO = 'SHOW_MODAL_EVENTOS_SOLICITACAO';

export const SOLICITACAO_CORRIDA_REABRIR = 'SOLICITACAO_CORRIDA_REABRIR';
export const SOLICITACAO_CORRIDA_REABRIR_SUCCESS = 'SOLICITACAO_CORRIDA_REABRIR_SUCCESS';
export const SOLICITACAO_CORRIDA_REABRIR_FAILED  = 'SOLICITACAO_CORRIDA_REABRIR_FAILED';

export const SOLICITACAO_CORRIDA_REENVIAR = 'SOLICITACAO_CORRIDA_REENVIA';
export const SOLICITACAO_CORRIDA_REENVIAR_SUCCESS = 'SOLICITACAO_CORRIDA_REENVIAR_SUCCESS';
export const SOLICITACAO_CORRIDA_REENVIAR_FAILED  = 'SOLICITACAO_CORRIDA_REENVIAR_FAILED';

export const SOLICITACAO_CORRIDA_FINALIZAR = 'SOLICITACAO_CORRIDA_FINALIZAR';
export const SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS = 'SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS';
export const SOLICITACAO_CORRIDA_FINALIZAR_FAILED = 'SOLICITACAO_CORRIDA_FINALIZAR_FAILED';


export const SOLICITACAO_CORRIDA_CANCELAR = 'SOLICITACAO_CORRIDA_CANCELAR';
export const SOLICITACAO_CORRIDA_CANCELAR_SUCCESS = 'SOLICITACAO_CORRIDA_CANCELAR_SUCCESS';
export const SOLICITACAO_CORRIDA_CANCELAR_FAILED = 'SOLICITACAO_CORRIDA_CANCELAR_FAILED';

export const SOLICITACAO_CORRIDA_EM_FILA            = 'SOLICITACAO_CORRIDA_EM_FILA';
export const SOLICITACAO_CORRIDA_EM_FILA_SUCCESS    = 'SOLICITACAO_CORRIDA_EM_FILA_SUCCESS';
export const SOLICITACAO_CORRIDA_EM_FILA_FAILED     = 'SOLICITACAO_CORRIDA_EM_FILA_FAILED';

export const HANDLE_REFERENCIA_PARTIDA = 'HANDLE_REFERENCIA_PARTIDA';

export const SOLICITACAO_CORRIDA_ENCERRAR = 'SOLICITACAO_CORRIDA_ENCERRAR'
export const SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS = 'SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS'
export const SOLICITACAO_CORRIDA_ENCERRAR_FAILED = 'SOLICITACAO_CORRIDA_ENCERRAR_FAILED'