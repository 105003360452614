/* VEICULO */
export const ESTACAO_LIST           = 'ESTACAO_LIST';
export const ESTACAO_LIST_SUCCESS   = 'ESTACAO_LIST_SUCCESS';
export const ESTACAO_LIST_FAILED    = 'ESTACAO_LIST_FAILED';
export const ESTACAO_ADD            = 'ESTACAO_ADD';
export const ESTACAO_ADD_SUCCESS    = 'ESTACAO_ADD_SUCCESS';
export const ESTACAO_ADD_FAILED     = 'ESTACAO_ADD_FAILED';
export const ESTACAO_UPDATE         = 'ESTACAO_UPDATE';
export const ESTACAO_UPDATE_SUCCESS = 'ESTACAO_UPDATE_SUCCESS';
export const ESTACAO_UPDATE_FAILED  = 'ESTACAO_UPDATE_FAILED';
export const ESTACAO_REMOVE         = 'ESTACAO_REMOVE';
export const ESTACAO_REMOVE_SUCCESS = 'ESTACAO_REMOVE_SUCCESS';
export const ESTACAO_REMOVE_FAILED  = 'ESTACAO_REMOVE_FAILED';
export const ESTACAO_LOAD           = 'ESTACAO_LOAD'
export const ESTACAO_LOAD_SUCCESS   = 'ESTACAO_LOAD_SUCCESS';
export const ESTACAO_LOAD_FAILED    = 'ESTACAO_LOAD_FAILED';