
import { PASSAGEIRO_LOAD, PASSAGEIRO_LOAD_SUCCESS, PASSAGEIRO_LOAD_FAILED, PASSAGEIRO_LIST, PASSAGEIRO_LIST_SUCCESS, PASSAGEIRO_LIST_FAILED, PASSAGEIRO_ADD, PASSAGEIRO_ADD_FAILED, PASSAGEIRO_ADD_SUCCESS, PASSAGEIRO_REMOVE, PASSAGEIRO_REMOVE_FAILED, PASSAGEIRO_REMOVE_SUCCESS, PASSAGEIRO_UPDATE, PASSAGEIRO_UPDATE_SUCCESS, PASSAGEIRO_UPDATE_FAILED, PASSAGEIRO_ENDERECOS, PASSAGEIRO_ENDERECOS_SUCCESS, PASSAGEIRO_ENDERECOS_FAILED } from './constants';

const INIT_STATE = {
    passageiros: {},
    passageiro: {},
    loading: false,
    error: null,
    passageiroStatus: null,
    id: {},
    enderecos: [],
    enderecoLoading: false
};

const VeiculoMarcaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PASSAGEIRO_LIST:
            return { ...state, passageiro: {}, loading: true, error: null, passageiroDeletado: null };
        case PASSAGEIRO_LIST_SUCCESS:
            return { ...state, passageiros: action.payload, loading: false, error: null, passageiroDeletado: null };
        case PASSAGEIRO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, passageiroDeletado: null };
        case PASSAGEIRO_LOAD:
            return { ...state, passageiro: {}, loading: true, error: null, passageiroStatus: null, passageiroDeletado: null }
        case PASSAGEIRO_LOAD_SUCCESS:
            return { ...state, passageiro: action.payload, loading: false, error: null, passageiroDeletado: null}
        case PASSAGEIRO_LOAD_FAILED:
            return { ...state, passageiro: {}, error: action.payload, loading: false, passageiroDeletado: null };
        case PASSAGEIRO_ADD:
            return { ...state, passageiro: action.payload, passageiroStatus: null, loading: true, error: null, passageiroDeletado: null}
        case PASSAGEIRO_ADD_SUCCESS:
            return { ...state, passageiro: action.payload,  passageiroStatus: "Passageiro adicionado com sucesso", passageiroDeletado: null, loading: false, error: null };
        case PASSAGEIRO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PASSAGEIRO_UPDATE:
            return { ...state, passageiro: action.payload, passageiroStatus: null, loading: true, error: null}
        case PASSAGEIRO_UPDATE_SUCCESS:
            return { ...state, passageiro: action.payload,  passageiroStatus: "Passageiro atualizado com sucesso", passageiroDeletado: null, loading: false, error: null };
        case PASSAGEIRO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, passageiroStatus: null };
        case PASSAGEIRO_REMOVE:
            return { ...state, passageiro: action.payload, passageiroStatus: null, loading: true, error: null, passageiroDeletado: null}
        case PASSAGEIRO_REMOVE_SUCCESS:
            return { ...state, passageiro: {}, passageiroStatus: null,  passageiroDeletado: "Passageiro removido com sucesso", loading: false, error: null };
        case PASSAGEIRO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, passageiroStatus: null, passageiroDeletado: null };
        
        // Busca os endereços do passageiro
        case PASSAGEIRO_ENDERECOS:
            return { ...state, passageiroId: action.payload, enderecoLoading: true }
        case PASSAGEIRO_ENDERECOS_SUCCESS:
            return { ...state, enderecos: action.payload, enderecoLoading: false }
        case PASSAGEIRO_ENDERECOS_FAILED:
            return { ...state, error: action.payload, enderecoLoading: false }
            
        default:
            return { ...state };
    }
};

export default VeiculoMarcaReducer;