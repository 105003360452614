import { 
    LOCAL_LIST,
    LOCAL_LIST_SUCCESS,
    LOCAL_LIST_FAILED,
    LOCAL_ADD,
    LOCAL_ADD_SUCCESS,
    LOCAL_ADD_FAILED,
    LOCAL_UPDATE,
    LOCAL_UPDATE_SUCCESS,
    LOCAL_UPDATE_FAILED,
    LOCAL_LOAD,
    LOCAL_LOAD_SUCCESS,
    LOCAL_LOAD_FAILED,
    LOCAL_REMOVE,
    LOCAL_REMOVE_SUCCESS,
    LOCAL_REMOVE_FAILED
} from './constants';

// List
export const localList        = (filter) => ({ type: LOCAL_LIST, payload: filter});
export const localListSuccess = (locais) => ({ type: LOCAL_LIST_SUCCESS, payload: locais });
export const localListFailed  = (error) => ({ type: LOCAL_LIST_FAILED, payload: error });

export const localLoad        = (id) => ({ type: LOCAL_LOAD, payload: id });
export const localLoadSuccess  = (local) => ({ type: LOCAL_LOAD_SUCCESS, payload: local });
export const localLoadFailed   = (error) => ({ type: LOCAL_LOAD_FAILED, payload: error });

// Add
export const localAdd         = (local) => ({ type: LOCAL_ADD, payload: local });
export const localAddSuccess  = (local, localStatus) => ({ type: LOCAL_ADD_SUCCESS, localStatus: localStatus, payload: local });
export const localAddFailed   = (error) => ({ type: LOCAL_ADD_FAILED, payload: error });

// Update
export const localUpdate         = (local) => ({ type: LOCAL_UPDATE, payload: local });
export const localUpdateSuccess  = (local, localStatus) => ({ type: LOCAL_UPDATE_SUCCESS, localStatus: localStatus, payload: local});
export const localUpdateFailed   = (error) => ({ type: LOCAL_UPDATE_FAILED, payload: error });

// remove
export const localRemove         = (local) => ({ type: LOCAL_REMOVE, payload: local });
export const localRemoveSuccess  = (local, localDeletado) => ({ type: LOCAL_REMOVE_SUCCESS, localDeletado: localDeletado, payload: local });
export const localRemoveFailed   = (error) => ({ type: LOCAL_REMOVE_FAILED, payload: error });