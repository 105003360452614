import {
    DASH_TOTALIZADOR_LIST,
    DASH_TOTALIZADOR_LIST_SUCCESS,
    DASH_TOTALIZADOR_LIST_FAILED,
    DASH_TOTAL_CENTRO_CUSTO,
    DASH_TOTAL_CENTRO_CUSTO_SUCCESS,
    DASH_TOTAL_CENTRO_CUSTO_FAILED,
    DASH_TOTAL_CIDADE,
    DASH_TOTAL_CIDADE_SUCCESS,
    DASH_TOTAL_CIDADE_FAILED,
    DASH_KM_LINEAR,
    DASH_KM_LINEAR_SUCCESS,
    DASH_KM_LINEAR_FAILED,
    MOTORISTA_EM_ATIVIDADE,
    MOTORISTA_EM_ATIVIDADE_SUCCESS,
    MOTORISTA_EM_ATIVIDADE_FAILED,
    QUANTIDADE_CORRIDA_SLA_SUCCESS,
    QUANTIDADE_CORRIDA_SLA_FAILED,
    QUANTIDADE_CORRIDA_SLA_LIST,
    QUANTIDADE_CORRIDA_EM_FILA_LIST,
    QUANTIDADE_CORRIDA_EM_FILA_FAILED,
    QUANTIDADE_CORRIDA_EM_FILA_SUCCESS,
    DASH_MOTORISTA_HORAS_ONLINE,
    DASH_MOTORISTA_HORAS_ONLINE_SUCCESS,
    DASH_MOTORISTA_HORAS_ONLINE_FAILED,
} from './constants';

const INIT_STATE = {
    totalizadores: {},
    totalCentroCustos: [],
    totalCidades: [],
    kmLinear: [],
    loading: false,
    error: null,
    motoristasAtividade: [],
    quantidadeCorridaSla: [],
    quantidadeCorridasEmFila: [],
    motoristasOnline: [],
    loadingMotoristasOnline: false,
    errorMotoristasOnline: null,
};

const DashboardReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DASH_TOTALIZADOR_LIST:
            return { ...state, totalizadores: {}, loading: true, error: null };
        case DASH_TOTALIZADOR_LIST_SUCCESS:
            return { ...state, totalizadores: action.payload, loading: false, error: null };
        case DASH_TOTALIZADOR_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DASH_TOTAL_CENTRO_CUSTO:
            return { ...state, totalCentroCustos: [], loading: true, error: null };
        case DASH_TOTAL_CENTRO_CUSTO_SUCCESS:
            return { ...state, totalCentroCustos: action.payload, loading: false, error: null };
        case DASH_TOTAL_CENTRO_CUSTO_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DASH_TOTAL_CIDADE:
            return { ...state, totalCidades: [], loading: true, error: null };
        case DASH_TOTAL_CIDADE_SUCCESS:
            return { ...state, totalCidades: action.payload, loading: false, error: null };
        case DASH_TOTAL_CIDADE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DASH_KM_LINEAR:
            return { ...state, kmLinear: [], loading: true, error: null };
        case DASH_KM_LINEAR_SUCCESS:
            return { ...state, kmLinear: action.payload, loading: false, error: null };
        case DASH_KM_LINEAR_FAILED:
            return { ...state, error: action.payload, loading: false };

        case MOTORISTA_EM_ATIVIDADE:
            return { ...state, motoristasAtividade: [], loading: true, error: null };
        case MOTORISTA_EM_ATIVIDADE_SUCCESS:
            return { ...state, motoristasAtividade: action.payload, loading: false, error: null };
        case MOTORISTA_EM_ATIVIDADE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case QUANTIDADE_CORRIDA_SLA_LIST:
            return { ...state, quantidadeCorridaSla: [], loading: true, error: null };
        case QUANTIDADE_CORRIDA_SLA_SUCCESS:
            return { ...state, quantidadeCorridaSla: action.payload, loading: false, error: null };
        case QUANTIDADE_CORRIDA_SLA_FAILED:
            return { ...state, error: action.payload, loading: false };

        case QUANTIDADE_CORRIDA_EM_FILA_LIST:
            return { ...state, quantidadeCorridasEmFila: [], loading: true, error: null };
        case QUANTIDADE_CORRIDA_EM_FILA_SUCCESS:
            return { ...state, quantidadeCorridasEmFila: action.payload, loading: false, error: null };
        case QUANTIDADE_CORRIDA_EM_FILA_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DASH_MOTORISTA_HORAS_ONLINE:
            return { ...state, loadingMotoristasOnline: true, errorMotoristasOnline: null };
        case DASH_MOTORISTA_HORAS_ONLINE_SUCCESS:
            return { ...state, motoristasOnline: action.payload, loadingMotoristasOnline: false, errorMotoristasOnline: null };
        case DASH_MOTORISTA_HORAS_ONLINE_FAILED:
            return { ...state, errorMotoristasOnline: action.payload, loadingMotoristasOnline: false };

        default:
            return { ...state };
    }
};

export default DashboardReducer;
