import {

    FATURAMENTO_CALCULAR,
    FATURAMENTO_CALCULAR_SUCCESS,
    FATURAMENTO_CALCULAR_FAILED,

    FATURA_LOAD, 
    FATURA_LOAD_SUCCESS, 
    FATURA_LOAD_FAILED, 
    FATURA_LIST, 
    FATURA_LIST_SUCCESS, 
    FATURA_LIST_FAILED, 
    FATURA_CLIENTE_LIST, 
    FATURA_CLIENTE_LIST_SUCCESS, 
    FATURA_CLIENTE_LIST_FAILED, 
    FATURA_REMOVE, 
    FATURA_REMOVE_FAILED, 
    FATURA_REMOVE_SUCCESS, 
    FATURA_UPDATE, 
    FATURA_UPDATE_SUCCESS, 
    FATURA_UPDATE_FAILED, 
    
    ITEM_FATURA_NEW, 
    ITEM_FATURA_LOAD, 
    ITEM_FATURA_ADD, 
    ITEM_FATURA_ADD_SUCCESS, 
    ITEM_FATURA_ADD_FAILED,
    ITEM_FATURA_UPDATE, 
    ITEM_FATURA_UPDATE_SUCCESS, 
    ITEM_FATURA_UPDATE_FAILED, 
    ITEM_FATURA_REMOVE_START,
    ITEM_FATURA_REMOVE,
    ITEM_FATURA_REMOVE_SUCCESS,
    ITEM_FATURA_REMOVE_FAILED,
    ITEM_FATURA_CANCEL,
    ITEM_FATURA_LIST,
    ITEM_FATURA_LIST_SUCCESS,
    ITEM_FATURA_LIST_FAILED,
    ITEM_FATURA_LIST_ALL,
    ITEM_FATURA_LIST_ALL_SUCCESS,
    ITEM_FATURA_LIST_ALL_FAILED,
    ITEM_FATURA_LOAD_SUCCESS,
    ITEM_FATURA_LOAD_FAILED,
    FATURA_PUBLICAR,
    FATURA_PUBLICAR_FAILED,
    FATURA_PUBLICAR_SUCCESS,
    FATURA_LIST_CLIENTE,
    FATURA_LIST_CLIENTE_SUCCESS,
    FATURA_LIST_CLIENTE_FAILED,
    FATURA_APROVAR,
    FATURA_APROVAR_SUCCESS,
    FATURA_APROVAR_FAILED,
    FATURA_REJEITAR,
    FATURA_REJEITAR_SUCCESS,
    FATURA_REJEITAR_FAILED,
    FATURAMENTO_CALCULAR_FIXO,
    FATURAMENTO_CALCULAR_FIXO_SUCCESS,
    FATURAMENTO_CALCULAR_FIXO_FAILED,
    AGRUPAMENTO_LIST,
    AGRUPAMENTO_LIST_SUCCESS,
    AGRUPAMENTO_LIST_FAILED,
    SHOW_DETALHE_MODAL,
    CORRIDAS_FATURADAS_LIST,
    CORRIDAS_FATURADAS_LIST_SUCCESS,
    CORRIDAS_FATURADAS_LIST_FAILED,
    DESPESAS_FATURADAS_LIST,
    DESPESAS_FATURADAS_LIST_SUCCESS,
    DESPESAS_FATURADAS_LIST_FAILED,
    AGRUPAMENTO_REMOVE,
    AGRUPAMENTO_REMOVE_SUCCESS,
    AGRUPAMENTO_REMOVE_FAILED,
    AGRUPAMENTO_LOAD,
    AGRUPAMENTO_LOAD_SUCCESS,
    AGRUPAMENTO_LOAD_FAILED,
    AGRUPAMENTO_CLIENTE_LOAD,
    AGRUPAMENTO_CLIENTE_LOAD_SUCCESS,
    AGRUPAMENTO_CLIENTE_LOAD_FAILED,
    RECALCULAR,
    RECALCULAR_SUCCESS,
    RECALCULAR_FAILED,
    DASH_FATURAMENTO,
    DASH_FATURAMENTO_SUCCESS,
    DASH_FATURAMENTO_FAILED,
    DASH_CLIENTE_FATURAMENTO,
    DASH_CLIENTE_FATURAMENTO_SUCCESS,
    DASH_CLIENTE_FATURAMENTO_FAILED} from './constants'

const INIT_STATE = {

    faturamentos: [],
    error: null,
    loading: false,
    fatura: {},
    itensFatura: [],
    itemFatura: {},
    success: null,
    faturaDeletado: null,
    modal: false,
    modalRemove: false,
    itemsFaturados: [],
    itemFaturaId: null,
    agrupamentos: [],
    agrupamento: '',
    corridas: [],
    despesas: [],
    dashboards: {},
    modalCorridasFaturadas: false,
    corridasFaturadas: [],
    despesasFaturadas: [],
    modalDespesasFaturadas: []
}

const FaturamentoReducer = (state = INIT_STATE, action) => {
    
    switch (action.type) {

        case FATURAMENTO_CALCULAR: 
            return { ...state, loading: true, error: false }
        case FATURAMENTO_CALCULAR_SUCCESS: 
            return { ...state, loading: false, faturamentos: action.payload, error: false }
        case FATURAMENTO_CALCULAR_FAILED:
            return { ...state, loading: false, error: action.payload }

        case FATURAMENTO_CALCULAR_FIXO: 
            return { ...state, loading: true, error: false }
        case FATURAMENTO_CALCULAR_FIXO_SUCCESS: 
            return { ...state, loading: false, faturamentos: action.payload, error: false }
        case FATURAMENTO_CALCULAR_FIXO_FAILED:
            return { ...state, loading: false, error: action.payload }

        case FATURA_LIST:
            return { ...state, loading: true, error: null, faturaDeletado: null };
        case FATURA_LIST_SUCCESS:
            return { ...state, faturamentos: action.payload, loading: false, error: null, faturaDeletado: null };
        case FATURA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, faturaDeletado: null };
        
        case FATURA_CLIENTE_LIST:
            return { ...state, loading: true, error: null, faturaDeletado: null };
        case FATURA_CLIENTE_LIST_SUCCESS:
            return { ...state, faturamentos: action.payload, loading: false, error: null, faturaDeletado: null };
        case FATURA_CLIENTE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, faturaDeletado: null };

        case FATURA_LOAD:
            return { ...state, loading: true, error: null, success: null, faturaDeletado: null }
        case FATURA_LOAD_SUCCESS:
            return { ...state, fatura: action.payload, loading: false, error: null, faturaDeletado: null}
        case FATURA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, faturaDeletado: null };

        case FATURA_UPDATE:
            return { ...state, success: null, loading: true, error: null}
        case FATURA_UPDATE_SUCCESS:
            return { ...state, fatura: action.payload,  success: "Operação atualizada com sucesso", faturaDeletado: null, loading: false, error: null };
        case FATURA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, success: null };
       
        case FATURA_REMOVE:
            return { ...state, success: null, loading: true, error: null, faturaDeletado: null}
        case FATURA_REMOVE_SUCCESS:
            return { ...state, fatura: {}, itensFatura: [], success: true,  faturaDeletado: "Operação removida com sucesso", loading: false, error: null }; 
        case FATURA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, success: null, faturaDeletado: null };
        
        case ITEM_FATURA_LOAD:
            return { ...state, loading: true };
        case ITEM_FATURA_LOAD_SUCCESS: 
            return { ...state, itemFatura: action.payload, modal: true, loading: false }
        case ITEM_FATURA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false }

        case ITEM_FATURA_NEW:
            return { ...state,  modal: true, itemFatura: { faturamentoId: state.fatura?.id } };

        case ITEM_FATURA_ADD:
            return { ...state, itemFatura: action.payload, loading: true };

        case ITEM_FATURA_ADD_SUCCESS:
            return { ...state, itemFatura: null, modal: false, loading: false, fatura: {...state.fatura} };

        case ITEM_FATURA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ITEM_FATURA_UPDATE:
            return { ...state, loading: true };

        case ITEM_FATURA_UPDATE_SUCCESS:
            return { ...state, itemFatura: null, modal: false, loading: false, fatura: {...state.fatura} };
        
        case ITEM_FATURA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ITEM_FATURA_REMOVE_START:
            return { ...state, modalRemove: true, itemFaturaId: action.payload }
            
        case ITEM_FATURA_REMOVE:
            return { ...state, loading: true };

        case ITEM_FATURA_REMOVE_SUCCESS:
            return { ...state, itemFaturaId: null, itemFatura: null, modal: false, loading: false, fatura: {...state.fatura}, modalRemove: false };

        case ITEM_FATURA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ITEM_FATURA_CANCEL:
            return { ...state, itemFatura: {}, modal: false, modalRemove: false };

        case ITEM_FATURA_LIST:
            return { ...state, loading: true, error: null };
        case ITEM_FATURA_LIST_SUCCESS: 
            return { ...state, loading: false, error: null, itensFatura: action.payload }
        case ITEM_FATURA_LIST_FAILED:
            return { ...state, loading: false, error: action.payload }

        case ITEM_FATURA_LIST_ALL:
            return { ...state, loading: true, error: null };
        case ITEM_FATURA_LIST_ALL_SUCCESS: 
            return { ...state, loading: false, error: null, itemsFaturados: action.payload }
        case ITEM_FATURA_LIST_ALL_FAILED:
            return { ...state, loading: false, error: action.payload }
        
        case FATURA_PUBLICAR: 
            return { ...state, loading: true, error: null }
        case FATURA_PUBLICAR_SUCCESS:
            return { ...state, loading: false, error: null, success: 'Fatura publicada com sucesso, aguarde aprovação do cliente.' }
        case FATURA_PUBLICAR_FAILED:
            return { ...state, loading: false, error: action.payload }

        case FATURA_APROVAR: 
            return { ...state, loading: true, error: null }
        case FATURA_APROVAR_SUCCESS:
            return { ...state, loading: false, error: null, success: 'Fatura aprovada com sucesso.' }
        case FATURA_APROVAR_FAILED:
            return { ...state, loading: false, error: action.payload }

        case FATURA_REJEITAR: 
            return { ...state, loading: true, error: null }
        case FATURA_REJEITAR_SUCCESS:
            return { ...state, loading: false, error: null, success: 'Fatura rejeitada com sucesso.' }
        case FATURA_REJEITAR_FAILED:
            return { ...state, loading: false, error: action.payload }

        case FATURA_LIST_CLIENTE: 
            return { ...state, loading: true, error: null }
        case FATURA_LIST_CLIENTE_SUCCESS:
            return { ...state, loading: false, error: null, faturamentos: action.payload }
        case FATURA_LIST_CLIENTE_FAILED:
            return { ...state, loading: false, error: action.payload }

        case AGRUPAMENTO_LIST: 
            return { ...state, loading: true, error: null }
        case AGRUPAMENTO_LIST_SUCCESS:
            return { ...state, loading: false, error: null, agrupamentos: action.payload }
        case AGRUPAMENTO_LIST_FAILED:
            return { ...state, loading: false, error: action.payload }

        case CORRIDAS_FATURADAS_LIST: 
            return { ...state, loading: true, error: null, modalCorridasFaturadas: false }
        case CORRIDAS_FATURADAS_LIST_SUCCESS:
            return { ...state, loading: false, error: null, corridas: action.payload }
        case CORRIDAS_FATURADAS_LIST_FAILED:
            return { ...state, loading: false, error: action.payload }

        case DESPESAS_FATURADAS_LIST: 
            return { ...state, loading: true, error: null }
        case DESPESAS_FATURADAS_LIST_SUCCESS:
            return { ...state, loading: false, error: null, despesas: action.payload }
        case DESPESAS_FATURADAS_LIST_FAILED:
            return { ...state, loading: false, error: action.payload }

        case SHOW_DETALHE_MODAL: 
            return { ...state, modal: !state.modal }

        case AGRUPAMENTO_REMOVE: 
            return { ...state, loading: true, error: null, success: null, faturaDeletado: null }
        case AGRUPAMENTO_REMOVE_SUCCESS:
            return { ...state, loading: false, error: null, faturaDeletado: action.payload }
        case AGRUPAMENTO_REMOVE_FAILED:
            return { ...state, loading: false, error: action.payload }

        case AGRUPAMENTO_LOAD:
            return { ...state, loading: true, error: null, success: null, faturaDeletado: null, modalCorridasFaturadas: false }
        case AGRUPAMENTO_LOAD_SUCCESS: 
            return { ...state, loading: false, error: null, agrupamento: action.payload }
        case AGRUPAMENTO_LOAD_FAILED: 
            return { ...state, loading: false, error: action.payload }
            
        case AGRUPAMENTO_CLIENTE_LOAD: 
            return { ...state, loading: true, error: null, success: null, faturaDeletado: null, modalCorridasFaturadas: false }
        case AGRUPAMENTO_CLIENTE_LOAD_SUCCESS:
            return { ...state, loading: false, error: null, agrupamento: action.payload }
        case AGRUPAMENTO_CLIENTE_LOAD_FAILED:
            return { ...state, loading: false, error: action.payload }

        case RECALCULAR:
            return { ...state, loading: true, error: null, success: null }
        case RECALCULAR_SUCCESS: 
            return { ...state, loading: false, error: null, success: action.payload }
        case RECALCULAR_FAILED: 
            return { ...state, loading: false, error: action.payload }

        case DASH_FATURAMENTO:
            return { ...state, loading: true, error: null, success: null, dashboards: {} }
        case DASH_FATURAMENTO_SUCCESS: 
            return { ...state, loading: false, dashboards: action.payload, error: null }
        case DASH_FATURAMENTO_FAILED:
            return { ...state, loading: false, dashboards: null, error: action.payload, success: null }

        case DASH_CLIENTE_FATURAMENTO:
            return { ...state, loading: true, error: null, success: null, dashboards: {} }
        case DASH_CLIENTE_FATURAMENTO_SUCCESS: 
            return { ...state, loading: false, dashboards: action.payload, error: null }
        case DASH_CLIENTE_FATURAMENTO_FAILED:
            return { ...state, loading: false, dashboards: null, error: action.payload, success: null }

        default: 
            return { ...state }
    }
}

export default FaturamentoReducer;