/* VEICULO */
export const SOLICITACAO_LIST               = 'SOLICITACAO_LIST';
export const SOLICITACAO_LIST_SUCCESS       = 'SOLICITACAO_LIST_SUCCESS';
export const SOLICITACAO_LIST_FAILED        = 'SOLICITACAO_LIST_FAILED';
export const SOLICITACAO_LISTALL            = 'SOLICITACAO_LISTALL';
export const SOLICITACAO_LISTALL_SUCCESS    = 'SOLICITACAO_LISTALL_SUCCESS';
export const SOLICITACAO_LISTALL_FAILED     = 'SOLICITACAO_LISTALL_FAILED';
export const SOLICITACAO_APROVAR            = 'SOLICITACAO_APROVAR'
export const SOLICITACAO_APROVAR_SUCCESS    = 'SOLICITACAO_APROVAR_SUCCESS'
export const SOLICITACAO_APROVAR_FAILED     = 'SOLICITACAO_APROVAR_FAILED'
export const SOLICITACAO_REJEITAR           = 'SOLICITACAO_REJEITAR'
export const SOLICITACAO_REJEITAR_SUCCESS   = 'SOLICITACAO_REJEITAR_SUCCESS'
export const SOLICITACAO_REJEITAR_FAILED    = 'SOLICITACAO_REJEITAR_FAILED'
export const SOLICITACAO_DOWNLOAD           = 'SOLICITACAO_DOWNLOAD'
export const SOLICITACAO_DOWNLOAD_SUCCESS   = 'SOLICITACAO_DOWNLOAD_SUCCESS'
export const SOLICITACAO_DOWNLOAD_FAILED    = 'SOLICITACAO_DOWNLOAD_FAILED'