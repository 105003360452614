/* MOTORISTA */
export const MOTORISTA_LIST           = 'MOTORISTA_LIST';
export const MOTORISTA_LIST_SUCCESS   = 'MOTORISTA_LIST_SUCCESS';
export const MOTORISTA_LIST_FAILED    = 'MOTORISTA_LIST_FAILED';
export const MOTORISTA_ADD            = 'MOTORISTA_ADD';
export const MOTORISTA_ADD_SUCCESS    = 'MOTORISTA_ADD_SUCCESS';
export const MOTORISTA_ADD_FAILED     = 'MOTORISTA_ADD_FAILED';
export const MOTORISTA_UPDATE         = 'MOTORISTA_UPDATE';
export const MOTORISTA_UPDATE_SUCCESS = 'MOTORISTA_UPDATE_SUCCESS';
export const MOTORISTA_UPDATE_FAILED  = 'MOTORISTA_UPDATE_FAILED';
export const MOTORISTA_REMOVE         = 'MOTORISTA_REMOVE';
export const MOTORISTA_REMOVE_SUCCESS = 'MOTORISTA_REMOVE_SUCCESS';
export const MOTORISTA_REMOVE_FAILED  = 'MOTORISTA_REMOVE_FAILED';
export const MOTORISTA_LOAD           = 'MOTORISTA_LOAD'
export const MOTORISTA_LOAD_SUCCESS   = 'MOTORISTA_LOAD_SUCCESS';
export const MOTORISTA_LOAD_FAILED    = 'MOTORISTA_LOAD_FAILED';

export const MOTORISTA_LIST_DOCUMENTOS           = 'MOTORISTA_LIST_DOCUMENTOS';
export const MOTORISTA_LIST_DOCUMENTOS_SUCCESS   = 'MOTORISTA_LIST_DOCUMENTOS_SUCCESS';
export const MOTORISTA_LIST_DOCUMENTOS_FAILED    = 'MOTORISTA_LIST_DOCUMENTOS_FAILED';