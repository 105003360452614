/* VEICULO */
export const CENTRO_CUSTO_LIST           = 'CENTRO_CUSTO_LIST';
export const CENTRO_CUSTO_LIST_SUCCESS   = 'CENTRO_CUSTO_LIST_SUCCESS';
export const CENTRO_CUSTO_LIST_FAILED    = 'CENTRO_CUSTO_LIST_FAILED';
export const CENTRO_CUSTO_ADD            = 'CENTRO_CUSTO_ADD';
export const CENTRO_CUSTO_ADD_SUCCESS    = 'CENTRO_CUSTO_ADD_SUCCESS';
export const CENTRO_CUSTO_ADD_FAILED     = 'CENTRO_CUSTO_ADD_FAILED';
export const CENTRO_CUSTO_UPDATE         = 'CENTRO_CUSTO_UPDATE';
export const CENTRO_CUSTO_UPDATE_SUCCESS = 'CENTRO_CUSTO_UPDATE_SUCCESS';
export const CENTRO_CUSTO_UPDATE_FAILED  = 'CENTRO_CUSTO_UPDATE_FAILED';
export const CENTRO_CUSTO_REMOVE         = 'CENTRO_CUSTO_REMOVE';
export const CENTRO_CUSTO_REMOVE_SUCCESS = 'CENTRO_CUSTO_REMOVE_SUCCESS';
export const CENTRO_CUSTO_REMOVE_FAILED  = 'CENTRO_CUSTO_REMOVE_FAILED';
export const CENTRO_CUSTO_LOAD           = 'CENTRO_CUSTO_LOAD'
export const CENTRO_CUSTO_LOAD_SUCCESS   = 'CENTRO_CUSTO_LOAD_SUCCESS';
export const CENTRO_CUSTO_LOAD_FAILED    = 'CENTRO_CUSTO_LOAD_FAILED';

export const CENTRO_CUSTO_LIST_NIVEL    = 'CENTRO_CUSTO_LIST_NIVEL';
export const CENTRO_CUSTO_LIST_NIVEL_SUCCESS    = 'CENTRO_CUSTO_LIST_NIVEL_SUCCESS';
export const CENTRO_CUSTO_LIST_NIVEL_FAILED    = 'CENTRO_CUSTO_LIST_NIVEL_FAILED';

export const CENTRO_CUSTO_LIST_RECURSIVO_USUARIO = 'CENTRO_CUSTO_LIST_RECURSIVO_USUARIO';
export const CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_SUCCESS = 'CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_SUCCESS';
export const CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_FAILED = 'CENTRO_CUSTO_LIST_RECURSIVO_USUARIO_FAILED';