import { 
    ESTACAO_LIST,
    ESTACAO_LIST_SUCCESS,
    ESTACAO_LIST_FAILED,
    ESTACAO_ADD,
    ESTACAO_ADD_SUCCESS,
    ESTACAO_ADD_FAILED,
    ESTACAO_UPDATE,
    ESTACAO_UPDATE_SUCCESS,
    ESTACAO_UPDATE_FAILED,
    ESTACAO_LOAD,
    ESTACAO_LOAD_SUCCESS,
    ESTACAO_LOAD_FAILED,
    ESTACAO_REMOVE,
    ESTACAO_REMOVE_SUCCESS,
    ESTACAO_REMOVE_FAILED
} from './constants';

// List
export const estacaoList        = (pageNumber, pageSize, rota, motoristaId) => ({ type: ESTACAO_LIST, payload: { pageNumber, pageSize, rota, motoristaId },});
export const estacaoListSuccess = (estacoes) => ({ type: ESTACAO_LIST_SUCCESS, payload: { estacoes } });
export const estacaoListFailed  = (error) => ({ type: ESTACAO_LIST_FAILED, payload: error  });

export const estacaoLoad        = (id) => ({ type: ESTACAO_LOAD, payload: { id } });
export const estacaoLoadSuccess  = (estacao) => ({ type: ESTACAO_LOAD_SUCCESS, payload: { estacao } });
export const estacaoLoadFailed   = (error) => ({ type: ESTACAO_LOAD_FAILED, payload: error  });

// Add
export const estacaoAdd         = (estacao) => ({ type: ESTACAO_ADD, payload: { estacao } });
export const estacaoAddSuccess  = (estacao, estacaoStatus) => ({ type: ESTACAO_ADD_SUCCESS, estacaoStatus: estacaoStatus, payload: { estacao } });
export const estacaoAddFailed   = (error) => ({ type: ESTACAO_ADD_FAILED, payload: error  });

// Update
export const estacaoUpdate         = (estacao) => ({ type: ESTACAO_UPDATE, payload: { estacao } });
export const estacaoUpdateSuccess  = (estacao, estacaoStatus) => ({ type: ESTACAO_UPDATE_SUCCESS, estacaoStatus: estacaoStatus, payload: { estacao } });
export const estacaoUpdateFailed   = (error) => ({ type: ESTACAO_UPDATE_FAILED, payload: error  });

// remove
export const estacaoRemove         = (estacao) => ({ type: ESTACAO_REMOVE, payload: { estacao } });
export const estacaoRemoveSuccess  = (estacao, estacaoDeletado) => ({ type: ESTACAO_REMOVE_SUCCESS, estacaoDeletado: estacaoDeletado, payload: { estacao } });
export const estacaoRemoveFailed   = (error) => ({ type: ESTACAO_REMOVE_FAILED, payload: error  });