/* VEICULO */
export const AUDITORIA_LIST           = 'AUDITORIA_LIST';
export const AUDITORIA_LIST_SUCCESS   = 'AUDITORIA_LIST_SUCCESS';
export const AUDITORIA_LIST_FAILED    = 'AUDITORIA_LIST_FAILED';
export const AUDITORIA_ADD            = 'AUDITORIA_ADD';
export const AUDITORIA_ADD_SUCCESS    = 'AUDITORIA_ADD_SUCCESS';
export const AUDITORIA_ADD_FAILED     = 'AUDITORIA_ADD_FAILED';
export const AUDITORIA_UPDATE         = 'AUDITORIA_UPDATE';
export const AUDITORIA_UPDATE_SUCCESS = 'AUDITORIA_UPDATE_SUCCESS';
export const AUDITORIA_UPDATE_FAILED  = 'AUDITORIA_UPDATE_FAILED';
export const AUDITORIA_REMOVE         = 'AUDITORIA_REMOVE';
export const AUDITORIA_REMOVE_SUCCESS = 'AUDITORIA_REMOVE_SUCCESS';
export const AUDITORIA_REMOVE_FAILED  = 'AUDITORIA_REMOVE_FAILED';
export const AUDITORIA_LOAD           = 'AUDITORIA_LOAD'
export const AUDITORIA_LOAD_SUCCESS   = 'AUDITORIA_LOAD_SUCCESS';
export const AUDITORIA_LOAD_FAILED    = 'AUDITORIA_LOAD_FAILED';
