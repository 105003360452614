
import { 

    ALTERARENDPASS_LOAD, 
    ALTERARENDPASS_LOAD_SUCCESS, 
    ALTERARENDPASS_LOAD_FAILED, 
    ALTERARENDPASS_LIST, 
    ALTERARENDPASS_LIST_SUCCESS, 
    ALTERARENDPASS_LIST_FAILED, 
    ALTERARENDPASS_ADD, 
    ALTERARENDPASS_ADD_FAILED, 
    ALTERARENDPASS_ADD_SUCCESS, 
    ALTERARENDPASS_REMOVE, 
    ALTERARENDPASS_REMOVE_FAILED, 
    ALTERARENDPASS_REMOVE_SUCCESS, 
    ALTERARENDPASS_UPDATE, 
    ALTERARENDPASS_UPDATE_SUCCESS, 
    ALTERARENDPASS_UPDATE_FAILED,
    ALTERARENDPASS_APROVAR,
    ALTERARENDPASS_APROVAR_SUCCESS,
    ALTERARENDPASS_APROVAR_FAILED,
    ALTERARENDPASS_REJEITAR,
    ALTERARENDPASS_REJEITAR_SUCCESS,
    ALTERARENDPASS_REJEITAR_FAILED,
    ALTERARENDPASS_DOWNLOAD_FAILED,
    ALTERARENDPASS_DOWNLOAD_SUCCESS,
    ALTERARENDPASS_DOWNLOAD
    
} 
    
from './constants';

const INIT_STATE = {
    alterarEndPassageiros: {},
    alterarEndPassageiro: {},
    loading: false,
    error: null,
    alterarEndPassageiroStatus: null,
    id: {}
};

const AlterarEnderecoPassReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALTERARENDPASS_LIST:
            return { ...state, loading: true, error: null, alterarEndPassDeletado: null };
        case ALTERARENDPASS_LIST_SUCCESS:
            return { ...state, alterarEndPassageiros: action.payload, loading: false, error: null, alterarEndPassDeletado: null };
        case ALTERARENDPASS_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, alterarEndPassDeletado: null };
        case ALTERARENDPASS_LOAD:
            return { ...state, loading: true, error: null, alterarEndPassageiroStatus: null, alterarEndPassDeletado: null }
        case ALTERARENDPASS_LOAD_SUCCESS:
            return { ...state, alterarEndPassageiro: action.payload, loading: false, error: null, alterarEndPassDeletado: null}
        case ALTERARENDPASS_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, alterarEndPassDeletado: null };
        case ALTERARENDPASS_ADD:
            return { ...state, alterarEndPassageiro: action.payload, alterarEndPassageiroStatus: null, loading: true, error: null, alterarEndPassDeletado: null}
        case ALTERARENDPASS_ADD_SUCCESS:
            return { ...state, alterarEndPassageiro: action.payload,  alterarEndPassageiroStatus: "Solicitação criada com sucesso", alterarEndPassDeletado: null, loading: false, error: null };
        case ALTERARENDPASS_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ALTERARENDPASS_UPDATE:
            return { ...state, alterarEndPassageiro: action.payload, alterarEndPassageiroStatus: null, loading: true, error: null}
        case ALTERARENDPASS_UPDATE_SUCCESS:
            return { ...state, alterarEndPassageiro: action.payload,  alterarEndPassageiroStatus: "Solicitação atualizada com sucesso", alterarEndPassDeletado: null, loading: false, error: null };
        case ALTERARENDPASS_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, alterarEndPassageiroStatus: null };
        case ALTERARENDPASS_REMOVE:
            return { ...state, alterarEndPassageiro: action.payload, alterarEndPassageiroStatus: null, loading: true, error: null, alterarEndPassDeletado: null}
        case ALTERARENDPASS_REMOVE_SUCCESS:
            return { ...state, alterarEndPassageiro: {}, alterarEndPassageiroStatus: null,  alterarEndPassDeletado: "Solicitação removida com sucesso", loading: false, error: null };
        case ALTERARENDPASS_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, alterarEndPassageiroStatus: null, alterarEndPassDeletado: null };
        case ALTERARENDPASS_APROVAR:
            return { ...state, alterarEndPassageiroStatus: null, loading: true, error: null, alterarEndPassDeletado: null}
        case ALTERARENDPASS_APROVAR_SUCCESS:
            return { ...state, alterarEndPassageiro: action.payload,  alterarEndPassageiroStatus: "Solicitação Aprovada com sucesso", alterarEndPassDeletado: null, loading: false, error: null };
        case ALTERARENDPASS_APROVAR_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ALTERARENDPASS_REJEITAR:
            return { ...state, alterarEndPassageiroStatus: null, loading: true, error: null, alterarEndPassDeletado: null}
        case ALTERARENDPASS_REJEITAR_SUCCESS:
            return { ...state, alterarEndPassageiro: action.payload,  alterarEndPassageiroStatus: "Solicitação rejeitada com sucesso", alterarEndPassDeletado: null, loading: false, error: null };
        case ALTERARENDPASS_REJEITAR_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ALTERARENDPASS_DOWNLOAD:
            return { ...state, alterarEndPassageiroStatus: null, loading: true, error: null, reembolsoDeletado: null}
        case ALTERARENDPASS_DOWNLOAD_SUCCESS:
            return { ...state, reembolso: action.payload,  alterarEndPassageiroStatus: "Download enviado", reembolsoDeletado: null, loading: false, error: null };
        case ALTERARENDPASS_DOWNLOAD_FAILED:
            return { ...state, error: action.payload, loading: false };
    default:
            return { ...state };
    }
};

export default AlterarEnderecoPassReducer;