import { 
    REEMBOLSO_LIST,
    REEMBOLSO_LIST_SUCCESS,
    REEMBOLSO_LIST_FAILED,
    REEMBOLSO_ADD,
    REEMBOLSO_ADD_SUCCESS,
    REEMBOLSO_ADD_FAILED,
    REEMBOLSO_UPDATE,
    REEMBOLSO_UPDATE_SUCCESS,
    REEMBOLSO_UPDATE_FAILED,
    REEMBOLSO_LOAD,
    REEMBOLSO_LOAD_SUCCESS,
    REEMBOLSO_LOAD_FAILED,
    REEMBOLSO_REMOVE,
    REEMBOLSO_REMOVE_SUCCESS,
    REEMBOLSO_REMOVE_FAILED,
    REEMBOLSO_UPLOAD,
    REEMBOLSO_UPLOAD_SUCCESS,
    REEMBOLSO_UPLOAD_FAILED,
    REEMBOLSO_DOWNLOAD,
    REEMBOLSO_DOWNLOAD_SUCCESS,
    REEMBOLSO_DOWNLOAD_FAILED,
    REEMBOLSO_REMOVEANEXO,
    REEMBOLSO_REMOVEANEXO_SUCCESS,
    REEMBOLSO_REMOVEANEXO_FAILED,

} from './constants';

// List
export const reembolsoList        = (filter) => ({ type: REEMBOLSO_LIST, payload: filter,});
export const reembolsoListSuccess = (reembolsos) => ({ type: REEMBOLSO_LIST_SUCCESS, payload: reembolsos });
export const reembolsoListFailed  = (error) => ({ type: REEMBOLSO_LIST_FAILED, payload: error  });

export const reembolsoLoad        = (id) => ({ type: REEMBOLSO_LOAD, payload: { id } });
export const reembolsoLoadSuccess  = (reembolso) => ({ type: REEMBOLSO_LOAD_SUCCESS, payload: reembolso });
export const reembolsoLoadFailed   = (error) => ({ type: REEMBOLSO_LOAD_FAILED, payload: error  });

// Add
export const reembolsoAdd         = (reembolso) => ({ type: REEMBOLSO_ADD, payload: reembolso });
export const reembolsoAddSuccess  = (reembolso, reembolsoStatus) => ({ type: REEMBOLSO_ADD_SUCCESS, reembolsoStatus: reembolsoStatus, payload: reembolso });
export const reembolsoAddFailed   = (error) => ({ type: REEMBOLSO_ADD_FAILED, payload: error  });

// Update
export const reembolsoUpdate         = (reembolso) => ({ type: REEMBOLSO_UPDATE, payload: reembolso });
export const reembolsoUpdateSuccess  = (reembolso, reembolsoStatus) => ({ type: REEMBOLSO_UPDATE_SUCCESS, reembolsoStatus: reembolsoStatus, payload: reembolso });
export const reembolsoUpdateFailed   = (error) => ({ type: REEMBOLSO_UPDATE_FAILED, payload: error  });

// remove
export const reembolsoRemove         = (reembolso) => ({ type: REEMBOLSO_REMOVE, payload: reembolso });
export const reembolsoRemoveSuccess  = (reembolso, reembolsoDeletado) => ({ type: REEMBOLSO_REMOVE_SUCCESS, reembolsoDeletado: reembolsoDeletado, payload: reembolso });
export const reembolsoRemoveFailed   = (error) => ({ type: REEMBOLSO_REMOVE_FAILED, payload: error  });

// upload files
export const reembolsoUpload         = (reembolsoId, arquivo) => ({ type: REEMBOLSO_UPLOAD, payload: { reembolsoId, arquivo } });
export const reembolsoUploadSuccess  = (reembolso, reembolsoStatus) => ({ type: REEMBOLSO_UPLOAD_SUCCESS, reembolsoStatus: reembolsoStatus, payload: reembolso });
export const reembolsoUploadFailed   = (error) => ({ type: REEMBOLSO_UPLOAD_FAILED, payload: error  });

// Download files
export const reembolsoDownload         = (arquivoId, nomeArquivo) => ({ type: REEMBOLSO_DOWNLOAD, payload: { arquivoId, nomeArquivo } });
export const reembolsoDownloadSuccess  = (reembolsoStatus) => ({ type: REEMBOLSO_DOWNLOAD_SUCCESS, reembolsoStatus: reembolsoStatus });
export const reembolsoDownloadFailed   = (error) => ({ type: REEMBOLSO_DOWNLOAD_FAILED, payload: error  });

// Remove files
export const reembolsoRemoveAnexo         = (arquivoId) => ({ type: REEMBOLSO_REMOVEANEXO, payload: { arquivoId } });
export const reembolsoRemoveAnexoSuccess  = (arquivoId) => ({ type: REEMBOLSO_REMOVEANEXO_SUCCESS, payload: arquivoId });
export const reembolsoRemoveAnexoFailed   = (error) => ({ type: REEMBOLSO_REMOVEANEXO_FAILED, payload: error  });

