/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import {
    SectionRegistrations_Container,
    SectionRegistrations_Label,
    SectionRegistrations_Items,
} from '../../navbar-menu';

// -> Icon <--------------------------------------------------------------------------------------

const IconPasseger = () => {
    return (
        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_2116)">
                <path d="M17.5696 16.4693C17.7819 16.6011 17.9889 16.7422 18.1899 16.8925L23.5 13.4999L23 12.9999L17.5696 16.4693Z" />
                <path d="M7.32612 23.8333H6.04351L5 24.4999L5.5 24.9999L7.32612 23.8333Z" />
                <path d="M4.33301 23.8333L5 24.4999L6.04351 23.8333H4.33301Z" />
                <path d="M23 12.9999L22.3332 12.3331L16.642 15.9691C16.9606 16.1167 17.2704 16.2837 17.5696 16.4693L23 12.9999Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.33301 23.8333C4.33301 23.8331 4.33301 23.8334 4.33301 23.8333H6.04351H7.32612H21.6663C21.6663 21.5347 20.7532 19.3303 19.1279 17.705C18.8333 17.4104 18.5197 17.1391 18.1899 16.8925C17.9889 16.7422 17.7819 16.6011 17.5696 16.4693C17.2704 16.2837 16.9606 16.1167 16.642 15.9691L4.33301 23.8333ZM4.45122 22.4065L15.4765 15.528C14.6798 15.2904 13.8461 15.1666 12.9997 15.1666C10.7011 15.1666 8.49673 16.0797 6.87142 17.705C5.58636 18.9901 4.74653 20.6371 4.45122 22.4065ZM6.49967 7.58325C6.49967 11.1745 9.40842 14.0833 12.9997 14.0833C16.5909 14.0833 19.4997 11.1745 19.4997 7.58325C19.4997 3.992 16.5909 1.08325 12.9997 1.08325C9.40842 1.08325 6.49967 3.992 6.49967 7.58325Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_13_2116">
                    <rect width="26" height="25.8834" />
                </clipPath>
            </defs>
        </svg>
    );
};

const IconMaps = () => {
    return (
        <svg viewBox="0 0 48 50" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.2499 24.9167C33.6618 23.5061 34.6879 21.7566 35.2301 19.8359C35.7723 17.9151 35.8124 15.8874 35.3466 13.9467L39.5049 12.1634C39.6317 12.109 39.7701 12.087 39.9075 12.0993C40.0449 12.1116 40.1771 12.1578 40.2923 12.2338C40.4074 12.3098 40.5019 12.4132 40.5672 12.5347C40.6326 12.6562 40.6667 12.7921 40.6666 12.93V36.6667L28.9999 41.6667L18.9999 36.6667L8.49492 41.1684C8.36811 41.2227 8.22978 41.2447 8.09236 41.2325C7.95494 41.2202 7.82272 41.174 7.70756 41.098C7.59241 41.022 7.49793 40.9186 7.4326 40.797C7.36727 40.6755 7.33313 40.5397 7.33325 40.4017V16.6667L12.5483 14.4317C12.1813 16.3077 12.2814 18.2451 12.8398 20.0732C13.3981 21.9014 14.3975 23.5642 15.7499 24.915L23.9999 33.1667L32.2499 24.9167ZM29.8933 22.56L23.9999 28.45L18.1066 22.5584C16.9414 21.3928 16.1479 19.908 15.8265 18.2915C15.5052 16.6751 15.6703 14.9996 16.3011 13.477C16.9319 11.9545 18 10.6531 19.3704 9.73752C20.7408 8.82194 22.3518 8.33325 23.9999 8.33325C25.648 8.33325 27.2591 8.82194 28.6294 9.73752C29.9998 10.6531 31.0679 11.9545 31.6987 13.477C32.3295 14.9996 32.4947 16.6751 32.1733 18.2915C31.8519 19.908 31.0585 21.3928 29.8933 22.5584V22.56Z" />
        </svg>
    );
};

const SectionRegistrations = () => {
    return (
        <SectionRegistrations_Container>
            <SectionRegistrations_Label>Cadastros</SectionRegistrations_Label>
            <SectionRegistrations_Items>
                <ItemWithIcon link="/passageiro" label="Passageiros" disable={false}>
                    <IconPasseger />
                </ItemWithIcon>

                <ItemWithIcon link="/local" label="Locais" disable={false}>
                    <IconMaps />
                </ItemWithIcon>
            </SectionRegistrations_Items>
        </SectionRegistrations_Container>
    );
};

export default SectionRegistrations;
