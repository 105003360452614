export const NEW_SOLICITACAO_CORRIDA_ADD = 'NEW_SOLICITACAO_CORRIDA_ADD';
export const NEW_SOLICITACAO_CORRIDA_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_FAILED = 'NEW_SOLICITACAO_CORRIDA_FAILED';

export const NEW_SOLICITACAO_CORRIDA_LIST = 'NEW_SOLICITACAO_CORRIDA_LIST';
export const NEW_SOLICITACAO_CORRIDA_LIST_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_LIST_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_LIST_FAILED = 'NEW_SOLICITACAO_CORRIDA_LIST_FAILED';

export const NEW_SOLICITACAO_CORRIDA_LOAD = 'NEW_SOLICITACAO_CORRIDA_LOAD';
export const NEW_SOLICITACAO_CORRIDA_LOAD_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_LOAD_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_LOAD_FAILED = 'NEW_SOLICITACAO_CORRIDA_LOAD_FAILED';

export const NEW_SOLICITACAO_CORRIDA_UPDATE = 'NEW_SOLICITACAO_CORRIDA_UPDATE';
export const NEW_SOLICITACAO_CORRIDA_UPDATE_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_UPDATE_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_UPDATE_FAILED = 'NEW_SOLICITACAO_CORRIDA_UPDATE_FAILED';

export const NEW_SOLICITACAO_CORRIDA_REMOVE = 'NEW_SOLICITACAO_CORRIDA_REMOVE';
export const NEW_SOLICITACAO_CORRIDA_REMOVE_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_REMOVE_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_REMOVE_FAILED = 'NEW_SOLICITACAO_CORRIDA_REMOVE_FAILED';

export const NEW_SOLICITACAO_CORRIDA_APROVAR = 'NEW_SOLICITACAO_CORRIDA_APROVAR';
export const NEW_SOLICITACAO_CORRIDA_APROVAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_APROVAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_APROVAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_APROVAR_FAILED';

export const NEW_SOLICITACAO_CORRIDA_LIBERAR = 'NEW_SOLICITACAO_CORRIDA_LIBERAR';
export const NEW_SOLICITACAO_CORRIDA_LIBERAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_LIBERAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_LIBERAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_LIBERAR_FAILED';

export const NEW_SOLICITACAO_CORRIDA_REJEITAR = 'NEW_SOLICITACAO_CORRIDA_REJEITAR';
export const NEW_SOLICITACAO_CORRIDA_REJEITAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_REJEITAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_REJEITAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_REJEITAR_FAILED';

export const NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR = 'NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR';
export const NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_REJEITAR_ENCERRAR_FAILED';

export const NEW_SOLICITACAO_ADD_PARADA = 'NEW_SOLICITACAO_ADD_PARADA';

export const NEW_SOLICITACAO_CORRIDA_NEW = 'NEW_SOLICITACAO_CORRIDA_NEW';

export const EVENTOS_SOLICITACAO = 'EVENTOS_SOLICITACAO';
export const EVENTOS_SOLICITACAO_SUCCESS = 'EVENTOS_SOLICITACAO_SUCCESS';
export const EVENTOS_SOLICITACAO_FAILED = 'EVENTOS_SOLICITACAO_FAILED';

export const SHOW_MODAL_EVENTOS_NEW_SOLICITACAO = 'SHOW_MODAL_EVENTOS_NEW_SOLICITACAO';

export const NEW_SOLICITACAO_CORRIDA_REABRIR = 'NEW_SOLICITACAO_CORRIDA_REABRIR';
export const NEW_SOLICITACAO_CORRIDA_REABRIR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_REABRIR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_REABRIR_FAILED = 'NEW_SOLICITACAO_CORRIDA_REABRIR_FAILED';

export const NEW_SOLICITACAO_CORRIDA_REENVIAR = 'NEW_SOLICITACAO_CORRIDA_REENVIA';
export const NEW_SOLICITACAO_CORRIDA_REENVIAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_REENVIAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_REENVIAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_REENVIAR_FAILED';

export const NEW_SOLICITACAO_CORRIDA_FINALIZAR = 'NEW_SOLICITACAO_CORRIDA_FINALIZAR';
export const NEW_SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_FINALIZAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_FINALIZAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_FINALIZAR_FAILED';

export const NEW_SOLICITACAO_CORRIDA_CANCELAR = 'NEW_SOLICITACAO_CORRIDA_CANCELAR';
export const NEW_SOLICITACAO_CORRIDA_CANCELAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_CANCELAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_CANCELAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_CANCELAR_FAILED';

export const NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO = 'NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO';
export const NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_SUCCESS =
    'NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_FAILED =
    'NEW_SOLICITACAO_CORRIDA_SOLICITAR_CANCELAMENTO_FAILED';

export const NEW_SOLICITACAO_CORRIDA_EM_FILA = 'NEW_SOLICITACAO_CORRIDA_EM_FILA';
export const NEW_SOLICITACAO_CORRIDA_EM_FILA_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_EM_FILA_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_EM_FILA_FAILED = 'NEW_SOLICITACAO_CORRIDA_EM_FILA_FAILED';

export const HANDLE_NEW_REFERENCIA_PARTIDA = 'HANDLE_NEW_REFERENCIA_PARTIDA';

export const NEW_SOLICITACAO_CORRIDA_ENCERRAR = 'NEW_SOLICITACAO_CORRIDA_ENCERRAR';
export const NEW_SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS = 'NEW_SOLICITACAO_CORRIDA_ENCERRAR_SUCCESS';
export const NEW_SOLICITACAO_CORRIDA_ENCERRAR_FAILED = 'NEW_SOLICITACAO_CORRIDA_ENCERRAR_FAILED';
