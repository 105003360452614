import { 
    ATUALIZACAODOCUMENTO_LIST,
    ATUALIZACAODOCUMENTO_LIST_SUCCESS,
    ATUALIZACAODOCUMENTO_LIST_FAILED,
    ATUALIZACAODOCUMENTO_ADD,
    ATUALIZACAODOCUMENTO_ADD_SUCCESS,
    ATUALIZACAODOCUMENTO_ADD_FAILED,
    ATUALIZACAODOCUMENTO_UPDATE,
    ATUALIZACAODOCUMENTO_UPDATE_SUCCESS,
    ATUALIZACAODOCUMENTO_UPDATE_FAILED,
    ATUALIZACAODOCUMENTO_LOAD,
    ATUALIZACAODOCUMENTO_LOAD_SUCCESS,
    ATUALIZACAODOCUMENTO_LOAD_FAILED,
    ATUALIZACAODOCUMENTO_REMOVE,
    ATUALIZACAODOCUMENTO_REMOVE_SUCCESS,
    ATUALIZACAODOCUMENTO_REMOVE_FAILED,
    ATUALIZACAODOCUMENTO_UPLOAD,
    ATUALIZACAODOCUMENTO_UPLOAD_SUCCESS,
    ATUALIZACAODOCUMENTO_UPLOAD_FAILED,
    ATUALIZACAODOCUMENTO_DOWNLOAD,
    ATUALIZACAODOCUMENTO_DOWNLOAD_SUCCESS,
    ATUALIZACAODOCUMENTO_DOWNLOAD_FAILED,
    ATUALIZACAODOCUMENTO_REMOVEANEXO,
    ATUALIZACAODOCUMENTO_REMOVEANEXO_SUCCESS,
    ATUALIZACAODOCUMENTO_REMOVEANEXO_FAILED
} from './constants';

// List
export const atualizacaoDocumentoList        = (filter) => ({ type: ATUALIZACAODOCUMENTO_LIST, payload: filter,});
export const atualizacaoDocumentoListSuccess = (atualizacaoDocumentos) => ({ type: ATUALIZACAODOCUMENTO_LIST_SUCCESS, payload: { atualizacaoDocumentos } });
export const atualizacaoDocumentoListFailed  = (error) => ({ type: ATUALIZACAODOCUMENTO_LIST_FAILED, payload: error  });

export const atualizacaoDocumentoLoad        = (id) => ({ type: ATUALIZACAODOCUMENTO_LOAD, payload: { id } });
export const atualizacaoDocumentoLoadSuccess  = (atualizacaoDocumento) => ({ type: ATUALIZACAODOCUMENTO_LOAD_SUCCESS, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoLoadFailed   = (error) => ({ type: ATUALIZACAODOCUMENTO_LOAD_FAILED, payload: error  });

// Add
export const atualizacaoDocumentoAdd         = (atualizacaoDocumento) => ({ type: ATUALIZACAODOCUMENTO_ADD, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoAddSuccess  = (atualizacaoDocumento, atualizacaoDocumentoStatus) => ({ type: ATUALIZACAODOCUMENTO_ADD_SUCCESS, atualizacaoDocumentoStatus: atualizacaoDocumentoStatus, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoAddFailed   = (error) => ({ type: ATUALIZACAODOCUMENTO_ADD_FAILED, payload: error  });

// Update
export const atualizacaoDocumentoUpdate         = (atualizacaoDocumento) => ({ type: ATUALIZACAODOCUMENTO_UPDATE, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoUpdateSuccess  = (atualizacaoDocumento, atualizacaoDocumentoStatus) => ({ type: ATUALIZACAODOCUMENTO_UPDATE_SUCCESS, atualizacaoDocumentoStatus: atualizacaoDocumentoStatus, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoUpdateFailed   = (error) => ({ type: ATUALIZACAODOCUMENTO_UPDATE_FAILED, payload: error  });

// remove
export const atualizacaoDocumentoRemove         = (atualizacaoDocumento) => ({ type: ATUALIZACAODOCUMENTO_REMOVE, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoRemoveSuccess  = (atualizacaoDocumento, atualizacaoDocumentoDeletado) => ({ type: ATUALIZACAODOCUMENTO_REMOVE_SUCCESS, atualizacaoDocumentoDeletado: atualizacaoDocumentoDeletado, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoRemoveFailed   = (error) => ({ type: ATUALIZACAODOCUMENTO_REMOVE_FAILED, payload: error  });

// upload files
export const atualizacaoDocumentoUpload         = (atualizacaoDocumentoId, arquivo) => ({ type: ATUALIZACAODOCUMENTO_UPLOAD, payload: { atualizacaoDocumentoId, arquivo } });
export const atualizacaoDocumentoUploadSuccess  = (atualizacaoDocumento, atualizacaoDocumentoStatus) => ({ type: ATUALIZACAODOCUMENTO_UPLOAD_SUCCESS, atualizacaoDocumentoStatus: atualizacaoDocumentoStatus, payload: { atualizacaoDocumento } });
export const atualizacaoDocumentoUploadFailed   = (error) => ({ type: ATUALIZACAODOCUMENTO_UPLOAD_FAILED, payload: error  });

// Download files
export const atualizacaoDocumentoDownload         = (arquivoId, nomeArquivo) => ({ type: ATUALIZACAODOCUMENTO_DOWNLOAD, payload: { arquivoId, nomeArquivo } });
export const atualizacaoDocumentoDownloadSuccess  = (atualizacaoDocumentoStatus) => ({ type: ATUALIZACAODOCUMENTO_DOWNLOAD_SUCCESS, atualizacaoDocumentoStatus: atualizacaoDocumentoStatus });
export const atualizacaoDocumentoDownloadFailed   = (error) => ({ type: ATUALIZACAODOCUMENTO_DOWNLOAD_FAILED, payload: error  });

// Remove files
export const atualizacaoDocumentoRemoveAnexo         = (arquivoId) => ({ type: ATUALIZACAODOCUMENTO_REMOVEANEXO, payload: { arquivoId } });
export const atualizacaoDocumentoRemoveAnexoSuccess  = (arquivoId) => ({ type: ATUALIZACAODOCUMENTO_REMOVEANEXO_SUCCESS, payload: arquivoId });
export const atualizacaoDocumentoRemoveAnexoFailed   = (error) => ({ type: ATUALIZACAODOCUMENTO_REMOVEANEXO_FAILED, payload: error  });