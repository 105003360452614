/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ItemWithIcon from '../../_menus-items/item-with-icon';

import {
    SectionRegistrations_Container,
    SectionRegistrations_Label,
    SectionRegistrations_Items,
} from '../../navbar-menu';
import { URL_PORTAL_V2 } from '../../../../helpers/url';

// -> Icon <--------------------------------------------------------------------------------------

const IconContract = () => {
    return (
        <svg viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_2691)">
                <path d="M21.6667 24.8334H4.33333C4.04602 24.8334 3.77047 24.7192 3.5673 24.5161C3.36414 24.3129 3.25 24.0373 3.25 23.75V4.25002C3.25 3.9627 3.36414 3.68715 3.5673 3.48399C3.77047 3.28082 4.04602 3.16669 4.33333 3.16669H21.6667C21.954 3.16669 22.2295 3.28082 22.4327 3.48399C22.6359 3.68715 22.75 3.9627 22.75 4.25002V23.75C22.75 24.0373 22.6359 24.3129 22.4327 24.5161C22.2295 24.7192 21.954 24.8334 21.6667 24.8334ZM7.58333 7.50002V11.8334H11.9167V7.50002H7.58333ZM7.58333 14V16.1667H18.4167V14H7.58333ZM7.58333 18.3334V20.5H18.4167V18.3334H7.58333ZM14.0833 8.58335V10.75H18.4167V8.58335H14.0833Z" />
            </g>
            <defs>
                <clipPath id="clip0_13_2691">
                    <rect width="26" height="26" transform="translate(0 0.883423)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const IconTypeOfOperation = () => {
    return (
        <svg viewBox="0 0 34 37" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2056 18.8614C14.3887 18.6021 15.6102 18.6015 16.7935 18.8596C16.8723 19.4033 17.0674 19.9215 17.3642 20.3756C17.6611 20.8297 18.052 21.208 18.508 21.4825C18.9637 21.7579 19.4729 21.9225 19.9978 21.9639C20.5227 22.0053 21.0498 21.9225 21.5398 21.7217C22.3452 22.6639 22.9552 23.77 23.3316 24.9706C22.9206 25.3139 22.5889 25.7495 22.3611 26.2452C22.1334 26.7409 22.0154 27.2838 22.0161 27.8336C22.0161 28.992 22.53 30.0249 23.3333 30.6967C22.9548 31.8962 22.344 33.0015 21.539 33.9437C21.049 33.743 20.5221 33.6603 19.9974 33.7018C19.4726 33.7432 18.9636 33.9076 18.508 34.1829C18.0524 34.4573 17.6617 34.8354 17.3651 35.2892C17.0684 35.7429 16.8733 36.2607 16.7944 36.804C15.6115 37.0639 14.3899 37.0651 13.2065 36.8077C13.1281 36.2635 12.9332 35.7447 12.6363 35.2901C12.3395 34.8355 11.9483 34.4568 11.492 34.182C11.0362 33.9067 10.527 33.7423 10.0021 33.7011C9.47719 33.6598 8.95015 33.7428 8.46015 33.9437C7.6547 33.0013 7.04466 31.8948 6.66842 30.6939C7.0792 30.3508 7.41077 29.9154 7.63851 29.4201C7.86625 28.9248 7.98431 28.3822 7.98393 27.8327C7.98436 27.2827 7.86616 26.7397 7.6381 26.244C7.41004 25.7483 7.078 25.3128 6.66667 24.9697C7.04524 23.7701 7.65604 22.6648 8.46103 21.7226C8.95097 21.9233 9.47789 22.006 10.0026 21.9646C10.5274 21.9231 11.0364 21.7587 11.492 21.4834C11.9476 21.209 12.3383 20.8309 12.6349 20.3771C12.9316 19.9234 13.1267 19.4047 13.2056 18.8614ZM15 30.583C15.6978 30.583 16.367 30.2934 16.8604 29.7777C17.3538 29.2621 17.631 28.5628 17.631 27.8336C17.631 27.1044 17.3538 26.4051 16.8604 25.8895C16.367 25.3739 15.6978 25.0842 15 25.0842C14.3022 25.0842 13.633 25.3739 13.1396 25.8895C12.6462 26.4051 12.369 27.1044 12.369 27.8336C12.369 28.5628 12.6462 29.2621 13.1396 29.7777C13.633 30.2934 14.3022 30.583 15 30.583Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 16.9998H6.66667V19.7465C6.38724 19.6892 6.11241 19.6071 5.84544 19.5006C4.71845 20.7854 3.86334 22.2926 3.33333 23.9284C3.90921 24.3962 4.37406 24.9902 4.69334 25.6661C5.01262 26.3421 5.17811 27.0825 5.17751 27.8325C5.17803 28.5819 5.01275 29.3218 4.69391 29.9972C4.37508 30.6726 3.91089 31.2663 3.33579 31.7342C3.36439 31.8231 3.39397 31.9117 3.42449 31.9998H0V16.9998ZM23.3333 10.3332H30V31.9998H26.5787C26.6089 31.9129 26.6383 31.8256 26.6667 31.7379C25.542 30.8219 24.8225 29.4134 24.8225 27.8338C24.8216 27.084 24.9867 26.3436 25.3055 25.6677C25.6244 24.9918 26.0888 24.3977 26.6642 23.9296C26.1373 22.2925 25.2833 20.7841 24.1558 19.4993C23.8884 19.606 23.6132 19.6883 23.3333 19.7456V10.3332Z"
            />
            <path d="M18.3333 0.33316H11.6667V17.6987L11.6889 17.6659C12.1042 17.0471 12.3773 16.3398 12.4879 15.5989C14.1441 15.2454 15.8543 15.2446 17.5109 15.5964C17.6212 16.3379 17.8943 17.0445 18.3099 17.6637L18.3333 17.6983V0.33316Z" />
        </svg>
    );
};

const IconShifts = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.8333 34.9999C27.6116 34.9999 25 32.3883 25 29.1666C25 25.9448 27.6116 23.3333 30.8333 23.3333C34.0551 23.3333 36.6667 25.9448 36.6667 29.1666C36.6667 32.3883 34.0551 34.9999 30.8333 34.9999ZM31.4167 29.1666V26.2499H30.25V30.3333H33.75V29.1666H31.4167Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6667 1.66656H18.3334V6.66656H21.6667V1.66656ZM21.6667 38.3332V33.3332H18.3334V38.3332H21.6667ZM8.21508 5.85823L5.85841 8.2149L9.39341 11.7499L11.7501 9.39323L8.21508 5.85823ZM34.1417 8.2149L31.7851 5.85656V5.85823L28.2501 9.39323L30.6067 11.7499L34.1417 8.2149ZM11.7501 30.6066L9.39341 28.2499L5.85841 31.7849L8.21508 34.1416L11.7501 30.6066ZM33.3334 21.6666H38.3334V18.3332H33.3334V21.6666ZM6.66675 21.6666V18.3332H1.66675V21.6666H6.66675Z"
            />
            <path d="M12.929 27.071C14.8044 28.9463 17.3479 29.9999 20.0001 29.9999C20.571 29.9999 21.137 29.9511 21.6923 29.8557C21.6754 29.6282 21.6667 29.3984 21.6667 29.1666C21.6667 28.8247 21.6855 28.4873 21.7219 28.1551C21.8947 26.5806 22.4663 25.126 23.3334 23.8946C23.4717 23.6983 23.6175 23.5076 23.7704 23.3229C19.8125 23.1169 16.6667 19.8424 16.6667 15.8332C16.6667 14.4239 17.0554 13.1054 17.7315 11.9791C18.4527 11.7755 19.2137 11.6666 20.0001 11.6666C24.6025 11.6666 28.3334 15.3975 28.3334 19.9999C28.3334 20.1161 28.331 20.2318 28.3263 20.3469C28.8656 20.1939 29.4251 20.0891 30 20.0373L30.0001 19.9999C30.0001 17.3477 28.9465 14.8042 27.0711 12.9288C25.1958 11.0535 22.6522 9.9999 20.0001 9.9999C17.3479 9.9999 14.8044 11.0535 12.929 12.9288C11.0536 14.8042 10.0001 17.3477 10.0001 19.9999C10.0001 22.6521 11.0536 25.1956 12.929 27.071Z" />
        </svg>
    );
};

const IconCompany = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 35.9455H23.6364V9.5911L35.2618 15.1458C35.5891 15.3022 35.868 15.5623 36.0638 15.8939C36.2597 16.2255 36.364 16.6138 36.3636 17.0109V35.9455H40V40H0V35.9455H3.63636V8.88156C3.63632 8.48932 3.73833 8.10548 3.93003 7.7766C4.12172 7.44771 4.39488 7.18789 4.71636 7.02864L18.7218 0.0873021C18.8604 0.0186668 19.0121 -0.010239 19.1633 0.00321583C19.3144 0.0166707 19.4601 0.0720589 19.5872 0.164339C19.7142 0.256619 19.8185 0.382859 19.8906 0.531569C19.9627 0.680279 20.0003 0.846736 20 1.01579V35.9455Z"
                fill="#F0F2F4"
            />
        </svg>
    );
};

const IconSLA = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
                d="M11 .543c.33-.029.663-.043 1-.043C18.351.5 23.5 5.649 23.5 12c0 .337-.014.67-.043 1h-1.506c-.502 5.053-4.766 9-9.951 9-5.523 0-10-4.477-10-10 0-5.185 3.947-9.449 9-9.95V.542zM11 13V4.062A8.001 8.001 0 0 0 12 20a8.001 8.001 0 0 0 7.938-7H11zm10.448-2A9.503 9.503 0 0 0 13 2.552V11h8.448z"
                fill="rgba(240,242,244,1)"
            />
        </svg>
    );
};

const IconOrganization = () => {
    return (
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9999 18.3333C22.2101 18.3333 24.3297 19.2112 25.8925 20.774C27.4553 22.3368 28.3333 24.4564 28.3333 26.6666V36.6666H11.6666V26.6666C11.6666 24.4564 12.5446 22.3368 14.1074 20.774C15.6702 19.2112 17.7898 18.3333 19.9999 18.3333ZM8.81325 23.3433C8.54802 24.2379 8.39133 25.1612 8.34659 26.0933L8.33325 26.6666V36.6666H3.33325V29.1666C3.33292 27.7292 3.8633 26.3423 4.82264 25.2719C5.78199 24.2016 7.10275 23.5231 8.53159 23.3666L8.81492 23.3433H8.81325ZM31.1866 23.3433C32.6698 23.4337 34.0626 24.0865 35.0809 25.1686C36.0993 26.2508 36.6664 27.6807 36.6666 29.1666V36.6666H31.6666V26.6666C31.6666 25.5116 31.4999 24.3966 31.1866 23.3433ZM9.16659 13.3333C10.2717 13.3333 11.3315 13.7722 12.1129 14.5536C12.8943 15.335 13.3333 16.3949 13.3333 17.4999C13.3333 18.605 12.8943 19.6648 12.1129 20.4462C11.3315 21.2276 10.2717 21.6666 9.16659 21.6666C8.06152 21.6666 7.00171 21.2276 6.22031 20.4462C5.43891 19.6648 4.99992 18.605 4.99992 17.4999C4.99992 16.3949 5.43891 15.335 6.22031 14.5536C7.00171 13.7722 8.06152 13.3333 9.16659 13.3333ZM30.8333 13.3333C31.9383 13.3333 32.9981 13.7722 33.7795 14.5536C34.5609 15.335 34.9999 16.3949 34.9999 17.4999C34.9999 18.605 34.5609 19.6648 33.7795 20.4462C32.9981 21.2276 31.9383 21.6666 30.8333 21.6666C29.7282 21.6666 28.6684 21.2276 27.887 20.4462C27.1056 19.6648 26.6666 18.605 26.6666 17.4999C26.6666 16.3949 27.1056 15.335 27.887 14.5536C28.6684 13.7722 29.7282 13.3333 30.8333 13.3333ZM19.9999 3.33325C21.768 3.33325 23.4637 4.03563 24.714 5.28587C25.9642 6.53612 26.6666 8.23181 26.6666 9.99992C26.6666 11.768 25.9642 13.4637 24.714 14.714C23.4637 15.9642 21.768 16.6666 19.9999 16.6666C18.2318 16.6666 16.5361 15.9642 15.2859 14.714C14.0356 13.4637 13.3333 11.768 13.3333 9.99992C13.3333 8.23181 14.0356 6.53612 15.2859 5.28587C16.5361 4.03563 18.2318 3.33325 19.9999 3.33325Z" />
        </svg>
    );
};

const SectionRegistrations = () => {
    return (
        <SectionRegistrations_Container>
            <SectionRegistrations_Label>Cadastros</SectionRegistrations_Label>
            <SectionRegistrations_Items>
                <>
                    <ItemWithIcon link="/contrato" label="Contratos" disable={false}>
                        <IconContract />
                    </ItemWithIcon>

                    <ItemWithIcon link="/tipooperacao" label="Tipos de Operações" disable={false}>
                        <IconTypeOfOperation />
                    </ItemWithIcon>

                    <ItemWithIcon link="/turno" label="Turnos" disable={false}>
                        <IconShifts />
                    </ItemWithIcon>

                    <ItemWithIcon
                        link="/admin/dominio"
                        label="Organizações local"
                        disable={false}
                    >
                        <IconOrganization />
                    </ItemWithIcon>

                    <ItemWithIcon
                        link={`${URL_PORTAL_V2}/contracts-panel/_registrations/organizations`}
                        label="Organizações Identity"
                        disable={false}>
                        <IconOrganization />
                    </ItemWithIcon>

                    <ItemWithIcon
                        link={`${URL_PORTAL_V2}/contracts-panel/_registrations/sla`}
                        label="SLA"
                        disable={true}>
                        <IconSLA />
                    </ItemWithIcon>
                </>
            </SectionRegistrations_Items>
        </SectionRegistrations_Container>
    );
};

export default SectionRegistrations;
