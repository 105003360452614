import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { USUARIO_LOAD, USUARIO_LIST, USUARIO_ADD, USUARIO_UPDATE, USUARIO_REMOVE } from './constants';

import {
    usuarioListSuccess,
    usuarioListFailed,
    usuarioAddSuccess,
    usuarioAddFailed,
    usuarioUpdateSuccess,
    usuarioUpdateFailed,
    usuarioLoadSuccess,
    usuarioLoadFailed,
    usuarioRemoveSuccess,
    usuarioRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* usuarioLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/usuario/' + id, options);
        yield put(usuarioLoadSuccess(response));
    } catch (error) {
        yield put(usuarioLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* usuarioList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/admin/usuario/filter', options);
            yield put(usuarioListSuccess(response));
        } catch (error) {
            yield put(usuarioListFailed(error.message));
        }    
}


/**
 * Usuario
 * @param {*} payload - pageNumber and pageSize
 */
function* usuarioAdd({ payload: usuario  }) {

    const options = {
        body: JSON.stringify(usuario) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/usuario', options);
        yield put(usuarioAddSuccess(response));
    } catch (error) {
        yield put(usuarioAddFailed(error.message));
    }    
}

/**
 * Usuario
 * @param {*} payload - pageNumber and pageSize
 */
function* usuarioUpdate({ payload: usuario  }) {

    const options = {
        body: JSON.stringify(usuario) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/usuario/' + usuario?.id, options);
        yield put(usuarioUpdateSuccess(response));
    } catch (error) {
        yield put(usuarioUpdateFailed(error.message));
    }    
}

/**
 * Usuario
 * @param {*} payload - pageNumber and pageSize
 */
function* usuarioRemove({ payload: usuario  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/admin/usuario/' + usuario?.id, options);
        yield put(usuarioRemoveSuccess({}));
    } catch (error) {
        yield put(usuarioRemoveFailed(error.message));
    }    
}

export function* watchusuarioLoad(): any{
    yield takeEvery(USUARIO_LOAD, usuarioLoad);
}

export function* watchUsuarioList(): any {
    yield takeEvery(USUARIO_LIST, usuarioList);
}

export function* watchUsuarioAdd(): any {
    yield takeEvery(USUARIO_ADD, usuarioAdd);
}

export function* watchUsuarioUpdate(): any {
    yield takeEvery(USUARIO_UPDATE, usuarioUpdate);
}

export function* watchUsuarioRemove(): any {
    yield takeEvery(USUARIO_REMOVE, usuarioRemove);
}

function* usuario(): any {
    yield all([fork(watchUsuarioList), fork(watchUsuarioAdd), fork(watchUsuarioUpdate), fork(watchusuarioLoad), fork(watchUsuarioRemove)]);
}

export default usuario;