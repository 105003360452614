import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { ESTADO_LOAD, ESTADO_LIST, ESTADO_ADD, ESTADO_UPDATE, ESTADO_REMOVE } from './constants';

import {
    estadoListSuccess,
    estadoListFailed,
    estadoAddSuccess,
    estadoAddFailed,
    estadoUpdateSuccess,
    estadoUpdateFailed,
    estadoLoadSuccess,
    estadoLoadFailed,
    estadoRemoveSuccess,
    estadoRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* estadoLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/estado/' + id, options);
        yield put(estadoLoadSuccess(response));
    } catch (error) {
        yield put(estadoLoadFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* estadoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/estado/filter', options);
            yield put(estadoListSuccess(response));
        } catch (error) {
            yield put(estadoListFailed(error.message));
        }    
}


/**
 * estado
 * @param {*} payload - pageNumber and pageSize
 */
function* estadoAdd({ payload: estado  }) {

    const options = {
        body: JSON.stringify(estado) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/estado', options);
        yield put(estadoAddSuccess(response));
    } catch (error) {
        yield put(estadoAddFailed(error.message));
    }    
}

/**
 * estado
 * @param {*} payload - pageNumber and pageSize
 */
function* estadoUpdate({ payload: estado  }) {

    const options = {
        body: JSON.stringify(estado) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/estado/' + estado?.id, options);
        yield put(estadoUpdateSuccess(response));
    } catch (error) {
        yield put(estadoUpdateFailed(error.message));
    }    
}

/**
 * estado
 * @param {*} payload - pageNumber and pageSize
 */
function* estadoRemove({ payload: estado  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/estado/' + estado?.id, options);
        yield put(estadoRemoveSuccess({}));
    } catch (error) {
        yield put(estadoRemoveFailed(error.message));
    }    
}

export function* watchEstadoLoad(): any{
    yield takeEvery(ESTADO_LOAD, estadoLoad);
}

export function* watchEstadoList(): any {
    yield takeEvery(ESTADO_LIST, estadoList);
}

export function* watchEstadoAdd(): any {
    yield takeEvery(ESTADO_ADD, estadoAdd);
}

export function* watchEstadoUpdate(): any {
    yield takeEvery(ESTADO_UPDATE, estadoUpdate);
}

export function* watchEstadoRemove(): any {
    yield takeEvery(ESTADO_REMOVE, estadoRemove);
}

function* estado(): any {
    yield all([fork(watchEstadoList), fork(watchEstadoAdd), fork(watchEstadoUpdate), fork(watchEstadoLoad), fork(watchEstadoRemove)]);
}

export default estado;