import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { CENTRO_CUSTO_LOAD, CENTRO_CUSTO_LIST, CENTRO_CUSTO_ADD, CENTRO_CUSTO_UPDATE, CENTRO_CUSTO_REMOVE, CENTRO_CUSTO_LIST_NIVEL, CENTRO_CUSTO_LIST_RECURSIVO_USUARIO } from './constants';

import {
    centroCustoListSuccess,
    centroCustoListFailed,
    centroCustoAddSuccess,
    centroCustoAddFailed,
    centroCustoUpdateSuccess,
    centroCustoUpdateFailed,
    centroCustoLoadSuccess,
    centroCustoLoadFailed,
    centroCustoRemoveSuccess,
    centroCustoRemoveFailed,
    centroCustoListNivelSuccess,
    centroCustoListNivelFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/centroCusto/' + id, options);
        yield put(centroCustoLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(centroCustoLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/centroCusto/filter', options);
            yield put(centroCustoListSuccess(response));
        } catch (error) {
            yield put(centroCustoListFailed(error.message));
        }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoListNivel({ payload: nivel }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/centroCusto/nivel?nivel=${nivel}`, options);
        yield put(centroCustoListNivelSuccess(response));
    } catch (error) {
        yield put(centroCustoListNivelFailed(error.message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoLisRecursivoPorUsuario({ payload: usuarioId }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, `/central/v1/api/v1/centroCusto/recursivo/usuario/${usuarioId}`, options);
        yield put(centroCustoListNivelSuccess(response));
    } catch (error) {
        yield put(centroCustoListNivelFailed(error.message));
    }    
}

/**
 * centroCusto
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoAdd({ payload: { centroCusto }  }) {

    const options = {
        body: JSON.stringify(centroCusto) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/centroCusto', options);
        yield put(centroCustoAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(centroCustoAddFailed(message));
    }    
}

/**
 * centroCusto
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoUpdate({ payload: { centroCusto }  }) {

    const options = {
        body: JSON.stringify(centroCusto) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/centroCusto/' + centroCusto?.id, options);
        yield put(centroCustoUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(centroCustoUpdateFailed(message));
    }    
}

/**
 * centroCusto
 * @param {*} payload - pageNumber and pageSize
 */
function* centroCustoRemove({ payload: centroCustoId  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/centroCusto/' + centroCustoId, options);
        yield put(centroCustoRemoveSuccess(centroCustoId));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(centroCustoRemoveFailed(message));
    }    
}

export function* watchCentroCustoLoad(): any{
    yield takeEvery(CENTRO_CUSTO_LOAD, centroCustoLoad);
}

export function* watchCentroCustoList(): any {
    yield takeEvery([CENTRO_CUSTO_LIST], centroCustoList);
}

export function* watchCentroCustoAdd(): any {
    yield takeEvery(CENTRO_CUSTO_ADD, centroCustoAdd);
}

export function* watchCentroCustoUpdate(): any {
    yield takeEvery(CENTRO_CUSTO_UPDATE, centroCustoUpdate);
}

export function* watchCentroCustoRemove(): any {
    yield takeEvery(CENTRO_CUSTO_REMOVE, centroCustoRemove);
}

export function* watchCentroCustoListNivel(): any {
    yield takeEvery(CENTRO_CUSTO_LIST_NIVEL, centroCustoListNivel);
}

export function* watchCentroCustoListRecursivoPorUsuario(): any {
    yield takeEvery(CENTRO_CUSTO_LIST_RECURSIVO_USUARIO, centroCustoLisRecursivoPorUsuario);
}

function* centroCusto(): any {
    yield all([
        fork(watchCentroCustoAdd), 
        fork(watchCentroCustoUpdate), 
        fork(watchCentroCustoLoad), 
        fork(watchCentroCustoRemove), 
        fork(watchCentroCustoList),
        fork(watchCentroCustoListNivel),
        fork(watchCentroCustoListRecursivoPorUsuario)
    ]);
}

export default centroCusto;