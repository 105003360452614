
import { EMPRESA_LOAD, EMPRESA_LOAD_SUCCESS, EMPRESA_LOAD_FAILED, EMPRESA_LIST, EMPRESA_LIST_SUCCESS, EMPRESA_LIST_FAILED, EMPRESA_ADD, EMPRESA_ADD_FAILED, EMPRESA_ADD_SUCCESS, EMPRESA_REMOVE, EMPRESA_REMOVE_FAILED, EMPRESA_REMOVE_SUCCESS, EMPRESA_UPDATE, EMPRESA_UPDATE_SUCCESS, EMPRESA_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    empresas: {},
    empresa: {},
    loading: false,
    error: null,
    empresaStatus: null,
    id: {}
};

const EmpresaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case EMPRESA_LIST:
            return { ...state, loading: true, error: null, empresaDeletado: null };
        case EMPRESA_LIST_SUCCESS:
            return { ...state, empresas: action.payload, loading: false, error: null, empresaDeletado: null };
        case EMPRESA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, empresaDeletado: null };
        case EMPRESA_LOAD:
            return { ...state, empresa: {}, loading: true, error: null, empresaStatus: null, empresaDeletado: null }
        case EMPRESA_LOAD_SUCCESS:
            return { ...state, empresa: action.payload, loading: false, error: null, empresaDeletado: null}
        case EMPRESA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, empresaDeletado: null };
        case EMPRESA_ADD:
            return { ...state, empresa: action.payload, empresaStatus: null, loading: true, error: null, empresaDeletado: null}
        case EMPRESA_ADD_SUCCESS:
            return { ...state, empresa: action.payload,  empresaStatus: "Empresa adicionado com sucesso", empresaDeletado: null, loading: false, error: null };
        case EMPRESA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case EMPRESA_UPDATE:
            return { ...state, empresa: action.payload, empresaStatus: null, loading: true, error: null}
        case EMPRESA_UPDATE_SUCCESS:
            return { ...state, empresa: action.payload,  empresaStatus: "Empresa atualizado com sucesso", empresaDeletado: null, loading: false, error: null };
        case EMPRESA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, empresaStatus: null };
        case EMPRESA_REMOVE:
            return { ...state, empresa: action.payload, empresaStatus: null, loading: true, error: null, empresaDeletado: null}
        case EMPRESA_REMOVE_SUCCESS:
            return { ...state, empresa: {}, empresaStatus: null,  empresaDeletado: "Empresa removido com sucesso", loading: false, error: null };
        case EMPRESA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, empresaStatus: null, empresaDeletado: null };
        default:
            return { ...state };
    }
};

export default EmpresaReducer;