
import { FERIADO_LOAD, FERIADO_LOAD_SUCCESS, FERIADO_LOAD_FAILED, FERIADO_LIST, FERIADO_LIST_SUCCESS, FERIADO_LIST_FAILED, FERIADO_ADD, FERIADO_ADD_FAILED, FERIADO_ADD_SUCCESS, FERIADO_REMOVE, FERIADO_REMOVE_FAILED, FERIADO_REMOVE_SUCCESS, FERIADO_UPDATE, FERIADO_UPDATE_SUCCESS, FERIADO_UPDATE_FAILED } from './constants';

const INIT_STATE = {
    feriados: {},
    feriado: {},
    loading: false,
    error: null,
    feriadoStatus: null,
    id: {}
};

const FeriadoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FERIADO_LIST:
            return { ...state, loading: true, error: null, feriadoDeletado: null };
        case FERIADO_LIST_SUCCESS:
            return { ...state, feriados: action.payload, loading: false, error: null, feriadoDeletado: null };
        case FERIADO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, feriadoDeletado: null };
        case FERIADO_LOAD:
            return { ...state, feriado: {}, loading: true, error: null, feriadoStatus: null, feriadoDeletado: null }
        case FERIADO_LOAD_SUCCESS:
            return { ...state, feriado: action.payload, loading: false, error: null, feriadoDeletado: null}
        case FERIADO_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, feriadoDeletado: null };
        case FERIADO_ADD:
            return { ...state, feriado: action.payload, feriadoStatus: null, loading: true, error: null, feriadoDeletado: null}
        case FERIADO_ADD_SUCCESS:
            return { ...state, feriado: action.payload,  feriadoStatus: "Tipo de Operação criado com sucesso", feriadoDeletado: null, loading: false, error: null };
        case FERIADO_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case FERIADO_UPDATE:
            return { ...state,  feriado: action.payload, feriadoStatus: null, loading: true, error: null}
        case FERIADO_UPDATE_SUCCESS:
            return { ...state, feriado: action.payload,  feriadoStatus: "Tipo de Operação atualizado com sucesso", feriadoDeletado: null, loading: false, error: null };
        case FERIADO_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, feriadoStatus: null };
        case FERIADO_REMOVE:
            return { ...state, feriadoId: action.payload, feriadoStatus: null, loading: true, error: null, feriadoDeletado: null}
        case FERIADO_REMOVE_SUCCESS:
            return { ...state, feriadoStatus: null,  feriadoDeletado: "Tipo de Operação removida com sucesso", loading: false, error: null };
        case FERIADO_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, feriadoStatus: null, feriadoDeletado: null };

        default:
            return { ...state };
    }
};

export default FeriadoReducer;