import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { DOMINIO_LOAD, DOMINIO_LIST, DOMINIO_ADD, DOMINIO_UPDATE, DOMINIO_REMOVE } from './constants';

import {
    dominioListSuccess,
    dominioListFailed,
    dominioAddSuccess,
    dominioAddFailed,
    dominioUpdateSuccess,
    dominioUpdateFailed,
    dominioLoadSuccess,
    dominioLoadFailed,
    dominioRemoveSuccess,
    dominioRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* dominioLoad({ payload: id }) {

    const options = {
        method: 'GET',
        headers: 
            {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/dominio/' + id, options);
        yield put(dominioLoadSuccess(response));
    } catch (error) {
        yield put(dominioLoadFailed(error.message));
    }    
}

/**
 * Lista dominios
 * @param {*} payload - pageNumber and pageSize
 */
function* dominioList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
                const response = yield call(fetchJSON, '/central/v1/api/v1/admin/dominio/filter', options);
                yield put(dominioListSuccess(response));
        } catch (error) {
            yield put(dominioListFailed(error.message));
        }    
}


/**
 * dominio
 * @param {*} payload - pageNumber and pageSize
 */
function* dominioAdd({ payload: dominio  }) {

    const options = {
        body: JSON.stringify(dominio) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/dominio', options);
        yield put(dominioAddSuccess(response));
    } catch (error) {
        yield put(dominioAddFailed(error.message));
    }    
}

/**
 * dominio
 * @param {*} payload - pageNumber and pageSize
 */
function* dominioUpdate({ payload: dominio  }) {

    const options = {
        body: JSON.stringify(dominio) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            }
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/dominio/' + dominio?.id, options);
        yield put(dominioUpdateSuccess(response));
    } catch (error) {
        yield put(dominioUpdateFailed(error.message));
    }    
}

/**
 * dominio
 * @param {*} payload - pageNumber and pageSize
 */
function* dominioRemove({ payload: dominio  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/admin/dominio/' + dominio?.id, options);
        yield put(dominioRemoveSuccess({}));
    } catch (error) {
        yield put(dominioRemoveFailed(error.message));
    }    
}

export function* watchdominioLoad(): any{
    yield takeEvery(DOMINIO_LOAD, dominioLoad);
}

export function* watchdominioList(): any {
    yield takeEvery(DOMINIO_LIST, dominioList);
}

export function* watchdominioAdd(): any {
    yield takeEvery(DOMINIO_ADD, dominioAdd);
}

export function* watchdominioUpdate(): any {
    yield takeEvery(DOMINIO_UPDATE, dominioUpdate);
}

export function* watchdominioRemove(): any {
    yield takeEvery(DOMINIO_REMOVE, dominioRemove);
}

function* dominio(): any {
    yield all([fork(watchdominioList), fork(watchdominioAdd), fork(watchdominioUpdate), fork(watchdominioLoad), fork(watchdominioRemove)]);
}

export default dominio;