
import StateManager from 'react-select';
import { SOLICITACAO_CORRIDA_FINALIZAR } from '../solicitacaocorrida/constants';
import { SOLICITACAO_PARADA_CORRIDA_NEW } from '../solicitacaoParadaCorrida/constants';
import { 
    CORRIDA_LOAD, 
    CORRIDA_LOAD_SUCCESS, 
    CORRIDA_LOAD_FAILED, 
    CORRIDA_LIST, 
    CORRIDA_LIST_SUCCESS, 
    CORRIDA_LIST_FAILED, 
    CORRIDA_ADD, 
    CORRIDA_ADD_FAILED, 
    CORRIDA_ADD_SUCCESS, 
    CORRIDA_REMOVE, 
    CORRIDA_REMOVE_FAILED, 
    CORRIDA_REMOVE_SUCCESS, 
    CORRIDA_UPDATE, 
    CORRIDA_UPDATE_SUCCESS, 
    CORRIDA_UPDATE_FAILED, 
    CORRIDA_FINALIZAR, 
    CORRIDA_FINALIZAR_SUCCESS, 
    CORRIDA_FINALIZAR_FAILED, 
    CORRIDA_CANCELAR, 
    CORRIDA_CANCELAR_SUCCESS, 
    CORRIDA_CANCELAR_FAILED, 
    CORRIDA_MODAL, 
    CORRIDA_REENVIAR,
    CORRIDA_REENVIAR_SUCCESS,
    CORRIDA_REENVIAR_FAILED,
    CORRIDA_MOTORISTA_LIST,
    CORRIDA_MOTORISTA_LIST_SUCCESS,
    CORRIDA_MOTORISTA_LIST_FAILED,
    ACOMPANHAMENTO,
    ACOMPANHAMENTO_SUCCESS,
    ACOMPANHAMENTO_FAILED} from './constants';

const INIT_STATE = {
    corridas: {},
    corrida: {},
    loading: false,
    error: null,
    corridaStatus: null,
    id: {},
    modal: false,

    /// ACOMPANHAMENTO
    acompanhamentoLoading: false,
    acompanhamentoData: [],
    acompanhamentoError: null
};

const CorridaReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CORRIDA_LIST:
            return { ...state, loading: true, error: null, corridaStatus: null, corridaDeletado: null };
        case CORRIDA_LIST_SUCCESS:
            return { ...state, corridas: action.payload, loading: false, error: null, corridaDeletado: null };
        case CORRIDA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false, corridaDeletado: null };
        case CORRIDA_LOAD:
            return { ...state, corrida: {}, loading: true, error: null, corridaStatus: null, corridaDeletado: null }
        case CORRIDA_LOAD_SUCCESS:
            return { ...state, corrida: action.payload, loading: false, error: null, corridaDeletado: null}
        case CORRIDA_LOAD_FAILED:
            return { ...state, error: action.payload, loading: false, corridaDeletado: null, corrida: {} };
        case CORRIDA_ADD:
            return { ...state, corrida: action.payload, corridaStatus: null, loading: true, error: null, corridaDeletado: null}
        case CORRIDA_ADD_SUCCESS:
            return { ...state, corrida: action.payload,  corridaStatus: "Solicitação criada com sucesso", corridaDeletado: null, loading: false, error: null };
        case CORRIDA_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CORRIDA_UPDATE:
            return { ...state, corrida: action.payload, corridaStatus: null, loading: true, error: null}
        case CORRIDA_UPDATE_SUCCESS:
            return { ...state, corrida: action.payload,  corridaStatus: "Corrida atualizada com sucesso", corridaDeletado: null, loading: false, error: null };
        case CORRIDA_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false, corridaStatus: null };
        case CORRIDA_REMOVE:
            return { ...state, corrida: action.payload, corridaStatus: null, loading: true, error: null, corridaDeletado: null}
        case CORRIDA_REMOVE_SUCCESS:
            return { ...state, corrida: {}, corridaStatus: null,  corridaDeletado: "Corrida removida com sucesso", loading: false, error: null };
        case CORRIDA_REMOVE_FAILED:
            return { ...state, error: action.payload, loading: false, corridaStatus: null, corridaDeletado: null };
        case CORRIDA_FINALIZAR:
            return { ...state, corrida: action.payload, corridaStatus: null, loading: true, error: null, corridaDeletado: null}
        case CORRIDA_FINALIZAR_SUCCESS:
            return { ...state, corrida: {}, corridaDeletado: null,  corridaStatus: null, loading: false, error: null };
        case CORRIDA_FINALIZAR_FAILED:
            return { ...state, error: action.payload, loading: false, corridaStatus: null, corridaDeletado: null };
        case CORRIDA_CANCELAR:
            return { ...state, corrida: action.payload, corridaStatus: null, loading: true, error: null, corridaDeletado: null}
        case CORRIDA_CANCELAR_SUCCESS:
            return { ...state, corrida: {}, corridaDeletado: null,  corridaStatus: null, loading: false, error: null };
        case CORRIDA_CANCELAR_FAILED:
            return { ...state, error: action.payload, loading: false, corridaStatus: null, corridaDeletado: null };

        case CORRIDA_REENVIAR:
            return { ...state, corrida: action.payload, corridaStatus: null, loading: true, error: null, corridaDeletado: null}
        case CORRIDA_REENVIAR_SUCCESS:
            return { ...state, corrida: {}, corridaDeletado: null,  corridaStatus: null, loading: false, error: null };
        case CORRIDA_REENVIAR_FAILED:
            return { ...state, error: action.payload, loading: false, corridaStatus: null, corridaDeletado: null };

        case CORRIDA_MOTORISTA_LIST:
            return { ...state, loading: true }
        case CORRIDA_MOTORISTA_LIST_SUCCESS:
            return { ...state, corridas: action.payload, loading: false, error: null }
        case CORRIDA_MOTORISTA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false }

        case CORRIDA_MODAL:
            return { ...state, modal: !state.modal }

        /// ACOMPANHAMENTO
        case ACOMPANHAMENTO:
            return { ...state, acompanhamentoLoading: true }
        case ACOMPANHAMENTO_SUCCESS:
            return { ...state, acompanhamentoData: action.payload, acompanhamentoLoading: false }
        case ACOMPANHAMENTO_FAILED:
            return { ...state, acompanhamentoError: action.payload, acompanhamentoLoading: false }

        case SOLICITACAO_PARADA_CORRIDA_NEW: 
            return { ...state, corridaStatus: null }

        case SOLICITACAO_CORRIDA_FINALIZAR: 
            return { ...state, corridaStatus: null }

        default:
            return { ...state };
    }
};

export default CorridaReducer;