/* EMPRESA */
export const EMPRESA_LIST           = 'EMPRESA_LIST';
export const EMPRESA_LIST_SUCCESS   = 'EMPRESA_LIST_SUCCESS';
export const EMPRESA_LIST_FAILED    = 'EMPRESA_LIST_FAILED';
export const EMPRESA_ADD            = 'EMPRESA_ADD';
export const EMPRESA_ADD_SUCCESS    = 'EMPRESA_ADD_SUCCESS';
export const EMPRESA_ADD_FAILED     = 'EMPRESA_ADD_FAILED';
export const EMPRESA_UPDATE         = 'EMPRESA_UPDATE';
export const EMPRESA_UPDATE_SUCCESS = 'EMPRESA_UPDATE_SUCCESS';
export const EMPRESA_UPDATE_FAILED  = 'EMPRESA_UPDATE_FAILED';
export const EMPRESA_REMOVE         = 'EMPRESA_REMOVE';
export const EMPRESA_REMOVE_SUCCESS = 'EMPRESA_REMOVE_SUCCESS';
export const EMPRESA_REMOVE_FAILED  = 'EMPRESA_REMOVE_FAILED';
export const EMPRESA_LOAD           = 'EMPRESA_LOAD'
export const EMPRESA_LOAD_SUCCESS   = 'EMPRESA_LOAD_SUCCESS';
export const EMPRESA_LOAD_FAILED    = 'EMPRESA_LOAD_FAILED';