import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../../helpers/api';

import { getLoggedInUser } from '../../../helpers/authUtils'

import { EMPRESA_LOAD, EMPRESA_LIST, EMPRESA_ADD, EMPRESA_UPDATE, EMPRESA_REMOVE } from './constants';

import {
    empresaListSuccess,
    empresaListFailed,
    empresaAddSuccess,
    empresaAddFailed,
    empresaUpdateSuccess,
    empresaUpdateFailed,
    empresaLoadSuccess,
    empresaLoadFailed,
    empresaRemoveSuccess,
    empresaRemoveFailed
} from './actions';


/**
 * Carrega usuário
 * @param {*} payload - pageNumber and pageSize
 */
function* empresaLoad({ payload: { id } }) {

    const options = {
        method: 'GET',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/empresa/' + id, options);
        yield put(empresaLoadSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = 'Seus dados podem estar incorretos.';
                break;
            case 403:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 400:
                message = 'Não foi possível seguir com a solicitação, confira se seus dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(empresaLoadFailed(message));
    }    
}

/**
 * Lista usuários
 * @param {*} payload - pageNumber and pageSize
 */
function* empresaList({ payload: filter }) {

        const options = {
            method: 'POST',
            headers: 
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getLoggedInUser().token
                },
            body: JSON.stringify(filter)
        };
    
        try {
            const response = yield call(fetchJSON, '/central/v1/api/v1/admin/Empresa/filter', options);
            yield put(empresaListSuccess(response));
        } catch (error) {
            yield put(empresaListFailed(error.message));
        }    
}


/**
 * empresa
 * @param {*} payload - pageNumber and pageSize
 */
function* empresaAdd({ payload: { empresa }  }) {

    const options = {
        body: JSON.stringify(empresa) ,
        method: 'POST',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/empresa', options);
        yield put(empresaAddSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(empresaAddFailed(message));
    }    
}

/**
 * empresa
 * @param {*} payload - pageNumber and pageSize
 */
function* empresaUpdate({ payload: { empresa }  }) {

    const options = {
        body: JSON.stringify(empresa) ,
        method: 'PUT',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        const response = yield call(fetchJSON, '/central/v1/api/v1/admin/empresa/' + empresa?.id, options);
        yield put(empresaUpdateSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            default:
                message = error.message;
        }
        yield put(empresaUpdateFailed(message));
    }    
}

/**
 * empresa
 * @param {*} payload - pageNumber and pageSize
 */
function* empresaRemove({ payload: { empresa }  }) {

    const options = {
        method: 'DELETE',
        headers: 
            { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getLoggedInUser().token
            },
    };

    try {
        yield call(fetchJSON, '/central/v1/api/v1/admin/empresa/' + empresa?.id, options);
        yield put(empresaRemoveSuccess({}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = error.message ?? 'Algo deu errado! Por favor, tente novamente.';
                break;
            case 401:
                message = error.message ?? 'Faça o login novamente.';
                break;
            case 403:
                message = 'Você não possui privilégios suficientes para realizar está ação.';
                break;
            case 400:
                message = error.message ?? 'Não foi possível seguir com a solicitação, confira se os dados estão corretos.';
                break;
            case 404:
                message = 'Não conseguimos se comunicar :(';
                break; 
            default:
                message = error.message;
        }
        yield put(empresaRemoveFailed(message));
    }    
}

export function* watchempresaLoad(): any{
    yield takeEvery(EMPRESA_LOAD, empresaLoad);
}

export function* watchempresaList(): any {
    yield takeEvery(EMPRESA_LIST, empresaList);
}

export function* watchempresaAdd(): any {
    yield takeEvery(EMPRESA_ADD, empresaAdd);
}

export function* watchempresaUpdate(): any {
    yield takeEvery(EMPRESA_UPDATE, empresaUpdate);
}

export function* watchempresaRemove(): any {
    yield takeEvery(EMPRESA_REMOVE, empresaRemove);
}

function* empresa(): any {
    yield all([fork(watchempresaList), fork(watchempresaAdd), fork(watchempresaUpdate), fork(watchempresaLoad), fork(watchempresaRemove)]);
}

export default empresa;