import { 
    PASSAGEIRO_LIST,
    PASSAGEIRO_LIST_SUCCESS,
    PASSAGEIRO_LIST_FAILED,
    PASSAGEIRO_ADD,
    PASSAGEIRO_ADD_SUCCESS,
    PASSAGEIRO_ADD_FAILED,
    PASSAGEIRO_UPDATE,
    PASSAGEIRO_UPDATE_SUCCESS,
    PASSAGEIRO_UPDATE_FAILED,
    PASSAGEIRO_LOAD,
    PASSAGEIRO_LOAD_SUCCESS,
    PASSAGEIRO_LOAD_FAILED,
    PASSAGEIRO_REMOVE,
    PASSAGEIRO_REMOVE_SUCCESS,
    PASSAGEIRO_REMOVE_FAILED,
    PASSAGEIRO_ENDERECOS,
    PASSAGEIRO_ENDERECOS_SUCCESS,
    PASSAGEIRO_ENDERECOS_FAILED
} from './constants';

// List
export const passageiroList        = (filter) => ({ type: PASSAGEIRO_LIST, payload: filter });
export const passageiroListSuccess = (passageiros) => ({ type: PASSAGEIRO_LIST_SUCCESS, payload: passageiros });
export const passageiroListFailed  = (error) => ({ type: PASSAGEIRO_LIST_FAILED, payload: error  });

export const passageiroLoad        = (id) => ({ type: PASSAGEIRO_LOAD, payload: { id } });
export const passageiroLoadSuccess  = (passageiro) => ({ type: PASSAGEIRO_LOAD_SUCCESS, payload: { passageiro } });
export const passageiroLoadFailed   = (error) => ({ type: PASSAGEIRO_LOAD_FAILED, payload: error  });

// Add
export const passageiroAdd         = (passageiro) => ({ type: PASSAGEIRO_ADD, payload: { passageiro } });
export const passageiroAddSuccess  = (passageiro, passageiroStatus) => ({ type: PASSAGEIRO_ADD_SUCCESS, passageiroStatus: passageiroStatus, payload: { passageiro } });
export const passageiroAddFailed   = (error) => ({ type: PASSAGEIRO_ADD_FAILED, payload: error  });

// Update
export const passageiroUpdate         = (passageiro) => ({ type: PASSAGEIRO_UPDATE, payload: { passageiro } });
export const passageiroUpdateSuccess  = (passageiro, passageiroStatus) => ({ type: PASSAGEIRO_UPDATE_SUCCESS, passageiroStatus: passageiroStatus, payload: { passageiro } });
export const passageiroUpdateFailed   = (error) => ({ type: PASSAGEIRO_UPDATE_FAILED, payload: error  });

// remove
export const passageiroRemove         = (passageiro) => ({ type: PASSAGEIRO_REMOVE, payload: { passageiro } });
export const passageiroRemoveSuccess  = (passageiro, passageiroDeletado) => ({ type: PASSAGEIRO_REMOVE_SUCCESS, passageiroDeletado: passageiroDeletado, payload: { passageiro } });
export const passageiroRemoveFailed   = (error) => ({ type: PASSAGEIRO_REMOVE_FAILED, payload: error  });

// enderecos
export const passageiroEnderecos         = (passageiroId, centroCustoId) => ({ type: PASSAGEIRO_ENDERECOS, payload: { passageiroId, centroCustoId } });
export const passageiroEnderecosSuccess  = (enderecos) => ({ type: PASSAGEIRO_ENDERECOS_SUCCESS,  payload: enderecos });
export const passageiroEnderecosFailed   = (error) => ({ type: PASSAGEIRO_ENDERECOS_FAILED, payload: error });